import React, { useEffect } from "react";
import { json, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Table } from "../Table/Table";
import {
  useRobot_listMutation,
  useLazyBranchListQuery,
} from "../../constants/redux/api/api";
import useUser from "../../constants/Storage/userData";
import { useState } from "react";
const RobotList = () => {
  const navigate = useNavigate();
  // const {user, setUser}=useUser();
  const [data, setdata] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [branchdata, setbranchdata] = useState([]);
  const [dropDownList, setDropDownList] = useState();
  const user = JSON.parse(localStorage.getItem("resturent_users"));

  const [branchList] = useLazyBranchListQuery();

  //RTK
  const [robotapi] = useRobot_listMutation();

  const getrobotlist = () => {
    const formdata = new URLSearchParams();
    const companyid = user?.company_id;
    formdata.append("company_id", companyid);
    robotapi(formdata)
      .unwrap()
      .then((res) => {
        const temp = res?.data[0]?.company_branch_details;
        setdata(temp);
        setfilterdata(temp);
        setDropDownList("All");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getList = () => {
    branchList()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          const temp = [...res?.data?.branches].reverse();
          // setBranch(res?.data?.branches?.id)
          setbranchdata(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getList();
  }, []);
  useEffect(() => {
    getrobotlist();
  }, []);
  const branchFilter = (value) => {
    setDropDownList(value);
    if (value == "All") {
      setdata(filterdata);
    } else {
      let finder = filterdata?.filter((item) => item?._id == value);
      setdata(finder);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Robot ID",
        accessor: "code",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Robot Name",
        accessor: "robot_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "robot_status",
        Cell: ({ value, row }) => {
          return (
            // <button

            // onClick={() =>{
            //   getstatus(row.original._id)}}
            //   className={`${
            //     value
            //       ? "btn-success"
            //       : "btn-danger"
            //   } btn`}
            // >
            //   {value ? "Active" : "Deactive"}

            // </button>
            <p>
              {value == "robot_off" ? (
                <>
                  {" "}
                  <CheckCircleIcon className="danger_icon me-1" /> De Active
                </>
              ) : (
                <>
                  <CheckCircleIcon className="active_icon me-1" /> Active
                </>
              )}
            </p>
          );
        },
      },

      {
        Header: "View",
        accessor: "action",
        Cell: ({ value, row }) => {
          console.log(row);
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/restaurant/robot-order-detail", {
                    state: { data: row.original, type: "view" },
                  })
                }
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                View
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="add-food-section">
        <div action="">
          <div className="d-flex flex-wrap">
            <div className="col-md-4 col-12 px-md-3 px-0 mb-3">
              <label className="mb-2" htmlFor="">
                Branch Name
              </label>
              <select
                className="form-control"
                placeholder="Select Branch"
                value={dropDownList}
                onChange={(e) => branchFilter(e.target.value)}
              >
                <option selected disabled value=" ">
                  Select Branch
                </option>
                <option value="All">All</option>
                {branchdata?.map((item, ind) => {
                  return (
                    <option key={ind} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}

                {/* {data.map((item,i)=>{
                  return(
                    <option value={item.id}>
                      {item?.name}
                    </option>
                  )
                })} */}
                {/* <option value={"All"}>All</option> */}
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="table-responsive menu-table side_pad my-4">
      

      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Robot ID</th>
            <th>Robot Name</th>
            <th>Branch Name</th>
            <th>Status</th>
            <th>Orders</th>
          </tr>
        </thead>
        <tbody className="">
          <tr>
            <td>01</td>
            <td> #001</td>
            <td>Dinning Bot</td>
            <td>Branch 3</td>
            <td>
              <CheckCircleIcon className="active_icon me-1" /> Active
            </td>
            <td>
              <button
                onClick={() => navigate("/restaurant/robot-order-detail")}
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td>02</td>
            <td> #002</td>
            <td>Dinning Bot</td>
            <td>Branch 1</td>
            <td>
              <ErrorIcon className="danger_icon me-1" /> Inactive
            </td>
            <td>
              <button
                onClick={() => navigate("/restaurant/robot-order-detail")}
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td>03</td>
            <td> #003</td>
            <td>Dinning Bot</td>
            <td>Branch 2</td>
            <td>
              <CheckCircleIcon className="energizing_icon me-1" />
              Energizing
            </td>
            <td>
              <button
                onClick={() => navigate("/restaurant/robot-order-detail")}
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table> */}
      <div className="table-responsive menu-table side_pad mt-4">
        <Table data={data} columns={columns} />
      </div>
    </div>
    // </div>
  );
};

export default RobotList;
