import React, { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate ,useLocation } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import {
  useCategory_addMutation,useLazyCategory_viewQuery,useCategory_updateMutation
} from "../../../constants/redux/api/api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Table } from "../../Table/Table";
import TopHeader from "../../../components/TopHeader";
import { toast } from "react-toastify";

const RestaurantAddCategory= ({ menuOpen }) => {
  const location =useLocation();

  console.log(location.state.data,"d")
  const dataid= location.state.data
  const types= location.state.type
  const editdata= location.state.data
  console.log(editdata,"type")


  const navigate = useNavigate();
  const [allCategData, setAllCategData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [addForm, setAddForm] = useState(false);
  const [data,setdata]=useState([]);
  const[name,setname]=useState("")
  const[image,setImage]=useState({})
  const[item,setitem]=useState("")
  const [NameErr,setNameErr]=useState(false)
  const [imageErr,setImageErr]=useState(false)
  const [disablebtn,setdisablebtn]=useState(false)
  const [type,settype]=useState(false)
  const [branch,setbranch]=useState("")

 
  //RTK
  const [addcategoryApi]= useCategory_addMutation();
  const [ViewCategory] =useLazyCategory_viewQuery();
  const[updateCategory]=useCategory_updateMutation()

  const handleClick = (e) => {
    e.preventDefault();
    setAddForm(true);
  };

  const  getViewCategory =()=>{
    ViewCategory(editdata?._id)
    .unwrap()
    .then(
      (res)=>{
        console.log(res,"viewres")
        let data=res?.data
      
        setbranch(res?.data?.
          company_branch_id)
        
        setname(data?.
          category_name)
          setImage(data?.image_url?.
            CategoryImage)
      }
    )
    .catch(err=>console.log(err))
  }
   

  useEffect(()=>{
    if(editdata?._id&&(types == 'edit' || types=="view")){
      
      getViewCategory()
    }
    

  },[types,editdata])
   
  const onSubmit =()=>{
    if(name?.length==0 ||(types!="edit"&&image?.size === undefined)){
      setNameErr(true);
     if(types!="edit"){setImageErr(true)}
    }else{
      setdisablebtn(true)
      const formData = new FormData();
      formData.append("category_name",name);
      if(image?.name){

        formData.append("image",image);
      }
      if(types==='edit'){
        // formData.append("company_branch_id",branch)
        const id =editdata?._id
        updateCategory({payload:formData,id})
        .unwrap()
        .then(res=>{
           navigate(-1)
           setdisablebtn(false)
          console.log(res)
          toast.success(res?.message)
        })
          .catch(err=>{
            setdisablebtn(false)
          })
        }else{
          formData.append("company_branch_id",dataid)
         addcategoryApi(formData)
         .unwrap()
         .then(res=>{
          navigate(-1)
           setdisablebtn(false)
           toast.success(res?.message)
           console.log(res)
         }
           )
           .catch(err=>{
             setdisablebtn(false)
             console.log(err)})
       }
    }
  }



 

  return (
    
    
    <div className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
      >
          <TopHeader type={types === "edit" ? "Edit_category" : "add_category"}/>
    <h3 style={{ "font-size": "25px", color: "#25aae1" }}>{types=="view"?"View Category":(types==="edit"?"Edit Category":"Add Category")}</h3> 
        <div className="add-food-section row">
            <div className="form-group col-6">
              <label>Category Name</label>
              <input
              disabled={types=="view"?true:false}
                type="text"
                placeholder="Enter category Name"
                className="form-control"
                value={name}
                onChange={(e)=>setname(e.target.value)}
              />
            {name?.length==0&&NameErr&&(<p className="errtext">Enter category name</p>)}
            </div>
            <div className="form-group col-6">
              <label>Category Image</label>
              <input
              disabled={types=="view"?true:false}
                type="file"
                placeholder="Enter Category Image"
                className="form-control"
                // value={image}
                onChange={(e)=>setImage (e.target.files[0])}  
              />
            {image.size==undefined&&imageErr&&(<p className="errtext">Select file</p>)}
            {(types=="view"||types=="edit")&&<div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                {image && (
                  <img
                    src={image}
                    style={{ width: "100px" }}
                  />
                )}
              </div>}
            </div>
            {types!="view"&&<div  onClick={onSubmit} className="form-group col-6">
                {types==="edit" ? (
                        <button 
                        disabled={disablebtn}
                        className="btn btn-primary">
                          Update
                        </button>
                    ):(
                    // <input type="submit" 
                    // disabled={disablebtn}
                    // className="btn-submit"/>
                    <button 
                    disabled={disablebtn}
                    className="btn btn-primary">
                      submit
                    </button>
               ) }
            
            </div>}
          </div>

     </div>
  );
};

export default RestaurantAddCategory;
