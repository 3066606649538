import React, { useEffect, useState } from "react";
import { HashRouter as Router } from "react-router-dom";
import ReactRoutes from "./ReactRoutes";
import "./assets/scss/styles.css";
import "./assets/scss/responcive.css";
import "./assets/scss/responcive.scss";
import "./assets/scss/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { WS_URL } from "./constants/redux/api/constants";
import { useDispatch, useSelector } from "react-redux";
import { saveAlertMessage } from "./constants/redux/slice/alertMessage";
import ErrorPopup from "./components/ErrorPopup";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { loadergif } from "./assets/img";

const App = () => {
  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state.alertMessage);
  const storage = localStorage.getItem("name");
  const ws = new WebSocket(WS_URL);
  // const ws = new WebSocket("ws:192.168.1.28:8080/");
  const [recieve, setRecieve] = useState("");
  const [loading, setLoading] = useState(false);

  // For Loader
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.api.queries).some((query) => query.status === "pending")
  );

  useEffect(() => {
    if (isSomeQueryPending) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [isSomeQueryPending]);
 

  useEffect(() => {
    ws.onopen = () => {
      console.warn("connected");
    };

    ws.onmessage = (e) => {
      const msg = e.data;
      setRecieve(msg);
    };

    // ws.send(activeTableInd);

    ws.onclose = () => {
      console.warn("web socket closed");
    };
  }, []);

  useEffect(() => {
    let split = recieve.split(":");
    if (storage == split[0] && split[1] == "home") {
      window.location.href = "/#/welcome";
    }
  }, [recieve]);

  const onCloseClick = () => {
    dispatch(saveAlertMessage(null));
  };

  return (
    <div> 
      {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
     {alertMessage && (
        <ErrorPopup alertMessage={alertMessage} onCloseClick={onCloseClick} />
      )}
    <Router> 
      <ReactRoutes />
      <ToastContainer />
    </Router>
    </div>
  );
};

export default App;
