import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar3 from "../Navbar/Navbar3";

const Layout2 = ({ toggle, menuOpen, setMenuOpen }) => {
  return (
    <div className="">
      <Navbar3 toggle={toggle} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Outlet />
    </div>
  );
};

export default Layout2;
