import { useNavigate, useLocation } from "react-router-dom";
import {
  useLazyBranch_viewQuery,
  useLazyPointViewQuery,
  usePointEditMutation,
  useTable_updateMutation,
} from "../../constants/redux/api/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const RestaurantBranchData = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const [name, setname] = useState("");
  const [nameErr, setnameErr] = useState(false);
  const [disbtn, setdisbtn] = useState(false);

  const [branchView] = useLazyBranch_viewQuery();
  const [branchUpdate] = useTable_updateMutation();
  const [pointUpdateApi] = usePointEditMutation();
  const [pointViewApi] = useLazyPointViewQuery();

  const getViewBranch = () => {
    branchView(data?._id)
      .unwrap()
      .then((res) => {
        const data = res?.data;
        setname(data?.table_name);
        // console.log(res)
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("data", data);

  const getViewPoint = () => {
    pointViewApi(data?._id)
      .unwrap()
      .then((res) => {
        const data = res?.data;
        setname(data?.point_name);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const tableupdate = () => {
    if (name.length === 0) {
      setnameErr(true);
    } else {
      setdisbtn(true);
      const id = data?._id;
      const formdata = new URLSearchParams();
      if (location?.state?.method == "point") {
        formdata.append("company_id", data?.company?._id);
        formdata.append("company_branch_id", data?.branch?.[0]?._id);
        formdata.append("point_id", data?.code);
        formdata.append("point_name", name);
        pointUpdate(formdata, id);
      } else {
        formdata.append("company_id", data?.company?.id);
        formdata.append("company_branch_id", data?.branch?._id);
        formdata.append("table_id", data?.table_id);
        formdata.append("table_name", name);
        tableUpdate(formdata, id);
      }
    }
  };

  const tableUpdate = (formdata, id) => {
    branchUpdate({ payload: formdata, id })
      .unwrap()
      .then((res) => {
        setdisbtn(false);
        navigate(-1);
        console.log(res);
        toast.success(res?.message);
      })
      .catch((err) => {
        console.log("err", err);
      });
    setdisbtn(false);
    // toast.error(err?.message)
  };

  const pointUpdate = (formdata, id) => {
    pointUpdateApi({ payload: formdata, id })
      .unwrap()
      .then((res) => {
        setdisbtn(false);
        navigate(-1);
        console.log(res);
        toast.success(res?.message);
      })
      .catch((err) => {
        console.log("err", err);
      });
    setdisbtn(false);
    // toast.error(err?.message)
  };

  useEffect(() => {
    if (data?._id) {
      if (location?.state?.method == "point") {
        getViewPoint();
      } else {
        getViewBranch();
      }
    }
  }, []);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="add-food-section">
        <div action="">
          <div className="d-flex flex-wrap">
            <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
              <label for="">Company Name</label>
              <input
                value={
                  data?.company?.company_name
                    ? data?.company?.company_name
                    : data?.company?.name
                    ? data?.company?.name
                    : ""
                }
                type="text"
                className="form-control"
                placeholder="Enter category name"
                disabled
              />
            </div>
            <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
              <label for="">Branch Name</label>
              <input
                value={
                  data?.branch?.name
                    ? data?.branch?.name
                    : data?.branch?.[0]?.name
                    ? data?.branch?.[0]?.name
                    : ""
                }
                type="text"
                className="form-control"
                placeholder="Enter category name"
                disabled
              />
            </div>

            {location?.state?.method == "point" ? (
              <>
                {" "}
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label for="">Point ID</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter category name"
                    value={data?.code ? data?.code : ""}
                    disabled
                  />
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label for="">Point Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Table name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                  {name?.length === 0 && nameErr && (
                    <p className="errtext">Enter Name</p>
                  )}
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label for="">Table ID</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter category name"
                    value={data?.code ? data?.code : ""}
                    disabled
                  />
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label for="">Table Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Table name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                  {name?.length === 0 && nameErr && (
                    <p className="errtext">Enter name</p>
                  )}
                </div>
              </>
            )}

            <div className="px-md-3 pe-3">
              <input
                disabled={disbtn}
                className="btn-submit"
                type="submit"
                onClick={() => tableupdate()}
              />
            </div>
            <div
              className="btn-outline2 cp ac-jc d-flex"
              onClick={() => {
                navigate("/restaurant/branch-details");
              }}
            >
              <p className="mb-0">Back</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantBranchData;
