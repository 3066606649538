import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { food } from "../../assets/images";
import { biryaniCategory, product } from "../../assets/img";
import moment from "moment";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {useRobots_listMutation} from "../../constants/redux/api/api"
function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={"text-nowrap"}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={"text-nowrap"}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}
const CategoryList = () => {
  const [status, setStatus] = useState(false);
  const [robotOrderList, setRobotOrderList] = useState()
  const [robots_list] = useRobots_listMutation()
  const getRobotData = async() => {
    robots_list().unwrap().then((res) => {
           setRobotOrderList(res)
    }).catch((err) => {
          console.log({message : err.message})
    })
  }
  useEffect(() => {
   
   getRobotData()
  },[])
  console.log(robotOrderList,"robotOrderList135")
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Category Name List",
        accessor: "_id",
        Cell: ({ value, row }) => {
        
          return <p>{value}</p>;
        },
      },
      {
        Header: "Category Image",
        accessor: "company_info",
        Cell: ({ value, row }) => {
          
          return <p>{value?.company_name}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                // handleStatusChange(row.original._id);
              }}
              className={`${
                value ? "bg-success" : "bg-danger"
              } px-2 py-1 rounded-1 border-0 text-white`}
            >
              {value ? "Active" : "Deactive"}
            </button>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/add-robot-order", {
                    state: { type: "edit", data: row.original },
                  })
                }
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              <button
                className="bg-warning border-0 rounded-1 p-1"
                onClick={() =>
                 {}
                }
              >
                <Delete />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="table-responsive menu-table side_pad mt-4">
      <Table columns={columns} data={robotOrderList} className="" />
    </div>
  );
};

export default CategoryList;
