export const langingPageBg = require("./landingPageBg.jpg");
export const headerBg = require("./header-bg.jpg");
export const biryaniCategory = require("./briyani-thumbnail.jpg");
export const burgerCategory = require("./burger-thumbnail.jpg");
export const pizzaCategory = require("./pizza-thumbnail.jpg");
export const drinkCategory = require("./drink-thumbnail.jpg");
export const tableImg = require("./table.png");
export const successAnimation = require("./order_placed_animation.mp4");
export const faceAnimation = require("./ezgif.com-resize.gif");
export const loadergif = require("./loader.gif");
export const warehouse = require("../images/warehouse1.png");
export const productionhouse = require("../images/productionhouse.png");

// Dashboard
export const sales = require("./dash_sales.png");
export const customer = require("./dash_customer.png");
export const product = require("./dash_products.png");
export const revenue = require("./dash_revenue.png");
