import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateTableMutation,
  useEditTableMutation,
} from "../constants/redux/api/api";

const AddTable = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;
  const [name, setName] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  //   RTK QUERY
  const [createTable] = useCreateTableMutation();
  const [editTable] = useEditTableMutation();

  const onSubmit = () => {
    if (name === "") {
      setNameErr(true);
    } else {
      setDisableBtn(true);
      createTable({ table_name: name })
        .unwrap()
        .then((res) => {
          setDisableBtn(false);
          if (res?.status === "success") {
            console.log(res);
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableBtn(false);
        });
    }
  };

  const onEditSubmit = () => {
    if (name === "") {
      setNameErr(true);
    } else {
      setDisableBtn(true);
      editTable({ id: editData?._id, payload: { table_name: name } })
        .unwrap()
        .then((res) => {
          setDisableBtn(false);
          if (res?.status === "success") {
            console.log(res);
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableBtn(false);
        });
    }
  };

  useEffect(() => {
    if (type === "edit") {
      setName(editData?.table_name);
    }
  }, [type, location, editData]);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}>
      <div className="menu-list">
        <h1>{type === "edit" ? "Edit Table" : "Add Table"}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Table Name</label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter table name"
                />
                {name === "" && nameErr && (
                  <p className="err-state">Enter table name*</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3"></div>
              <div
                onClick={
                  disableBtn ? null : type === "edit" ? onEditSubmit : onSubmit
                }
                className="px-md-3 pe-3">
                <input className="btn-submit" type="submit" />
              </div>
              <div onClick={() => navigate(-1)} class="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTable;
