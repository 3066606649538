import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCompany_robot_addMutation,
  useLazyCompanyRobotViewQuery,
  useRobotOrderBranchListMutation,
} from "../../../constants/redux/api/api";
import {
  useRobot_order_addMutation,
  useCompany_management_listMutation,
  useRobot_order_listMutation,
  useLazyGet_robotList_idQuery,
  useEdit_company_robotMutation,
  useRobots_listMutation,
  useLazyRobot_order_viewQuery,
  useLazyRobots_order_payments_listQuery,
  useRobots_order_payments_addMutation,
  useLazyRobot_list_companyQuery,
} from "../../../constants/redux/api/api";
import moment from "moment";
import { loadergif } from "../../../assets/img";
import TopHeader from "../../../components/TopHeader";
const AddBranch = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;

  //   RTK QUERY
  const [company_robot_add] = useCompany_robot_addMutation();
  const [edit_company_robot] = useEdit_company_robotMutation();
  const [robot_list_company] = useLazyRobot_list_companyQuery();
  const [robot_order_list] = useCompany_management_listMutation();
  const [robotOrderList] = useRobotOrderBranchListMutation();
  const [robotViewApi] = useLazyCompanyRobotViewQuery();

  //   state Variable declaration
  const [loading, setLoading] = useState(false);
  const [comapnyManageList, setCompanyManagementList] = useState([]);
  const [branchErr, setBranchErr] = useState(false);
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [manuFaDateErr, setManuFactDateErr] = useState(false);
  const [sFVsErr, setSFVsErr] = useState(false);
  const [dateOfPurchErr, setDateOfPurchErr] = useState(false);
  const [warrieErr, setWarrienErr] = useState(false);
  const [attachmentErr, setAttachmentErr] = useState(false);
  const [branch, setBranch] = useState("");
  const [branchData, setBranchData] = useState({});
  const [robotIdChe, setRobotIdChe] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [manuFaDate, setManuFactDate] = useState("");
  const [sFVs, setSFVs] = useState("");
  const [warrie, setWarrien] = useState("");
  const [dateOfPurch, setDateOfPurch] = useState("");
  const [attachment, setAttachment] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [robotList, setRobotList] = useState([]);
  const [robotLists, setRobotLists] = useState([]);
  const [robotNumId, setClientName] = useState("");
  const [codeName, setCodeName] = useState("");
  const [robotType, setrobotType] = useState("");
  const [robotId, setRobotId] = useState([]);
  const [btn, setBtn] = useState(false);
  const [document, setDounload] = useState(false);
  const [trim,settrim]=useState("")
  const [docs,setDocs]=useState("")
  useEffect(() => {
    const getROBOTId = () => {
      robot_list_company()
        .unwrap()
        .then((res) => {
          setRobotLists(res?.data);
        })
        .catch((err) => {
          console.log({ message: err.message });
        });
    };
    getROBOTId();
  }, []);

  //   Add form data function
  const handleSubmitForm = async (e) => {
    const format = new FormData();
    format.append("company_id", companyId);
    format.append(`robot_id`, robotIdChe);
    format.append(
      "manufacturing_date",
      moment(manuFaDate).format("YYYY-MM-DD")
    );
    format.append("purchase_date", moment(dateOfPurch).format("YYYY-MM-DD"));
    format.append("software_version", sFVs);
    format.append("warrenty_period", warrie);
    format.append("company_branch_id", branch);
    format.append("attachment", attachment);
    format.append("name", robotType);

    Object.values(roboTemp)?.map((item, ind) => {
      format.append(`code[${ind}]`, item);
    });

    if (
      branch?.length == 0 ||
      companyName?.length == 0 ||
      robotIdChe?.length == 0 ||
      manuFaDate?.length == 0 ||
      sFVs?.length == 0 ||
      warrie?.length == 0 ||
      dateOfPurch?.length == 0 ||
      (type !== "edit" && attachment?.length == 0)
    ) {
      setBranchErr(true);
      setCompanyNameErr(true);
      setManuFactDateErr(true);
      setSFVsErr(true);
      setDateOfPurchErr(true);
      setWarrienErr(true);
      type !== "edit" && setAttachmentErr(true);
    } else if (format) {
      if (type == "edit") {
        setLoading(true)
        setBtn(true);
        let id = editData?._id;
        edit_company_robot({ payload: format, id })
          .unwrap()
          .then((res) => {
            setLoading(false)
            setBtn(false);
            navigate(-1);
          })
          .catch((err) => {
            setBtn(false);
            setLoading(false)
            console.log(err);
          });
      } else {
        setLoading(true)
        setBtn(true);
        company_robot_add(format)
          .unwrap()
          .then((res) => {
            setLoading(false)
            setBtn(false);
            if (res?.status === "success") {
              navigate(-1);
              console.log(res);
            }
          })
          .catch((error) => {
            setBtn(false);
            setLoading(false)
            console.log(error, "error");
          });
      }
    }
  };

  // const getCompanyManagementList = () => {
  //   robot_order_list()
  //     .unwrap()
  //     .then((res) => {
  //       if (res?.status === "success") {
  //         let temp = [];
  //         res?.data?.map((item, ind) => {
  //           if (item?.payment_status == "order_dispatched") {
  //             temp.push(item);
  //           }
  //         });

  //         const mergeIds = (list) => {
  //           const subarrsById = {};
  //           for (const { company_info, branch_info } of list) {
  //             let id = company_info._id;
  //             let name = company_info.company_name;
  //             if (!subarrsById[id]) {
  //               subarrsById[id] = { id, name, branch_info: [...branch_info] };
  //             } else {
  //               subarrsById[id].branch_info.push(...branch_info);
  //             }
  //           }
  //           return Object.values(subarrsById);
  //         };

  //         console.log(mergeIds(temp));
  //         setCompanyManagementList(mergeIds(temp));
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "error");
  //     });
  // };

const onClick=()=>{
  setDounload(true)
}





  const getCompanyManagementList = async () => {
    robot_order_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = res?.data?.filter((i) => i?.status == true);
          setCompanyManagementList(temp.reverse());
          console.log(temp,"list")
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const companySelecter = (item) => {
    let temp = comapnyManageList?.find((i) => i?._id == item);
    console.log("tem131", temp);
    setCompanyName(item);
    setCompanyId(item);
    // setRobotId(temp?.number_of_robots);
    setBranchList(temp?.branches);
    setClientName(temp?.name);
  };

  const branchSelecter = (item) => {
    // console.log("ite", item);
    let temp = branchList?.find((i) => i?.id == item);
    console.log("temp", temp);
    setBranch(item);
    setBranchData(temp);

    let obj = {
      company_branch_name: item,
      company_name: companyName,
    };

    robotOrderList(obj)
      .unwrap()
      .then((res) => {
        let temp = res?.data?.filter((i) => i?.status == true);
        setRobotList(temp);
        if (type !== "edit") {
          setRobotIdChe("");
          setrobotType("");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanyManagementList();
  }, []);

  // console.log(branchPin, "branchPinErr227");
  const [roboTemp, setRoboTemp] = useState({});

  const handleFormChanges = (value, index) => {
    setRoboTemp((val) => ({ ...val, [index]: value }));
  };

  const selectRobotOrder = (id) => {
    let finder = robotList?.find((i) => i?.robot_type?._id == id);
    setrobotType(finder?.robot_type?.name);
    setRobotIdChe(finder?.robot_type?._id);
    let temp = [];
    for (let i = 0; i < finder?.number_of_robots; i++) {
      temp.push(i);
    }
    // console.log("temtempp", temp);
    setRobotId(temp);
    // console.log("finder", finder);
  };

  const getVIew = () => {
    robotViewApi(editData?._id)
      .unwrap()
      .then((res) => {
        console.log("ddd",res);
        if (res?.status == "success") {
          let data = res?.data?.[0]
          console.log("data?.company_info?._id", data);
          // setCompanyName(data?.company_info?._id);
          companySelecter(data?.company_info?._id, data?.branches?._id);
          branchSelecter(data?.branches?._id);
          setRobotIdChe(data?.robot_id);
          setrobotType(data?.robot_details?.name);
          
          setBranchData({
            location: data?.branches?.location,
            pincode: data?.branches?.pincode,
          });
          setSFVs(data?.software_version);
          setWarrien(data?.warranty_period);
          if (data?.manufacturing_date) {
            setManuFactDate(
              moment(data?.manufacturing_date).format("YYYY-MM-DD")
            );
          }

          if (data?.purchase_date) {
            setDateOfPurch(moment(data?.purchase_date).format("YYYY-MM-DD"));
          }
          setCodeName(data?.code);
          // setBranch(data?.branches?._id);
          // setAttachment(data?.attachment[0] )
          setDocs(data?.attachment[0])
          let Id = []
          console.log(data?.code,"code")

          data?.code?.map((item, index)=>{
            Id?.push(item)
            setRoboTemp((previous) => ({ ...previous, [index]: item }));
          })
          setRobotId(Id)

        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
console.log(roboTemp,"robotId")
const doc=()=>{
  const url = docs
  const trimmedFilename = url.substring(url.lastIndexOf('/') + 1);
   settrim(trimmedFilename);
}
useEffect(()=>{
if(docs&&(type == "edit" || type=="view")){
  doc()
}
},[docs])

  useEffect(() => {
    if (type == "edit" || type=="view"&& comapnyManageList?.length > 0) {
      getVIew();
    }
  }, [comapnyManageList, type]);
//   const url = attachment
//   const trimmedFilename = url.substring(url.lastIndexOf('/') + 1);
// console.log(trimmedFilename); 
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <TopHeader
        type={type === "edit" ? "edit-company-robot" : "add-company-robot"}
      />
      <div className="menu-list">
        <h1>{type === "view"?"View Robot Order":(type === "edit" ? "Edit Company Robot" : "Add Company Robot")}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2 " for="">
                  Company Name
                </label>
                <select
                  disabled={type=='view'||type=="edit"?true:false}
                  className="form-control mb-1"
                  name="company_id"
                  id=""
                  value={companyName}
                  onChange={(e) => {
                    companySelecter(e.target.value);
                  }}
                  // disabled={type == "edit" ? true : false}
                >
                  <option hidden>Select company</option>
                  {comapnyManageList?.map((item, ind) => {
                    return (
                      <option value={item?._id} key={ind}>
                        {item?.companyname}
                      </option>
                    );
                  })}
                </select>
                {companyName?.length == 0 && companyNameErr && (
                  <p className="text-danger m-0">Select Company</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Branch Name
                </label>

                <select
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  onChange={(e) => {
                    // setSUbmitDatas((prev) => ({...prev,branchName: e.target.value}))
                    branchSelecter(e.target.value);
                  }}
                  value={branch}
                  disabled={type=='view'||type=="edit"?true:false}
                  // disabled={type == "edit" ? true : false}
                >
                  <option hidden>Branch Name</option>
                  {branchList?.map((item, ind) => {
                    return (
                      <option value={item?.id} key={ind}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                {branch?.length == 0 && branchErr && (
                  <p className="text-danger m-0">Select Branch</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative ">
                <label className="mb-2" for="">
                  Branch Location
                </label>
                <input
                disabled={type == "view" ? true : false}
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  readOnly
                  value={branchData?.location}
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  {" "}
                  Branch Pincode
                </label>
                <input
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  readOnly
                  value={branchData?.pincode}
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  {/* Robot Order ID */}
                  Robot Type
                </label>
                <select
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  value={robotIdChe}
                  onChange={(e) => {
                    selectRobotOrder(e.target.value);
                  }}
                  disabled={type == "edit"||type == "view" ? true : false}
                >
                  <option hidden>Select Robot Order ID</option>
                  {robotList?.map((item, ind) => {
                    return (
                      <option value={item?.robot_type?._id} key={ind}>
                        {item?.robot_type?.name}
                      </option>
                    );
                  })}
                </select>
                {/* {robotIdChe?.length == 0 && robotIdCheErr && (
                  <p className="m-0 text-danger">Enter Robot Id</p>
                )} */}
              </div>
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  {" "}
                  Robot Type
                </label>
                <select
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  value={robotType}
                  disabled
                  onChange={(e) => {
                    selectRobotOrder(e.target.value);
                  }}
                >
                  <option hidden></option>
                  {robotLists?.map((item, ind) => {
                    return (
                      <option value={item?._id} key={ind}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                {robotType?.length == 0 && robotNameErr && (
                  <p className="m-0 text-danger">Enter Robot Type</p>
                )}
              </div> */}
              {robotId?.map((item, index) => {
                return (
                  <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                    <label className="mb-2" for="">
                      Robot Id
                    </label>
                    <input
                      disabled={type == "view" ? true : false}
                      name={`robot_id${index}`}
                      type="text"
                      className="form-control mb-1"
                      placeholder="Enter Robot Id"
                      value={roboTemp[index]}
                      onChange={(e) => {
                        handleFormChanges(e.target.value, index);
                      }}
                    />
                    {/* <div className="col-md-1 col-14 ms-2">
                  <input
                    class="btn-submit"
                    value={"+ Add"}
                    onClick={(e) => addlist(e)}
                    type="submit"
                  />
                </div>
                {robotList.length >= 1 && (
                  <div className="col-md-1 col-14 ms-2">
                    <input
                      class="btn-submit"
                      value={"✕"}
                      onClick={(e) => removelist(e)}
                      type="submit"
                    />
                  </div>
                )} */}
                  </div>
                );
              })}

              {/* {(type == "edit" || type == "view") && (
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" for="">
                    Robot Id
                  </label>  
                  <input
                    type="text"
                    className="form-control mb-1"
                    placeholder="Enter Robot Id"
                    value={codeName}
                    onChange={(e) => {
                      setCodeName(e.target.value);
                    }}
                  />
                </div>
              )} */}

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Manufacturing Date
                </label>
                <input
                 disabled={type == "view" ? true : false}
                  name="manufacturing_date"
                  type="date"
                  className="form-control"
                  placeholder="Enter Manufacturing Date"
                  value={manuFaDate}
                  onChange={(e) => {
                    setManuFactDate(e.target.value);
                  }}
                />
                {manuFaDate?.length == 0 && manuFaDateErr && (
                  <p className="text-danger m-0">Enter Manufacturing Date</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Software Versions
                </label>
                <input
                 disabled={type == "view" ? true : false}
                  name="software_version"
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Software Versions"
                  value={sFVs}
                  onChange={(e) => {
                    setSFVs(e.target.value);
                  }}
                />
                {sFVs?.length == 0 && sFVsErr && (
                  <p className="text-danger m-0">Enter Software version</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Date of Purchase
                </label>
                <input
                 disabled={type == "view" ? true : false}
                  name="purchase_date"
                  type="date"
                  className="form-control mb-1"
                  placeholder="Enter Date of Purchase"
                  value={dateOfPurch}
                  onChange={(e) => {
                    setDateOfPurch(e.target.value);
                  }}
                />
                {dateOfPurch?.length == 0 && dateOfPurchErr && (
                  <p className="text-danger m-0">Enter Date of Purchase</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Warranty Period
                </label>
                <input
                 disabled={type == "view" ? true : false}
                  type="date"
                  className="form-control mb-1"
                  placeholder="Enter  Warranty Period"
                  name="warranty_period"
                  value={warrie}
                  onChange={(e) => {
                    setWarrien(e.target.value);
                  }}
                />
                {warrie?.length == 0 && warrieErr && (
                  <p className="text-danger m-0">Enter Warranty Period</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Attachment
                </label>
                <input
                 disabled={type == "view" ? true : false}
                  name="attachment"
                  type="file"
                  className="form-control"
                  accept="application/pdf"
                  onChange={(e) => {
                    setAttachment(e.target.files[0]);
                  }}
                  />
                    {(type=="view"||type=="edit")&&
                
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <p>{trim}</p>
                  <button onClick={()=>onClick() } className="border-0 ad-btn bg-danger ">Download</button>
  {document && (
    <iframe src={attachment} style={{ height: "0px" }}></iframe>)}    
</div>}
                {attachment?.length == 0 && attachmentErr && (
                  <p className="text-danger m-0">Please attach the image</p>
                )}
              </div>
              {type!="view" &&  <div className="col-md-6 col-12 px-md-0 px-0 position-relative mb-3 d-flex">
                <div className="px-md-3 pe-3">
                  {type === "edit" ? (
                    <button
                      className="btn-submit"
                      onClick={(e) => handleSubmitForm(e)}
                      disabled={btn ? true : false}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className="btn-submit"
                      onClick={(e) => handleSubmitForm(e)}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  )}
                </div>
                <div onClick={() => navigate(-1)} class="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBranch;
