import { Delete, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import moment from "moment/moment";
import { useLazyRobots_order_payments_listQuery } from "../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={"text-nowrap"}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={"text-nowrap"}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const PaymentListComp = ({ orderPaymentList }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;
  const [status, setStatus] = useState(false);
  const [list, setList] = useState([]);
  const[document,setDocument]=useState(false)

  useEffect(() => {
    setList(orderPaymentList);
  }, [orderPaymentList]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Amount Received",
        accessor: "amount",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      // {
      //   Header: "Payment Mode",
      //   accessor: "payment_mode",
      //   Cell: ({ value, row }) => {
      //     return <p>{value}</p>;
      //   },
      // },
      // {
      //   Header: "Transaction ID",
      //   accessor: "transaction_no",
      //   Cell: ({ value, row }) => {
      //     return <p>{value}</p>;
      //   },
      // },
      {
        Header: "Attachment",
        accessor: "attachment[0]",
        Cell: ({ value, row }) => {
       
        //   const onClick = ()=>{
        //  !setDocument}
        //  console.log(document)
        
          
          //  <div>
          //  <button onClick={onClick} className="border-0 ad-btn bg-danger ">Download</button>
          //   {/* {document && ( 
          //     <iframe src={value} style={{ height: "0px" }}></iframe>
          //     )
          //     } */}
          //  </div>
          return <div>
              {/* <button  className="border-0 ad-btn bg-danger ">Download</button> */}
              <a className="border-0 ad-btn bg-danger" style={{padding:"5%",textDecoration:"none"}}  href={value} target="_blank">Download</a>
            </div>
          
          
        },
      },
      {
        Header: "Payment Date",
        accessor: "payment_date",
        Cell: ({ value, row }) => {
          return <p>{value ? moment(value).format("DD-MM-YYYY") : "-"}</p>;
        },
      },
      // {
      //   Header: "Paid Amount",
      //   accessor: "robot_type",
      //   Cell: ({ value, row }) => {
      //     return <p>{value}</p>;
      //   },
      // },
    ],
    []
  );

  return (
    <div className="table-responsive menu-table side_pad my-4 ">
      <Table columns={columns} data={list} className="" />
    </div>
  );
};

export default PaymentListComp;
