import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ScreenHeader from "../components/ScreenHeader";
import { menu_section_left } from "../assets/images";
import MenuViewFormComp from "../components/CompanyView/MenuViewFormComp";
import { Delete, Edit } from "@mui/icons-material";
import {
  useCompanyUserBranchMutation,
  useCompanyUserDeleteNewMutation,
  useCompany_user_viewMutation,
  useLazyCompanyUserStatusNewQuery,
} from "../constants/redux/api/api";

import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import TopHeader from "../components/TopHeader";
import Swal from "sweetalert2";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const CompanyViewScreen = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const viewData = location?.state?.data;
   console.log(viewData,"view")
  // State variable's declare
  const [status, setStatus] = useState(false);
  const [dropDownList, setDropDownList] = useState();
  const [viewList, setViewList] = useState([]);
  const [list, setList] = useState([]);

  // RTK Query
  const [company_user_view] = useCompanyUserBranchMutation();
  const [company_user_status] = useLazyCompanyUserStatusNewQuery();
  const [company_user_delete] = useCompanyUserDeleteNewMutation();

  // Company View Data API GET
  const viewGetList = async () => {
    const id = viewData && viewData?._id;
    const formData = new URLSearchParams()
    formData.append("companyId",viewData?.id)
    company_user_view(formData)
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let finder = res?.data?.filter(
            (i) => i.company_info._id == viewData?._id
          );
          console.log(finder,"finder")
          let temp = [];
          finder.map((item, ind) => {
            if (item?.user_name) {
              temp.push(item);
            }
          });
          console.log("temp", temp);
          setList(temp.reverse());
          setViewList(temp.reverse());
          setDropDownList("All");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (viewData?._id) {
      viewGetList();
    } else {
      navigate(-1);
    }
  }, []);

  const companyDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        company_user_delete(id)
          .unwrap()
          .then((res) => {
            // console.log(res, "res140");
            viewGetList();
          })
          .catch((err) => {
            console.log(err.message, "errMsg142");
          });
      }
    });
  };

  const handleStatusChange = (id) => {
    company_user_status(id)
      .then((res) => {
        if (res?.data?.status == "success") {
          viewGetList();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "User Name",
        accessor: "user_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "company_branch_info",
        Cell: ({ value, row }) => {
          return <p>{value.name}</p>;
        },
      },

      // {
      //   Header: "Role",
      //   accessor: "role_id",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <p>
      //         {value == 1
      //           ? "Admin"
      //           : value == 2
      //           ? "Kitchen Admin"
      //           : value == 2
      //           ? "Waiter"
      //           : ""}
      //       </p>
      //     );
      //   },
      // },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              className={`${
                value ? "bg-success" : "bg-danger"
              } px-2 py-1 rounded-1 border-0 text-white`}
              onClick={() => handleStatusChange(row.original._id)}
            >
              {value ? "Active" : "Deactive"}
            </button>
          );
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/add-users", {
                    state: { data: row.original, type: "edit", item: viewData },
                  })
                }
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              {/* <button
                className="bg-warning border-0 rounded-1 p-1"
                onClick={() => companyDelete(row.original._id)}
              >
                <Delete />
              </button> */}
              <button
                onClick={() =>
                  navigate("/add-users", {
                    state: { type: "view", data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
                </button> 
            </div>
          );
        },
      },
    ],
    []
  );

  // BRANCH FILTER
  const branchFilter = (id) => {
    setDropDownList(id);
    if (id == "All") {
      setViewList(list);
    } else {
      let finder = list?.filter((i) => i.company_branch_info._id == id);
      setViewList(finder);
    }
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"admin-company-user"} />
      <div className="menu-list">
        <h1>Company Users List</h1>
      </div>
      <MenuViewFormComp
        menuOpe={menuOpen}
        dropDownList={dropDownList}
        branchFilter={branchFilter}
        viewData={viewData}
      />

      <div className="">
        <div className="side_pad">
          <div className="menu_section">
            <div className="align-items-center justify-content-between d-flex flex-md-wrap">
              <div className="align-items-center d-flex ">
                <div className="px-2">
                  <img src={menu_section_left} alt="decor" />
                </div>
                <div className="px-2 ">
                  <h5>{"Check out your menu"}</h5>
                  <p>{"You can add your menu from here"}</p>
                </div>
              </div>
              <div className="d-flex gap-2">
                <button
                  onClick={() =>
                    navigate("/add-users", {
                      state: { item: viewData },
                    })
                  }
                  className="btn-outline"
                >
                  + Add User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive menu-table side_pad mt-4">
        <Table columns={columns} data={viewList} className="" />
      </div>
    </div>
  );
};

export default CompanyViewScreen;
