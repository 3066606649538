import React from "react";
import { useNavigate } from "react-router-dom";
// import TopHeader from "../components/TopHeader";
// import ScreenHeader from "../components/ScreenHeader";
// import BranchList from "../components/CustomerManagement/BranchList";
// import { menu_section_left } from "../assets/images";
import HiRobotList from "../HiRobot/HiRobotList";
import TopHeader from "../../components/TopHeader";
import ScreenHeader from "../../components/ScreenHeader";
import { menu_section_left } from "../../assets/images";
import AgvBranchList from "./AgvBranchList";



const AgvBranch = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"agv-branch"} />
      <ScreenHeader
        title="AGV Branch "
        // menuLink="/add-agv-branch"
        // menuBtn="Add AGV Branch"
        menuTitle="Check out your Company robots"
        description="You can add your robots from here"
        img={menu_section_left}
      />
      <AgvBranchList />
    </div>
  );
};

export default AgvBranch;

