import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import mapImage from "../../assets/images/map.jpg";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useBranch_addMutation,
  useBranchList_TableMutation,
  useDinningUpdateMutation,
  useLazyRestaurant_branch_viewQuery,
  useLazyView_databranchQuery,
  useRestaurant_branch_updateMutation,
  useLazyPointListQuery,
} from "../../constants/redux/api/api";
import { Table } from "../Table/Table";
import { useEffect } from "react";
import TopHeader from "../../components/TopHeader";
import { loadergif } from "../../assets/img";

const RestaurantViewBranchDetails = ({ menuOpen }) => {
  // let {
  //   state: { data },
  // } = useLocation();
  let location = useLocation();
  let data = location?.state?.data;

  // console.log(data.id,"dat")
  //RTK
  const [branch_add] = useBranch_addMutation();
  const [table_list] = useBranchList_TableMutation();
  const [pointListApi] = useLazyPointListQuery();
  const [get_view] = useLazyRestaurant_branch_viewQuery();
  const [updateapi] = useRestaurant_branch_updateMutation();

  const [loading, setLoading] = useState(false);
  let [uploadFile, setUploadFile] = useState([]);
  let [tableData, settabledata] = useState([]);
  let [pointData, setPointdata] = useState([]);
  let [tabActive, setTabActive] = useState({
    view: "d-block",
    table: "d-none",
    point: "d-none",
    map: "d-none",
  });
  let [name, setName] = useState("");
  let [phone, setPhone] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [description, setDescription] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [updatedata, setUpdatedata] = useState([]);

  let [nameerr, setNameerr] = useState(false);
  let [phoneerr, setPhoneerr] = useState(false);
  let [emailerr, setEmailerr] = useState(false);
  let [passworderr, setPassworderr] = useState(false);
  let [passwordStringerr, setPasswordStringerr] = useState("");
  let [confirmPassworderr, setConfirmPassworderr] = useState(false);
  let [descriptionerr, setDescriptionerr] = useState(false);

  const navigate = useNavigate();

  //   Event File Upload
  const handleUploadFile = (e) => {
    if (e.target.files) {
      setUploadFile([...uploadFile, e.target.files]);
    }
  };

  // Event File Remove
  const handleRemoveFile = (e, i) => {
    setUploadFile((prev) =>
      prev.filter((file, index) => {
        return index !== i;
      })
    );
  };
  const getview = () => {
    let id = data.id;
    get_view(id)
      .unwrap()
      .then((res) => {
        const responseData = res?.data;
        console.log(responseData[0], "res");
        setUpdatedata(responseData);
        if (responseData?.length == 1) {
          setPhone(responseData[0]?.contactnumber);
          setEmail(responseData[0]?.email);
          setDescription(responseData[0]?.description);
        }
      })
      .catch((err) => err);
  };
  useEffect(() => {
    if (data?.id) {
      getview(data?.id);
    }
  }, [data]);

  // console.log(updatedata,"update")
  const handelSubmit = () => {
    if (
      name?.length === 0 ||
      phone?.length == 0 ||
      email?.length == 0 ||
      description?.length == 0 ||
      (email?.length == 0 && password?.length == 0) ||
      // confirmPassword != password ||
      (email?.length == 0 && confirmPassword.length == 0)
    ) {
      setNameerr(true);
      setEmailerr(true);
      setPhoneerr(true);
      setDescriptionerr(true);
      if (email?.length == 0) {
        setPassworderr(true);
        setConfirmPassworderr(true);
      }
    } else {
      const formData = new URLSearchParams();
      formData.append("name", name);
      formData.append("primary_contact", phone);
      formData.append("company_branch_id", data.id);
      formData.append("description", description);
      formData.append("email", email);
      // if(updatedata?.length==0){

      formData.append("companypassword", password);
      formData.append("confirm_password", confirmPassword);
      // }
      if (updatedata?.length > 0) {
        setLoading(true);
        setDisableBtn(true);
        updateapi({ payload: formData, id: data.id })
          .unwrap()
          .then((res) => {
            // getview(data.id)
            setDisableBtn(false);
            setLoading(false);
            console.log("response", res);
          })
          .catch((err) => {
            setDisableBtn(false);
            setLoading(false);
            console.log(err, "err");
          });
      } else {
        setDisableBtn(true);
        branch_add(formData)
          .unwrap()
          .then((res) => {
            // getview(data.id)
            setDisableBtn(false);
            console.log("response", res);
          })
          .catch((err) => {
            setDisableBtn(false);
            console.log(err, "err");
          });
      }
    }
  };
  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setPasswordStringerr("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setPasswordStringerr("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setPasswordStringerr("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setPasswordStringerr("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setPasswordStringerr("");
      }
    } else {
      setPasswordStringerr("");
      setPassword(new_pass);
    }
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  const pattern = new RegExp(/^[6-9]\d{0,9}$/g);

  const getTable = () => {
    const fromdata = new URLSearchParams();
    fromdata.append("company_branch_id", data?.id);
    table_list(fromdata)
      .unwrap()
      .then((res) => {
        settabledata(res?.data);
        console.log(res, "table");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getPointList = () => {
    pointListApi()
      .unwrap()
      .then((res) => {
        setPointdata(res?.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    if (data?.id) {
      getTable();
      getPointList();
    }
  }, [data]);

  const handleActiveTab = (e) => {
    if (e.target.innerText === "View Branch") {
      let obj = {
        view: "d-block",
        table: "d-none",
        point: "d-none",
        map: "d-none",
      };
      setTabActive(obj);
    } else if (e.target.innerText === "Table") {
      let obj = {
        view: "d-none",
        table: "d-block",
        point: "d-none",
        map: "d-none",
      };
      setTabActive(obj);
    } else if (e.target.innerText === "Point") {
      let obj = {
        view: "d-none",
        table: "d-none",
        point: "d-block",
        map: "d-none",
      };
      setTabActive(obj);
    } else {
      let obj = {
        view: "d-none",
        table: "d-none",
        point: "d-none",
        map: "d-block",
      };
      setTabActive(obj);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Company Name",
        accessor: "company.company_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "branch.name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Table ID",
        accessor: "code",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "Table Name",
        accessor: "table_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/restaurant/view-branch-details", {
                    state: {
                      type: "view",
                      data: row.original,
                      method: "table",
                    },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const columnsPoint = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Company Name",
        accessor: "company.name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "branch[0].name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Point ID",
        accessor: "code",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "Point Name",
        accessor: "point_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/restaurant/view-branch-details", {
                    state: {
                      type: "view",
                      data: row.original,
                      method: "point",
                    },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {loading && (
        <div
          style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}
        >
          <span style={{ marginLeft: 10 }}>
            <img src={loadergif} />
          </span>
        </div>
      )}
      <TopHeader type={"Branch_details"} />
      <div className="side_pad mb-2 menu-list">
        <ul className="nav">
          <li
            className={`nav-item ${
              tabActive.view === "d-block" && "activeTab"
            }`}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleActiveTab(e);
            }}
          >
            <a className="nav-link py-0">
              <h1
                className={`mb-0 ${
                  tabActive && tabActive.view === "d-none"
                    ? "head_color"
                    : "text-light"
                }`}
              >
                View Branch
              </h1>
            </a>
          </li>
          <li
            className={`nav-item ${
              tabActive.table === "d-block" && "activeTab"
            }`}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleActiveTab(e);
            }}
          >
            <a className="nav-link py-0">
              <h1
                className={`mb-0 ${
                  tabActive && tabActive.table === "d-none"
                    ? "head_color"
                    : "text-light"
                }`}
              >
                Table
              </h1>
            </a>
          </li>
          <li
            className={`nav-item ${
              tabActive.point === "d-block" && "activeTab"
            }`}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleActiveTab(e);
            }}
          >
            <a className="nav-link py-0">
              <h1
                className={`mb-0 ${
                  tabActive && tabActive.point === "d-none"
                    ? "head_color"
                    : "text-light"
                }`}
              >
                Point
              </h1>
            </a>
          </li>
          <li
            className={`nav-item ${tabActive.map === "d-block" && "activeTab"}`}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              handleActiveTab(e);
            }}
          >
            <a className="nav-link py-0">
              <h1
                className={`mb-0 ${
                  tabActive && tabActive.map === "d-none"
                    ? "head_color"
                    : "text-light"
                }`}
              >
                Map
              </h1>
            </a>
          </li>
        </ul>
        <hr></hr>
        <div
          id="view_branch_details"
          className={`${tabActive && tabActive.view}`}
        >
          <div className="row mx-1 mb-3 add-food-section">
            <div className="form-group col-6">
              <label>Company Name:</label>
              <input
                type="text"
                className="form-control"
                value={data?.companyname ? data?.companyname : ""}
                readOnly
                disabled
              />
            </div>
            <div className="form-group col-6">
              <label>Branch Name:</label>
              <input
                type="text"
                className="form-control"
                value={data?.name ? data?.name : ""}
                readOnly
                disabled
              />
            </div>
            <div className="form-group col-6">
              <label>Branch Location:</label>
              <input
                type="text"
                className="form-control"
                value={data?.location ? data?.location : ""}
                readOnly
                disabled
              />
            </div>
            <div className="form-group col-6">
              <label>Pin Code:</label>
              <input
                type="text"
                className="form-control"
                value={data?.pincode ? data?.pincode : ""}
                readOnly
                disabled
              />
            </div>
          </div>
          <div className="add-food-section">
            <form className="add-food-section">
              <div className="row">
                <div className="form-group col-6">
                  <label>Primary Contact Name </label>
                  <input
                    type="text"
                    placeholder="Enter Primary Contact"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  {name?.length < 3 && nameerr && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      Enter Name
                    </p>
                  )}
                </div>
                <div className="form-group col-6">
                  <label>Phone Number</label>

                  <input
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");
                        setPhone(e.target.value);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    maxlength={10}
                    value={phone ? phone : ""}
                    onChange={(e) => setPhone(e.target.value)}
                    type="number"
                    name="mobile"
                    className="form-control"
                    id="Mobile Number"
                    placeholder="Mobile Number"
                  />
                  {phone?.length == 0 && phoneerr && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      Enter Phone number{" "}
                    </p>
                  )}
                  {/* {phone.length > 1 && phone?.length != 10 && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      Enter valid Phone number{" "}
                    </p>
                  )} */}
                  {phone?.length > 0 && (
                    <p className="errtext">
                      {phone?.length !== 10
                        ? "phone number must be 10 digits"
                        : pattern.test(phone) == false
                        ? "Enter valid Phone number"
                        : ""}
                    </p>
                  )}
                </div>
                <div className="form-group col-6">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {email.length == 0 && emailerr && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      Enter Email
                    </p>
                  )}

                  {email.length > 1 && validEmail.test(email) === false && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      Enter valid email
                    </p>
                  )}
                </div>
                <div className="form-group col-6">
                  <label>Password</label>
                  <input
                    type="text"
                    placeholder="Enter Password"
                    className="form-control"
                    name="companypassword"
                    value={password}
                    onChange={(e) => handlePassword(e)}
                  />
                  {password?.length == 0 && passworderr && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      Enter password
                    </p>
                  )}
                  {password?.length > 0 && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      {passwordStringerr}
                    </p>
                  )}
                </div>
                <div className="form-group col-6">
                  <label>Confirm Password</label>
                  <input
                    type="text"
                    placeholder="Enter Confirm Password"
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  {confirmPassword.length == 0 && confirmPassworderr && (
                    <p className="errtext">Enter confirmPassword</p>
                  )}
                  {confirmPassword.length > 0 &&
                    confirmPassword != password && (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          color: "red",
                        }}
                      >
                        confirmPassword Must be match password
                      </p>
                    )}
                </div>

                {/* <div className="form-group col-6">
                  <label>File Upload</label>
                  <input
                    type="file"
                    onChange={(e) => handleUploadFile(e)}
                    className="form-control mb-0"
                    multiple={true}
                  />
                  <br />
                  <div className="d-flex px-1">
                    {Array.isArray(uploadFile) ? (
                      uploadFile.map((data, i) => {
                        const imageUrl = URL.createObjectURL(data[0]);
                        return (
                          <Fragment key={i}>
                            <img
                              src={imageUrl}
                              alt="img"
                              style={{ width: "50px", height: "40px" }}
                            />
                            <p className="m-0 px-1">{data[0].name}</p>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleRemoveFile(data[0].name, i)}
                            >
                              &#x2715;
                            </span>
                          </Fragment>
                        );
                      })
                    ) : (
                      <p>h</p>
                    )}
                  </div>
                </div> */}
                <div className="form-group col-12">
                  <label>Description</label>
                  <textarea
                    cols={5}
                    rows={5}
                    type="e"
                    placeholder="Description"
                    className="form-control"
                    name="description"
                    value={description ? description : ""}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                  {description?.length == 0 && descriptionerr && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      Enter Description
                    </p>
                  )}
                </div>
              </div>
              <button
                type="button"
                disabled={disableBtn}
                className="btn btn-primary"
                onClick={() => handelSubmit()}
              >
                {updatedata?.length > 0 ? "Update" : "Submit"}
              </button>
            </form>
          </div>
        </div>
        <div id="branch_table" className={`${tabActive && tabActive.table}`}>
          <div className="table-responsive menu-table side_pad mt-4">
            <Table data={tableData} columns={columns} />
          </div>
        </div>
        <div id="branch_point" className={`${tabActive && tabActive.point}`}>
          <div className="table-responsive menu-table side_pad mt-4">
            <Table data={pointData} columns={columnsPoint} />
          </div>
        </div>
        <div id="branch_map" className={`${tabActive && tabActive.map}`}>
          <div className="robot_mapping mb-3" id="img_show">
            <img
              className="map_img rounded-3"
              src={mapImage}
              width={100}
              alt="map"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantViewBranchDetails;
