import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import { useGetTablesQuery } from "../constants/redux/api/api";
import { useDispatch } from "react-redux";
import { saveSelectedTable } from "../constants/redux/slice/selectedTable";
import { useNavigate } from "react-router-dom";
import { tableImg } from "../assets/img";
import { io } from "socket.io-client";
import { WS_URL } from "../constants/redux/api/constants";
import Websocket from "../components/Websocket";
import { saveSelectedTableName } from "../constants/redux/slice/selectedTableName";

const SelectTablePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storage = localStorage.getItem("name");
  const socket = useRef();
  // socket.current = io("ws://192.168.1.29:8060");
  const websocketUrl = WS_URL;
  const [tablesList, setTablesList] = useState([]);
  const [webSock, setWebSock] = useState(null);
  const [activeTable, setActiveTable] = useState("");
  const [activeTableInd, setActiveTableInd] = useState("");
  const [name, setName] = useState("");
  const [recieve, setRecieve] = useState("");
  const [webSocket, setWebSocket] = useState(false);

  // RTK QUERY
  const { data: tablesData, isSuccess: isTablesSuccess } = useGetTablesQuery();

  useEffect(() => {
    if (isTablesSuccess) {
      // console.log(tablesData);
      setTablesList(tablesData?.result);
    }
  }, [tablesData, isTablesSuccess]);

  const onTableClick = (item, ind) => {
    setActiveTable(item?._id);
    setActiveTableInd(item?.table_name);
    dispatch(saveSelectedTable(item?._id));
    dispatch(saveSelectedTableName(item?.table_name));
    setWebSocket(true);
    // setTimeout(() => {
    //   navigate("/menu");
    // }, 600);
  };

  const SubmitHandler = () => {
    if (name?.length == 0) {
      alert("enter your name");
    } else {
      localStorage.setItem("name", name);
      alert("name saved");
      setName("");
    }
  };

  useEffect(() => {
    let split = recieve.split(":");
    if (storage == split[0] && split[1] == 0) {
      navigate("/menu");
    }
  }, [recieve]);

  return (
    <>
      {" "}
      {webSocket && (
        <Websocket setRecieve={setRecieve} type={"table"} id={activeTableInd} />
      )}
      <Header type="table" />
      <div className="tables">
        {tablesList?.map((item, ind) => {
          return (
            <div
              onClick={() => onTableClick(item, ind + 1)}
              key={item?._id}
              className={activeTable === item?._id ? "active" : ""}
            >
              {/* <p>{item?.table_name}</p> */}
              <img alt="table" src={tableImg} />
              <div className="table-name">
                <p>{item?.table_name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SelectTablePage;
