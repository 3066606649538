import {
  biryaniCategory,
  burgerCategory,
  drinkCategory,
  pizzaCategory,
} from "../assets/img";

export const categories = [
  {
    id: 0,
    title: "Biryani",
    image: biryaniCategory,
    foodItems: [
      {
        title: "Chicken Biryani",
        price: "₹180.00",
        image:
          "https://www.kannammacooks.com/wp-content/uploads/buhari-hotel-chennai-chicken-biryani-recipe-1-4.jpg",
        qty: 0,
      },
      {
        title: "Mutton Biryani",
        price: "₹220.00",
        image:
          "https://5.imimg.com/data5/TW/DN/PS/SELLER-29361735/biryani-image-jpg.JPG",
        qty: 0,
      },
      {
        title: "Chicken Mandi Biryani",
        price: "₹200.00",
        image:
          "https://www.indianhealthyrecipes.com/wp-content/uploads/2021/12/chicken-biryani-recipe.jpg",
        qty: 0,
      },
      {
        title: "Hyderabadi Chicken Biryani",
        price: "₹250.00",
        image:
          "https://www.licious.in/blog/wp-content/uploads/2022/06/chicken-hyderabadi-biryani-01.jpg",
        qty: 0,
      },
    ],
  },
  {
    id: 1,
    title: "Burger",
    image: burgerCategory,
    foodItems: [
      {
        title: "Chicken Burger",
        price: "₹180.00",
        image:
          "https://img.freepik.com/free-photo/exploding-burger-with-vegetables-melted-cheese-black-background-generative-ai_157027-1734.jpg?w=2000",
        qty: 0,
      },
      {
        title: "Veg Burger",
        price: "₹220.00",
        image:
          "https://www.foodandwine.com/thmb/pwFie7NRkq4SXMDJU6QKnUKlaoI=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Ultimate-Veggie-Burgers-FT-Recipe-0821-5d7532c53a924a7298d2175cf1d4219f.jpg",
        qty: 0,
      },
      {
        title: "Cheesy Chicken Burger",
        price: "₹200.00",
        image:
          "https://simply-delicious-food.com/wp-content/uploads/2022/06/Grilled-chicken-burgers2.jpg",
        qty: 0,
      },
      {
        title: "Double Cheese Burger",
        price: "₹250.00",
        image:
          "https://happilyunprocessed.com/wp-content/uploads/2022/11/Juiciest-Burger-Youll-Ever-eat-2.jpg",
        qty: 0,
      },
    ],
  },
  {
    id: 2,
    title: "Pizza",
    image: pizzaCategory,
    foodItems: [
      {
        title: "Classic Pizza",
        price: "₹90.00",
        image:
          "https://img.freepik.com/free-photo/freshly-baked-pizza-rustic-wooden-table-generated-by-ai_24640-89788.jpg?w=2000",
        qty: 0,
      },
      {
        title: "Chicken Pizza",
        price: "₹120.00",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/classic-cheese-pizza-recipe-2-64429a0cb408b.jpg?crop=0.8888888888888888xw:1xh;center,top&resize=1200:*",
        qty: 0,
      },
      {
        title: "Chicken Cheese Pizza",
        price: "₹150.00",
        image:
          "https://recipesblob.oetker.in/assets/d8a4b00c292a43adbb9f96798e028f01/1272x764/pizza-pollo-arrostojpg.jpg",
        qty: 0,
      },
      {
        title: "Mushroom Pizza",
        price: "₹100.00",
        image:
          "https://static.onecms.io/wp-content/uploads/sites/43/2022/12/05/7245-jays-signature-pizza-crust-4x3-0805.jpg",
        qty: 0,
      },
    ],
  },
  {
    id: 3,
    title: "Drinks",
    image: drinkCategory,
    foodItems: [
      {
        title: "Pepsi",
        price: "₹45.00",
        image:
          "https://i.insider.com/62a36543ac96ba001991227a?width=1136&format=jpeg",
        qty: 0,
      },
      {
        title: "Mojito",
        price: "₹60.00",
        image:
          "https://cdn.loveandlemons.com/wp-content/uploads/2020/07/mojito.jpg",
        qty: 0,
      },
      {
        title: "Strawberry Cocktail",
        price: "₹80.00",
        image:
          "https://www.garnishwithlemon.com/wp-content/uploads/2019/06/Strawberry-Vodka-Cocktail-featured-image.jpg",
        qty: 0,
      },
    ],
  },
];

export const allFoods = [
  {
    title: "Chicken Biryani",
    price: "₹180.00",
    image:
      "https://www.kannammacooks.com/wp-content/uploads/buhari-hotel-chennai-chicken-biryani-recipe-1-4.jpg",
    qty: 0,
  },
  {
    title: "Mutton Biryani",
    price: "₹220.00",
    image:
      "https://5.imimg.com/data5/TW/DN/PS/SELLER-29361735/biryani-image-jpg.JPG",
    qty: 0,
  },
  {
    title: "Chicken Mandi Biryani",
    price: "₹200.00",
    image:
      "https://www.indianhealthyrecipes.com/wp-content/uploads/2021/12/chicken-biryani-recipe.jpg",
    qty: 0,
  },
  {
    title: "Hyderabadi Chicken Biryani",
    price: "₹250.00",
    image:
      "https://www.licious.in/blog/wp-content/uploads/2022/06/chicken-hyderabadi-biryani-01.jpg",
    qty: 0,
  },

  {
    title: "Chicken Burger",
    price: "₹180.00",
    image:
      "https://img.freepik.com/free-photo/exploding-burger-with-vegetables-melted-cheese-black-background-generative-ai_157027-1734.jpg?w=2000",
    qty: 0,
  },
  {
    title: "Veg Burger",
    price: "₹220.00",
    image:
      "https://www.foodandwine.com/thmb/pwFie7NRkq4SXMDJU6QKnUKlaoI=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Ultimate-Veggie-Burgers-FT-Recipe-0821-5d7532c53a924a7298d2175cf1d4219f.jpg",
    qty: 0,
  },
  {
    title: "Cheesy Chicken Burger",
    price: "₹200.00",
    image:
      "https://simply-delicious-food.com/wp-content/uploads/2022/06/Grilled-chicken-burgers2.jpg",
    qty: 0,
  },
  {
    title: "Double Cheese Burger",
    price: "₹250.00",
    image:
      "https://happilyunprocessed.com/wp-content/uploads/2022/11/Juiciest-Burger-Youll-Ever-eat-2.jpg",
    qty: 0,
  },
  {
    title: "Classic Pizza",
    price: "₹90.00",
    image:
      "https://img.freepik.com/free-photo/freshly-baked-pizza-rustic-wooden-table-generated-by-ai_24640-89788.jpg?w=2000",
    qty: 0,
  },
  {
    title: "Chicken Pizza",
    price: "₹120.00",
    image:
      "https://hips.hearstapps.com/hmg-prod/images/classic-cheese-pizza-recipe-2-64429a0cb408b.jpg?crop=0.8888888888888888xw:1xh;center,top&resize=1200:*",
    qty: 0,
  },
  {
    title: "Chicken Cheese Pizza",
    price: "₹150.00",
    image:
      "https://recipesblob.oetker.in/assets/d8a4b00c292a43adbb9f96798e028f01/1272x764/pizza-pollo-arrostojpg.jpg",
    qty: 0,
  },
  {
    title: "Mushroom Pizza",
    price: "₹100.00",
    image:
      "https://static.onecms.io/wp-content/uploads/sites/43/2022/12/05/7245-jays-signature-pizza-crust-4x3-0805.jpg",
    qty: 0,
  },
  {
    title: "Pepsi",
    price: "₹45.00",
    image:
      "https://i.insider.com/62a36543ac96ba001991227a?width=1136&format=jpeg",
    qty: 0,
  },
  {
    title: "Mojito",
    price: "₹60.00",
    image:
      "https://cdn.loveandlemons.com/wp-content/uploads/2020/07/mojito.jpg",
    qty: 0,
  },
  {
    title: "Strawberry Cocktail",
    price: "₹80.00",
    image:
      "https://www.garnishwithlemon.com/wp-content/uploads/2019/06/Strawberry-Vodka-Cocktail-featured-image.jpg",
    qty: 0,
  },
];

export const menu_list = [
  {
    company: "Ninos",
    branchcount: 2,
    viewcount: 10,
    menu: "Indian food",
    client_name: "Priya"
  },
  {
    company: "Ninos",
    branchcount: 2,
    viewcount: 10,
    menu: "Indian food",
    client_name: "Priya",
  },
  {
    company: "Ninos",
    branchcount: 2,
    viewcount: 10,
    menu: "Indian food",
    client_name: "Priya"
  },
];

export const detail_menu = [
  {
    cusine: "Indian",
    dish: "Biriyani",
    dish_img: biryaniCategory,
    price: 100,
  },
  {
    cusine: "Japanese",
    dish: "Sushi",
    dish_img: biryaniCategory,
    price: 100,
  },
];

export const cusine = [
  {
    list: "Indian",
  },
  {
    list: "cuisine",
  },
  {
    list: "Japanese",
  },
  {
    list: "Italian",
  },
];
