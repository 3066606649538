import React from "react";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import ScreenHeader from "../../components/ScreenHeader";
import BranchList from "../../components/CustomerManagement/BranchList";
import { menu_section_left } from "../../assets/images";
import HiRobotList from "./HiRobotList";
import AgvBranchList from "../AgvManagement/AgvBranchList";
import HiBranchList from "./HiBranchList";



const HiBranch = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"hi-branch"} />
      <ScreenHeader
        title="Hi Robot Branch "
        // menuLink="/add-hi-Robot-branch"
        // menuBtn="Add Hi Robot Branch"
        menuTitle="Check out your Company robots"
        description="You can add your robots from here"
        img={menu_section_left}
      
      />
      <HiBranchList />
    </div>
  );
};

export default HiBranch;

