import React, { useEffect, useState } from "react";
import { food, menu_section_left } from "../assets/images";
import CreateIcon from "@mui/icons-material/Create";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";
import { PanoramaFishEye, Visibility } from "@mui/icons-material";
import { useOrderListQuery } from "../constants/redux/api/api";

const OrderList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);

  // RTK QUERY
  const { data: orderData, isSuccess: isListSuccess } = useOrderListQuery();

  useEffect(() => {
    if (isListSuccess) {
      console.log(orderData);
      setOrderList(orderData?.result);
    }
  }, [orderData, isListSuccess]);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}>
      <div className="menu-list">
        <h1>Order List</h1>
      </div>

      {/* table start */}
      <div className="table-responsive menu-table side_pad mt-4">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Table</th>
              <th>Ordered Food</th>
              <th>Total Price</th>
              <th>Status</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>01</td>
              <td>VIP</td>
              <td>Cheese Burger</td>
              <td>₹150.00</td>
              <td>
                <button className="btn-success btn">Completed</button>
              </td>
              <td className="text-center">
                <div className="btn-group">
                  <td className="text-center">
                    <div className="btn-group">
                      <button
                        onClick={() => navigate("/order-details")}
                        type="button"
                        className="btn btn-secondary">
                        <Visibility />
                      </button>
                    </div>
                  </td>
                </div>
              </td>
            </tr>
            <tr>
              <td>02</td>
              <td>01</td>
              <td>Double Cheese Burger</td>
              <td>₹170.00</td>
              <td>
                <button className="btn-warning btn">Pending</button>
              </td>
              <td className="text-center">
                <div className="btn-group">
                  <td className="text-center">
                    <div className="btn-group">
                      <button
                        onClick={() => navigate("/order-details")}
                        type="button"
                        className="btn btn-secondary">
                        <Visibility />
                      </button>
                    </div>
                  </td>
                </div>
              </td>
            </tr> */}
            {orderList?.map((item, ind) => {
              return (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{item?.table_name}</td>
                  {/* <td>#0007</td> */}
                  <td>{item?.orderedfoods[0]?.food}</td>
                  <td>{item?.total}</td>
                  <td>
                    <button
                      className={
                        item?.status == 0
                          ? "btn-info btn"
                          : item?.status == 1
                          ? "btn-warning btn"
                          : item?.status == 2
                          ? "btn-success btn"
                          : "btn-info btn"
                      }>
                      {item?.status == 0
                        ? "Order Taken"
                        : item?.status == 1
                        ? "Prepared"
                        : item?.status == 2
                        ? "Delivered"
                        : "Waiting"}
                    </button>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/order-details", {
                                state: { data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary">
                            <Visibility />
                          </button>
                        </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* table end */}
    </div>
  );
};

export default OrderList;
