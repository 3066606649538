import React, { useState, useEffect } from "react";
import { useNavigate,useLocation} from "react-router-dom";
import { menu_section_left } from "../../assets/images";
import { Delete, Edit } from "@mui/icons-material";
import ScreenHeader from "./ScreenHeader";
import {useMenu_listMutation,useLazyMenu_statusQuery,useMenu_deleteMutation} from "../../constants/redux/api/api"
import { Table } from "../Table/Table";
import Swal from "sweetalert2";
import TopHeader from "../../components/TopHeader";
import { toast } from "react-toastify";
import useUser from "../../constants/Storage/userData";
import VisibilityIcon from "@mui/icons-material/Visibility";

const RestaurantMenuViewScreen = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [dropDownList, setDropDownList] = useState();
  const user = JSON.parse(localStorage.getItem('resturent_users'))
  // const {user, setUser}=useUser();
  const [active, setActive] = useState(true);
  const [datas,setdata] =useState([])
  const [disbtn,setdisbtn] = useState(false)
  const [ids,setid]=useState()
  const [data,setdatas]=useState();
  
  const location =useLocation()
  const branchdata = location?.state?.data
  console.log(branchdata,"data")
  
  let branchId

 const branchIDSetter=()=>{
 if(branchdata?.id){
setid(branchdata?.id)
setdatas(branchdata)
 }else{
setid(user?.company_branch_id)
let result = user?.branches?.find((item)=>item?.id==user?.company_branch_id)
    console.log(result,"resut")
    setdatas(result)
 }  
}

useEffect(()=>{
  branchIDSetter()
  // branchdatas()
},[])

console.log(ids,"menuid")
let result
const branchdatas=()=>{
  if(branchdata==null){
  result= user?.branches.find((item)=>item?.id==branchId)
    console.log(result,"resut")
    setdatas(result)
    // data=result
  }else{
    result= branchdata
    setdatas(result)
  }
  
}



console.log(data,"data")
  //RTk 
  const [menuList]=useMenu_listMutation();
  const [menuState]=useLazyMenu_statusQuery();
  const [menuDelete]=useMenu_deleteMutation();

   const getStatus=(id, branch_id)=>{
    setdisbtn(true)
    menuState(id)
    .unwrap()
    .then(res=>{
      setdisbtn(false)
      console.log(res)
      getmenulist(branch_id)
      toast.success(res?.message)
    })
    .catch(err=>{console.log(err)
      setdisbtn(false)
    })
   }
   
   const deletemenu=(id,branch_id)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
      setdisbtn(true)
    menuDelete(id)
    .unwrap()
    .then(res=>{
      setdisbtn(false)
      toast.success(res?.message)
      getmenulist(branch_id)
    })
    .catch(err=>{
      setdisbtn (false)
      console.log(err)})
   }
  })
   }

   const getmenulist=(id)=>{
    console.log(ids,"get")
    const formdata = new URLSearchParams()
    formdata.append("company_branch_id",id ? id : ids)

    menuList(formdata)
    .unwrap()
    .then(res=>{
      console.log(res)
      setdata(res?.data)
    })
    .catch(err=>err)
   }

  useEffect(()=>{
    if(ids){
     getmenulist()
    }
   
  },[ids])

  const columns = React.useMemo(
    () => [
      {
        
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Food",
        accessor: "Name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Food Image",
        accessor: "image_url.MenuImage",
        Cell: ({ value, row }) => {
          return <img src={value} />;
        },
      },
      // {
      //   Header: "Price",
      //   accessor: "Price",
      //   Cell: ({ value, row }) => {
      //     return <p>{value}</p>;
      //   },
      // },
   
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
             disabled={disbtn}
            onClick={() =>{
              getStatus(row?.original?._id, row?.original?.companyBranch?.id)}
            }
            className={`${
              value
                ? "btn-success"
                : "btn-danger"
            } btn`}
          >
            {value ? "Active" : "Deactive"}
          </button>
          )
        },
      },
     
     
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          // console.log(row)
          return (
            <div className="d-flex justify-content-center gap-2">
              
                <button onClick={
                  ()=>navigate("/restaurant/add-menu",{state:{
                  type:"edit",
                  editdata:row.original,
              }})}  
                
                className="bg-primary text-white border-0 rounded-1 p-1 me-2">
                  <Edit />
                </button>
                {/* <button 
                disabled={disbtn}
                onClick={()=>deletemenu(row?.original?._id,row?.original?.companyBranch?.id)}
                className="bg-warning border-0 rounded-1 p-1">
                  <Delete />
                </button> */}
                <button
                onClick={() =>
                  navigate("/restaurant/add-menu", {
                    state: { type: "views", editdata: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"menu"}/>
      <div className="menu-list">
        <h1>Menu</h1>
        <div className="add-food-section row">
          <div className="form-group col-4">
            <label>Branch Name</label>
            <input
              type="text"
              placeholder="Enter Branch Name"
              className="form-control"
              value={data?.name}
              disabled
            />
          </div>
          <div className="form-group col-4">
            <label>Location</label>
            <input
              type="text"
              value={data?.
                location
                }
              placeholder="Enter Location"
              className="form-control"
              disabled
            />
          </div>
          <div className="form-group col-4">
            <label>Pin Code</label>
            <input
              type="text"
              value={data?.pincode
              }
              placeholder="Enter Pin Code"
              className="form-control"
              disabled
            />
          </div>
        </div>
      </div>
      {/* <ScreenHeader

        title=""
        menuLink="/restaurant/add-menu"
        menuBtn={["Add Food"]}
        menuTitle="Check out your menu"
        description="You can add your menu from here"
        img={menu_section_left}
        // dataes={data}
      /> */}

   <div className="">
      <div className="menu-list">
      </div>
    
      <div className="side_pad">
        <div className="menu_section">
          <div className="align-items-center justify-content-between d-flex flex-md-wrap">
            <div className="align-items-center d-flex ">
              <div className="px-2">
                <img src={menu_section_left} alt="decor" />
              </div>
              <div className="px-2 ">
                <h5>{"Check out your menu"}</h5>
                <p>{"You can add your menu from here"}</p>
              </div>
            </div>
            <div className="d-flex gap-2">
              <button onClick={()=>navigate("/restaurant/add-menu",{state:{
                data:data,
                type:'add'
              }})}  
              className="btn-outline">Add Food</button>
              {/* {Array.isArray(menuBtn) &&
                menuBtn.length > 0 &&
                menuBtn.map((data, i) => {
                  return (
                    <>
                      {symbol && (
                        <button
                          onClick={() =>{
                            // console.log(data,"dat")
                            navigate("/restaurant/add-menu"
                              // Array.isArray(menuLink) ? menuLink[i] : menuLink
                            )
                          }
                          }
                          className="btn-outline"
                          key={i}
                        >
                          {symbol + "  " + data}
                        </button>
                      )}
                      {symbol == undefined && (
                        <button
                          onClick={() =>
                            
                            navigate(
                              Array.isArray(menuLink) ? menuLink[i] : menuLink
                            )
                          }
                          className="btn-outline"
                          key={i}
                        >
                          {data}
                        </button>
                      )} 
                     </>
                  );
                })} */}
            </div>
          </div>
        </div>
      </div>
    </div> 

      {/* <div className="menu-list">
        <h1>Filter</h1>
      </div>
      <MenuViewFormComp
        menuOpen={menuOpen}
        dropDownList={dropDownList}
        setDropDownList={setDropDownList}
      /> */}
      {/* <div className="table-responsive menu-table side_pad">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Food</th>
              <th>Food Image</th>
              <th>Price</th>
              <th>Status</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            <tr>
              <td>1</td>
              <td>Briyani</td>
              <td>
                <img
                  src="/static/media/briyani-thumbnail.a4cb6f90744ceda0e2fd.jpg"
                  alt="img"
                  width={100}
                />
              </td>
              <td>120</td>
              <td>
                <button
                  onClick={() => (active ? setActive(false) : setActive(true))}
                  class={`btn ${active ? "btn-success" : "btn-danger"}`}
                >
                  {active ? "Active" : "DeActive"}
                </button>
              </td>
              <td>
                <button className="bg-primary text-white border-0 rounded-1 p-1 me-2">
                  <Edit />
                </button>
                <button className="bg-warning border-0 rounded-1 p-1">
                  <Delete />
                </button>
              </td>
            </tr> 
          </tbody>
        </table>
      </div> */}
        <div className="table-responsive menu-table side_pad mt-4">
            <Table data={datas} columns={columns}/>
          </div>
    </div>
  );
};

export default RestaurantMenuViewScreen;
