import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserList from "../components/UserList";
import ScreenHeader from "../components/ScreenHeader";
import { menu_section_left } from "../assets/images";
import TopHeader from "../components/TopHeader";

const AdminScreen = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [tableList, setTableList] = useState([]);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"admins"} />
      <ScreenHeader
        title="Admin List"
        menuLink="/admin-add-users"
        menuBtn="Add Admin"
        menuTitle="Check out your Admin"
        description="You can add your admin from here"
        img={menu_section_left}
      />
      <UserList />
    </div>
  );
};

export default AdminScreen;
