import React from "react";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import ScreenHeader from "../../components/ScreenHeader";
// import BranchList from "../../components/CustomerManagement/BranchList";
import { menu_section_left } from "../../assets/images";
import HiRobotList from "./HiRobotList";
import { useCompanyRobotViewRoboMutation } from "../../constants/redux/api/api";


const HiCompanyUsers = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"add-hi-robot"} />
      <ScreenHeader
        title="Hi Robot List"
        menuLink="/add-hi-robots"
        menuBtn="Add Company Robot"
        menuTitle="Check out your Company robots"
        description="You can add your robots from here"
        img={menu_section_left}
      />
      <HiRobotList />
    </div>
  );
};

export default HiCompanyUsers;
