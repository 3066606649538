import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Checkout from "../components/Checkout";
import CheckoutItem from "../components/CheckoutItem";
import { useDispatch, useSelector } from "react-redux";
import { allFoods } from "../storage/dummyData";
import { saveSelectedFoods } from "../constants/redux/slice/selectedFoods";
import SuccessPopup from "../components/SuccessPopup";
import { Link, useNavigate } from "react-router-dom";
import {
  usePlaceOrderMutation,
  useShowCategoriesQuery,
} from "../constants/redux/api/api";
import { BASE_URL, WS_URL } from "../constants/redux/api/constants";
import Websocket from "../components/Websocket";

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storage = localStorage.getItem("name");
  const websocketUrl = WS_URL;

  const selectedFoods = useSelector((state) => state.selectedFoods);
  const [totalValue, setTotalValue] = useState(0);
  const [successPopup, setSuccessPopup] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [webSock, setWebSock] = useState(false);
  const [recieve, setRecieve] = useState("");
  const selectedTable = useSelector((state) => state.selectedTable);

  // RTK QUERY
  const { data: categoryApiData, isSuccess: isCategSuccess } =
    useShowCategoriesQuery();
  const [placeOrder] = usePlaceOrderMutation();

  useEffect(() => {
    if (isCategSuccess) {
      console.log(categoryApiData);
      setCategoryData(categoryApiData?.result);
    }
  }, [categoryApiData, isCategSuccess]);

  useEffect(() => {
    if (isCategSuccess) {
      let tmp = [];
      categoryData?.forEach((el) => {
        tmp = [...tmp, ...el?.categoryItems];
      });
      setCategoryItems(tmp);
    }
  }, [categoryData, isCategSuccess]);

  const onMinusClick = (item) => {
    const tmp = { ...selectedFoods };
    if (tmp[item?.food] == 1) {
    } else {
      tmp[item?.food] -= 1;
      dispatch(saveSelectedFoods(tmp));
    }
  };

  const onAddClick = (item) => {
    const tmp = { ...selectedFoods };
    tmp[item?.food] += 1;
    dispatch(saveSelectedFoods(tmp));
  };

  const onRemoveClick = (item) => {
    const tmp = { ...selectedFoods };
    tmp[item?.food] = null;
    dispatch(saveSelectedFoods(tmp));
  };

  useEffect(() => {
    let tmp = 0;
    categoryItems.forEach((item) => {
      if (selectedFoods[item?.food]) {
        const price = Number(item.price);
        let totalPrice = price * selectedFoods[item?.food];
        tmp += totalPrice;
        setTotalValue(tmp);
      }
    });
    const values = Object.values(selectedFoods);
    const checkAllNull = values?.every((el) => el == null);
    // console.log(checkAllNull);
    if (checkAllNull) {
      setTotalValue(0);
    }
  }, [selectedFoods, categoryData, categoryItems]);

  const onPlaceOrder = async () => {
    // const tmp = JSON.stringify(selectedFoods);
    // localStorage.setItem("selectedItems", tmp);

    const values = Object.values(selectedFoods);
    const checkAllNull = values?.every((el) => el == null);
    if (!checkAllNull) {
      // setSuccessPopup(true);
      console.log("categoryData", categoryData);
      let payload = [];

      categoryData?.forEach((item) => {
        const categoryItems = item?.categoryItems;
        categoryItems?.forEach((it) => {
          if (selectedFoods[it?.food]) {
            console.log(it?.food);
            const tmp = {
              sub_id: it?.id,
              qty: selectedFoods[it?.food],
              cat_id: item?.categoryNameId,
            };
            payload.push(tmp);
          }
        });
      });
      console.log(payload);
      console.log(selectedTable);
      // const formData = new FormData();
      // formData.append("table_id", selectedTable);

      // const urlencoded = new URLSearchParams();
      // urlencoded.append("table_id", selectedTable);
      // payload?.forEach((item, index) => {
      //   urlencoded.append(`foods[${index}][cat_id]`, item?.cat_id);
      //   urlencoded.append(`foods[${index}][sub_id]`, item?.sub_id);
      //   urlencoded.append(`foods[${index}][qty]`, item?.qty);
      // });
      // placeOrder(urlencoded)
      //   .unwrap()
      //   .then((res) => {
      //     if (res?.status === "success") {
      //       console.log(res);
      //     }
      //   })
      //   .catch((err) => console.log(err));
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("table_id", selectedTable);
      payload?.forEach((item, index) => {
        urlencoded.append(`foods[${index}][cat_id]`, item?.cat_id);
        urlencoded.append(`foods[${index}][sub_id]`, item?.sub_id);
        urlencoded.append(`foods[${index}][qty]`, item?.qty);
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(BASE_URL + "place-order/create", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setSuccessPopup(true);
          setTimeout(() => {
            setSuccessPopup(false);
            setWebSock(true);
          }, 5000);
        })
        .catch((error) => console.log("error", error));
    }
  };

  useEffect(() => {
    let split = recieve.split(":");
    if (storage == split[0] && split[1] == 0) {
      navigate("/order");
    }
  }, [recieve]);

  const onClickBack = () => {
    setSuccessPopup(false);
    dispatch(saveSelectedFoods({}));
    navigate("/menu");
  };

  return (
    <div className="checkout-page">
      {webSock && <Websocket setRecieve={setRecieve} type={"kitchen"} />}
      <Header type="checkout" />
      <div className="order-value">
        <p>Order List</p>
        <p>Total Value: ₹{totalValue}</p>
      </div>
      {categoryItems.map((item) => {
        if (selectedFoods[item?.food] >= 1)
          return (
            <CheckoutItem
              onMinusClick={onMinusClick}
              onAddClick={onAddClick}
              onRemoveClick={onRemoveClick}
              qty={selectedFoods[item?.food]}
              item={item}
              type={"placeOrder"}
            />
          );
      })}
      {/* CHECKOUT COMP */}
      {/* <Checkout onPlaceOrder={onPlaceOrder} type="checkout" /> */}
      <div className="checkout-btm">
        <p>Total Value: ₹{totalValue}</p>
        <div class="checkout-btn">
          <div
            style={{ color: "#fff" }}
            onClick={() => navigate("/menu", { state: { type: "addMore" } })}
          >
            {/* <Link to={"/menu"} className="link"> */}
            {"Add More"}
            {/* </Link> */}
          </div>
          <div onClick={onPlaceOrder}>
            <Link to={null} className="link">
              {"Place Order"}
            </Link>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "30px" }}></div>
      {successPopup && <SuccessPopup onClickBack={onClickBack} />}
    </div>
  );
};

export default CheckoutPage;
