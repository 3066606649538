import React, { useEffect, useState } from "react";
import { food, menu_section_left } from "../assets/images";
import { useNavigate } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  useGetTablesAdminQuery,
  useLazyTableStatusChangeQuery,
} from "../constants/redux/api/api";
import ScreenHeader from "../components/ScreenHeader";
import { customer, product, revenue, sales } from "../assets/img";
import RecentOrders from "./RecentOrders";
import StaticsOrder from "./StaticsOrder";

const TableList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [tableList, setTableList] = useState([]);

  //   RTK QUERY
  // const {
  //   data: tableData,
  //   isSuccess: isTableSuccess,
  //   refetch: refetchTables,
  // } = useGetTablesAdminQuery();
  // const [tableStatusChange] = useLazyTableStatusChangeQuery();

  // useEffect(() => {
  //   if (isTableSuccess) {
  //     console.log(tableData);
  //     setTableList(tableData?.result);
  //   }
  // }, [tableData, isTableSuccess]);

  const onActiveClick = (item) => {
    // tableStatusChange(item?._id)
    //   .unwrap()
    //   .then((res) => {
    //     if (res?.status === "success") {
    //       console.log(res);
    //       // refetchTables();
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  //   delete table data event :
  const onClickDelete = (item) => {
    if (item) {
      // const tableArr  = tableData?.result;
      // const filterArr = Array.isArray(tableArr) && tableArr.filter(data => data.table_name != item[`table_name`]);
    }
  };

  return (
    <div
      className={
        menuOpen ? "side-content2 menu-list" : "side-content menu-list"
      }
      style={{ transition: "0.5s" }}
    >
      <h1 className="m-0">Filter</h1>
      <div className="w-100 add-food-section">
        <div className="d-md-flex w-100 ps-2 side_pad align-items-center">
          <div className="form-group mx-2">
            <label className="text1 py-2">From Date </label>
            <input
              type="date"
              name="start date"
              className="form-control me-3"
              placeholder="Enter From Date"
            />
          </div>
          <div className="form-group mx-2">
            <label className="text1 py-2">To Date </label>
            <input
              type="date"
              name="end date"
              className="border-0 form-control"
              placeholder="Enter To Date"
            />
          </div>
          <div className="form-group mx-2">
            <label className="text1 py-2">Robot Type</label>
            <select
              type="text"
              name="end date"
              className="form-control"
              style={{ width: "200px" }}
              placeholder="Enter Phone No"
            >
              <option selected disabled hidden>
                Select Robot
              </option>
              <option>Robot 1</option>
              <option>Robot 2</option>
              <option>Robot 3</option>
            </select>
          </div>
          <div className="add-food-section2 mx-2 mt-lg-5 p-0 bg-transparent form-group">
            <input className="btn btn-primary btn-submit" type="submit" />
          </div>
        </div>
      </div>
      <div className="menu-list d-flex align-items-center justify-content-between w-97 ">
        <h1 className="m-0">Statistics Card</h1>
        {/* <button
          style={{ borderColor: "#25aae1", backgroundColor: "#000" }}
          onClick={() => navigate("/add-table")}
          className="btn-outline primary1">
          + Add Table
        </button> */}
      </div>

      {/* table start */}
      {/* <div className="table-responsive menu-table side_pad mt-4">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Table Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {tableList?.map((item, ind) => {
              return (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{item?.table_name}</td>
                  <td>
                    <button
                      onClick={() => onActiveClick(item)}
                      className={`${
                        item?.status == 1 ? "btn-success" : "btn-danger"
                      } btn`}
                    >
                      {item?.status == 1 ? "Active" : "De-Active"}
                    </button>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/add-table", {
                                state: { type: "edit", data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary"
                          >
                            <CreateIcon />
                          </button>
                          <button
                            onClick={() => onClickDelete(item)}
                            type="button"
                            className="btn btn-secondary"
                          >
                            <CloseOutlinedIcon />
                          </button>
                        </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}

      <StaticsOrder />

      {/* table end */}

      {/* Changed Table start */}
      <div className="d-flex ac-jc mb-4 table-responsive menu-table side_pad">
        <div className="col-lg-3 col-sm-6 col-12 mt-3">
          <div className="dash_card">
            <div className="d-flex ac-jc">
              <div className="col-9 dash_count">
                <h2>200</h2>
                <h3>Sales</h3>
              </div>
              <div className="col-3 dash_icon">
                <div className="ic_on">
                  <img src={sales} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mt-3">
          <div className="dash_card">
            <div className="d-flex ac-jc">
              <div className="col-9 dash_count">
                <h2>25</h2>
                <h3>Customers</h3>
              </div>
              <div className="col-3 dash_icon">
                <div className="ic_on">
                  <img src={customer} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mt-3">
          <div className="dash_card">
            <div className="d-flex ac-jc">
              <div className="col-9 dash_count">
                <h2>04</h2>
                <h3>Robots</h3>
              </div>
              <div className="col-3 dash_icon">
                <div className="ic_on">
                  <img src={product} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mt-3">
          <div className="dash_card">
            <div className="d-flex ac-jc">
              <div className="col-9 dash_count">
                <h2>₹50k</h2>
                <h3>Revenue</h3>
              </div>
              <div className="col-3 dash_icon">
                <div className="ic_on">
                  <img src={revenue} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section 2 */}

      <div className="table-responsive menu-table side_pad">
        <div className="page_title menu-list">
          <h1 className="m-0">Recent Orders</h1>
          {/* <div className="robo_table mt-4">
            <table>
              <thead>
                <tr>
                  <th scope="col">Count</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Order No</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Product Cost</th>
                  <th scope="col">Date</th>
                  <th scope="col">Payment Mode</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Count">01</td>
                  <td data-label="Client Name">Henry Klein</td>
                  <td data-label="Order No">02312</td>
                  <td data-label="Product Name">RO - Waiter</td>
                  <td data-label="Product Cost">₹14,500</td>
                  <td data-label="Date">15th May 2023</td>
                  <td data-label="Payment Mode">COD</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    02
                  </td>
                  <td data-label="Client Name">Estella Bryan </td>
                  <td data-label="Order No">02313</td>
                  <td data-label="Product Name"> RO - Waiter</td>
                  <td data-label="Product Cost">₹14,500</td>
                  <td data-label="Date">15th May 2023</td>
                  <td data-label="Payment Mode">Card</td>
                </tr>
              </tbody>
            </table>
          </div> */}
          <RecentOrders />
        </div>
      </div>

      {/* Changed Table end */}

      <ScreenHeader
        // title="Statistics Card"
        menuLink="/add-users"
        menuBtn="Add New Company"
        menuTitle="Add Company for Mapping"
        description="Please Add Company for mapping"
        img={menu_section_left}
      />
    </div>
  );
};

export default TableList;
