import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import {
  useLazyRobots_viewQuery,
  useRobots_updateMutation,
  useRobotsEditMutation,
  useRobot_addMutation,
  useRobo_deleteMutation,
  useRole_updateMutation,
} from "../../constants/redux/api/api";
import { loadergif } from "../../assets/img";
const AddRobotType = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const editType = location?.state?.type;
  const editData = location?.state?.data;
  const [loading, setLoading] = useState(false);
  //   RTK QUERY
  const [robot_add] = useRobot_addMutation();
  const [robots_edit] = useLazyRobots_viewQuery();
  const [robotsUpdate] = useRobotsEditMutation();

  const [spec, setSpec] = useState([{ specif1: "sas" }]);
  const [feature, setFeature] = useState([{ feat1: "" }]);
  const [hardWare, setHardWare] = useState([{ feat3: "" }]);
  const [images, setImage] = useState([]);
  const [attachFiles, setAttachFile] = useState("");
  const [specificationsArr, setSpecificationArr] = useState([]);
  const [featureArr, setFeatureArr] = useState([]);
  const [hardwareArr, setHardwareArr] = useState([]);
  const [trimfile,setTrimfile]=useState("")
  const [addFormData, setAddFormData] = useState({
    name: "",
    software_name: "",
    specifications: specificationsArr,
    features: featureArr,
    hardwares: hardwareArr,
    description: "",
    images: images,
    attachments: attachFiles,
  });

  const [specification, setSpecification] = useState([{ specifications: "" }]);
  const [hardware, setHardware] = useState([{ hardware: "" }]);
  const [features, setFeatures] = useState([{ features: "" }]);
  const [btn, setBtn] = useState(false);
  const[document,setDocument]=useState(false)
  const [docatt,setDocatt]=useState("")

  const onClickSpecification = () => {
    setSpec([...spec, { specif1: "" }]);
  };

  const handleRemoveInput = (ind) => {
    const newInputs = [...spec];
    newInputs.splice(ind, 1);
    setSpec(newInputs);
  };
  const handleRemoveFeature = (ind) => {
    const newInputs = [...feature];
    newInputs.splice(ind, 1);
    setFeature(newInputs);
  };
  const handleRemoveHardware = (ind) => {
    const newInputs = [...hardWare];
    newInputs.splice(ind, 1);
    setHardWare(newInputs);
  };
  const onClickFeatureHandler = () => {
    setFeature([
      ...feature,
      {
        feat1: "",
      },
    ]);
  };
  const onClickHardwareHandler = () => {
    setHardWare([
      ...hardWare,
      {
        feat3: "",
      },
    ]);
  };

  const viewList = () => {
    robots_edit(editData?._id)
      .unwrap()
      .then((res) => {
        console.log("res", res);
        let data = res?.robot;
        setAddFormData({
          name: data?.name,
          software_name: data?.software_name,
          description: data?.description,
          specifications: data?.specifications,
          features: data?.features,
          hardwares: data?.hardwares,
        });

        setImage(data?.image_urls?.imageUrls[0])
        setAttachFile(data?.image_urls?.attachmentUrls[0])
        setDocatt(data?.image_urls?.attachmentUrls[0])
        let specificationList = [];
        data?.specifications?.map((item, ind) => {
          specificationList.push({
            specifications: item,
          });
        });
        let hardwaresList = [];
        data?.hardwares?.map((item, ind) => {
          hardwaresList.push({
            hardware: item,
          });
        });
        let featuresList = [];
        data?.features?.map((item, ind) => {
          featuresList.push({
            features: item,
          });
        });

        setSpecification(specificationList);
        setHardware(hardwaresList);
        setFeatures(featuresList);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("editData", editData?.id);

  //   Form submit function
  const handleSubmitForm = async () => {
    // form data Append
    const urlencoded = new FormData();
    urlencoded.append("name", addFormData?.name);
    urlencoded.append("software_name", addFormData?.software_name);
    urlencoded.append("description", addFormData?.description);

    const specificationData = addFormData?.specifications || [];
    const featureData = addFormData?.features || [];
    const hardwareData = addFormData?.hardwares || [];
    const imageData = addFormData?.images || [];
    const attachmentData = addFormData?.attachments || [];

    if (
      (Array.isArray(specificationData) && specificationData.length > 0) ||
      (Array.isArray(featureData) && featureData.length > 0) ||
      (Array.isArray(hardwareData) && hardwareData.length > 0) ||
      (Array.isArray(attachmentData) && attachmentData.length > 0) ||
      (Array.isArray(imageData) && imageData.length > 0)
    ) {
      // specificationData.forEach((data, index) => {
      //   urlencoded.append(`specifications[${index}]`, data);
      // });

      // featureData.forEach((data, index) => {
      //   urlencoded.append(`features[${index}]`, data);
      // });

      // hardwareData.forEach((data, index) => {
      //   urlencoded.append(`hardwares[${index}]`, data);
      // });

      imageData.forEach((item, ind) => {
        if (item?.name) {
          urlencoded.append(`images`, item);
        }
      });

      attachmentData.forEach((item, i) => {
        if (item?.name) {
          urlencoded.append(`attachments`, item);
        }
      });
    }

    specification?.forEach((data, index) => {
      if (data?.specifications) {
        urlencoded.append(`specifications[${index}]`, data?.specifications);
      }
    });

    features?.forEach((data, index) => {
      if (data?.features) {
        urlencoded.append(`features[${index}]`, data?.features);
      }
    });

    hardware?.forEach((data, index) => {
      if (data?.hardware) {
        urlencoded.append(`hardwares[${index}]`, data?.hardware);
      }
    });

    if (editType == "edit") {
      setLoading(true)
      setBtn(true);
      robotsUpdate({ payload: urlencoded, id: editData?.id })
        .unwrap()
        .then((res) => {
          setLoading(false)
          setBtn(false);
          // console.log("robotsUpdate", res);
          navigate(-1);
        })
        .catch((err) => {
          setLoading(false)
          setBtn(false);
          console.log(err, "error");
        });
    } else {
      setBtn(true);
      setLoading(true)
      robot_add(urlencoded)
        .unwrap()
        .then((res) => {
          setLoading(false)
          setBtn(false);
          console.log("rse", res);
          navigate(-1);
        })
        .catch((err) => {
          setLoading(false)
          setBtn(false);
          console.log(err, "error");
        });
    }
  };

  const handleOnChangeInput = (e, type, index) => {
    const inputName = e.target.name;

    if (inputName && type === undefined) {
      setAddFormData({
        ...addFormData,
        [inputName]: e.target.value,
      });
    } else if (type === "specifications") {
      specificationsArr[index] = e.target.value;
      setSpecificationArr(specificationsArr);
      setAddFormData({
        ...addFormData,
        [type]: specificationsArr,
      });
    } else if (type === `features`) {
      featureArr[index] = e.target.value;
      setFeatureArr(featureArr);
      setAddFormData({
        ...addFormData,
        [type]: featureArr,
      });
    } else if (type === "hardwares") {
      hardwareArr[index] = e.target.value;
      setHardwareArr(hardwareArr);
      setAddFormData({
        ...addFormData,
        [type]: hardwareArr,
      });
    } else if (type === "images") {
      var files = e.target.files;
      setImage((prev) => [...prev, ...files]);
      setAddFormData({
        ...addFormData,
        [type]: [...images, ...files],
      });
    } else if (type === "attachments") {
      let files = e.target.files;
      setAttachFile((prev) => [...prev, ...files]);
      setAddFormData({
        ...addFormData,
        [type]: [...attachFiles, ...files],
      });
    }
  };

  // console.log("addFormData", addFormData);

  useEffect(() => {
    if (editData?._id&&(editType == "edit" ||editType=="view")) {
      viewList();
    }
  }, []);

  // SPECIFICATION
  const addSpecification = () => {
    let temp = [...specification];
    temp.push({ specifications: "" });
    setSpecification(temp);
  };

  const removeSpecification = (ind) => {
    let temp = [...specification];
    temp.splice(ind, 1);
    setSpecification(temp);
  };

  const handleSpecification = (event, ind) => {
    let temp = [...specification];
    let name = event.target.name;
    let value = event.target.value;

    temp[ind][name] = value;
    setSpecification(temp);
  };

  // FEATURE
  const addFeature = () => {
    let temp = [...features];
    temp.push({ specifications: "" });
    setFeatures(temp);
  };

  const removeFeature = (ind) => {
    let temp = [...features];
    temp.splice(ind, 1);
    setFeatures(temp);
  };

  const handleFeature = (event, ind) => {
    let temp = [...features];
    let name = event.target.name;
    let value = event.target.value;

    temp[ind][name] = value;
    setFeatures(temp);
  };

  // HARDWARE
  const addHardware = () => {
    let temp = [...hardware];
    temp.push({ specifications: "" });
    setHardware(temp);
  };

  const removeHardware = (ind) => {
    let temp = [...hardware];
    temp.splice(ind, 1);
    setHardware(temp);
  };

  const handleHardware = (event, ind) => {
    let temp = [...hardware];
    let name = event.target.name;
    let value = event.target.value;

    temp[ind][name] = value;
    setHardware(temp);
  };

  const onClick = ()=>{
  
      setDocument(true) 
    
  }
  console.log("doc",attachFiles)
  const doc=()=>{
    if(editType=="edit"||editType=="view"){
      // if(addFormData?.image>0){
  
        const url = docatt
        const trimmedFilename = url.substring(url.lastIndexOf('/') + 1);
        setTrimfile(trimmedFilename)
      }
    // } 
  }
  useEffect(()=>{
    if(docatt){
       doc()
    }
  },[docatt])
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <TopHeader type={editType === "edit" ? "edit-robo" : "add-robo"} />
      <div className="menu-list">
        <h1>{editType=="view"?"View Robot":(editType === "edit" ? "Edit Robot" : "Add Robot")}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Robot Name
                </label>
                <input
                 disabled={editType=="view"?true:false}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Robot Name"
                  value={addFormData?.name}
                  onChange={(e) => handleOnChangeInput(e)}
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Software Name
                </label>
                <input
                   disabled={editType=="view"?true:false}
                  name="software_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Software Name"
                  value={addFormData?.software_name}
                  onChange={(e) => handleOnChangeInput(e)}
                />
              </div>

              {specification?.map((it, ind) => {
                return (
                  <div
                    className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3"
                    key={ind}
                  >
                    <label className="mb-2" htmlFor="">
                      Specification {ind + 1}
                    </label>
                    <div className="d-flex w-100">
                      <input
                        disabled={editType=="view"?true:false}
                        name="specifications"
                        type="text"
                        value={it?.specifications}
                        className="form-control"
                        placeholder="Enter Specification"
                        onChange={(e) => {
                          handleOnChangeInput(e, `specifications`, ind);
                          handleSpecification(e, ind);
                        }}
                      />
                      {specification?.length === ind + 1 && (
                        <button
                          onClick={() => addSpecification()}
                          className="border-0 ad-btn"
                        >
                          + Add
                        </button>
                      )}

                      <button
                        onClick={() => removeSpecification(ind)}
                        className="border-0 ad-btn bg-secondary "
                      >
                        x
                      </button>
                    </div>
                  </div>
                );
              })}
              {features?.map((it, ind) => {
                return (
                  <div
                    className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3"
                    key={ind}
                  >
                    <label className="mb-2 " htmlFor="">
                      Feature {ind + 1}
                    </label>
                    <div className="d-flex w-100">
                      <input
                        disabled={editType=="view"?true:false}
                        name="features"
                        type="text"
                        className="form-control"
                        placeholder="Enter Feature"
                        value={it?.features}
                        onChange={(e) => {
                          handleOnChangeInput(e, `features`, ind);
                          handleFeature(e, ind);
                        }}
                      />
                      {features?.length === ind + 1 && (
                        <button
                          onClick={() => addFeature()}
                          className="border-0 ad-btn"
                        >
                          + Add
                        </button>
                      )}

                      <button
                        onClick={() => removeFeature(ind)}
                        className="border-0 ad-btn bg-secondary "
                      >
                        x
                      </button>
                    </div>
                  </div>
                );
              })}

              {/* hardeare */}
              {hardware?.map((it, ind) => {
                return (
                  <div
                    className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3"
                    key={ind}
                  >
                    <label className="mb-2 " htmlFor="">
                      Hardware {ind + 1}
                    </label>
                    <div className="d-flex w-100">
                      <input
                        disabled={editType=="view"?true:false}
                        name="hardware"
                        type="text"
                        className="form-control"
                        placeholder="Enter Hardware"
                        value={it?.hardware}
                        onChange={(e) => {
                          handleOnChangeInput(e, "hardwares", ind);
                          handleHardware(e, ind);
                        }}
                      />
                      {hardware?.length === ind + 1 && (
                        <button
                          onClick={() => addHardware()}
                          className="border-0 ad-btn"
                        >
                          + Add
                        </button>
                      )}
                      <button
                        onClick={() => removeHardware(ind)}
                        className="border-0 ad-btn bg-secondary "
                      >
                        x
                      </button>
                    </div>
                  </div>
                );
              })}

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Description
                </label>
                <textarea
                   disabled={editType=="view"?true:false}
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  id=""
                  cols="2"
                  rows="2"
                  value={addFormData?.description}
                  onChange={(e) => handleOnChangeInput(e)}
                ></textarea>
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  image
                </label>
                <input
                  disabled={editType=="view"?true:false}
                  name="images"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleOnChangeInput(e, "images")}
                  className="form-control"
                />
                  
                {(editType=="view"||editType=="edit")&&<div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                {images && (
                  <img
                    src={images}
                    style={{ width: "100px" }}
                  />
                )}
               </div>}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Attachment
                </label>
                <input
                  disabled={editType=="view"?true:false}
                  name="attachements"
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handleOnChangeInput(e, "attachments")}
                  className="form-control"
      
                  multiple
                />
                {(editType=="view"||editType=="edit")&&
                
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  
                  <p>{trimfile}</p>
                  <button onClick={()=>onClick() } className="border-0 ad-btn bg-danger ">Download</button>
  {document && (
    <iframe src={attachFiles} style={{ height: "0px" }}></iframe>)}    
</div>}
</div>
             {editType!="view"&&<div className="px-md-3 pe-3">
                {editType === "edit" ? (
                  <button
                    className="btn-submit"
                    onClick={() => handleSubmitForm()}
                    disabled={btn ? true : false}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn-submit"
                    onClick={() => handleSubmitForm()}
                    disabled={btn ? true : false}
                  >
                    Submit
                  </button>
                )}
              </div>}
              {editType!="view"&&<div onClick={() => navigate(-1)} className="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRobotType;
