import React, { useEffect, useState } from "react";
import { useLazyList_restaurant_menuQuery } from "../../constants/redux/api/api";

const MenuViewFormComp = ({ dropDownList, seletBranch, data, List }) => {
  return (
    <div className="side_pad">
      <div className="add-food-section">
        <div action="">
          <div className="d-flex flex-wrap">
            <div className="col-md-4 col-12 px-md-3 px-0 position-relative mb-3">
              <label className="mb-2" htmlFor="">
                Company Name
              </label>
              <input
                name="name"
                type="text"
                disabled
                value={data?.data?.company_name?.companyname}
                className="form-control"
                placeholder="Enter Company Name"
              />
            </div>
            <div className="col-md-3 col-12 px-md-3 px-0 position-relative mb-3">
              <label className="mb-2" htmlFor="">
                Client Name
              </label>
              <input
                name="name"
                type="text"
                disabled
                value={data?.data?.company_name?.clientname}
                className="form-control"
                placeholder="Enter Company Name"
              />
            </div>
            <div className="col-md-5 col-12 px-md-3 px-0 position-relative mb-3">
              <label className="mb-2" htmlFor="">
                Branch
              </label>
              <select
                className="form-control"
                value={dropDownList}
                onChange={(e) => seletBranch(e.target.value)}
                name=""
                id=""
              >
                <option selected value={""} disabled>
                  Select Branch
                </option>
                <option value={"All"}>All</option>
                {data?.data?.company_branch_details?.map((item, ind) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuViewFormComp;
