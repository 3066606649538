import { useNavigate } from "react-router-dom";


const RobotEdit =({menuOpen})=>{
    const navigate = useNavigate()
    return(
        <div
        className={menuOpen ? "side-content2" : "side-content"}
        style={{ transition: "0.5s" }}
      >
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter company name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Branch Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter branch name"
                />
              </div>
  
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Table ID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter table ID"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Table Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter table name"
                />
              </div>
              <div className="px-md-3 pe-3">
                <input className="btn-submit" type="submit" />
              </div>
              <div
                className="btn-outline2 cp ac-jc d-flex"
                onClick={() => {
                  navigate("/branch-details");
                }}
              >
                <p className="mb-0">Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default RobotEdit;