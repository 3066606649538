import React, { useEffect, useState } from "react";
import {
  useLazyRoles_modulesQuery,
  useLazyRole_viewQuery,
  useRole_addMutation,
  useRole_updateMutation,
} from "../../constants/redux/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TopHeader from "../../components/TopHeader";

const AddRolesNew = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [roleName, setRoleName] = useState("");
  const [moduleArray, setModuleArray] = useState({});
  const location = useLocation();
  const [RolesList, setRoleList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [btn, setBtn] = useState(false);

  // RTK query
  const [role_add] = useRole_addMutation();
  const [role_modules] = useLazyRoles_modulesQuery();
  const [role_view] = useLazyRole_viewQuery();
  const [role_update] = useRole_updateMutation();

  const getList = () => {
    role_modules()
      .then((res) => {
        // console.log("role_modules", res);
        setRoleList(res?.data?.result);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const getView = () => {
    role_view(location?.state?.data?._id)
      .unwrap()
      .then((res) => {
        // console.log("getView", res);
        setRoleName(res?.data?.name);
        res?.data?.module?.map((item, ind) => {
          setModuleArray((val) => ({
            ...val,
            [item?.moduleName]: item?.permissions,
          }));
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("moduleArray", moduleArray);

  const handleClickAdd = () => {
    if (roleName !== null && roleName !== "") {
      let temp = {
        name: roleName,
        type: 2,
      };

      var urlencoded = new URLSearchParams();

      urlencoded.append("name", roleName);
      urlencoded.append("type", "admin");

      Object.keys(moduleArray).map((item, ind) => {
        if (moduleArray[item]?.length > 0) {
          urlencoded.append(`module[${ind}][moduleName]`, item);
          moduleArray[item]?.map((per, index) => {
            urlencoded.append(`module[${ind}][permissions][${index}]`, per);
          });
        }
      });

      setBtn(true);
      if (location?.state?.type === "edit") {
        role_update({ id: location?.state?.data?._id, payload: urlencoded })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status === "success") {
              toast.success(res?.message);
              navigate("/roles-permissions");
            }
          })
          .catch((err) => {
            console.log(err, "err");
            setBtn(false);
          });
      } else {
        role_add(urlencoded)
          .unwrap()
          .then((res) => {
            if (res?.status === "success") {
              toast.success(res?.message);
              setBtn(false);
              navigate("/roles-permissions");
            }
          })
          .catch((err) => {
            console.log(err, "err");
            setBtn(false);
          });
      }
    }
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      let updatedModuleArray = {};
      RolesList.forEach(item => {
        updatedModuleArray[item.code] =item.permissions; 
      });
      setModuleArray(updatedModuleArray);
    } else {
      setModuleArray({});
    }
  };

  const toggleModuleSelection = (moduleCode, checked) => {
    if (checked) {
      const updatedModuleArray = { ...moduleArray };
      updatedModuleArray[moduleCode] = RolesList.find(item => item.code === moduleCode)?.permissions || [];
      setModuleArray(updatedModuleArray);
    } else {
  
      const updatedModuleArray = { ...moduleArray };
      delete updatedModuleArray[moduleCode];
      setModuleArray(updatedModuleArray);
    }
  };
  const handleChecked = (id, ind, code, index) => {
    // USED
    if (moduleArray[code]) {
      let objOne = [...moduleArray[code]];
      console.log(objOne,"code")
      if (objOne.includes(id)) {
        let ids = objOne.indexOf(id);
        objOne.splice(ids, 1);
      } else {
        objOne.push(id);
      }

      setModuleArray((val) => ({ ...val, [code]: objOne }));
    } else {
      setModuleArray((val) => ({ ...val, [code]: [id] }));
      console.log(moduleArray)
    }

    // NOT USED
    let roleId = [...permissions];
    if (roleId.includes(id)) {
      let ids = roleId.indexOf(id); 
      roleId.splice(ids, 1);
      setPermissions(roleId);  
    } else {
      roleId.push(id);
      setPermissions(roleId);
    }
  };

  useEffect(() => {
    if (location?.state?.type === "edit"||location?.state?.type === "view") {
      getView();
    }
  }, []);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader
        type={location?.state?.type === "edit" ? "editRole" : "addRole"}
      />
      <div className="menu-list d-flex align-items-center justify-content-between w-97 me-3">
        <h1 className="m-0">
          {location?.state?.type === "view"?"View Role":(location?.state?.type === "edit" ? "Edit Role" : "Add Role")}
        </h1>
 {/* {lo} */}
{location?.state?.type !== "view"&&<button
  className="radio-btns mb-2 border-0 bg-transparent text-white d-flex align-items-center justify-content-center"
>
  <div
    className="check-box1 me-2"
    style={{
      border: selectAll ? "1px solid red" : "1px solid red",
      backgroundColor: selectAll ?  "transparent":" ",
    }}
    onClick={toggleSelectAll}
  >
    <div className={selectAll ? "check-box11" : ""}></div>
  </div>
  <p className="mb-0 text-nowrap">Select All</p>
</button>}
      </div>
      <div className="col-md-6 col-12 ms-0 ps-0  px-0 position-relative mb-3">
        <label className="mb-2" htmlFor="">
          Roles Name
        </label>
        <input
          disabled={location?.state?.type === "view"?true:false}
          name="name"
          value={roleName}
          type="text"
          className="form-control ms-0"
          placeholder="Enter Roles Name"
          onChange={(e) => setRoleName(e.target.value)}
        />
      </div>
      <div className="w-100 d-flex flex-wrap gap-2">
        {RolesList?.map((item, index) => {
          return (
            <div className="role-boxes" key={index}>
               
              <button
  className="radio-btns mb-2 border-0 bg-transparent text-white d-flex align-items-center justify-content-center"
  onClick={(e) => toggleModuleSelection(item.code, !moduleArray[item.code]?.length)}
  disabled={location?.state?.type === "view"}
>
  <div
    className="check-box1 me-2"
    style={{
      border: moduleArray[item.code]?.length > 0 ? "1px solid red" : "1px solid red",
      backgroundColor: moduleArray[item.code]?.length > 0 ?  "transparent":""
    }}
  >
    <div className={moduleArray[item.code]?.length > 0 ? "check-box11" : ""}></div>
  </div>
  <h5>{item?.code}</h5>
</button>
              <div>
                {item?.permissions?.map((data, ind) => {
                  return (
                    <button
                      key={ind}
                      className="radio-btns mb-2 border-0 bg-transparent text-white d-flex align-items-center justify-content-center"
                      disabled={location?.state?.type === "view"}
                    >
                      <div
                      // disabled  
                        className="check-box1 me-2"
                        // disabled={location?.state?.type === "view"?true:false}
                        disabled={location?.state?.type === "view"}
                        onClick={(e) => {
                          handleChecked(data, ind, item?.code, index);
                        }}
                      >
                        <div
                          className={
                            moduleArray?.[item?.code]?.includes(data)
                              ? "check-box11"
                              : ""
                          }   
                        ></div>
                      </div>
                      <p 
                      className="mb-0 text-nowrap">{data}</p>
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {location?.state?.type != "view"&&<div className="py-3">
        <button
          style={{ borderColor: "#25aae1", backgroundColor: "#000" }}
          className="btn-outline add_role"
          
          onClick={() => {
            handleClickAdd();
          }}
          disabled={btn ? true : false}
        >
          {location?.state?.type === "edit" ? "Edit Role" : "Add Role"}
        </button>
      </div>}
    </div>
  );
};

export default AddRolesNew;
