import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useLazyProfileQuery} from "../../constants/redux/api/api"

const RestaurantProfileDetailScreen = ({ menuOpen }) => {
  const navigate = useNavigate();

//RTK
 const [profileApi]=useLazyProfileQuery()

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("12345678");
  const [userRole, setUserRole] = useState("");
  const [data,setdata]=useState({})
   const getprofile=()=>{
    profileApi()
    .unwrap()
    .then(res=>{
      console.log(res?.data)
      setdata(res?.data)
    })
    .catch()
    
   }
   useEffect(()=>{
     getprofile()
   },[])

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list">
        <h1>Profile Detail</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Name
                </label>
                <input
                  disabled
                  value={data?.clientname}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Email
                </label>
                <input
                  disabled
                  value={data?.
                    emailid
                    }
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="Enter Email name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Phone No
                </label>
                <input
                  disabled
                  value={data?.
                    contactnumber}
                  name="phone"
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone No"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Role
                </label>
                <input
                  disabled
                  value={"Company admin"}
                  // name="phone"
                  type="text"
                  className="form-control"
                  // placeholder="Enter Phone No"
                />
                {/* <select className="form-control" disabled name="" id="">
                  <option value="Super Admin">{userRole}</option>
                </select> */}
              </div>
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                                <label className="mb-2" htmlFor="">
                                    Password
                                </label>
                                <input
                                    disabled
                                    value={password}
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter Password"
                                />
                            </div> */}
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3 invisible invissble">
                                <label className="mb-2" htmlFor="">
                                    Confirm Password
                                </label>
                                <input
                                    disabled
                                    name="confirmPassword"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Confirm Password"
                                />
                            </div> */}
              <div onClick={() => navigate(-1)} class="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantProfileDetailScreen;
