import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDeletePopup from "../components/ConfirmDeletePopup";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { menu_list } from "../storage/dummyData";
import { Search } from "@mui/icons-material";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  useLazyCompanyRobotListRoboQuery,
  useLazyRobotManagementListQuery,
} from "../constants/redux/api/api";
import TopHeader from "../components/TopHeader";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end" size="lg">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const RobotManagementList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [categItems, setCategItems] = useState([]);
  const [deleteDetails, setDeleteDetails] = useState({});

  const [deletePopup, setDeletePopup] = useState(false);

  // RTK QUERY
  const [robotListApi] = useLazyRobotManagementListQuery();
  const [roboListApi] = useLazyCompanyRobotListRoboQuery();

  const getList = () => {
    roboListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Company",
        accessor: "company_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Client Name",
        accessor: "client_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Branch Count",
        accessor: "branch_count",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Robot Count",
        accessor: "number_of_robots",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/robot-management", {
                    state: { data: row?.original },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"robot-manage-list"} />
      <div className="menu-list d-flex ac-jb w-100">
        <div className="w-100">
          <h1>{"Robot Management"}</h1>
        </div>
      </div>

      {/* new table start */}

      <div className="table-responsive menu-table side_pad mt-4">
        <Table columns={columns} data={list} className="" />
      </div>
      {/* new table end */}

      {deletePopup && (
        <ConfirmDeletePopup
          // onConfirmClick={confirmDeleteClick}
          onCancelClick={() => setDeletePopup(false)}
        />
      )}
    </div>
  );
};

export default RobotManagementList;
