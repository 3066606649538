import React from "react";
import { useNavigate } from "react-router-dom";
import CompanyUserList from "../../../components/CustomerManagement/CompanyUserList";
import ScreenHeader from "../../../components/ScreenHeader";
import { menu_section_left } from "../../../assets/images";
import { Search } from "@mui/icons-material";

const IndCompanyUsers = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}>
      <div className="menu-list d-flex ac-jb w-100">
        <div className="w-100">
          <h1>{"Industrial Employe List"}</h1>
        </div>
        <div className="w-50 position-relative">
          <div className="add-food-section bg-transparent">
            <input
              type="text"
              name="location"
              className="form-control ps-5"
              placeholder="Search..."
            />
          </div>
          <div style={{ position: "absolute", top: 30, left: 34 }}>
            <Search />
          </div>
        </div>
      </div>
      {/* <ScreenHeader
        title="Company Users"
        menuLink="/add-company-users"
        menuBtn="Add Company"
        menuTitle="Check out your Company Users"
        description="You can add your Company Users from here"
        img={menu_section_left}
      /> */}

      <CompanyUserList />
    </div>
  );
};

export default IndCompanyUsers;
