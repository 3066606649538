import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryList from "../../../components/CategoryList/CategoryList";
import CusineList from "../../../components/CategoryList/CusineList";
import BranchTableList from "../../../components/RobotManagement/BranchTableList";
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
} from "../../../constants/redux/api/api";

const BranchPointListScreen = ({ menuOpen }) => {
  const [listShow, setListShow] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter company name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Branch Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter branch name"
                />
              </div>

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Point ID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Point ID"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Point Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Point name"
                />
              </div>
              <div className="px-md-3 pe-3">
                <input className="btn-submit" type="submit" />
              </div>
              <div
                onClick={() => navigate(-1)}
                className="btn-outline2 cp ac-jc d-flex">
                <p className="mb-0">Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BranchPointListScreen;
