import React from "react";
import { useNavigate } from "react-router-dom";
import SpeechList from "../../../src/screens/HiRobot/SpeechList";
import ScreenHeader from "../../../src/components/ScreenHeader";
import { menu_section_left } from "../../../src/assets/images";
import TopHeader from "../../../src/components/TopHeader";

const SpeechDetails = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"add-Hi-robots"} />
      <ScreenHeader
        title="Speech List"
        menuLink="/add-speech"
        menuBtn="Add Hi-Robot"
        menuTitle="Check out your Hi robots"
        description="You can add your speech from here"
        img={menu_section_left}
      />
      <SpeechList />
    </div>
  );
};

export default SpeechDetails;
