import React from "react";
import RolesList from "../../components/RolesList";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";

const RollsPermission = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"roles"} />
      <div className="menu-list d-flex align-items-center justify-content-between w-97 me-3">
        <h1 className="m-0">Roles & Permissions </h1>
        <button
          style={{ borderColor: "#25aae1", backgroundColor: "#000" }}
          onClick={() =>
            navigate("/add-roles", {
              state: { type: "Add_role" },
            })
          }
          className="btn-outline primary1 add_role"
        >
          + Add Roles
        </button>
      </div>
      <RolesList />
    </div>
  );
};

export default RollsPermission;
