import React, { useEffect, useState } from "react";
import { logo, profileimg } from "../../assets/images";
import MenuIcon from "@mui/icons-material/Menu";
import GridViewIcon from "@mui/icons-material/GridView";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutPopup from "../LogoutPopup";
import { useLogoutMutation } from "../../constants/redux/api/api";
import { KeyboardArrowDown } from "@mui/icons-material";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import useUser from "../../constants/Storage/userData";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

const Navbar2 = ({ menuOpen, toggle, setMenuOpen }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  const [logoutPopupShow, setLogoutPopupShow] = useState(false);
  const [showDropdown, setShowDropDown] = useState(false);
  const [showListDropdown, setShowListDropDown] = useState(false);
  const [showListDropdown1, setShowListDropDown1] = useState(false);
  const [showListDropdown3, setShowListDropDown3] = useState(false);
  const [showListDropdown2, setShowListDropDown2] = useState(false);
  const [userEmail, setUserEmail] = useState({
    email: localStorage.getItem("email"),
  });
  // RTK query
  const [logout] = useLogoutMutation();

  const toggleLogoutPopup = () => {
    setLogoutPopupShow(!logoutPopupShow);
  };

  const confirmLogout = () => {
    if (user?.email) {
      logout({ email: user?.email })
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
              window.location.reload();
            }, 300);
          }
        });
    } else {
      localStorage.clear();
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 300);
    }
  };

  useEffect(() => {
    if (!menuOpen) {
      setShowListDropDown(false);
    }
  });
  return (
    <div className="view_pad">
      <header className="header-admin user_head">
        <div
          className={`${menuOpen && "logo-toggle"} d-flex align-items-center`}
          style={{ transition: "0.3s" }}
        >
          <button
            className="header_toggle bg-transparent border-0"
            onClick={toggle}
          >
            {!menuOpen ? (
              <MenuIcon className="menu_icon fs-3" />
            ) : (
              <CloseOutlinedIcon CloseOutlinedIcon className="menu_icon fs-3" />
            )}
          </button>
          <div className="nav_logo">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="position-relative">
          <button
            onClick={() => setShowDropDown(!showDropdown)}
            className="header_img align-selfe-end border-0"
          >
            <img src={user?.admin_profile_image} alt="profile" />
          </button>
          {showDropdown && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setShowDropDown(!showDropdown)}
            />
          )}
          {showDropdown && (
            <div className="profile_dropdown">
              <button
                className="text m-0 py-2 px-3 cust-btn"
                onClick={() => {
                  navigate("/view-profile");
                  setShowDropDown(false);
                }}
              >
                Profile view
              </button>
              <button
                className="text m-0 py-2 px-3 cust-btn"
                onClick={() => {
                  setLogoutPopupShow(true);
                  setShowDropDown(false);
                }}
              >
                Log out
              </button>
            </div>
          )}
        </div>
      </header>
      <div className={menuOpen ? "show l-navbar" : "l-navbar"}>
        <nav className="nav user-side-nav">
          <div>
            <div className="nav_list">
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  pathname === "/table-list" ? "active" : ""
                }`}
                onClick={() => {
                  navigate("/table-list");
                  setMenuOpen(true);
                }}
              >
                <GridViewIcon
                  className={`fs-5' ${
                    pathname === "/table-list" ? "active" : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Dashboard</p>
              </button>

              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  pathname === "/roles-permissions" || pathname === "/add-roles"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  navigate("/roles-permissions");
                  setMenuOpen(true);
                }}
              >
                <ToggleOffOutlinedIcon
                  className={`fs-5' ${
                    pathname === "/roles-permissions" ||
                    pathname === "/add-roles"
                      ? "active"
                      : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Roles and Permissions</p>
              </button>
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0
                 ${
                   pathname === "/admin-users" || pathname === "/add-users"
                     ? "active"
                     : ""
                 }
                `}
                onClick={() => {
                  navigate("/admin-users");
                  setMenuOpen(true);
                }}
              >
                <AdminPanelSettingsIcon
                  className={`fs-5' ${
                    pathname === "/admin-users" ? "active" : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Admin</p>
              </button>
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0
                 ${
                   pathname === "/lead-management" || pathname === "/add-Lead"
                     ? "active"
                     : ""
                 }
                `}
                onClick={() => {
                  navigate("/lead-management");
                  setMenuOpen(true);
                }}
              >
                <ManageAccountsOutlinedIcon
                  className={`fs-5' ${
                    pathname === "/lead-management" ? "active" : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Lead Management</p>
              </button>
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0
                 ${
                   pathname === "/robot-type" || pathname === "/add-robot-type"
                     ? "active"
                     : ""
                 }
                `}
                onClick={() => {
                  navigate("/robot-type");
                  setMenuOpen(true);
                }}
              >
                <SmartToyOutlinedIcon
                  className={`fs-5' ${
                    pathname === "/robot-type" ? "active" : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Robots</p>
              </button>

              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0
                 ${
                   pathname === "/company-details" ||
                   pathname === "/add-company"
                     ? "active"
                     : ""
                 }
                `}
                onClick={() => {
                  navigate("/company-details");
                  setMenuOpen(true);
                }}
              >
                <ApartmentIcon
                  className={`fs-5' ${
                    pathname === "/company-details" ? "active" : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Company Management</p>
              </button>

              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0
                 ${
                   pathname === "/robot-order" ||
                   pathname === "/add-robot-order"
                     ? "active"
                     : ""
                 }
                `}
                onClick={() => {
                  navigate("/robot-order");
                  setMenuOpen(true);
                }}
              >
                <PrecisionManufacturingOutlinedIcon
                  className={`fs-5' ${
                    pathname === "/lead-management" ? "active" : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Robot Order</p>
              </button>
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0
                 ${
                   pathname === "/branch-details" || pathname === "/add-branch"
                     ? "active"
                     : ""
                 }
                `}
                onClick={() => {
                  navigate("/branch-details");
                  setMenuOpen(true);
                }}
              >
                <BusinessOutlinedIcon
                  className={`fs-5' ${
                    pathname === "/branch-details" ? "active" : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Company Robots</p>
              </button>

              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  // pathname === "/company-details" ||
                  // pathname === "/branch-details" ||
                  (showListDropdown && pathname === "/company-users") ||
                  // pathname === "/add-company" ||
                  // pathname === "/add-branch" ||
                  // pathname === "/add-company-users" ||
                  (showListDropdown && pathname === "/menu-management")
                    ? // pathname === "/add-category" ||
                      // pathname === "/add-menu"

                      "active"
                    : ""
                }`}
                onClick={() => {
                  // setShowListDropDown(!showListDropdown);
                  setShowListDropDown(!showListDropdown);
                  setShowListDropDown2(false);
                  setMenuOpen(true);
                  // setCustomermanagement((prev) => !prev);
                }}
              >
                <StorefrontOutlinedIcon className={`fs-5`} />
                <p className="nav_name mb-0">Restaurant Management</p>
                <KeyboardArrowDown />
              </button>
              {showListDropdown && (
                <>
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/menu-management" ||
                      pathname === "/add-category" ||
                      pathname === "/add-menu" ||
                      pathname === "/menu-view"
                        ? "active"
                        : ""
                    }
                  `}
                    onClick={() => {
                      navigate("/menu-management");
                    }}
                  >
                    <p className="nav_name mb-0">Menu Management</p>
                  </button>
                  {/* <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/company-details" ||
                      pathname === "/add-company"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/company-details");
                    }}
                  >
                    <p className="nav_name mb-0">Company List</p>
                  </button> */}

                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/company-users" ||
                      pathname === "/add-company-users" ||
                      pathname === "/company-view" ||
                      pathname === "/company-add-user"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/company-users");
                    }}
                  >
                    <p className="nav_name mb-0">Branch Admin</p>
                  </button>
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/robot-manage-list" ||
                      pathname === "/add-company"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/robot-manage-list");
                    }}
                  >
                    <p className="nav_name mb-0">Robot Management</p>
                  </button>
                </>
              )}


              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  // pathname === "/company-details" ||
                  // pathname === "/branch-details" ||
                  (showListDropdown1 && pathname === "/hi-company-users") ||
                  // pathname === "/add-company" ||
                  // pathname === "/add-branch" ||
                  // pathname === "/add-company-users" ||
                  (showListDropdown1 && pathname === "/menu-management")
                    ? // pathname === "/add-category" ||
                      // pathname === "/add-menu"

                      "active"
                    : ""
                }`}
                onClick={() => {
                  // setShowListDropDown(!showListDropdown);
                  setShowListDropDown1(!showListDropdown1);
                  setShowListDropDown2(false);
                  setShowListDropDown(false);
                  setMenuOpen(true);
                  // setCustomermanagement((prev) => !prev);
                }}
              >
                <EmojiPeopleIcon className={`fs-5`} />
                <p className="nav_name mb-0">Hi Robot Management</p>
                <KeyboardArrowDown />
              </button>
              {showListDropdown1 && (
                <>
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/hi-company-users" ||
                      pathname === "/add-category" ||
                      pathname === "/add-menu" ||
                      pathname === "/menu-view"
                        ? "active"
                        : ""
                    }
                  `}
                    onClick={() => {
                      navigate("/hi-company-users");
                    }}
                  >
                    <p className="nav_name mb-0">Company </p>
                  </button>
                 
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/hi-Robot-branch" ||
                      pathname === "/add-company-users" ||
                      pathname === "/company-view" ||
                      pathname === "/company-add-user"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/hi-Robot-branch");
                    }}
                  >
                    <p className="nav_name mb-0">Branch </p>
                  </button>
                  {/* <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/robot-manage-list" ||
                      pathname === "/add-company"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/robot-manage-list");
                    }}
                  >
                    <p className="nav_name mb-0">Robot Management</p>
                  </button> */}
                </>
              )}
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  // pathname === "/company-details" ||
                  // pathname === "/branch-details" ||
                  (showListDropdown3 && pathname === "/agv-user-list") ||
                  // pathname === "/add-company" ||
                  // pathname === "/add-branch" ||
                  // pathname === "/add-company-users" ||
                  (showListDropdown3 && pathname === "/menu-management")
                    ? // pathname === "/add-category" ||
                      // pathname === "/add-menu"

                      "active"
                    : ""
                }`}
                onClick={() => {
                  // setShowListDropDown(!showListDropdown);
                  setShowListDropDown3(!showListDropdown3);
                  setShowListDropDown2(false);
                  setShowListDropDown1(false);
                  setShowListDropDown(false);
                  setMenuOpen(true);
                  // setCustomermanagement((prev) => !prev);
                }}
              >
                <EmojiTransportationIcon className={`fs-5`} />
                <p className="nav_name mb-0">AGV Management</p>
                <KeyboardArrowDown />
              </button>
              {showListDropdown3 && (
                <>
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/agv-user-list" ||
                      pathname === "/add-category" ||
                      pathname === "/add-menu" ||
                      pathname === "/menu-view"
                        ? "active"
                        : ""
                    }
                  `}
                    onClick={() => {
                      navigate("/agv-user-list");
                    }}
                  >
                    <p className="nav_name mb-0">Company </p>
                  </button>
                 
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/agv-branch" ||
                      pathname === "/add-company-users" ||
                      pathname === "/company-view" ||
                      pathname === "/company-add-user"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/agv-branch");
                    }}
                  >
                    <p className="nav_name mb-0">Branch</p>
                  </button>
                  {/* <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/robot-manage-list" ||
                      pathname === "/add-company"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/robot-manage-list");
                    }}
                  >
                    <p className="nav_name mb-0">Robot Management</p>
                  </button> */}
                </>
              )}

              {/* <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  // pathname === "/company-details" ||
                  // pathname === "/branch-details" ||
                  (showListDropdown2 && pathname === "/company-users") ||
                  // pathname === "/add-company" ||
                  // pathname === "/add-branch" ||
                  // pathname === "/add-company-users" ||
                  (showListDropdown2 && pathname === "/menu-management")
                    ? // pathname === "/add-category" ||
                      // pathname === "/add-menu"

                      "active"
                    : ""
                }`}
                onClick={() => {
                  setShowListDropDown2(!showListDropdown2);
                  setShowListDropDown(false);
                  setMenuOpen(true);
                  // setCustomermanagement((prev) => !prev);
                }}
              >
                <StorefrontOutlinedIcon className={`fs-5`} />
                <p className="nav_name mb-0">Industrial Management</p>
                <KeyboardArrowDown />
              </button> */}
              {showListDropdown2 && (
                <>
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/ind-company-users" ||
                      pathname === "/add-company-users" ||
                      pathname === "/company-view" ||
                      pathname === "/company-add-user"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/ind-company-users");
                    }}
                  >
                    <p className="nav_name mb-0">Company Users</p>
                  </button>
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/ind-robot-manage-list" ||
                      pathname === "/add-company"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/ind-robot-manage-list");
                    }}
                  >
                    <p className="nav_name mb-0">Robot Management</p>
                  </button>
                </>
              )}

              {/* <p className="nav_link  ">
                <HelpOutlineIcon className="primary1 fs-5" />
                <span className="nav_name">Help &amp; Support</span>
              </p> */}
            </div>
          </div>
          <p onClick={toggleLogoutPopup} className="nav_link">
            <LogoutIcon className="primary1 fs-5" />
            <span className="nav_name">SignOut</span>{" "}
          </p>
        </nav>
      </div>
      {logoutPopupShow && (
        <LogoutPopup
          onCancelClick={toggleLogoutPopup}
          onConfirmClick={confirmLogout}
        />
      )}
    </div>
  );
};

export default Navbar2;
