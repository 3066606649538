import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useRobot_order_listMutation,
  useRobot_order_statusMutation,
  useRobot_order_deleteMutation,
  useRobot_order_payment_statusMutation,
  useCompany_management_listMutation,
} from "../constants/redux/api/api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { loadergif } from "../assets/img";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import moment from "moment/moment";
import Swal from "sweetalert2";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={"text-nowrap"}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={"text-nowrap"}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end " size="lg">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const RobotOrderList = () => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();

  //   RTK Query
  const [robot_order_list] = useRobot_order_listMutation();
  const [robo_order_status] = useRobot_order_statusMutation();
  const [robot_order_delete] = useRobot_order_deleteMutation();
  const [robot_order_payment_status] = useRobot_order_payment_statusMutation();
  const [comapnyManageList, setCompanyManagementList] = useState([]);
  //   State variable declaration
  const [robotOrderList, setRobotOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  // GET Robot Order List
  const getRobotOrderList = async () => {
    setLoading(true)
    robot_order_list()
      .unwrap()
      .then((res) => {
        setLoading(false)
        if (res?.status === "success") {
          let temp = [];
          res?.data?.map((item, ind) => {
            temp.push(item);
          })
          console.log('temp', temp);
          setRobotOrderList(temp.reverse());
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err, "error");
      });
  };

  const handleStatusChange = (_id) => {
    if (_id) {
      setLoading(true)
      robo_order_status(_id)
        .unwrap()
        .then((res) => {
          setLoading(false)
          if (res?.status === "success") {
            getRobotOrderList();
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log(err, "err");
        });
    }
  };

  const handlePaymentStatusChange = (_id, value) => {
    let formdata = new URLSearchParams();
    formdata.append("payment_status", value);
    robot_order_payment_status({ id: _id, payload: { payment_status: value } })
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          getRobotOrderList();
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const handleClickDelete = (_id, mail) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        robot_order_delete(_id)
          .unwrap()
          .then((res) => {
            if (res?.status === "success") {
              getRobotOrderList();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    getRobotOrderList();
  }, []);
  console.log(robotOrderList, "robotOrderList207");
  const getCompanyManagementList = () => {
    company_management_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = [];
          res?.data?.map((item, ind) => {
            temp.push(item);
          });
          setCompanyManagementList(temp.reverse());
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  // useEffect(() => {
  //   getCompanyManagementList();
  // }, []);
  const [company_management_list] = useCompany_management_listMutation();
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
     
      {
        Header: "Company Name",
        accessor: "company_name.companyname",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Robot Count",
        accessor: "number_of_robots",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Client Name",
        accessor: "company_name.clientname",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ value, row }) => {
          return <p>{value ? moment(value).format("DD-MM-YYYY") : "-"}</p>;
        },
      },
      // {
      //   Header: "Product Name",
      //   accessor: "robot_type_info",
      //   Cell: ({ value, row }) => {
      //     return <p>{value?.robot_type}</p>;
      //   },
      // },
      {
        Header: "Product Cost",
        accessor: "product_cost",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Due Amount",
        accessor: "due_amount",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                handleStatusChange(row.original._id);
              }}
              className={`${
                value ? "bg-success" : "bg-danger"
              } px-2 py-1 rounded-1 border-0 text-white`}
            >
              {value ? "Active" : "Deactive"}
            </button>
          );
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        Cell: ({ value, row }) => {
          return (
            <select
              className="form-control text-center px-2 py-1 rounded-1 border-0  bg-dark bg-primary text-white "
              name=""
              id=""
              value={value}
              onChange={(e) => {
                handlePaymentStatusChange(row.original._id, e.target.value);
              }}
            >
              <option className="text-success" value="order_received">
                Order Received
              </option>
              <option className="text-success" value="manufacturing">
                Manufacturing
              </option>
              <option className="text-success" value="under_testing">
                Under Testing
              </option>
              <option className="text-success" value="order_ready">
                Order Ready
              </option>
              <option className="text-success" value="order_dispatched">
                Order Dispatched
              </option>
            </select>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/add-robot-order", {
                    state: { type: "edit", data: row.original },
                  })
                }
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              {/* <button
                className="bg-warning border-0 rounded-1 p-1"
                onClick={() =>
                  handleClickDelete(row.original._id, row.original.email)
                }
              >
                <Delete />
              </button> */}
              <button
                onClick={() =>
                  navigate("/add-robot-order", {
                    state: { type: "view", data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
    {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
    <div className="table-responsive menu-table side_pad mt-4">
      <Table columns={columns} data={robotOrderList} className="" />
    </div>
    </>
  );
};

export default RobotOrderList;
