// import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useLazyBranchListQuery,
  useLazyBranch_statusQuery,useLazyBranchview_listQuery
} from "../../constants/redux/api/api";
import { Table } from "../Table/Table";

const RestaurantBranchList = () => {
  const [branchData,setbranchData]=useState([])
  const [data, setData] = useState([]);
  const [disbtn,setdisbtn]=useState(false)
  //RTK
  const [branchListApi] = useLazyBranchListQuery();
  // const [branchListApi] = useLazyBranchview_listQuery();
  const [branchStatus] = useLazyBranch_statusQuery();
  const navigate = useNavigate();

  const branch_status = (id) => {
    let formdata = new URLSearchParams();
    // formdata.append("status",value);
    branchStatus(id)
      .unwrap()
      .then((res) => {
        console.log(res);
        getList();
      })
      .catch((err) => console.log(err));
  };

  const getList = () => {
    branchListApi()
      .unwrap()
      .then((res) => {
        if (res.status === "success") {
        const temp =[]
        const arr=res?.data?.branches
        arr?.map((item)=>(
        temp.push(
            {...item,
              contactnumber:res?.data?.contactnumber,
              contactname:res?.data?.companyinfo?.clientname,
              companyname:res?.data?.companyinfo?.companyname,

              status:res?.data?.status}
          )
          ))
          setData(temp)
            console.log(arr,"array")
         console.log(temp,"rrrrrrrrrrr")
         
          // setData(temp);
          // console.log(res?.data[0].branches,'new')
          // console.log(res?.data,'new')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Contact Name",
        accessor: "contactname",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Contact Number",
        accessor: "contactnumber",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: ({ value, row }) => {
      //     console.log(row);
      //     return (
      //       <button
      //         onClick={() => branch_status(row.original.id)}
      //         className={`${
      //           value ? "bg-success" : "bg-danger"
      //         } px-2 py-1 rounded-1 border-0 text-white`}
      //       >
      //         {value ? "Active" : "Deactive"}
      //       </button>
      //     );
      //   },
      // },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/restaurant/view-branch", {
                    state: {
                      type: "view",
                      data: row.original,
                    },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="table-responsive menu-table side_pad mt-4">
        <Table data={data} columns={columns} />
      </div>
    </>
  );
};

export default RestaurantBranchList;
