import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { categories } from "../storage/dummyData";
import Category from "../components/Category";
import FoodItem from "../components/FoodItem";
import Checkout from "../components/Checkout";
import { useDispatch, useSelector } from "react-redux";
import { saveSelectedFoods } from "../constants/redux/slice/selectedFoods";
import { useShowCategoriesQuery } from "../constants/redux/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const MenuPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const navType = location?.state?.type;
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCat, setSelectedCat] = useState(0);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [showCheckout, setShowCheckout] = useState(false);
  const [cartLength, setCartLength] = useState("0");

  const selectedFoods = useSelector((state) => state.selectedFoods);

  // RTK QUERY
  const { data: categoryApiData, isSuccess: isCategSuccess } =
    useShowCategoriesQuery();

  useEffect(() => {
    if (isCategSuccess) {
      console.log(categoryApiData);
      setCategoryData(categoryApiData?.result);
    }
  }, [categoryApiData, isCategSuccess]);

  //   const onCategoryClick = (id) => {
  //     const temp = [...selectedCat];
  //     if (selectedCat.includes(id)) {
  //       const el = temp.indexOf(id);
  //       temp.splice(el, 1);
  //     } else {
  //       temp.push(id);
  //     }
  //     setSelectedCat(temp);
  //   };

  const onCategoryClick = (id) => {
    setSelectedCat(id);
  };

  useEffect(() => {
    if (isCategSuccess) {
      const [tmp] = categoryData?.filter(
        (el) => el?.categoryNameId === selectedCat
      );
      console.log(tmp?.categoryItems);
      setDisplayProducts(tmp?.categoryItems);
    }
  }, [selectedCat, categoryData, isCategSuccess]);

  const onAddClick = (item) => {
    const temp = { ...selectedProducts };
    temp[item?.food] = 1;
    setSelectedProducts(temp);
    // item.qty += 1;
  };

  const onMinusClick = (item) => {
    const temp = { ...selectedProducts };
    if (temp[item?.food] == 1) {
      temp[item?.food] = null;
    } else {
      temp[item?.food] -= 1;
    }
    setSelectedProducts(temp);
  };

  const onIncreaseClick = (item) => {
    const temp = { ...selectedProducts };
    temp[item?.food] += 1;
    setSelectedProducts(temp);
  };

  useEffect(() => {
    dispatch(saveSelectedFoods(selectedProducts));
    console.log(selectedProducts);
    const SelectedVal = Object.values(selectedProducts);
    const tmp = SelectedVal?.some((el) => el);
    setShowCheckout(tmp);
    setCartLength(SelectedVal?.length);
  }, [selectedProducts]);

  useEffect(() => {
    if (navType === "addMore") {
      const SelectedVal = Object.values(selectedFoods);
      const tmp = SelectedVal?.some((el) => el);
      setShowCheckout(tmp);
      if (tmp) {
        setSelectedProducts(selectedFoods);
      }
    }
  }, [navType]);

  return (
    <div style={{ position: "relative" }}>
      <Header type="menu" />
      <div className="menu-wrapper">
        <div className="categories">
          <h2>Categories</h2>
          <div className="category-tile">
            {categoryData?.map((item) => (
              <Category
                item={item}
                className={selectedCat === item?.categoryNameId && "selected"}
                onCategoryClick={onCategoryClick}
              />
            ))}
          </div>
        </div>
        <div className="food-items">
          {displayProducts?.map((item) => (
            <FoodItem
              selectedProducts={selectedProducts}
              item={item}
              onAddClick={onAddClick}
              onMinusClick={onMinusClick}
              onIncreaseClick={onIncreaseClick}
            />
          ))}
        </div>
      </div>
      {/* CHECKOUT COMP */}
      {/* {showCheckout && <Checkout type="menu" />} */}
      {showCheckout && (
        <div className="cart" onClick={() => navigate("/checkout")}>
          {/* <img alt="icon" src="" /> */}
          <p>{cartLength}</p>
          <ShoppingCartIcon />
        </div>
      )}
    </div>
  );
};

export default MenuPage;
