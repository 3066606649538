import { configureStore } from "@reduxjs/toolkit";
import selectedFoods from "../slice/selectedFoods";
import { api } from "../api/api";
import { rtkQueryErrorLogger } from "../slice/errorLogger";
import alertMessage from "../slice/alertMessage";
import selectedTable from "../slice/selectedTable";
import selectedTableName from "../slice/selectedTableName";

const store = configureStore({
  reducer: {
    selectedTable: selectedTable,
    selectedTableName: selectedTableName,
    selectedFoods: selectedFoods,
    alertMessage: alertMessage,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
});

export default store;
