import React from "react";

const RobotsList = () => {
  return (
    <div className="side-content robot-detailsx">
      <div className="menu-list ">
        <h1>Robots List</h1>
        <div className="side_pad pb-3">
          <div className="view_map">
            <h6>Perambur Branch Informations</h6>
            <a className="tab_view">View Map</a>
          </div>
          <div className="robo_table">
            <table>
              <thead>
                <tr>
                  <th scope="col">Count</th>
                  <th scope="col">Robot ID</th>
                  <th scope="col">Robot Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Map Location</th>
                  <th scope="col">Orders</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Count">01</td>
                  <td data-label="Robot ID">#001</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-check ac_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-check"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-check ac_ic"></i> Font Awesome fontawesome.com --> */}
                    Active
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    02
                  </td>
                  <td data-label="Robot ID">#002</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-exclamation dec_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-exclamation"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-exclamation dec_ic"></i> Font Awesome fontawesome.com --> */}
                    Inactive
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    03
                  </td>
                  <td data-label="Robot ID">#003</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-check ac_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-check"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-check ac_ic"></i> Font Awesome fontawesome.com --> */}
                    Active
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    04
                  </td>
                  <td data-label="Robot ID">#004</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-check ac_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-check"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-check ac_ic"></i> Font Awesome fontawesome.com -->  */}
                    Active
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    05
                  </td>
                  <td data-label="Robot ID">#005</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-exclamation dec_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-exclamation"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-exclamation dec_ic"></i> Font Awesome fontawesome.com --> */}
                    Inactive
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="view_map">
            <h6>Perambur Branch Informations</h6>
            <a className="tab_view">View Map</a>
          </div>
          <div className="robo_table ">
            <table>
              <thead>
                <tr>
                  <th scope="col">Count</th>
                  <th scope="col">Robot ID</th>
                  <th scope="col">Robot Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Map Location</th>
                  <th scope="col">Orders</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Count">01</td>
                  <td data-label="Robot ID">#001</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-check ac_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-check"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-check ac_ic"></i> Font Awesome fontawesome.com --> */}
                    Active
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    02
                  </td>
                  <td data-label="Robot ID">#002</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-exclamation dec_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-exclamation"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-exclamation dec_ic"></i> Font Awesome fontawesome.com --> */}
                    Inactive
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    03
                  </td>
                  <td data-label="Robot ID">#003</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-check ac_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-check"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-check ac_ic"></i> Font Awesome fontawesome.com --> */}
                    Active
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    04
                  </td>
                  <td data-label="Robot ID">#004</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-check ac_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-check"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-check ac_ic"></i> Font Awesome fontawesome.com --> */}
                    Active
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Count">
                    05
                  </td>
                  <td data-label="Robot ID">#005</td>
                  <td data-label="Robot Name">Dinning Bot</td>
                  <td data-label="Status">
                    <svg
                      className="svg-inline--fa fa-circle-exclamation dec_ic"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="circle-exclamation"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg="">
                      <path
                        fill="currentColor"
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"></path>
                    </svg>
                    {/* <!-- <i className="fa-solid fa-circle-exclamation dec_ic"></i> Font Awesome fontawesome.com --> */}
                    Inactive
                  </td>
                  <td data-label="Map Location">Map ID</td>
                  <td data-label="Orders">
                    <a className="tab_view">View</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RobotsList;
