import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HiRobotList from "../HiRobot/HiRobotList";
import AgvList from "./AgvList";
import TopHeader from "../../components/TopHeader";
import ScreenHeader from "../../components/ScreenHeader";
import { menu_section_left } from "../../assets/images";
import AgvLocationList from "./AgvLocationList";

const AGVLocation = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      <TopHeader type={"agv-robot"} />
      <ScreenHeader
        type="add"
        title="Check out your Company robots"
        menuLink="/add-agv-locations"
        menuBtn="Add Company Locations"
        menuTitle="Check out your Company robots"
        description="You can add your robots from here"
        img={menu_section_left}
        data={location?.state?.data}
        title3={"Check out your Company robots"}
      />
      <AgvLocationList />
    </div>
  );
};

export default AGVLocation;
