import React from "react";

const ConfirmDeletePopup = ({ onConfirmClick, onCancelClick }) => {
  return (
    <div onClick={onCancelClick} className="popup-wrap">
      <div className="popup-innerbox">
        <p>Are you sure you want to delete this item?</p>
        <div>
          <button onClick={onConfirmClick}>Confirm</button>
          <button onClick={onCancelClick}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeletePopup;
