import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import {
  useLead_addMutation,
  useLead_updateMutation,
} from "../../constants/redux/api/api";
import { loadergif } from "../../assets/img";
const AddLeadManagement = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formType = location?.state?.type;
  const formData = location?.state?.data;
  const [btn, setBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leadObj, setLeadObj] = useState({
    company_name: "",
    name: "",
    email: "",
    description: "",
    address: "",
    mobile: "",
  });
  const [leadObjErr, setLeadObjErr] = useState({
    company_name: false,
    name: false,
    email: false,
    description: "",
    mobile: false,
  });

  //   RTK QUERY
  const [lead_add] = useLead_addMutation();
  const [lead_update] = useLead_updateMutation();

  const handleOnSubmitForm = async () => {
    if (
      leadObj.company_name === "" ||
      leadObj.name === "" ||
      leadObj.mobile === "" ||
      leadObj.description === "" ||
      leadObj.email === ""
    ) {
      let errorObj = {};
      for (let key in leadObj) {
        if (leadObj[key] === "") {
          errorObj[key] = true;
        } else {
          errorObj[key] = false;
        }
      }
      setLeadObjErr({ ...leadObjErr, ...errorObj });
    } else if (leadObj) {
      if (formType === "Edit") {
        setLoading(true)
        setBtn(true);
        let id = formData?._id;
        lead_update({ payload: leadObj, id: id })
          .unwrap()
          .then((res) => {
            setLoading(false)
            setBtn(false);
            if (res.status === "success") {
              navigate(-1);
            }
          })
          .catch((err) => {
            setLoading(false)
            setBtn(false);
            console.log(err, "err");
          });
      } else {
        setBtn(true);
        setLoading(true)
        lead_add(leadObj)
          .unwrap()
          .then((res) => {
            setLoading(false)
            setBtn(false);
            if (res.status === "success") {
              navigate(-1);
            }
          })
          .catch((err) => {
            setLoading(false)
            setBtn(false);
            console.log(err, "err");
          });
      }
    }
  };

  const handleOnChangeInput = (e) => {
    const inputName = e && e.target.name;
    if (inputName !== null) {
      setLeadObj({
        ...leadObj,
        [inputName]: e.target.value,
      });
      if (e.target.value === "" || e.target.value === null) {
        setLeadObjErr({
          ...leadObjErr,
          [inputName]: true,
        });
      } else {
        setLeadObjErr({
          ...leadObjErr,
          [inputName]: false,
        });
      }
    }
  };

  useState(() => {
    if (formType === "Edit" ||formType === "view") {
      setLeadObj({
        company_name: formData?.company_name,
        name: formData?.name,
        email: formData?.email,
        description: formData?.description,
        address: formData?.address,
        mobile: formData?.mobile,
      });
    }
  }, [formType, formData]);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
       {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <TopHeader type={formType === "Edit" ? "edit-lead" : "add-Lead"} />
      <div className="menu-list">
        <h1>{formType=='view'?"View Leads":(formType === "Edit" ? "Edit Leads" : "Add Leads")}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Company Name
                  <span className="text-danger">*</span>
                </label>
                <input
                  disabled={formType=='view'?true:false}
                  onChange={(e) => handleOnChangeInput(e)}
                  value={leadObj?.company_name || ""}
                  name="company_name"
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Company Name"
                />
                {leadObj.company_name === "" && leadObjErr.company_name && (
                  <p className="mb-0 text-danger">Company Name is Required</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Contact Name
                  <span className="text-danger">*</span>
                </label>
                <input
                 disabled={formType=='view'?true:false}
                  onChange={(e) => handleOnChangeInput(e)}
                  value={leadObj.name || ""}
                  name="name"
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Contact name"
                />
                {leadObj.name === "" && leadObjErr.name && (
                  <p className="mb-0 text-danger">Contact Name is Required</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Contact Number
                  <span className="text-danger">*</span>
                </label>
                <input
                 disabled={formType=='view'?true:false}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 10) {
                      e.target.setCustomValidity("invalid Number");
                    } else if (e.target.value.length == 10) {
                      e.target.setCustomValidity("");

                      handleOnChangeInput(e);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxLength={10}
                  name="mobile"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={leadObj.mobile || ""}
                  type="number"
                  className="form-control mb-1"
                  placeholder="Enter Contact No"
                />
                {leadObj.mobile === "" && leadObjErr.mobile && (
                  <p className="mb-0 text-danger">Contact Number is Required</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Email Id
                  <span className="text-danger">*</span>
                </label>
                <input
                 disabled={formType=='view'?true:false}
                  onChange={(e) => handleOnChangeInput(e)}
                  value={leadObj.email || ""}
                  name="email"
                  type="email"
                  className="form-control mb-1"
                  placeholder="Enter Email"
                />
                {leadObj.email === "" && leadObjErr.email && (
                  <p className="mb-0 text-danger">Email is Required</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Address
                </label>
                <input
                 disabled={formType=='view'?true:false}
                  onChange={(e) => handleOnChangeInput(e)}
                  value={leadObj.address || ""}
                  name="address"
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Address"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Description
                  <span className="text-danger">*</span>
                </label>
                <textarea
                 disabled={formType=='view'?true:false}
                  className="form-control mb-1"
                  placeholder="Enter Description"
                  name="description"
                  id=""
                  cols="30"
                  rows="3"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={leadObj?.description || ""}
                ></textarea>
                {leadObj.description === "" && leadObjErr.description && (
                  <p className="mb-0 text-danger">Description is Required</p>
                )}
              </div>

              {formType!="view"&&<div className="px-md-3 pe-3">
                {formType === "Edit" ? (
                  <button
                    className="btn-submit"
                    onClick={() => handleOnSubmitForm()}
                    disabled={btn ? true : false}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn-submit"
                    onClick={() => handleOnSubmitForm()}
                    disabled={btn ? true : false}
                  >
                    Submit
                  </button>
                )}
              </div>}
              {formType!="view"&&<div onClick={() => navigate(-1)} className="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLeadManagement;
