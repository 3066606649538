import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Table } from "../Table/Table";
import {
  useRobot_listMutation,
  useLazyBranchListQuery,
  useOrder_listMutation,
} from "../../constants/redux/api/api";
import useUser from "../../constants/Storage/userData";
import { useState } from "react";
import TopHeader from "../../components/TopHeader";
import moment from "moment/moment";
const RobotOrderList = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(1);
  const { user, setUser } = useUser();
  const [data, setdata] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [branchdata, setbranchdata] = useState([]);
  const [dropDownList, setDropDownList] = useState("All");

  const [branchList] = useLazyBranchListQuery();
  const id = JSON.parse(localStorage.getItem("resturent_users"));

  //RTK
  const [robotapi] = useOrder_listMutation();

  const getrobotlist = () => {
    const formdata = new URLSearchParams();
    const companyid = user?.company_id;

    if (dropDownList == "All") {
      formdata.append("company_id", id?.company_id);
    } else {
      formdata.append("company_branch_id", dropDownList);
    }

    robotapi(formdata)
      .unwrap()
      .then((res) => {
        const temp = [];
        res?.data?.map((item, ind) => {
          temp.push({
            ...item,
            company_id: res?.company_id,
          });
        });

        setdata(temp);

        // setfilterdata(temp);
        // setDropDownList("");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getList = () => {
    branchList()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res, "list");
          const temp = [...res?.data?.branches].reverse();
          // setBranch(res?.data?.branches?.id)
          setbranchdata(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    getrobotlist();
  }, [dropDownList]);

  const branchFilter = (value) => {
    setDropDownList(value);
    // if (value == ""){
    //   setdata(filterdata);
    // } else {
    //   let finder = filterdata?.filter((item) => item?.company_branch_id
    //    == value);
    //   setdata(finder);
    // }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Order Id",
        accessor: "order_id",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Date & Time",
        accessor: "createdAt",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("MMMM Do YYYY, h:mm:ss a")}</p>;
        },
      },
      // {
      //   Header: "Branch Name",
      //   accessor: "name",
      //   Cell: ({ value, row }) => {
      //     return <p>{value}</p>;
      //   },
      // },
      {
        Header: "Table Id",
        accessor: "drop_point",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Robot Id",
        accessor: "robot_code",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        // accessor: "order_status",
        accessor: "task_status",
        Cell: ({ value, row }) => {
          return (
            <p>
              {value == "order_taken" && (
                <button
                  // onClick={() => setStatus((prev) => !prev)}
                  onClick={() => setStatus(2)}
                  className={`${
                    status ? "" : ""
                  } px-2 py-1 rounded-1 border-0 text-white bg-danger`}
                >
                  Order Taken
                </button>
              )}
              {status == 2 && (
                <button
                  onClick={() => setStatus(3)}
                  className={`${
                    status ? "" : ""
                  } px-2 py-1 rounded-1 bg-warning border-0 text-white`}
                >
                  kitchen Completed
                </button>
              )}
              {status == 3 && (
                <button
                  onClick={() => setStatus(1)}
                  className={`${status ? "" : ""} 
                bg-danger px-2 py-1 rounded-1 border-0 text-white`}
                >
                  Order Completed
                </button>
              )}
              {status == 3 && (
                <button
                  onClick={() => setStatus(1)}
                  className={`${status ? "" : ""} 
                bg-danger px-2 py-1 rounded-1 border-0 text-white`}
                >
                  concel
                </button>
              )}
              {/* <CheckCircleIcon className="active_icon me-1" /> Active */}
              <p>{value}</p>
            </p>
          );
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              {/* <button
                onClick={() => navigate("/restaurant/view-robot-order")}con
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
               <VisibilityIcon />
              </button> */}
              <button
                onClick={() =>
                  navigate("/restaurant/view-robot-order", {
                    state: { data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="add-food-section">
        <div action="">
          <div className="d-flex flex-wrap">
            <div className="col-md-4 col-12 px-md-3 px-0 mb-3">
              <label className="mb-2" htmlFor="">
                Branch Name
              </label>
              <select
                className="form-control"
                placeholder="Select Branch"
                value={dropDownList}
                onChange={(e) => branchFilter(e.target.value)}
              >
                <option value="All">All</option>
                {branchdata?.map((item, ind) => {
                  return (
                    <option key={ind} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive menu-table side_pad mt-4">
        <Table data={data} columns={columns} />
      </div>
    </div>
  );
};

export default RobotOrderList;
