import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDinninguser_addMutation,
  useLazyDinning_branchListQuery,
  useLazyDinningViewQuery,
  useDinningUser_UpdateMutation,
} from "../../constants/redux/api/api";
import { toast } from "react-toastify";
import TopHeader from "../../components/TopHeader";
import { loadergif } from "../../assets/img";

const RestaurantAddUsers = ({ menuOpen }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;
  console.log(location, "loc");
  console.log(type, "type");
  console.log(editData, "edit");

  let [name, setName] = useState("");
  let [phone, setPhone] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [description, setDescription] = useState("");
  let [dinningbranches, setDinningBranch] = useState([]);
  const [submitBtn, setSubmitBtn] = useState(false);

  let [nameerr, setNameerr] = useState(false);
  let [phoneerr, setPhoneerr] = useState(false);
  let [emailerr, setEmailerr] = useState(false);
  let [passworderr, setPassworderr] = useState(false);
  let [passwordStringerr, setPasswordStringerr] = useState("");
  let [confirmPassworderr, setConfirmPassworderr] = useState(false);
  let [descriptionerr, setDescriptionerr] = useState(false);
  let [dropdown, setdropdown] = useState(false);
  let [roleerr, setroleerr] = useState(false);
  let [value, setValue] = useState("");
  let [types, settypes] = useState("");

  const [pincode, setPinCode] = useState("");
  const [b_location, setBLocation] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  //   RTK QUERY
  // const [createTable] = useCreateTableMutation();
  // const [editTable] = useEditTableMutation();
  const [dinninglist] = useLazyDinning_branchListQuery();
  const [dinningadd] = useDinninguser_addMutation();
  const [dinning_View] = useLazyDinningViewQuery();
  const [dinning_UserUpdate] = useDinningUser_UpdateMutation();

  const dinning = () => {
    dinninglist()
      .then((res) => {
        setDinningBranch(res.data.data);
        console.log(res.data.data, "dinning");
      })
      .catch((err) => console.log(err));
  };

//   const isSomeQueryPending = useSelector((state) =>
//   Object.values(state.api.queries).some((query) => query.status === "pending")
// );

// useEffect(() => {
//   if (isSomeQueryPending) {
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//     }, 3000);
//   }
// }, [isSomeQueryPending]);


  useEffect(() => {
    dinning();
  }, []);

  const dinningView = () => {
    dinning_View(editData?._id)
      .unwrap()
      .then((res) => {
        console.log("editData", res);
        let data = res?.data;
        setName(data?.name);
        setEmail(data?.email);
        if (data?.mobile) {
          setPhone(data?.mobile);
        }
        setDescription(data?.description);
        // settypes(data?.company_branch_id );
        settypes(data?.type);
        // handleSelect=(id)=>{
        // setValue(data?.company_branch_id)
        handleSelect(data?.company_branch_id);
        //   let result = dinningbranches.find((obj) => obj._id === id);
        //   setPinCode(result?.pincode);
        //   setBLocation(result?.location);

        // }

        // setValue(data?.company_branch_id)

        // setPinCode(data?.company_branch?.pincode)
        // setBLocation(data?.company_branch?.location)
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (dinningbranches?.length > 0 &&(type === "edit"||type=="view")) {
      dinningView();
    }
  }, [dinningbranches, type, editData]);
  // const onChangeForm = (e) => {
  //   // const { name, email, phone, password, confirmPassword } = validation;
  //   // setValidation({
  //   //   ...validation,
  //   //   [e.target.name]: [e.target.value],
  //   // });
  //   if (
  //     name?.length == 0 ||
  //     email === "" ||
  //     phone === "" ||
  //     password === "" ||
  //     confirmPassword === ""
  //   ) {
  //     setNameErr(true);
  //     setEmailErr(true);
  //     setPhoneErr(true);
  //     setPasswordErr(true);
  //     setConfirmPasswordErr(true);
  //   }
  //   console.log(name);
  // };

  const onSubmit = (e) => {
    if (
      name?.length === 0 ||
      phone?.length == 0 ||
      email?.length == 0 ||
      description?.length == 0 ||
      (type != "edit" && password?.length == 0) ||
      (type != "edit" && confirmPassword.length == 0) ||
      value?.length == 0 ||
      types.length == 0
    ) {
      setNameerr(true);
      setEmailerr(true);
      setPhoneerr(true);
      setDescriptionerr(true);
      if (type !== "edit") {
        setPassworderr(true);
        setConfirmPassworderr(true);
      }
      setdropdown(true);
      setroleerr(true);
    } else {
      setLoading(true);
      const formData = new URLSearchParams();
      formData.append("name", name);
      formData.append("mobile", phone);
      formData.append("company_branch_id", value);
      formData.append("description", description);
      formData.append("email", email);
        formData.append("password", password);
        formData.append("confirmPassword", confirmPassword);
  
      formData.append("type", types);
      setDisableBtn(true);
      if (type === "edit") {
        setLoading(true);
        let id = editData?._id;
        dinning_UserUpdate({ payload: formData, id: id })
          .unwrap()
          .then((res) => {
            if (res?.status === "success") {
              setDisableBtn(false);
              setLoading(false);
              navigate(-1);
              toast.success(res?.message);
            }
          })
          .catch((err) => {
            console.log(err);
            setDisableBtn(false);
            setLoading(false);
          });
      } else {
        setLoading(true);
        dinningadd(formData)
          .unwrap()
          .then((res) => {
            setDisableBtn(false);
            setLoading(false);
            if (res?.status === "success") {
              navigate(-1);
              toast.success(res?.message);
            }
          })
          .catch((err) => {
            setDisableBtn(false);
            setLoading(false);
            console.log(err, "err");
          });
      }
    }
  };
  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setPasswordStringerr("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setPasswordStringerr("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setPasswordStringerr("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setPasswordStringerr("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setPasswordStringerr("");
      }
    } else {
      setPasswordStringerr("");
      setPassword(new_pass);
    }
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  const pattern = new RegExp(/^[6-9]\d{0,9}$/g);

  const onEditSubmit = () => {
    if (name === "") {
      // setNameErr(true);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (type === "edit"||type=="view") {
      setName(editData?.table_name);
    }
  }, [type, location, editData]);

  const branchData = [
    {
      name: "Branch 1",
      pin_code: 600501,
      location: "Guindy",
    },
    {
      name: "Branch 2",
      pin_code: 600502,
      location: "Vadapalani",
    },
    {
      name: "Branch 3",
      pin_code: 600503,
      location: "Bengaluru",
    },
  ];
  const handleSelect = (id) => {
    setValue(id);
    let result = dinningbranches.find((obj) => obj._id === id);
    setPinCode(result?.pincode);
    setBLocation(result?.location);

    console.log(result?._id);
    // let arr =
    //   Array.isArray(dinningbranches) &&
    //   dinningbranches.filter((data) => data.name == e.target.value);
    // if (arr.length == 1) {
    //   setPinCode(arr[0]?.pincode);
    //   setBLocation(arr[0]?.location);
    // } else {
    //   setPinCode("");
    //   setBLocation("");
    // }
  };
  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    const pattern = /^[6-9]\d{0,9}$/; // Pattern to check if the number starts with 6 to 9 and has up to 10 digits in total

    if (inputValue.length > e.target.maxLength) {
      e.target.value = inputValue.slice(0, e.target.maxLength);
    }

    if (!pattern.test(inputValue)) {
      e.target.setCustomValidity("Invalid Number");
      phoneerr(true);
    } else {
      e.target.setCustomValidity("");
      phoneerr(false);
      setPhone(inputValue);
    }
  };
  const handleWheel = (e) => {
    e.preventDefault();
  };
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
       {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <TopHeader type={type == "edit" ? "edit_Role" : "add_Role"} />
      <div className="menu-list">
        <h1>{type=="view"?"View User":(type === "edit" ? "Edit User" : "Add User")}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label className="mb-2" htmlFor="">
                  User Name
                </label>
                <input
                disabled={type=="view"?true:false}
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
                {name?.length < 3 && nameerr && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter Name
                  </p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label className="mb-2" htmlFor="">
                  Email
                </label>
                <input
                disabled={type=="view"?true:false}
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Email name"
                />
                {email?.length == 0 && emailerr && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter Email
                  </p>
                )}
                {email?.length > 1 && validEmail.test(email) === false && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter valid email
                  </p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label className="mb-2" htmlFor="">
                  Phone No
                </label>
                <input
                  disabled={type=="view"?true:false}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 10) {
                      e.target.setCustomValidity("invalid Number");
                    } else if (e.target.value.length == 10) {
                      e.target.setCustomValidity("");
                      setPhone(e.target.value);
                    }
                  }}
                  // onInput={handlePhoneInputChange}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  // onFocus={(e) =>
                  //   e.target.addEventListener("wheel", handleWheel, {
                  //     passive: false,
                  //   })
                  // }
                  maxlength={10}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="number"
                  name="mobile"
                  className="form-control"
                  id="Mobile Number"
                  placeholder="Mobile Number"
                />
                {phone?.length == 0 && phoneerr && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter Phone number{" "}
                  </p>
                )}
                {/* {phone.length > 1 &&
                  pattern.test(phone) === true &&
                  phone?.length != 10 && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                      }}
                    >
                      Phone number must be 10{" "}
                    </p>
                  )} */}
                {/* {phone.length > 0 && pattern.test(phone) === false && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter valid Phone number{" "}
                  </p>

                )} */}
                {phone.length > 0 && (
    <p style={{ display: "flex", justifyContent: "end", color: "red" }}>
        {phone.length !== 10 ? "Phone number must be 10 digits" : (pattern.test(phone)==false ? "Enter valid Phone number" : "")}
    </p>
)}

              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label>Branch:</label>
                <select
                disabled={type=="view"?true:false}
                  className="form-control"
                  placeholder="Select branch"
                  value={value}
                  onChange={(e) => {
                    handleSelect(e.target.value);
                  }}
                >
                  <option value={""} selected hidden>
                    Select Branch
                  </option>
                  {Array.isArray(dinningbranches) &&
                    dinningbranches?.map((data, i) => {
                      return (
                        <option value={data?._id} key={i}>
                          {data?.name}
                        </option>
                      );
                    })}
                </select>
                {value?.length == 0 && dropdown && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter Branch
                  </p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label>Location:</label>
                <input
                // disabled={type=="view"?true:false}
                  type="text"
                  value={b_location ? b_location : ""}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label>PinCode:</label>
                <input
                  type="text"
                  className="form-control"
                  value={pincode ? pincode : ""}
                  disabled
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label className="mb-2" htmlFor="">
                  User Role
                </label>
                <select
                disabled={type=="view"?true:false}
                  className="form-control"
                  name=""
                  id=""
                  value={types}
                  onChange={(e) => settypes(e.target.value)}
                >
                  <option value="" selected hidden>
                    Select role
                  </option>
                  <option value="2">Waiter</option>
                  <option value="1">Kitchen </option>
                  {/* <option value="1">Admin</option> */}
                </select>
                {types?.length == 0 && roleerr && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter Role
                  </p>
                )}
              </div>

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label className="mb-2" htmlFor="">
                  Password
                </label>
                <input
                disabled={type=="view"?true:false}
                  value={password}
                  name="password"
                  onChange={(e) => handlePassword(e)}
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                />
                {password?.length == 0 && passworderr && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter password
                  </p>
                )}
                {password?.length > 0 && (
                  <p className="errtext">{passwordStringerr}</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative">
                <label className="mb-2" htmlFor="">
                  Confirm Password
                </label>
                <input
                disabled={type=="view"?true:false}
                  value={confirmPassword}
                  name="confirmPassword"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  type="password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                />
                {confirmPassword?.length == 0 && confirmPassworderr && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter confirmPassword{" "}
                  </p>
                )}
                {confirmPassword?.length > 0 && confirmPassword != password && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    confirmPassword Must be match password
                  </p>
                )}
              </div>

              <div className="form-group col-12">
                <label>Description</label>
                <textarea
                disabled={type=="view"?true:false}
                  cols={5}
                  rows={5}
                  type="e"
                  placeholder="Description"
                  className="form-control"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                {description?.length == 0 && descriptionerr && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                    }}
                  >
                    Enter Description
                  </p>
                )}
              </div>

              {/* <div className="col-12 px-md-3 px-0 position-relative mb-3">
                <input
                  type="checkbox"
                  id="primary_contact"
                  className="form-check-input"
                />
                <label for="#primary_contact" className="form-check-label ms-2">
                  Primary Contact
                </label>
              </div> */}
              {/* <div className="col-12 px-md-0 px-0  d-flex  position-relative mb-3">
                <div
                  onClick={
                    disableBtn
                      ? null
                      : type === "edit"
                      ? onEditSubmit
                      : onSubmit
                  }
                  className="px-md-3 pe-3"
                >
                  <input className="btn-submit" type="submit" />
                </div> */}
             {type!='view'&&<div onClick={() => onSubmit()} className="px-md-3 pe-3">
                {type === "edit" ? (
                  <button className="btn-submit" disabled={submitBtn}>
                    Update
                  </button>
                ) : (
                  <input
                    className="btn-submit"
                    disabled={submitBtn}
                    type="submit"
                  />
                )}
              </div>}
              <div onClick={() => navigate(-1)} class="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default RestaurantAddUsers;
