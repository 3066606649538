import React, { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Search } from "@mui/icons-material";
import {
  useCategoriesQuery,
  useDeleteItemMutation,
  useStatusChangeMutation,
} from "../../constants/redux/api/api";
import ConfirmDeletePopup from "../../components/ConfirmDeletePopup";
import { menu_list } from "../../storage/dummyData";

const RestaurantMenuList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [allCategData, setAllCategData] = useState([]);
  const [categItems, setCategItems] = useState([]);
  const [deleteDetails, setDeleteDetails] = useState({});

  const [deletePopup, setDeletePopup] = useState(false);

  // RTK QUERY
  const { data: categoryData, isSuccess: isCategSuccess } =
    useCategoriesQuery();
  const [statusChange] = useStatusChangeMutation();
  const [deleteItem] = useDeleteItemMutation();

  useEffect(() => {
    if (isCategSuccess) {
      console.log(categoryData?.result);
      setAllCategData(categoryData?.result);
    }
  }, [categoryData, isCategSuccess]);

  useEffect(() => {
    if (allCategData?.length > 0) {
      let tmp = [];
      allCategData?.forEach((item) => {
        const categoryItemsTmp = item?.categoryItems?.map((el) => {
          return {
            ...el,
            categoryName: item?.categoryName,
            categoryNameId: item?.categoryNameId,
          };
        });
        tmp = [...tmp, ...categoryItemsTmp];
      });
      setCategItems([...tmp]);
    }
  }, [allCategData]);

  const onActiveClick = (item) => {
    console.log({
      categoryNameId: item?.categoryNameId,
      categoryItemsId: item?.id,
    });
    const formData = new FormData();
    formData.append("categoryNameId", item?.categoryNameId);
    formData.append("categoryItemsId", item?.id);
    statusChange({
      categoryNameId: item?.categoryNameId,
      categoryItemsId: item?.id,
    })
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          console.log(res);
        }
      })
      .catch((err) => console.log(err));
  };

  const onClickDelete = (item) => {
    setDeletePopup(true);
    setDeleteDetails(item);
  };

  const confirmDeleteClick = () => {
    const item = {
      ...deleteDetails,
    };
    console.log({
      categoryid: item?.categoryNameId,
      categoryItemsid: item?.id,
    });
    // const formData = new FormData();
    // formData.append("categoryNameId", item?.categoryNameId);
    // formData.append("categoryItemsId", item?.id);
    deleteItem({
      categoryid: item?.categoryNameId,
      categoryItemsid: item?.id,
    })
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          setDeletePopup(true);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <div className="menu-list d-flex ac-jb w-100">
        <div className="w-100">
          <h1>Restaurant Menu</h1>
        </div>
        <div className="w-50 position-relative">
          <div className="add-food-section bg-transparent">
            <input
              type="text"
              // value={"Robot Type 2"}
              name="location"
              className="form-control ps-5"
              placeholder="Search..."
            />
          </div>
          <div style={{ position: "absolute", top: 30, left: 34 }}>
            <Search />
          </div>
        </div>
      </div>
      {/* table start */}
      <div className="table-responsive menu-table side_pad d-none">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Category</th>
              <th>Food Image</th>
              <th>Name</th>
              <th>Price</th>
              <th>Status</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {categItems?.map((item, ind) => {
              return (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{item?.categoryName}</td>
                  <td>
                    <img
                      src={item?.url}
                      // src={food}
                      alt="food"
                    />
                  </td>
                  <td>{item?.food}</td>
                  <td>₹{item?.price}</td>
                  <td>
                    <button
                      onClick={() => onActiveClick(item)}
                      className={`${
                        item?.categoryItemsActivity == 1
                          ? "btn-success"
                          : "btn-danger"
                      } btn`}
                    >
                      {item?.categoryItemsActivity == 1
                        ? "Active"
                        : "De-Active"}
                    </button>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/add-menu", {
                                state: { type: "edit", data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary px-4"
                          >
                            <VisibilityIcon />
                          </button>
                          {/* <button
                            onClick={() => onClickDelete(item)}
                            type="button"
                            className="btn btn-secondary">
                            <CloseOutlinedIcon />
                          </button> */}
                        </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* table end */}

      {/* new table start */}
      <div className="table-responsive menu-table side_pad">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Branch Name</th>
              <th>Location</th>
              <th>Pin Code</th>
              <th>Robot Count</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {menu_list?.map((item, ind) => {
              return (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{item?.company}</td>
                  <td>{item?.client_name}</td>
                  <td>{item?.viewcount}</td>
                  <td>{item?.branchcount}</td>
                  <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/menu-view", {
                                state: { type: "edit", data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary px-4"
                          >
                            <VisibilityIcon />
                          </button>
                        </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* new table end */}

      {deletePopup && (
        <ConfirmDeletePopup
          onConfirmClick={confirmDeleteClick}
          onCancelClick={() => setDeletePopup(false)}
        />
      )}
    </div>
  );
};

export default RestaurantMenuList;
