import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { food } from "../../assets/images";
import { biryaniCategory, product } from "../../assets/img";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  useRobots_listMutation,
  useLazyList_cusine_robotQuery,
  useDelete_cusine_robotMutation,
  useCusineStatusMutation,
} from "../../constants/redux/api/api";
import Swal from "sweetalert2";
function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={"text-nowrap"}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={"text-nowrap"}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}
const CusineList = ({ listCusine, cusineList, getUpdate }) => {
  const [status, setStatus] = useState(false);
  const location = useLocation();
  const data = location?.state?.data?.data;
  const navigate = useNavigate();
  const [list_cusine_robot] = useLazyList_cusine_robotQuery();
  const [delete_cusine_robot] = useDelete_cusine_robotMutation();
  const [cusine_status] = useCusineStatusMutation();

  const deleteCusine = (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_cusine_robot(value._id)
          .unwrap()
          .then((res) => {
            console.log(res, "res132");
            if (res.status == "success") {
              listCusine();
            }
          })
          .catch((err) => {
            console.log({ message: err.message });
          });
      }
    });
  };

  const handleStatusChange = (id) => {
    cusine_status(id)
      .unwrap()
      .then((res) => {
        console.log(res, "res154");
        if (res.status == "success") {
          listCusine();
        }
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Branch",
        accessor: "company_branch_details",
        Cell: ({ value, row }) => {
          return <p>{value?.name}</p>;
        },
      },
      {
        Header: "Cusine",
        accessor: "cuisine_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "image_url.CategoryImage",
      //   Cell: ({ value, row }) => {

      //     return <img src={value}  alt="images" />
      //   },
      // },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                handleStatusChange(row.original._id);
              }}
              className={`${
                value ? "bg-success" : "bg-danger"
              } px-2 py-1 rounded-1 border-0 text-white`}
            >
              {value ? "Active" : "Deactive"}
            </button>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() => getUpdate(row.original,"edit")}
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              {/* <button
                className="bg-warning border-0 rounded-1 p-1"
                onClick={() => {
                  deleteCusine(row.original);
                }}
              >
                <Delete />
              </button> */}
              <button
                onClick={() => getUpdate(row.original,"view")}
                className="bg-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                 <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="table-responsive menu-table side_pad mt-4">
      <Table columns={columns} data={cusineList} className="" />
    </div>
  );
};

export default CusineList;
