import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";

const IndOrderDetailScreen = ({ menuOpen }) => {
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}>
      <div className="menu-list mb-0">
        <div className="">
          <h1>Order Details</h1>
        </div>
        {/* <div className="d-flex ac-jb w-100 ps-2 table-responsive menu-table side_pad align-items-center"> */}
      </div>
      <div className="d-flex ac-je py-2">
        <div className="select_date d-flex flex-column">
          <label className="text1 py-2">From Date </label>
          <input
            type="date"
            name="start date"
            className="form-control2 me-3"
            placeholder="Enter Phone No"
          />
        </div>
        <div className="select_date d-flex flex-column me-3">
          <label className="text1 py-2">To Date </label>
          <input
            type="date"
            name="end date"
            className="border-0 form-control2"
            placeholder="Enter Phone No"
          />
        </div>
        {/* </div> */}
      </div>

      <div className="side_pad">
        <div class="robot-order-det">
          <div class="d-flex flex-wrap justify-content-between ">
            <div class="robot_date">
              <h5 className="mb-0">
                Date : <span>28-Dec-2023</span>
              </h5>
            </div>
            <div class="robot_date mar_b">
              <span>
                Day :<b> ThursDay</b>
              </span>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-content-between ">
            <div class="robot_name">
              <h5>
                Robot Name : <span>Dunscart</span>
              </h5>
            </div>
            <div class="robot_name mar_b">
              <h5>
                Status : <CheckCircleIcon className="active_icon me-1" />
                <span>Active</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="side_pad">
        <div class="robot-order-det">
          <div class="d-flex flex-wrap justify-content-between ">
            <div class="robot_date">
              <h5 className="mb-0">
                Date : <span>29-Dec-2023</span>
              </h5>
            </div>
            <div class="robot_date mar_b">
              <span>
                Day :<b> FriDay</b>
              </span>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-content-between ">
            <div class="robot_name mar_b">
              <h5>
                Robot Name : <span>Dunscart</span>
              </h5>
            </div>
            <div class="robot_name mar_b">
              <h5>
                Status : <CheckCircleIcon className="active_icon me-1" />
                <span>Active</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IndOrderDetailScreen;
