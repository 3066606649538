import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cusine } from "../storage/dummyData";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import {
  useMenu_management_addMutation,
  useRobots_listMutation,
  useLazyList_cusine_robotQuery,
  useCreate_add_menuMutation,
  useAdd_menu_updateMutation,
  useMenu_management_listMutation,
  useLazySingle_add_menu_listQuery,
} from "../constants/redux/api/api";
import TopHeader from "../components/TopHeader";

const AddMenu = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const id = location?.state.data;
  const data = location?.state?.data?.data;
  const editData = location?.state?.editData;
  //   state Variable
  const [availTime, setAvailTime] = useState([]);
  const [availTimeErr, setAvailTimeErr] = useState(false);
  const [ingredient, setIngredient] = useState([]);
  const [create_add_menu] = useCreate_add_menuMutation();
  const [addFormData, setAddFormData] = useState({
    Company_Name: "",
    Branch: "",
    Category_Type: "Briyani",
    Cuisine_Type: "",
    Name: "",
    Price: "",
    Food_Available_time: availTime,
    Ingredient_list: ingredient,
    Food_image: "",
  });
  const [addFormDataErr, setAddFormDataErr] = useState({
    Company_Name: false,
    Branch: false,
    Category_Type: false,
    Cuisine_Type: false,
    Name: false,
    Price: false,
    Food_Available_time: false,
    Ingredient_list: false,
    Food_image: false,
  });
  const [allCategories, setAllCategories] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fieldSets, setFieldSets] = useState([]);
  const [robots_list] = useRobots_listMutation();
  const [add_menu_update] = useAdd_menu_updateMutation();
  const [cusine, setCusine] = useState([]);
  const [listRobot, setListRobot] = useState([]);
  const [comName, setCompName] = useState("");
  const [branch, setBranch] = useState("");
  const [branchErr, setBranchErr] = useState(false);
  const [categoryType, setCategoryType] = useState("");
  const [categoryTypeErr, setCategoryTypeErr] = useState(false);
  const [cusineType, setCusineType] = useState("");
  const [cusineTypeErr, setCusineTypeErr] = useState(false);
  const [image, setImage] = useState("");
  const [food, setFood] = useState("");
  const [foodErr, setFoodErr] = useState(false);
  const [price, setPrice] = useState("");
  const [priceErr, setPriceErr] = useState(false);
  const [foodAva, setFoodAva] = useState("");
  const [incrediant, setIncrediant] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [cusineList, setCusineList] = useState([]);
  const [categoryLists, setCategoryLists] = useState([]);
  const [cusineLists, setCusineLists] = useState([]);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [menu_management_add] = useMenu_management_addMutation();
  const [menu_management_list] = useMenu_management_listMutation();
  const [list_cusine_robot] = useLazyList_cusine_robotQuery();
  const [single_add_menu_list] = useLazySingle_add_menu_listQuery();

  const onEditMenu = (id) => {
    single_add_menu_list(id)
      .unwrap()
      .then((res) => {
        let data = res?.data[0];
        selectBranch(data?.company_branch_id);
        setFood(data?.Name);
        setPrice(data?.Price);
        setImage(data?.image_url?.MenuImage)
        let temp = [];
        data?.Food_Available_time?.map((item, ind) => {
          if (item == "All") {
            temp.push({
              name: "All",
              id: 1,
            });
          } else if (item == "Breakfast") {
            temp.push({
              name: "Breakfast",
              id: 2,
            });
          } else if (item == "Lunch") {
            temp.push({
              name: "Lunch",
              id: 4,
            });
          } else if (item == "Dinner") {
            temp.push({
              name: "Dinner",
              id: 5,
            });
          }
        });
        console.log("temp", temp);
        setAvailTime(temp);
        setCusineType(data?.cuisine?.id);
        setCategoryType(data?.category?.id);
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
  };

  useEffect(() => {
    if (type == "edit"||type == "view" ){
      onEditMenu(editData?._id);
    }
  }, [categoryLists, cusineLists, type]);

  console.log("data", data);

  const onSubmit = () => {
    if (
      branch?.length == 0 ||
      categoryType?.length == 0 ||
      cusineType?.length == 0 ||
      food?.length == 0 ||
      // price?.length == 0 ||
      availTime?.length == 0
    ) {
      // setPriceErr(true);
      setCategoryTypeErr(true);
      setCusineTypeErr(true);
      setFoodErr(true);
      setBranchErr(true);
      setAvailTimeErr(true);
    } else {
      const cusineTypess = cusineList.find((res) => res._id == cusineType);
      const category = categoryList.find((res) => res._id == categoryType);

      const formData = new FormData();
      // assign
      formData.append("company_id", data?.company_name?._id);
      formData.append("company_branch_id", branch);
      formData.append("Name", food);
      formData.append("category_id", categoryType);
      formData.append("cusine_id", cusineType);
      // formData.append("Price", price);
      // formData.append("Category_Type", category.category_name);
      // formData.append("Cuisine_Type", cusineTypess.cuisine_name);
      availTime?.map((item, ind) => {
        formData.append(`Food_Available_time[${ind}]`, item?.name);
      });

      if (image?.name) {
        formData.append("food_image", image);
      }
      // formData.append("Ingredient_list[0]", incrediant)
      setBtn(true);
      create_add_menu(formData)
        .unwrap()
        .then((res) => {
          console.log(res, "res123");
          setBtn(false);
          if (res.status == "success") {
            navigate(-1);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log({ message: err.message });
        });
    }
  };

  const getLists = () => {
    // CATEGORY
    menu_management_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let finder = res?.data?.filter(
            (i) => i.company_id._id == data?.company_name?._id
          );
          setCategoryLists([...finder].reverse());
        }
      })
      .catch((err) => {
        console.log(err, "error message");
      });

    // CUSINE
    list_cusine_robot()
      .unwrap()
      .then((res) => {
        let finder = res?.data?.filter(
          (i) => i.company_id._id == data?.company_name?._id
        );
        setCusineLists([...finder].reverse());
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
  };

  useEffect(() => {
    getLists();
  }, []);

  console.log("editData", editData);

  const onEditSubmit = () => {
    if (
      branch?.length == 0 ||
      categoryType?.length == 0 ||
      cusineType?.length == 0 ||
      food?.length == 0 ||
      // price?.length == 0 ||
      availTime?.length == 0
    ) {
      // setPriceErr(true);
      setCategoryTypeErr(true);
      setCusineTypeErr(true);
      setFoodErr(true);
      setBranchErr(true);
      setAvailTimeErr(true);
    } else {
      const cusineTypess = cusineList.find((res) => res._id == cusineType);
      const category = categoryList.find((res) => res._id == categoryType);
      console.log(cusineTypess, "cusineType105");
      console.log(category, "cusineType105");
      const formData = new FormData();
      // assign
      // formData.append("Company_Name", addFormData?.Company_Name);
      formData.append("Name", food);
      formData.append("category_id", categoryType);
      formData.append("cusine_id", cusineType);
      // formData.append("Name", addFormData?.Name);
      // formData.append("Price", price);
      // formData.append("Category_Type", category?.category_name);
      // formData.append("Cuisine_Type", cusineTypess?.cuisine_name);
      availTime?.map((item, ind) => {
        formData.append(`Food_Available_time[${ind}]`, item?.name);
      });
      if (image?.name) {
        formData.append("food_image", image);
      }
      setBtn(true);
      add_menu_update({ payload: formData, id: editData._id })
        .unwrap()
        .then((res) => {
          setBtn(false);
          console.log(res, "res208");
          navigate(-1);
        })
        .catch((err) => {
          setBtn(false);
          console.log({ message: err.message });
        });
      // editItem(formData)
      //   .unwrap()
      //   .then((res) => {
      //     if (res?.status === "success") {
      //       console.log(res);
      //       navigate(-1);
      //     }
      //   })
      //   .catch((err) => console.log(err));
    }
  };

  const handleAddSet = () => {
    setFieldSets([...fieldSets, ""]);
  };

  //   Ingredient events
  const handleIngredientOnChange = (e, index) => {
    const { value, name } = e && e.target;
    if (name === "Ingredient_list") {
      let temp = [...fieldSets];
      temp[index] = value;
      setIngredient(temp);
    }
  };
  const handleRemoveSet = (index, ind) => {
    const updatedFieldSets = [...fieldSets];
    updatedFieldSets.splice(index, 1);
    setFieldSets(updatedFieldSets);
    if (Array.isArray(ingredient) && ingredient?.length > 0 && ind > -1) {
      let temp = ingredient.splice(ind, 1);
      setIngredient(temp);
    }
  };

  //   availabel time option
  const timeOption = [
    { name: "All", id: 1 },
    { name: "Breakfast", id: 2 },
    { name: "Lunch", id: 4 },
    { name: "Dinner", id: 5 },
  ];

  const selectBranch = (id) => {
    setBranch(id);
    let categoryFinder = categoryLists?.filter(
      (i) => i?.company_branch_details?.id == id
    );
    let cusineFinder = cusineLists?.filter(
      (i) => i?.company_branch_details?.id == id
    );
    setCategoryList(categoryFinder);
    setCusineList(cusineFinder);
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={type === "edit" ? "edit-food" : "add-food"} />
      <div className="menu-list">
        <h1>{type === "view" ? "View Food" :(type === "edit" ? "Edit Food" : "Add Food")}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">
                  Company Name<span className="text-danger">*</span>
                </label>
                <input
                  // disabled={type=='view'?true:false}
                  value={data?.company_name?.companyname}
                  type="text"
                  name="Company_Name"
                  disabled
                  className="form-control"
                  placeholder="Enter Branch"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">
                  Branch<span className="text-danger">*</span>
                </label>
                <select
                disabled={type=='view'?true:false}
                  className="form-control"
                  placeholder="Enter Branch"
                  name="Branch"
                  value={branch}
                  onChange={(e) => {
                    // handleOnChange(e);
                    selectBranch(e.target.value);
                  }}
                >
                  <option value={""} hidden>
                    Select Branch
                  </option>
                  {data?.company_branch_details?.map((item, ind) => {
                    return (
                      <option value={item?._id} key={ind}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                {branch?.length == 0 && branchErr && (
                  <p className="err-state">Select Branch*</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">
                  Category Type<span className="text-danger">*</span>
                </label>
                <select
                disabled={type=='view'?true:false}
                  value={categoryType}
                  onChange={(e) => {
                    // handleOnChange(e);
                    setCategoryType(e.target.value);
                  }}
                  className="form-control form-select"
                  style={{ textTransform: "capitalize" }}
                  aria-label="Default select example"
                >
                  <option hidden selected>
                    Select Category
                  </option>
                  {categoryList?.map((item, ind) => {
                    return (
                      <option key={ind} value={item?._id}>
                        {item?.category_name}
                      </option>
                    );
                  })}
                </select>
                {categoryType?.length == 0 && categoryTypeErr && (
                  <p className="err-state">Select Category*</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">
                  Cuisine Type<span className="text-danger">*</span>
                </label>
                <select
                disabled={type=='view'?true:false}
                  name="Cuisine_Type"
                  value={cusineType}
                  onChange={(e) => {
                    // handleOnChange(e);
                    setCusineType(e.target.value);
                  }}
                  className="form-control form-select"
                  style={{ textTransform: "capitalize" }}
                  aria-label="Default select example"
                >
                  <option hidden selected>
                    Select Cuisine
                  </option>
                  {cusineList?.map((item, ind) => {
                    return (
                      <option key={ind} value={item?._id}>
                        {item?.cuisine_name}
                      </option>
                    );
                  })}
                </select>
                {cusineType?.length == 0 && cusineTypeErr && (
                  <p className="err-state">Select Cuisine*</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">
                  Food Image<span className="text-danger">*</span>
                </label>
                <input
                disabled={type=='view'?true:false}
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  accept="image/png, image/jpeg, image/jpg, image/webp"
                  type="file"
                  name="Food_image"
                  className="form-control"
                />
                {/* {addFormData?.food_image === "" &&
                  addFormDataErr?.food_image && (
                    <p className="err-state">Select food image*</p>
                  )} */}
                  {(type=="view"||type=="edit")&&<div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                {image && (
                  <img
                    src={image}
                    style={{ width: "100px" }}
                  />
                )}
              </div>}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">
                  Food Name<span className="text-danger">*</span>
                </label>
                <input
                disabled={type=='view'?true:false}
                  value={food}
                  onChange={(e) => {
                    // handleOnChange(e);
                    setFood(e.target.value);
                  }}
                  type="text"
                  name="Name"
                  className="form-control"
                  placeholder="Enter Food name"
                />
                {food?.length == 0 && foodErr && (
                  <p className="err-state">Enter food name*</p>
                )}
              </div>
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">
                  Price<span className="text-danger">*</span>
                </label>
                <input
                  value={price}
                  onChange={(e) => {
                    // handleOnChange(e);
                    setPrice(e.target.value);
                  }}
                  type="number"
                  name="Price"
                  className="form-control"
                  placeholder="Enter price"
                />
                {price?.length == 0 && priceErr && (
                  <p className="err-state">Enter price*</p>
                )}
              </div> */}
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">
                  Food Available <span className="text-danger">*</span>
                </label>
                <Multiselect
                disabled={type=='view'?true:false}
                  options={timeOption}
                  selectedValues={availTime}
                  onSelect={setAvailTime}
                  displayValue="name"
                  placeholder="Select available Time"
                  className="add_availTime form-control"
                  name="Food_Available_time"
                />
                {availTime?.length == 0 && availTimeErr && (
                  <p className="err-state">Select Food Available*</p>
                )}
              </div>
              <div className="col-12 px-md-3 px-0 position-relative mb-3 align-items-center">
                {fieldSets.map((fieldSet, index) => (
                  <div
                    className="col-6 ps-0 px-0 position-relative mb-3 align-items-center"
                    key={index}
                  >
                    <div className="d-flex align-items-center me-4">
                      <input
                      disabled={type=='view'?true:false}
                        type="text"
                        className="form-control"
                        placeholder="Enter List"
                        onChange={(e) => {
                          handleIngredientOnChange(e, index + 1);
                        }}
                        name="Ingredient_list"
                      />

                      <button
                        className="px-md-3 ms-3 mb-4 border-0 ad-btn bg-secondary"
                        onClick={() => handleRemoveSet("", index + 1)}
                      >
                        x
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {type != "view" && <div className="w-100 d-flex">
                <div className="px-md-3 pe-3">
                  {type === "edit" ? (
                    <button
                      className="btn-submit"
                      onClick={onEditSubmit}
                      disabled={btn ? true : false}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className="btn-submit"
                      onClick={onSubmit}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  )}
                </div>
                <div onClick={() => navigate(-1)} className="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMenu;
