import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDeletePopup from "../components/ConfirmDeletePopup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { menu_list } from "../storage/dummyData";
import { Search } from "@mui/icons-material";
import {
  useMenu_management_listMutation,
  useLazyList_restaurant_menuQuery,
} from "../constants/redux/api/api";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import moment from "moment/moment";
import TopHeader from "../components/TopHeader";
import { GlobalFilter } from "../constants/Storage/GlobalFilter";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex justify-content-end">
          <div class="menu-list d-flex ac-jb w-100">
            <div class="w-100"></div>
            <div class="w-50 position-relative">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </div>
        </Col>
      </Row>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={"text-nowrap"}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={"text-nowrap"}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end " size="lg">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const MenuList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [allCategData, setAllCategData] = useState([]);
  const [categItems, setCategItems] = useState([]);
  const [deleteDetails, setDeleteDetails] = useState({});

  const [deletePopup, setDeletePopup] = useState(false);
  const [robotList, setRobotList] = useState([]);
  //   RTK QUERY
  const [menu_management_list] = useMenu_management_listMutation();
  const [list_restaurant_menu] = useLazyList_restaurant_menuQuery();
  useEffect(() => {
    if (allCategData?.length > 0) {
      let tmp = [];
      allCategData?.forEach((item) => {
        const categoryItemsTmp = item?.categoryItems?.map((el) => {
          return {
            ...el,
            categoryName: item?.categoryName,
            categoryNameId: item?.categoryNameId,
          };
        });
        tmp = [...tmp, ...categoryItemsTmp];
      });
      setCategItems([...tmp]);
    }
  }, [allCategData]);

  useEffect(() => {
    const getRestaurantMenu = async () => {
      list_restaurant_menu()
        .unwrap()
        .then((res) => {
          console.log(res, "res42");
          if (res.status == "success") {
            let temp = res?.data
            // ?.filter(
            //   (i) =>
            //     i?.status == true && i?.payment_status == "order_dispatched"
            // );
            setRobotList(temp);
          }
        })
        .catch((err) => {
          console.log({ message: err.message });
        });
    };
    getRestaurantMenu();
  }, []);

  const onActiveClick = (item) => {
    const formData = new FormData();
    formData.append("categoryNameId", item?.categoryNameId);
    formData.append("categoryItemsId", item?.id);
  };

  const confirmDeleteClick = () => {
    const item = {
      ...deleteDetails,
    };
    // const formData = new FormData();
    // formData.append("categoryNameId", item?.categoryNameId);
    // formData.append("categoryItemsId", item?.id);
    // deleteItem({
    //   categoryid: item?.categoryNameId,
    //   categoryItemsid: item?.id,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     if (res?.status === "success") {
    //       setDeletePopup(true);
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Company",
        accessor: "company_name",
        Cell: ({ value, row }) => {
          return <p>{value?.companyname}</p>;
        },
      },
      {
        Header: "Client Name",
        accessor: "company_name.clientname",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Branch Count",
        accessor: "branch_count",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      // {
      //   Header: "Product Name",
      //   accessor: "robot_type_info",
      //   Cell: ({ value, row }) => {
      //     return <p>{value?.robot_type}</p>;
      //   },
      // },
      {
        Header: "Robot Count",
        accessor: "number_of_robots",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/menu-view", {
                    state: { type: "edit", data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"menu-managment"} />
      <div className="menu-list d-flex ac-jb w-100">
        <div className="w-100">
          <h1>Restaurant Menu</h1>
        </div>
        {/* <div className="w-50 position-relative">
          <div className="add-food-section bg-transparent">
            <input
              type="text"
              name="location"
              className="form-control ps-5"
              placeholder="Search..."
            />
          </div>
          <div style={{ position: "absolute", top: 30, left: 34 }}>
            <Search />
          </div>
        </div> */}
      </div>
      {/* table start */}
      <div className="table-responsive menu-table side_pad d-none">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Category</th>
              <th>Food Image</th>
              <th>Name</th>
              <th>Price</th>
              <th>Status</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {categItems?.map((item, ind) => {
              return (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{item?.categoryName}</td>
                  <td>
                    <img
                      src={item?.url}
                      // src={food}
                      alt="food"
                    />
                  </td>
                  <td>{item?.food}</td>
                  <td>₹{item?.price}</td>
                  <td>
                    <button
                      onClick={() => onActiveClick(item)}
                      className={`${
                        item?.categoryItemsActivity == 1
                          ? "btn-success"
                          : "btn-danger"
                      } btn`}
                    >
                      {item?.categoryItemsActivity == 1
                        ? "Active"
                        : "De-Active"}
                    </button>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/add-menu", {
                                state: { type: "edit", data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary px-4"
                          >
                            <VisibilityIcon />
                          </button>
                          {/* <button
                            onClick={() => onClickDelete(item)}
                            type="button"
                            className="btn btn-secondary">
                            <CloseOutlinedIcon />
                          </button> */}
                        </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* table end */}

      {/* new table start */}
      <div className="table-responsive menu-table side_pad">
        {/* <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Company</th>
              <th>Client Name</th>
              <th>Branch Count</th>
              <th>Robot Count</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {menu_list?.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td>{ind + 1}</td>
                  <td>{item?.company}</td>
                  <td>{item?.client_name}</td>
                  <td>{item?.viewcount}</td>
                  <td>{item?.branchcount}</td>
                  <td className="text-center">
                    <div className="btn-group">
                      <button
                        onClick={() =>
                          navigate("/menu-view", {
                            state: { type: "edit", data: item },
                          })
                        }
                        type="button"
                        className="btn btn-secondary px-4"
                      >
                        <VisibilityIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
        <Table columns={columns} data={robotList} className="" />
      </div>
      {/* new table end */}

      {deletePopup && (
        <ConfirmDeletePopup
          onConfirmClick={confirmDeleteClick}
          onCancelClick={() => setDeletePopup(false)}
        />
      )}
    </div>
  );
};

export default MenuList;
