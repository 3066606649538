// export const BASE_URL = "http://192.168.190.:7000/";// LOCAL
// export const BASE_URL = "http://192.168.1.29:7000/"; // LOCAL
// export const BASE_URL = "https://crm.theteamrobotics.com:5000/"; // LIVE
export const BASE_URL = "https://www.api.theteamrobotics.com:7000/"; // LIVE

export const WS_URL = "wss:143.110.187.231:8080";

export const URL = {
  LOGIN: "admin/login",
  LOGOUT: "admin/logout",
  NEW_PASSWORD: "admin/newpassword",
  FORGOT_PASSWORD: "admin/forgotpassword",
  ADMIN_LIST: "admin/list",
  ADMIN_ADD: "admin/add",
  ADMIN_STATUS: "admin/status/",
  ADMIN_UPDATE: "admin/update/",
  ADMIN_VIEW: "admin/view/",
  ADMIN_DELETE: "admin/delete/",
  ROLE_MODULES: "admin/modules/list",
  ROLE_LIST: "admin/roles/list",
  ROLE_STATUS: "admin/roles/status/",
  ROLE_ADD: "admin/roles/add/",
  ROLE_UPDATE: "admin/roles/update/",
  ROLE_VIEW: "admin/roles/view/",
  LEAD_LIST: "admin/leads/list",
  LEAD_STATUS: "admin/leads/status/",
  LEAD_ADD: "admin/leads/add",
  LEAD_UPDATE: "admin/leads/update/",
  LEAD_VIEW: "admin/leads/view/",
  lEADSTATUS: "admin/leads/leadstatus/",
  ROBOTS_LIST: "admin/robots/list",
  ROBOTS_VIEW: "admin/robots/view/",
  ROBOTS_STATUS: "admin/robots/status/",
  ROBOTS_DELETE: "admin/robots/delete/",
  ROBOT_ADD: "admin/robots/add",
  ROBOT_UPDATE: "admin/robotss/editcategory/",
  ROBOT_EDIT: "admin/robots/update/",
  COMPANY_MANAGEMENT_ADD: "admin/companymanagement/add",
  COMPANY_MANAGEMENT_STATUS: "admin/companymanagement/status/",
  COMPANY_MANAGEMENT_LIST: "admin/companymanagement/list",
  COMPANY_MANAGEMENT_UPDATE: "admin/companymanagement/update/",
  COMPANY_MANAGEMENT_VIEW: "admin/companymanagement/view/",
  COMPANY_MANAGEMENT_DELETE: "admin/companymanagement/delete/",
  ROBOT_ORDER_ADD: "admin/robotOrder/add",
  ROBOT_ORDER_STATUS: "admin/robotOrder/status/",
  ROBOT_ORDER_PAYMENT_STATUS: "admin/robotOrder/paymentstatus/",
  ROBOT_ORDER_LIST: "admin/robotOrder/list",
  ROBOT_ORDER_VIEW: "admin/robotOrder/view/",
  ROBOTS_ORDER_DELETE: "admin/robotOrder/delete/",
  ROBOTS_ORDER_PAYMENTS_LIST: "admin/robotOrderPayment/list/",
  ROBOTS_ORDER_PAYMENTS_ADD: "admin/robotOrderPayment/add",
  COMPANY_ROBOT_ADD: "admin/companyRobot/addcompany",
  COMPANT_ROBOT_VIEW: "admin/companyRobot/viewcompanyrobot/",
  MENU_MANAGEMENT_LIST: "admin/robotss/listcategory",
  MENU_MANAGEMENT_ADD: "admin/menu/addmenu",
  MENU_MANAGEMENT_VIEW: "",
  MENU_MANAGEMENT_CATEGORY_STATUS: "admin/menu/status/",
  MENU_MANAGEMENT_CATEGORY_EDIT: "",
  MENU_MANAGEMENT_DELETE: "",
  MENU_MANAGEMENT_ADD_CATEGORY: "admin/robotss/addcategory",
  COMPANY_USER_ADD: "admin/companyUser/add",
  COMPANY_USER_LIST: "admin/companyuser/list",
  COMPANY_USER_VIEW: "admin/companyUser/view/",
  ROLE_DELETE: "admin/roles/delete/",
  LEAD_DELETE: "admin/leads/delete/",
  ADD_COMPANY: "admin/companyRobot/addcompany",
  LIST_COMPANY_ROBOT: "admin/companyRobot/listcompanyrobot",
  DELETE_COMAPANY_ROBOT: "admin/companyRobot/deletecompanyrobot",
  EDIT_COMPANY_ROBOT: "admin/companyRobot/updatecompanyrobot",
  GET_ROBOTLIST_ID: "admin/companyRobot/statuscompanyrobot",
  UPDATE_ROBOT_ORDER: "admin/robotOrder/updaterobot",
  RESTAURANT_MENU: "admin/robotOrder/list",
  CATEGORY_UPDATES: "admin/robotss/editcategory",
  DELETE_CATEGORY: "admin/robotss/delete",
  CUSINELIST_ROBOT: "admin/robotss/listcusine",
  CUSINE_CREATE_ROBOT: "admin/robotss/createcusine",
  DELETE_CUSINE_ROBOT: "admin/robotss/deletecusines",
  CUSINE_STATUS: "admin/robotss/cusinesstatus",
  ADD_CATEGORY_STATUS: "admin/robotss/status",
  UPDATE_CUSINE_ROBOT: "admin/robotss/updatecusines",
  ADD_MENU: "admin/robotss/addmenu",
  LIST_MENU: "admin/robotss/listmenu",
  ADD_MENU_DELETE: "admin/robotss/deletemenu",
  SINGLE_ADD_MENU_LIST: "admin/robotss/menuview",
  ADD_MENU_UPDATE: "admin/robotss/editmenu",
  ROBOT_LIST_COMPANY: "admin/robots/list",
  ROBOT_ORDER_LIST_WITH_BRANCH: "admin/robotOrder/robotOrderIdlist",
  COMPANY_USERS_LIST: "admin/companymanagement/companyuserList",
  COMPANY_USERS: "admin/companyUser/list",
  COMPANY_USER_VIEW_NEW: "admin/companyUser/view/",
  COMPANY_USER_EDIT_NEW: "admin/companyUser/update/",
  COMPANY_USER_ADD_NEW: "admin/companyUser/add",
  COMPANY_USER_STATUS_CHANGE: "admin/companyUser/status/",
  COMPANY_USER_DELETE_URL: "admin/companyUser/delete/",
  COMPANY_ROBOTS_STATUS: "admin/companyRobot/statuscompanyrobot/",
  ROBOT_MANAGEMNET_LIST: "admin/robotOrder/robotmanagentlist",
  ROBOT_MANAGEMENT_BRANCH_LIST: "admin/companyRobot/listrobot",
  TABLE_ALL_LIST: "admin/table/get-table",
  TABLE_CREATE_ADMIN: "admin/table/create-table",
  TABLE_VIEW_ADMIN: "admin/table/get-table-view/",
  TABLE_EIDT_ADMIN: "admin/table/edit-table/",
  BRANCH_DELETE: "admin/companymanagement/delete/",
  COMPANY_ROBOT_LIST_ROBO: "admin/companyRobot/listrobot",
  COMPANY_ROBOTS_VIEW_NEW: "admin/companyRobot/robotlistview",
  ROBOT_MANAGEMENT_VIEW: "robot/robotic_communication/listadminrobot",
 //Hi
  COMPANY_ROBOT_HiLIST_ROBO:"admin/companyRobot/listhicompanyrobot",
  COMPANY_ROBOT_HiVIEW_ROBO:"admin/companyRobot/viewhicompanyrobot/",
  HIROBOT_DETAILSLIST:"admin/Hi/robotlistview",
  HIROBOTSPEECHADD:"admin/Hi/add",
  HiROBOTLIST:"admin/Hi/list",
  HIROBOTVIEW:"admin/Hi/view/",
  HIROBOTUPDATE:"admin/Hi/update",
  HIROBOTSPEECHFUCTION:"admin/Hi/listquestion",
  HIROBOTSPEECHCONVERSATION:"admin/Hi/hirobotupdate",
  //agv
  AGVLISTCOMPANYROBOT: "admin/AGV/listcompanyrobot",
  AGVROBOTLISTVIEW: "admin/AGV/robotlistview",
  ROBOTORDERAGVLIST: "admin/robotOrder/robotOrderAgvlist",
  LISTLOCATION: "/admin/AGV/listlocation",
  AGVMAPLOCATION: "/admin/AGV/maplocation",

  // FOR RESTAURANT PANAL

  LOGIN_RES: "admin/companymanagement/login",
  BRANCH_LIST: "/company/branch/listbranch",
  BRANCH_STATUS: "/company/branch/status/",
  BRANCH_ADD: "/company/branch/branchAdd",
  DINNING_BRANCHLIST: "/company/dinningUser/dinningbranchList",
  DINNINGUSER_ADD: "/company/dinningUser/add",
  DINNINGUSER_LIST: "/company/dinningUser/dinningUserList",
  DINNINSUSER_DELETE: "/company/dinningUser/dinningdelete/",
  DINNINGSTATUS: "/company/dinningUser/dinningUserStatus/",
  DINNING_VIEW: "/company/dinningUser/dinningUserView/",
  RESTAURANT_BRANCH_UPDATE: "company/branch/branchupdate/",
  RESTAURANT_BRANCH_VIEW: "company/branch/view/",
  DINNINGUSER_UPDATE: "/company/dinningUser/dinningUserUpdate/",
  BRANCH_LIST_TABLE: "/company/companybranchtables/table",
  BRANCH_VIEW_TABLE: "/company/companyBranchTables/get-table-view/",
  BRANCH_UPDATE_TABLE: "/company/companyBranchTables/edit-table/",
  CATEGORY_LISTCATEGORY: "/company/category/listcategory",
  CATEGORY_ADDCATEGORY: "/company/category/addcategory",
  CATEGORY_STATUS: "/company/category/status/",
  CATEGORY_VIEW: "/company/category/categoryview/",
  CATEGORY_UPDATE: "/company/category/editcategory/",
  CUSINE_LIST: "/company/cusines/listcusine",
  CUSINE_Add: "/company/cusines/createcusine",
  CUSINE_VIEW: "/company/cusines/viewcusine/",
  CUSINE_UPDATE: "/company/cusines/updatecusines/",
  CUSINE_STATE: "/company/cusines/statuscusine/",
  MENU_LIST: "/company/menu/listmenu",
  MENU_STATUS: "/company/menu/MenuStatus/",
  MENU_ADD: "/company/menu/addmenu",
  MENU_DELETE: "/company/menu/deletemenu/",
  MENU_VIEW: "/company/menu/Menuview/",
  MENU_UPDATE: "/company/menu/editmenu/",
  MENU_FILTER: "/company/menu/menulist",
  PROFILE_VIEW: "/admin/companymanagement/profileview",
  POINTS_LIST: "company/companyBranchTables/get-point",
  POINT_VIEW: "company/companyBranchTables/get-point-view/",
  POINT_EDIT: "company/companyBranchTables/edit-point/",
  // ROBOTS_LISTS: "Robot/robotic_communication/listrobot",

  ROBOTS_LISTS: "/company/branch/robotscompanylist",
  COMPANY_LOGOUT: "/admin/companymanagement/logout",
  BRANCH_VIEW_DATA: "/company/branch/view/",
  BRANCH_UPDATE_DATA: "/company/branch/branchupdate/",
  BRANCHUSER_LIST: "/company/branch/branchUserList",
  ROBOIT_LIST_VIEW: "Robot/robotic_communication/listrobot",
  ORDER_LIST: "Robot/robotic_communication/listorder",
  ROBOIT_MENU_LIST: "mobile/menu/listordermenu",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const dashboardBanner = {
  loop: true,
  autoplay: true,
  center: true,
  dots: false,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
