import { useState } from "react";

export default function usePermission() {
  const getPermission = () => {
    const userPer = JSON.parse(localStorage.getItem("permission"));

    return userPer;
  };

  const [permission, setPermisson] = useState(getPermission());

  const savePermission = (userPermissions) => {
    localStorage.setItem("permission", JSON.stringify(userPermissions));
    setPermisson(userPermissions);
  };

  return {
    setPermisson: savePermission,
    permission,
  };
}
