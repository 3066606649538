import React, { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate,useLocation, useSearchParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import {
  useCategoriesQuery,
  useStatusChangeMutation,useCategory_ListMutation,useCategory_addMutation,useLazyCategory_statusQuery,
} from "../../../constants/redux/api/api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Table } from "../../Table/Table";
import TopHeader from "../../../components/TopHeader";
import { toast } from "react-toastify";

const RestaurantCategoryList = ({ menuOpen }) => { 
  const location =useLocation()
  const datas =location.state;
  const [searchParams, setSearchparams]=useSearchParams()
  const navigate = useNavigate();
  const [allCategData, setAllCategData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [addForm, setAddForm] = useState(false);
  const [data,setdata]=useState([]);
  const[name,setname]=useState("")
  const[image,setImage]=useState({})
  const[item,setitem]=useState("")
  const [NameErr,setNameErr]=useState(false)
  const [imageErr,setImageErr]=useState(false)
  const [disablebtn,setdisablebtn]=useState(false)
  const [type,settype]=useState(false)
  // const [searchbtn, setsearchbtn] = useState(false);

  console.log('searchParams', searchParams.get('no'));
  const branchId = searchParams.get('no')

 
  const [branchListApi] = useCategory_ListMutation();
  const [addcategoryApi]= useCategory_addMutation();
  const[categorysts] =useLazyCategory_statusQuery();
  // RTK QUERY
  // const { data: categoryData, isSuccess: isCategSuccess } =
  //   useCategoriesQuery();
  // const [statusChange] = useStatusChangeMutation();

  // useEffect(() => {
  //   if (isCategSuccess) {
  //     console.log(categoryData?.result);
  //     setAllCategData(categoryData?.result);
  //   }
  // }, [categoryData, isCategSuccess]);

  // useEffect(() => {
  //   let tmp = [];
  //   allCategData?.forEach((el) => {
  //     tmp.push({
  //       categoryName: el?.categoryName,
  //       categoryNameId: el?.categoryNameId,
  //       categoryimage: el?.categoryimage,
  //       categoryActivity: el?.categoryActivity,
  //     });
  //   });
  //   setAllCategories(tmp);
  // }, [allCategData]);

  const onActiveClick = (item) => {
    const formData = new FormData();
    formData.append("categoryNameId", item?.categoryNameId);
    formData.append("categoryItemsId", item?.id);
    // statusChange({
    //   categoryNameId: item?.categoryNameId,
    //   //   categoryItemsId: item?.id,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     if (res?.status === "success") {
    //       console.log(res);
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   setAddForm(true);
  // };
  const getstatus =(id)=>{
    setdisablebtn(true)
    categorysts(id).
    unwrap()
    .then((res)=>{
      setdisablebtn(false)
      toast.success(res?.message)
      getList()
    })
    .catch(err=>{
      setdisablebtn(false)
      console.log(err)})
  } 



  const onSubmit =()=>{
    if(name?.length==0 || image?.size === undefined){
      setNameErr(true);
      setImageErr(true)
    }else{
      setdisablebtn(true)
      const formData = new FormData();
      formData.append("category_name",name);
      formData.append("image",image)
      addcategoryApi(formData)
      .unwrap()
      .then(res=>{
        setdisablebtn(false)
        console.log(res)
      }
        )
        .catch(err=>{
          setdisablebtn(false)
          console.log(err)})
    }
  }

  const getList = () => {
    const formdata = new URLSearchParams()
    formdata.append("company_branch_id",branchId)
    branchListApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setdata(res?.data)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(()=>{
    getList()
  },[])
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Category",
        accessor: "category_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Category Image",
        accessor: "image_url.CategoryImage",
        Cell: ({ value, row }) => {
          return <img src={value}/>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
            disabled={disablebtn}
            onClick={() =>{
              getstatus(row.original._id)}}
            className={`${
              value
                ? "btn-success"
                : "btn-danger"
            } btn`}
          >
            {value ? "Active" : "Deactive"}
          </button>
          )
        },
      },
     
     
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          console.log(row)
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() => navigate("/restaurant/add",{
                  state: { type: "edit", data: row.original },
                })
              
              }
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              <button
                onClick={() =>
                  navigate("/restaurant/add", {
                    state: {
                      type: "view",
                      data: row.original,
                    
                    },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"View_Category"}/>
      {!addForm ? (
        " "
      ) : (
        <h3 style={{ "font-size": "25px", color: "#25aae1" }}>Add Category</h3>
      )}

      {!addForm ? (
        <>
          <div className="menu-list d-flex justify-content-between align-items-center pe-4 mb-2">
            <h1>Category List</h1>
            <button
              type="button"
              onClick={() => {
                navigate("/restaurant/add",
                {state:{
                  data: searchParams.get('no')
                }}
                )
              }}
              className="btn btn-primary"
            >
              Add Category
            </button>
          </div>
          
          <div className="table-responsive menu-table side_pad mt-4">
            <Table data={data} columns={columns}/>
          </div>
        </>
      ) : (
        <>
          
          <div className="add-food-section row">
            <div className="form-group col-6">
              <label>Category Name</label>
              <input
                type="text"
                placeholder="Enter category Name"
                className="form-control"
                value={name}
                onChange={(e)=>setname(e.target.value)}
              />
            {name?.length==0&&NameErr&&(<p className="errtext">Enter category name</p>)}
            </div>
            <div className="form-group col-6">
              <label>Category Image</label>
              <input
                type="file"
                placeholder="Enter Category Image"
                className="form-control"
            
                onChange={(e)=>setImage (e.target.files[0])}  
              />
            {image.size==undefined&&imageErr&&(<p className="errtext">Select file</p>)}
            </div>
            <div className="form-group col-6">
              <button type="button" 
              disabled={disablebtn}
              
              onClick={onSubmit}
              className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantCategoryList;
