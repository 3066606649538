import { createSlice } from "@reduxjs/toolkit";

const selectedTableName = createSlice({
  name: "selectedTableName",
  initialState: null,
  reducers: {
    saveSelectedTableName: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveSelectedTableName } = selectedTableName.actions;
export default selectedTableName.reducer;
