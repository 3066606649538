import React, { useEffect, useState } from "react";
import { faceAnimation } from "../assets/img";
import { WS_URL } from "../constants/redux/api/constants";

const Websocket = ({ setRecieve, type, id }) => {
  const websocketUrl = WS_URL;
  const name = localStorage.getItem("name");
  const [webSock, setWebSock] = useState(null);
  const [socket, setSocket] = useState(false);

  const ws = new WebSocket(WS_URL);

  useEffect(() => {
    ws.onopen = () => {
      console.log("connected");
      setWebSock(ws);
      setSocket(true);
    };

    ws.onmessage = (e) => {
      const msg = e.data;
      // console.log("msg", msg);
      setRecieve(msg);
    };

    // ws.send(activeTableInd);

    ws.onclose = () => {
      console.log("web socket closed");
    };
  }, []);

  useEffect(() => {
    if (socket) {
      if (type && id) {
        webSock.send(`${name}:${type}-${id}`);
      } else {
        webSock.send(`${name}:${type}`);
      }
    }
  }, [socket, type, id]);

  return (
    <div className="websocket-popup">
      <img src={faceAnimation} />
    </div>
  );
};

export default Websocket;
