import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import {
    useCompany_management_listMutation,
    useHiquestionlistMutation,
    useHirobotspeechconversationMutation,
} from "../../constants/redux/api/api";

const AddHiBranchKey = ({ menuOpen }) => {

    const [inputs, setInputs] = useState([{ id: 1, questionId: '', answer: '', keywords: [], nextQuestionIds: [] }]);
    const [close, setClose] = useState(false);
    const location = useLocation();
    const [company_management_list] = useCompany_management_listMutation();
    const [question_list] = useHiquestionlistMutation();
    const [update_question]=useHirobotspeechconversationMutation();
    const [comapnyManageList, setCompanyManagementList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [branchData, setBranchData] = useState({});
    const [branch, setBranch] = useState("");
    const [companyNameErr, setCompanyNameErr] = useState(false);
    const [branchErr, setBranchErr] = useState(false);
    const [allQuestions, setAllQuestions] = useState([]);
    const [nextQuestions, setNextQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [btn, setBtn] = useState(false);

    const addInput = () => {
        const newId = inputs.length + 1;
        const newInput = { id: newId, questionId: '', answer: '', keywords: [], nextQuestionIds: [] };
        setInputs([...inputs, newInput]);
    }
 console.log(inputs,"allQuestions")
    const getCompanyManagementList = () => {
        company_management_list()
            .unwrap()
            .then((res) => {
                if (res?.status === "success") {
                    let temp = res?.data?.filter((i) => i?.status === true);
                    setCompanyManagementList(temp.reverse());
                }
            })
            .catch((err) => {
                console.log(err, "error");
            });
    };

    const questionList = async (company_id, company_branch_id) => {
        try {
            const res = await question_list({ company_id, company_branch_id }).unwrap();
            if (res?.status === "success") {
                let temp = res?.data;
                const speechData = temp.flatMap(item => item.speech);
                setAllQuestions(speechData.map(speech => ({
                    id: speech._id,
                    question: speech.question,
                    answer: speech.answer,
                    keywords: speech.keywords.map(keyword => ({
                        id: keyword._id, 
                        keywords: keyword.keyword
                    }))
                })));
                setNextQuestions(speechData); 
            }
        } catch (err) {
            console.log(err, "error");
        }
    };
    

    const handleInputChange = (id, field, value) => {
        const newInputs = inputs.map(input =>
            input.id === id ? { ...input, [field]: value } : input
        );
        setInputs(newInputs);
    }

    const handleClose = () => {
        if (inputs.length > 1) {
            const updatedInputs = [...inputs];
            updatedInputs.pop();
            setInputs(updatedInputs);
        }
    };

    const handleKeywordChange = (id, index, value) => {
        const newInputs = inputs.map(input => {
            if (input.id === id) {
                const newKeywords = [...input.keywords];
                newKeywords[index] = { 
                    ...newKeywords[index], 
                    keywords: value.split(',').map(k => k.trim())
                };
                return { ...input, keywords: newKeywords,nextQuestionIds: Array(newKeywords.length).fill('') };
            }
            return input;
        });
        setInputs(newInputs);
    };
    
    const handleQuestionChange = (id, event) => {
        const selectedQuestionId = event.target.value;
        const questionData = allQuestions.find(q => q.id === selectedQuestionId);
        if (questionData) {
            setInputs(inputs.map(input =>
                input.id === id ? {
                    ...input,
                    questionId: questionData.id,
                    answer: questionData.answer,
                    keywords: questionData.keywords
                } : input
            ));
        }
    };


    const handleNextQuestionChange = (id, index, value) => {
        const newInputs = inputs.map(input => {
            if (input.id === id) {
                const newNextQuestionIds = [...input.nextQuestionIds];
                newNextQuestionIds[index] = value;
                return { ...input, nextQuestionIds: newNextQuestionIds };
            }
            return input;
        });
        setInputs(newInputs);
    };
    


    useEffect(() => {
        getCompanyManagementList();
        if (companyName && branch) {
            questionList(companyName, branch); 
        }
    }, [companyName, branch]);

    const companySelecter = (item) => {
        let temp = comapnyManageList?.find((i) => i?._id === item);
        setBranchList(temp?.branches);
        setCompanyName(item);
        setBranch("");
        setBranchData({});
    };

    const branchSelecter = (item) => {
        let temp = branchList?.find((i) => i?.id === item);
        setBranchData(temp);
        setBranch(item);
    };
    const handleSubmit = async () => {
        try {
    
            const formattedSpeeches = inputs.flatMap(set => {
                if (set.keywords.length > 0) {
                    return set.keywords.map((keyword, index) => ({
                        id: keyword.id,
                        next_question_id: set.nextQuestionIds[index] || ''  
                    }));
                } else {
                    return [{
                        id: set.questionId,  
                        next_question_id: set.nextQuestionIds[0] || ''  

                    }];
                }
            });
    
            const filteredSpeeches = formattedSpeeches.filter(speech => speech.next_question_id !== '');
    
            console.log("filteredSpeeches", filteredSpeeches);
    
            // Submit the filtered speeches
            await update_question(filteredSpeeches)
                .unwrap()
                .then((res) => {
                    setLoading(false);
                    setBtn(false);
                    // navigate(-1);  // Uncomment if you want to navigate after submission
                })
                .catch((err) => {
                    setBtn(false);
                    setLoading(false);
                    console.log(err);
                });
    
        } catch (error) {
            console.error("An error occurred in handleSubmit:", error);
            setBtn(false);
            setLoading(false);
        }
    };
    
    
    return (
        <div className={menuOpen ? "side-content2" : "side-content"}
             style={{ transition: "0.5s" }}>
            <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2">Company Name</label>
                <select
                    className="form-control mb-1"
                    value={companyName}
                    onChange={(e) => companySelecter(e.target.value)}
                    name="companyname"
                >
                    <option value="" disabled hidden>Select Company</option>
                    {comapnyManageList?.map((item, ind) => (
                        <option value={item?._id} key={ind}>{item?.companyname}</option>
                    ))}
                </select>
                {companyName?.length === 0 && companyNameErr && (
                    <p className="text-danger m-0">Select Company Name</p>
                )}
            </div>
            <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <div>
                    <label className="mb-2" htmlFor="">Branch Name</label>
                    <select
                        className="form-control mb-1"
                        name="branchname"
                        value={branch}
                        onChange={(e) => branchSelecter(e.target.value)}
                    >
                        <option value="" hidden>Select Branch</option>
                        {branchList?.map((item, ind) => (
                            <option value={item?.id} key={ind}>{item?.name}</option>
                        ))}
                    </select>
                    {branch?.length === 0 && branchErr && (
                        <p className="text-danger m-0">Select Branch Name</p>
                    )}
                </div>
            </div>
            <div className='container pb-3'>
                <button className="border-0 ad-btn mt-4 ms-0" onClick={addInput}>Add</button>
                {inputs.map((input) => (
                    <div className="d-flex justify-content-between mt-4 w-100 tabbs" key={input.id}>
                        <div style={{ width: "20%" }}>
                            <h4 className="head-text">Question</h4>
                            <select
                                style={{ padding: "5px 25px 5px 4px", width: "100%" }}
                                value={input.questionId} 
                                onChange={(e) => handleQuestionChange(input.id, e)}
                            >
                                <option value="" hidden>Select Question</option>
                                {allQuestions.map((question) => (
                                    <option key={question.id} value={question.id}>
                                        {question.question}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div style={{ width: "30%" }}>
                            <h4 className="head-text">Answer</h4>
                            <textarea style={{ padding: "5px 25px 5px 4px", width: "100%" }}
                                      placeholder="Enter Your Answer"
                                      value={input.answer}
                                      onChange={(e) => handleInputChange(input.id, 'answer', e.target.value)}
                            />
                        </div>

                        <div>
                            <h4 className="head-text">Key Word</h4>
                            {input.keywords.map((keyword, index) => (
        <div className="d-flex" key={index}>
            <input 
                type="text" 
                className="form-control mb-1" 
                placeholder="Enter Keywords"
                value={keyword.keywords.join(', ')} 
                onChange={(e) => handleKeywordChange(input.id, index, e.target.value)} 
            />
        </div>
    ))}
                        </div>

                       
                        <div style={{ width: "30%" }}>
                            <h4 className="head-text">Next Question</h4>
                            
                            {input.keywords.length > 0 ? (
    input.keywords.map((_, index) => (
        <select
            key={index}
            style={{ padding: "5px 25px 5px 4px", width: "100%", marginBottom: '10px' }}
            value={input.nextQuestionIds[index] || ""}
            onChange={(e) => handleNextQuestionChange(input.id, index, e.target.value)} 
        >
            <option value="" hidden>Select Next Question</option>
            {nextQuestions.map((question) => (
                <option key={question._id} value={question._id}>
   {question.question || question.answer}
                  </option>
            ))}
        </select>
    ))
) : (
    <select
        style={{ padding: "5px 25px 5px 4px", width: "100%" }}
        value={input.nextQuestionIds || ""}
        onChange={(e) => handleNextQuestionChange(input.id, 0, e.target.value)} 
    >
        <option value="" hidden>Select Next Question</option>
        {nextQuestions.map((question) => (
            <option key={question._id} value={question._id}>
                 {question.question || question.answer}
            </option>
        ))}
    </select>
)}
</div>
                        <div>
                        {!close && <div onClick={handleClose}><CloseIcon /></div>}
                    </div>
                    </div>
                ))}
                 <button className="border-0 ad-btn mt-4" onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};

export default AddHiBranchKey;
