import React from "react";
import RestaurantRobotOrderList from "./RestaurantRobotOrderList";
import TopHeader from "../../components/TopHeader";

const RestaurantRobotOrder = ({ menuOpen }) => {
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"Robot_order_view"}/>
      <RestaurantRobotOrderList />
    </div>
  );
};

export default RestaurantRobotOrder;
