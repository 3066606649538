import { createSlice } from "@reduxjs/toolkit";

const selectedTable = createSlice({
  name: "selectedTable",
  initialState: null,
  reducers: {
    saveSelectedTable: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveSelectedTable } = selectedTable.actions;
export default selectedTable.reducer;
