import React from "react";
import { useNavigate } from "react-router-dom";
import RobotList from "../../../components/IndustrialManagement/RobotList";

const IndRobotManagementScreen = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}>
      <div className="menu-list">
        <h1>Robots List</h1>
      </div>
      <div className="d-flex ac-jb w-100">
        <div className="menu-list w-100">
          <h4>Perambur Branch Informations</h4>
        </div>
        <div className="menu-list w-100 d-flex ac-je me-4">
          <button
            // onClick={() => navigate("/add-branch-list")}
            onClick={() => navigate("/ind-add-branch-table")}
            className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-3">
            Add Point
          </button>
          <button
            onClick={() => navigate("/map-view")}
            className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2">
            View Map
          </button>
        </div>
      </div>

      <RobotList />
      <div className="d-flex ac-jb w-100">
        <div className="menu-list w-100">
          <h4>Perambur Branch Informations</h4>
        </div>
        <div className="menu-list w-100 d-flex ac-je me-4">
          <button
            // onClick={() => navigate("/add-branch-list")}
            onClick={() => navigate("/ind-add-branch-table")}
            className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-3">
            Add Point
          </button>
          <button
            onClick={() => navigate("/map-view")}
            className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2">
            View Map
          </button>
        </div>
      </div>
      <RobotList />
    </div>
  );
};
export default IndRobotManagementScreen;
