import React from "react";
import { useNavigate } from "react-router-dom";
import RobotOrderList from "../../components/RobotOrderList";
import { menu_section_left } from "../../assets/images";
import ScreenHeader from "../../components/ScreenHeader";
import TopHeader from "../../components/TopHeader";

const RobotOrder = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"robo-order"} />
      <ScreenHeader
        title="Robot Order"
        menuLink="/add-robot-order"
        menuBtn="Add order"
        menuTitle="Check out your order"
        description="You can add your order from here"
        img={menu_section_left}
      />
      <RobotOrderList />
    </div>
  );
};

export default RobotOrder;
