// import React from "react";
// import RestaurantRobotList from "./RestaurantRobotList";

// const RestaurantRobotOrderListScreen = ({ menuOpen }) => {
//   // /robot-orders
//   return (
//     <div
//       className={menuOpen ? "side-content2" : "side-content"}
//       style={{ transition: "0.5s" }}
//     >
      
//       <div class="menu-list d-flex ac-jb w-100">
//         <div class="w-100">
//           <h1>Robot List</h1>
//         </div>
//         <div class="w-50 position-relative">
//           <div class="add-food-section bg-transparent">
//             <input
//               type="text"
//               name="search"
//               class="form-control ps-5"
//               placeholder="Search..."
//             />
//           </div>
//           <div style={{ position: "absolute", top: "30px", left: "34px" }}>
//             <svg
//               class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
//               focusable="false"
//               aria-hidden="true"
//               viewBox="0 0 24 24"
//               data-testid="SearchIcon"
//             >
//               <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
//             </svg>
//           </div>
//         </div>
//       </div>
//       <div className="menu-list w-100">
//         <RestaurantRobotList />
//       </div>
//     </div>
//   );
// };

// export default RestaurantRobotOrderListScreen;
import TopHeader from "../../components/TopHeader";
import RobotList from "../screens/RobotList"
import { useNavigate } from "react-router-dom";
import RobotOrderList from "../screens/RobotOrderList";
import RestaurantRobotOrderList from "./RestaurantRobotOrderList";


const RestaurantRobotOrderListScreen = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"Robot_order"}/>
      <div class="menu-list d-flex ac-jb w-100">
        <div class="w-100">
          {/* <h1>Robot Oder</h1> */}
        </div>
        <div class="w-50 position-relative">
          {/* <div class="add-food-section bg-transparent">
            <input
              type="text"
              name="search"
              class="form-control ps-5"
              placeholder="Search..."
            />
          </div> */}
          {/* <div style={{ position: "absolute", top: "30px", left: "34px" }}>
            <svg
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="SearchIcon"
            >
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
            </svg>
          </div> */}
        </div>
      </div>
      <div className="menu-list w-100">
        <RobotOrderList />
        {/* <RestaurantRobotOrderList/> */}
      </div>
    </div>
  );
};

export default RestaurantRobotOrderListScreen;
