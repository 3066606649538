import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import { Table } from "../Table/Table";
import {
  useDinninguser_deleteMutation,
  useLazyDinningStatusQuery,
  useLazyDinninguser_listQuery,
} from "../../constants/redux/api/api";
import { toast } from "react-toastify";
const UserList = () => {
  const [status, setStatus] = useState(false);
  const [data, setdata] = useState([]);
  const [searchbtn, setsearchbtn] = useState(false);
  const navigate = useNavigate();

  // RTK Query
  const [userlist] = useLazyDinninguser_listQuery();
  const [Deleteuserlist] = useDinninguser_deleteMutation();
  const [dinningStatus] = useLazyDinningStatusQuery();

  const getUserList = () => {
    userlist()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          const temp = [...res.data].reverse();
          setdata(temp);
          console.log(res?.data);
        }
      })
      .catch((err) => console.log("userListERR", err));
  };

  const getdinningStatus = (id) => {
    setsearchbtn(true)
    dinningStatus(id)
    .unwrap()
      .then((res) => {
        console.log(res, "response");
        getUserList();
        setsearchbtn(false)
        toast.success(res?.message)
      })
      .catch((err) =>{  
        setsearchbtn(false)
        console.log("dinningStatus", err)});
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setsearchbtn(true)
        Deleteuserlist(id)
          .unwrap()
          .then((res) => {
            console.log(res);
            getUserList();
            setsearchbtn(false)
            toast.success(res?.message)
          })
          .catch((err) =>{
            setsearchbtn(false)
            console.log(err)});
      }
    });
  };

  useEffect(() => {
    getUserList();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "User Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Phone No",
        accessor: "mobile",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "companyBranches[0].name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Role",
        accessor: "type",
        Cell: ({ value, row }) => {
          return <p>{value==1?"Kitchen":"Waiter"}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          
          return (
            <button
              disabled={searchbtn}
              onClick={() => getdinningStatus(row.original._id)}
              className={`${
                value ? "bg-success" : "bg-danger"
              } px-2 py-1 rounded-1 border-0 text-white`}
            >
              {value ? "Active" : "Deactive"}
            </button>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              {/* <button
                      onClick={() =>
                        navigate("/view-branch", {
                          state: {
                            type: "view",
                            data:row.original
                          },
                        })
                      }
                      type="button"
                      className="btn btn-secondary px-4"
                    >
                      <VisibilityIcon />
                    </button> */}
              <button
                onClick={() => navigate("/restaurant/add-users",{
                  state: { type: "edit", data: row.original },
                })
              
              }
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              <button
                onClick={() =>
                  navigate("/restaurant/add-users", {
                    state: { type: "view", data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    // <div className="table-responsive menu-table side_pad mt-4">
    //   <table>
    //     <thead>
    //       <tr>
    //         <th>S.No</th>
    //         <th>User Name</th>
    //         <th>Email</th>
    //         <th>Phone No</th>
    //         <th>Status</th>
    //         <th>Action</th>
    //       </tr>
    //     </thead>
    //     <tbody className="">
    //       <tr>
    //         <td>1</td>
    //         <td>Mark Mattew</td>
    //         <td>mark@exaplemail.com</td>
    //         <td>98765 43209</td>
    //         <td>
    //           <button
    //             onClick={() => setStatus((prev) => !prev)}
    //             className={`${
    //               status ? "bg-success" : "bg-danger"
    //             } px-2 py-1 rounded-1 border-0 text-white`}
    //           >
    //             {status ? "Active" : "Deactive"}
    //           </button>
    //         </td>
    //         <td>
    //           <button
    //             onClick={() => navigate("/edit-user")}
    //             className="bg-primary text-white border-0 rounded-1 p-1 me-2"
    //           >
    //             <Edit />
    //           </button>
    //           <button className="bg-warning border-0 rounded-1 p-1">
    //             <Delete />
    //           </button>
    //         </td>
    //       </tr>
    //       <tr>
    //         <td>2</td>
    //         <td>Bezelel</td>
    //         <td>bezellel@exaplemail.com</td>
    //         <td>98765 43209</td>
    //         <td>
    //           <button
    //             onClick={() => setStatus((prev) => !prev)}
    //             className={`${
    //               status ? "bg-success" : "bg-danger"
    //             } px-2 py-1 rounded-1 border-0 text-white`}
    //           >
    //             {status ? "Active" : "Deactive"}
    //           </button>
    //         </td>
    //         <td>
    //           <button
    //             onClick={() => navigate("/edit-user")}
    //             className="bg-primary text-white border-0 rounded-1 p-1 me-2"
    //           >
    //             <Edit />
    //           </button>
    //           <button className="bg-warning border-0 rounded-1 p-1">
    //             <Delete />
    //           </button>
    //         </td>
    //       </tr>
    //     </tbody>
    //   </table>
    <div className="table-responsive menu-table side_pad mt-4">
      <Table data={data} columns={columns}  />
    </div>
  );
};

export default UserList;
