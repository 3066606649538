import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RobotList from "../components/RobotManagement/RobotList";
import RobotLists from "../components/RobotManagement/RobotLists";
import TopHeader from "../components/TopHeader";
import {
  useCompanyRobotViewRoboMutation,
  useLazyRobotManagementBranchListQuery,
} from "../constants/redux/api/api";

const RobotManagementScreen = ({ menuOpen }) => {
  const navigate = useNavigate();
  const loction = useLocation();
  const [list, setList] = useState([]);

  // console.log("loction", loction);

  // RTK QUERY
  const [companyRobotsListApi] = useLazyRobotManagementBranchListQuery();
  const [companyRobotViewApi] = useCompanyRobotViewRoboMutation();
  const getList = () => {
    let data = {
      company_id: loction?.state?.data?.company_branch_details[0]?.company_id,
    };
    companyRobotViewApi(data)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];
          res?.data?.map((item, id) => {
            temp.push({
              company_id: item?.company_branch_details?.[0]?.company_id,
              branch_id: item?.company_branch_details?.[0]?._id,
              location: item?.company_branch_details?.[0]?.location,
              name: item?.company_branch_details?.[0]?.name,
              pincode: item?.company_branch_details?.[0]?.pincode,
              company_branch_details: item?.company_branch_details,
            });
          });

          // const mergeIds = (list) => {
          const subarrsById = {};
          for (const {
            company_branch_id,
            robot_info,
            company_branch_info,
            company_info,
          } of res?.data) {
            if (!subarrsById[company_branch_id]) {
              subarrsById[company_branch_id] = {
                company_branch_id,
                company_branch_info,
                company_info,
                robot_info: [...robot_info],
              };
            } else {
              subarrsById[company_branch_id].robot_info.push(...robot_info);
            }
          }
          let tempArr = Object.values(subarrsById);

          // };

          console.log("mergeIds", tempArr);

          setList(tempArr);
          // console.log("temp", temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (loction?.state?.data?.company_branch_details[0]?.company_id) {
      getList();
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"robot-management"} />
      <div className="menu-list">
        <h1>Robots List</h1>
      </div>

      {list?.map((item, ind) => {
        return (
          <div key={ind}>
            <div className="d-flex ac-jb w-100">
              <div className="menu-list w-100">
                <h4>{item?.company_branch_info?.name} Branch Informations</h4>
              </div>
              <div className="menu-list w-100 d-flex ac-je me-4">
              <button
                  // onClick={() => navigate("/add-branch-list")}
                  onClick={() =>
                    navigate("/add-point-table", {
                      state: { data: item },
                    })
                  }
                  className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-3"
                >
                  Add Point
                </button>
                <button
                  // onClick={() => navigate("/add-branch-list")}
                  onClick={() =>
                    navigate("/add-branch-table", {
                      state: { data: item },
                    })
                  }
                  className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-3"
                >
                  Add Table
                </button>
                <button
                  onClick={() => navigate("/map-view")}
                  className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2"
                >
                  View Map
                </button>
              </div>
            </div>
            <RobotLists item={item} />
          </div>
        );
      })}
    </div>
  );
};
export default RobotManagementScreen;
