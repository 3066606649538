import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Search } from "@mui/icons-material";
import { useLazyCategory_ListQuery,useLazyBranchListQuery } from "../../../constants/redux/api/api";
import { menu_list } from "../../../constants/dummyData";
import { Table } from "../../Table/Table";
import TopHeader from "../../../components/TopHeader";

const RestaurantCategoryPage = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [dependency, setDependency] = useState(0);
  const [data,setData] =useState([])

  // RTK QUERY
  const [branchListApi] =useLazyBranchListQuery();

  const fetchProductList = useMemo(
    () => async () => {
      await branchListApi()
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    [dependency]
  );

  const getList = () => {
    branchListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          console.log(res,"list")
          const temp = [...res?.data?.branches].reverse();
          setData(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(()=>{
    getList()
  },[])
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Pin Code",
        accessor: "pincode",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
     
     
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/restaurant/view-category-cousin", {
                    state: {
                      type: "view",
                      data: row.original,
                    },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );


  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"Category & Cuisine type"}/>
      <div className="menu-list d-flex ac-jb w-100">
        <div className="w-100">
          <h1>Category & Cuisine type</h1>
        </div>
        {/* <div className="w-50 position-relative">
          <div className="add-food-section bg-transparent">
            <input
              type="text"
              // value={"Robot Type 2"}
              name="location"
              className="form-control ps-5"
              placeholder="Search..."
            />
          </div>
          <div style={{ position: "absolute", top: 30, left: 34 }}>
            <Search />
          </div>
        </div> */}
      </div>

      {/* new table start */}
      {/* <div className="table-responsive menu-table side_pad">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Branch Name</th>
              <th>Location</th>
              <th>Pin Code</th> */}
              {/* <th>Robot Count</th> */}
              {/* <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {menu_list?.map((item, ind) => {
              return (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{item?.company}</td>
                  <td>{item?.client_name}</td>
                  <td>{item?.viewcount}</td> */}
                  {/* <td>{item?.branchcount}</td> */}
                  {/* <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/restaurant/view-category-cousin", {
                                state: { type: "edit", data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary px-4"
                          >
                            <VisibilityIcon />
                          </button>
                        </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}
      <div className="table-responsive menu-table side_pad mt-4">
      <Table data={data} columns={columns} />
      </div>
    </div>
  );
};

export default RestaurantCategoryPage;
