const RestaurantEditUser = ({ menuOpen }) => {
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list">
        <h1>Edit User</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Name
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Email
                </label>
                <input
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="Enter Email name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Phone No
                </label>
                <input
                  name="phone"
                  type="number"
                  className="form-control mb-1"
                  placeholder="Enter Phone No"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Role
                </label>
                <select className="form-control" name="" id="">
                  <option value="" selected>
                    Select role
                  </option>
                  <option value="Super Admin">Waiter</option>
                  <option value="Admin">Kitchen Admin</option>
                  <option value="Technician">Robot Maintainer</option>
                </select>
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Password
                </label>
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Confirm Password
                </label>
                <input
                  name="confirmPassword"
                  type="password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label>Branch:</label>
                <select className="form-control" placeholder="Select branch">
                  <option value={""} selected>
                    Select Branch
                  </option>
                  <option value={"branch1"}>Branch 1</option>
                  <option value={"branch2"}>Branch 2</option>
                </select>
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label>Location:</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label>PinCode:</label>
                <input type="text" className="form-control" disabled />
              </div>
              <div className="col-12 px-md-3 px-0 position-relative mb-3">
                <input
                  type="checkbox"
                  id="primary_contact"
                  className="form-check-input"
                />
                <label for="#primary_contact" className="form-check-label ms-2">
                  Primary Contact
                </label>
              </div>
              <div className="col-12 px-md-0 px-0  d-flex  position-relative mb-3">
                <div className="px-md-3 pe-3">
                  <input className="btn-submit" type="submit" />
                </div>
                <div class="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantEditUser;
