import React from "react";
import AddButton from "./AddButton";
import { biryaniCategory } from "../assets/img";

const FoodItem = ({
  item,
  onAddClick,
  selectedProducts,
  onMinusClick,
  onIncreaseClick,
}) => {
  return (
    <div className="food-item">
      <img src={item?.url} alt="foods" />
      {/* <img src={biryaniCategory} alt="foods" /> */}
      <div className="food-name-price">
        <p>{item?.food}</p>
        <div className="food-price">
          <p>₹{item?.price}</p>
          <AddButton
            selectedProducts={selectedProducts}
            item={item}
            onAddClick={onAddClick}
            onMinusClick={onMinusClick}
            onIncreaseClick={onIncreaseClick}
          />
        </div>
      </div>
    </div>
  );
};

export default FoodItem;
