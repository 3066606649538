import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateTableMutation,
  useEditTableMutation,
} from "../../../constants/redux/api/api";

const AddCompanyUser = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;

  //   RTK QUERY
  const [createTable] = useCreateTableMutation();
  const [editTable] = useEditTableMutation();

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list">
        <h1>{type === "edit" ? "Edit User" : "Add User"}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Company Name
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Company Users
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Email Id
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Branch Name
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Branch name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Password
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3"></div>
              <div onClick={() => navigate(-1)} className="px-md-3 pe-3">
                <input className="btn-submit" type="submit" />
              </div>
              <div onClick={() => navigate(-1)} class="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCompanyUser;
