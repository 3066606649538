import { Delete, Edit } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { food } from "../../assets/images";
import { biryaniCategory, product } from "../../assets/img";

const CusineList = () => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="table-responsive menu-table side_pad mt-4">
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Cusine</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="">
          <tr>
            <td>1</td>
            {/* <td>DunsKart</td> */}
            <td>Chine</td>
            {/* <td>wait lifted for max 70kg</td> */}
            <td>
              <button
                onClick={() => setStatus((prev) => !prev)}
                className={`${
                  status ? "bg-success" : "bg-danger"
                } px-2 py-1 rounded-1 border-0 text-white`}
              >
                {status ? "Active" : "Deactive"}
              </button>
            </td>
            <td>
              <button
                onClick={() => navigate("/add-users")}
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              <button className="bg-warning border-0 rounded-1 p-1">
                <Delete />
              </button>
            </td>
          </tr>
          <tr>
            <td>2</td>
            {/* <td>RO-Waiter</td> */}
            <td>Indian</td>
            {/* <td>Waiter for the hotel</td> */}

            <td>
              <button
                onClick={() => setStatus((prev) => !prev)}
                className={`${
                  status ? "bg-success" : "bg-danger"
                } px-2 py-1 rounded-1 border-0 text-white`}
              >
                {status ? "Active" : "Deactive"}
              </button>
            </td>
            <td>
              <button
                onClick={() => navigate("/add-users")}
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              <button className="bg-warning border-0 rounded-1 p-1">
                <Delete />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CusineList;
