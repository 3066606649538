import React from "react";
import { useNavigate } from "react-router-dom";
import CompanyList from "../../../components/CustomerManagement/CompanyList";
import ScreenHeader from "../../../components/ScreenHeader";
import { menu_section_left } from "../../../assets/images";
import TopHeader from "../../../components/TopHeader";

const CompanyDetails = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"company-management"} />
      <ScreenHeader
        title="Company Management "
        menuLink="/add-company"
        menuBtn="Add Company"
        menuTitle="Check out your Company"
        description="You can add your Company from here"
        img={menu_section_left}
      />

      <CompanyList />
    </div>
  );
};

export default CompanyDetails;
