import React, { useState } from "react";
import { useSelector } from "react-redux";
import { biryaniCategory } from "../assets/img";
import Websocket from "./Websocket";

const CheckoutItem = ({
  item,
  qty,
  onMinusClick,
  onAddClick,
  onRemoveClick,
  type,
  setSocket,
}) => {
  const price = Number(item.price);

  const selectedTableName = useSelector((state) => state.selectedTableName);
  //   console.log(item.price, qty, price);
  return (
    <>
      <div>
        <div className="checkout-item">
          <div className="thumbnail">
            {/* <img src={item?.url} alt="food" /> */}
            <img src={biryaniCategory} alt="food" />
          </div>
          <p>{item?.food}</p>
          <div className={type == "placeOrder" ? "counter" : "counters"}>
            {type == "placeOrder" && (
              <p onClick={() => onMinusClick(item)}>-</p>
            )}
            <p className={type == "order" ? "price" : ""}>{qty}</p>
            {type == "placeOrder" && <p onClick={() => onAddClick(item)}>+</p>}
          </div>
          <p className="price">₹{price * qty}</p>
          {type == "placeOrder" && (
            <div onClick={() => onRemoveClick(item)} className="close-btn">
              X
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckoutItem;
