import React from "react";
import { successAnimation } from "../assets/img";

const SuccessPopup = ({ onClickBack }) => {
  return (
    <div className="success-popup">
      <video autoPlay muted>
        <source src={successAnimation} type="video/mp4" />
      </video>
      <h1>Your Order has been placed successfully🍔🤤!!!</h1>
      {/* <div onClick={onClickBack} className="back-btn">
        Back to Home
      </div> */}
    </div>
  );
};

export default SuccessPopup;
