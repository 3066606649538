import { Delete, Edit } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate,useLocation,useSearchParams} from "react-router-dom";
import {useCusine_ListMutation,useLazyCusine_statusQuery} from "../../../constants/redux/api/api"
import { useEffect } from "react";
import { Table } from "../../Table/Table";
import TopHeader from "../../../components/TopHeader";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
const RestaurantCuisineList = ({ menuOpen }) => {
  const [status, setStatus] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const[disableBtn,setDisableBtn]=useState(false)
  const [data, setdata] =useState([])
  const navigate = useNavigate();
  const [useSearch,setSearchparams]=useSearchParams()
  console.log(useSearch.get("no"))
  let branchId = useSearch.get("no") 

  // RTK 
  
const [Cuisinelist]=useCusine_ListMutation()
const [CuisineApi]=useLazyCusine_statusQuery()
  
const getStatus =(id)=>{
  setDisableBtn(true)
  CuisineApi(id)
  .then(res=>{
    toast.success(res?.data?.message)
    
    console.log(res,"toast")
    setDisableBtn(false)
    getcuisineList()
  })
  .catch(err=>{
    setDisableBtn(false)
    console.log(err)})
}



const getcuisineList =()=>{
  const formdata = new URLSearchParams()
  formdata.append("company_branch_id",branchId)
  Cuisinelist(formdata)
  .unwrap()
  .then((res)=>{
    if(res?.status=="success"){
      setdata(res?.data)
    }
  })
  .catch(err=>err)
}


useEffect(()=>{
  getcuisineList()
},[])



  const handleClick = (e) => {
    e.preventDefault();
    setAddForm(true);
  };



  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Cuisine",
        accessor: "cusine_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
   
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
            disabled={disableBtn}
            onClick={() =>{
              getStatus(row?.original?._id)}}
            className={`${
              value
                ? "btn-success"
                : "btn-danger"
            } btn`}
          >
            {value ? "Active" : "Deactive"}
          </button>
          )
        },
      },
     
     
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          console.log(row)
          return (
            <div className="d-flex justify-content-center gap-2">
              {/* <button
                onClick={() =>
                  navigate("/restaurant/add", {
                    state: {
                      type: "edit",
                      data: row.original,
                    
                    },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button> */}
              <button
                        onClick={() => navigate("/restaurant/Cousin-add",{
                          state:{
                            type:'edit',
                            data:row?.original
                          }
                        })}
                        className="bg-primary text-white border-0 rounded-1 p-1 me-2"
                      >
                        <Edit />
                      </button>
                      <button
                onClick={() =>
                  navigate("/restaurant/Cousin-add", {
                    state: { type: "view", data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"View_Cuisine"}/>
      {/* <div className="side_pad">
        {addForm && <h3>Add Cuisine</h3>}
        {!addForm ? (
          <> */}
            <div className="menu-list d-flex justify-content-between align-items-center pe-4 mb-2">
              <h1>Cuisine List</h1>
              <button
                type="button"
                onClick={() => {
                   navigate("/restaurant/Cousin-add",{
                    state:{
                      data:branchId
                    }
                   })
                }}
                className="btn btn-primary"
              >
                Add Cuisine
              </button>
            </div>
            {/* <div className="table-responsive menu-table side_pad mt-4">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Cuisine</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr>
                    <td>1</td>
                    <td>Chine</td>
                    <td>
                      <button
                        onClick={() => setStatus((prev) => !prev)}
                        className={`${
                          status ? "bg-success" : "bg-danger"
                        } px-2 py-1 rounded-1 border-0 text-white`}
                      >
                        {status ? "Active" : "Deactive"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => navigate("/restaurant/add-users")}
                        className="bg-primary text-white border-0 rounded-1 p-1 me-2"
                      >
                        <Edit />
                      </button> */}
                      {/* <button className="bg-warning border-0 rounded-1 p-1">
                        <Delete />
                      </button> */}
                    {/* </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Indian</td>
                    <td>
                      <button
                        onClick={() => setStatus((prev) => !prev)}
                        className={`${
                          status ? "bg-success" : "bg-danger"
                        } px-2 py-1 rounded-1 border-0 text-white`}
                      >
                        {status ? "Active" : "Deactive"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => navigate("/restaurant/add-users")}
                        className="bg-primary text-white border-0 rounded-1 p-1 me-2"
                      >
                        <Edit />
                      </button> */}
                      {/* <button className="bg-warning border-0 rounded-1 p-1">
                        <Delete />
                      </button> */}
                    {/* </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="add-food-section row">
            <div className="form-group col-4">
              <label>Cuisine Name</label>
              <input
                type="text"
                placeholder="Enter Cuisine Name"
                className="form-control"
              />
            </div> */}
            {/* <div className="form-group">
              <button type="button" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        )}
      </div> */}
       <div className="table-responsive menu-table side_pad mt-4">
            <Table data={data} columns={columns}/>
          </div>
    </div>
  );
};

export default RestaurantCuisineList;
