import { useState } from "react";
import mapImage from '../assets/images/map.jpg';

const ViewMapScreen = ({ menuOpen }) => {
    const [srcImg,setSrcImg] = useState('')
    const handleUpload = (e)=>{
        console.log(URL.createObjectURL(e.target.files[0]));
        setSrcImg(URL.createObjectURL(e.target.files[0]));
    }
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list">
        <div className="menu-list">
          <h1>Robots Mapping</h1>
        </div>
        <div className="menu-list d-flex align-items-center justify-content-between pe-2 mb-2 w-100 ">
          <div className="menu-list w-100">
            <h4>Perambur Branch Informations</h4>
          </div>
          <div className="add_img">
            <div className="form-group">
              <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(e)=>handleUpload(e)} multiple={true} className="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div className="side_pad">
        <div className="robot_mapping mb-3" id="img_show">
          <img
            className="map_img rounded-3"
            src={mapImage}
            width={100}
            alt="map"
          />
        </div>
      </div>
    </div>
  );
};
export default ViewMapScreen;
