import { createSlice } from "@reduxjs/toolkit";

const selectedFoods = createSlice({
  name: "selectedFoods",
  initialState: {},
  reducers: {
    saveSelectedFoods: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveSelectedFoods } = selectedFoods.actions;
export default selectedFoods.reducer;
