import React, { useState } from "react";
import { Outlet } from "react-router-dom";
// import Navbar1 from "../Navbar/Navber1";
import Navbar2 from "../Navbar/Navbar2";

const Layout = ({ toggle, menuOpen, setMenuOpen }) => {
  return (
    <div className="">
      <Navbar2 toggle={toggle} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Outlet />
      {/* <div className='w-100'>
                <Footer />
            </div> */}
    </div>
  );
};

export default Layout;
