import React from "react";
import { useNavigate } from "react-router-dom";
import BranchList from "../../../components/CustomerManagement/BranchList";
import ScreenHeader from "../../../components/ScreenHeader";
import { menu_section_left } from "../../../assets/images";
import TopHeader from "../../../components/TopHeader";

const BranchDetails = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"company-robo"} />
      <ScreenHeader
        title="Branch List"
        menuLink="/add-branch"
        menuBtn="Add Company Robot"
        menuTitle="Check out your Company robots"
        description="You can add your robots from here"
        img={menu_section_left}
      />
      <BranchList />
    </div>
  );
};

export default BranchDetails;
