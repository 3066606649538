import React from "react";

const AddButton = ({
  item,
  onAddClick,
  selectedProducts,
  onMinusClick,
  onIncreaseClick,
}) => {
  return (
    <>
      {selectedProducts[item?.food] ? (
        <div className="product-qty">
          <p onClick={() => onMinusClick(item)}>-</p>
          <p>{selectedProducts[item?.food]}</p>
          <p onClick={() => onIncreaseClick(item)}>+</p>
        </div>
      ) : (
        <div className="addBtn" onClick={() => onAddClick(item)}>
          Add
        </div>
      )}
    </>
  );
};

export default AddButton;
