import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import CategoryList from "../components/CategoryList/CategoryList";
import CusineList from "../components/CategoryList/CusineList";
import BranchTableList from "../components/RobotManagement/BranchTableList";
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useLazyRobotManagementListQuery,
  useLazyTableListAdminQuery,
} from "../constants/redux/api/api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import TopHeader  from "../components/TopHeader";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const AddPointTable = ({ menuOpen }) => {
  const [listShow, setListShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setList] = useState([]);

  const [tableListApi] = useLazyTableListAdminQuery();

  const getList = () => {
    tableListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = res?.data?.filter(
            (i) =>
              i?.branch?.[0]?._id == location?.state?.data?.company_branch_id
          );
          console.log("temp", temp);
          setList(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Company Name",
        accessor: "company.name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "branch[0].name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Table Name",
        accessor: "table_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() => {
                  // navigate("/add-robot-edit");
                  navigate("/add-branch-list", {
                    state: {
                      data: location?.state?.data,
                      type: "edit",
                      item: row.original,
                    },
                  });
                }}
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              <button
                onClick={() => navigate("/add-branch-list",{ state: {
                  data: location?.state?.data,
                  type: "view",
                  item: row.original,
                },})}
                        type="button"
                        className=" bg-secondary text-white border-0 rounded-1 p-1 me-2"
                      >
                        <VisibilityIcon />
                </button>
            </div>
          );
        },
      },
    ],
    []
  );

  console.log("location", location);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"add-branch-table"} />
      <div>
        <div className="add-food-section bg-transparent d-flex ac-je p-0 pe-4">
          <button
            class="btn-outline primary1"
            // onClick={() => setListShow(true)}
            onClick={() =>
              navigate("/add-point-list", {
                state: { data: location?.state?.data },
              })
            }
          >
            + Add Point
          </button>
        </div>
        <div className="table-responsive menu-table side_pad my-4">
          <Table columns={columns} data={list} />
        </div>
        {/* <BranchTableList setListShow={setListShow} /> */}
        <div
          className="btn-outline2 cp ac-jc d-flex"
          onClick={() => navigate("/robot-management")}
        >
          <p className="mb-0">Back</p>
        </div>
      </div>
    </div>
  );
};
export default AddPointTable;


