import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLazyOrderDetailsQuery } from "../constants/redux/api/api";

const OrderDetails = () => {
  const location = useLocation();
  const data = location?.state?.data;
  const [list, setList] = useState({});

  const [orderDetail] = useLazyOrderDetailsQuery();

  const getDetails = () => {
    orderDetail(data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.result);
        }
      });
  };

  useEffect(() => {
    if (data?.id) {
      getDetails();
    }
  }, []);
  return (
    <div className="side-content robot-order-details">
      <div className="menu-list">
        <h1>Order Details</h1>
        <div className="side_pad">
          <div className="robot-order-det">
            <div className="d-flex flex-wrap justify-content-between ">
              <div className="robot_name mar_b">
                <h5 style={{ fontSize: "20px" }}>
                  <span> Table : {list?.table_name}</span>
                </h5>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="robot_content mar_b">
                {/* <p>
                  Robot ID : <span>#0007</span>
                </p> */}
              </div>
              <div className="robot_name mar_b">
                <h5>
                  Status
                  <svg
                    style={{
                      width: "14px",
                      height: "14px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                    className={
                      list?.status == 0
                        ? "svg-inline--fa fa-circle-check ac_id"
                        : list?.status == 1
                        ? "svg-inline--fa fa-circle-check ac_ie"
                        : list?.status == 2
                        ? "svg-inline--fa fa-circle-check ac_ic"
                        : "svg-inline--fa fa-circle-check ac_id"
                    }
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="circle-check"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg="">
                    <path
                      fill="currentColor"
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                  </svg>{" "}
                  <span>
                    {" "}
                    {list?.status == 0
                      ? "Order Taken"
                      : list?.status == 1
                      ? "Prepared"
                      : list?.status == 2
                      ? "Delivered"
                      : "Waiting"}
                  </span>
                </h5>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="robot_content mar_b">
                <p>Total Price</p>
              </div>
              <div className="robot_content mar_b">
                <p>
                  <span>₹ {list?.total}</span>
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="robot_content mar_b">
                <p>
                  <span>Order List</span>
                </p>
              </div>
              <div className="robot_content mar_b">
                <p>
                  <span>Quantity</span>
                </p>
              </div>
            </div>
            {list?.orderedfoods?.map((item, ind) => {
              return (
                <div className="d-flex justify-content-between">
                  <div className="robot_content mar_b">
                    <p>
                      {ind + 1}. {item?.food}
                    </p>
                  </div>
                  <div className="robot_content mar_b">
                    <p>{"₹ " + item?.price + " x " + item?.qty}</p>
                  </div>
                </div>
              );
            })}
            {/* <div className="d-flex justify-content-between">
              <div className="robot_content mar_b">
                <p>2. French Fries</p>
              </div>
              <div className="robot_content mar_b">
                <p>01</p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="robot_content mar_b">
                <p>3. Chicken Tandoori Sandwich</p>
              </div>
              <div className="robot_content mar_b">
                <p>03</p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="robot_content mar_b">
                <p>4. Pepsi - 500ml</p>
              </div>
              <div className="robot_content mar_b">
                <p>01</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
