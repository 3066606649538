import React, { useEffect, useState } from "react";

const EditMenuManageMent = ({ editData, CategoryEdit, onCancelClick }) => {
  const [editFormData, setEditFormData] = useState({
    name: editData?.categoryName,
    image: "",
  });


  // onchange event
  const inputOnChangeHandler = (e) => {
    const { name, value } = e && e.target;
    if (name) {
      setEditFormData({
        ...editFormData,
        [name]: value,
      });
    }
  };

  //   Update edit Name
  useEffect(() => {
    editData &&
      setEditFormData({
        name: editData?.categoryName,
        image: editData?.categoryimage,
      });
  }, []);
  return (
    <div onClick={onCancelClick} className="edit-popup-wrap">
      <div className="popup-inner_box">
        <div className="add-food-section">
          <div action="">
            <h5>Edit Category</h5>
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label htmlFor="">Category Name</label>
                <input
                  value={editData?.categoryName || ""}
                  onChange={(e)=>{inputOnChangeHandler(e)}}
                  type="text"
                  className="form-control"
                  placeholder="Enter category name"
                />
                {editFormData?.name === "" && (
                  <p className="err-state">Enter category name*</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label htmlFor="">Category Image</label>
                <input
                  onChange={(e) => inputOnChangeHandler(e)}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/webp"
                  className="form-control"
                />
                {/* {editFormData?.image?.size === undefined && (
                  <p className="err-state">Enter category image*</p>
                )} */}
              </div>
              <div className="col-12 px-md-3 px-0 mb-3 d-flex">
                <button onClick={CategoryEdit} className="btn btn-primary mx-2">Update</button>
                <button onClick={onCancelClick} className="btn btn-light mx-2">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMenuManageMent;
