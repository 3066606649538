import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryList from "../components/CategoryList/CategoryList";
import CusineList from "../components/CategoryList/CusineList";
import TopHeader from "../components/TopHeader";
import {
  useMenu_management_add_categoryMutation,
  useMenu_management_listMutation,
  useLazyList_restaurant_menuQuery,
  useUpdate_categoryMutation,
  useCreate_cusine_robotMutation,
  useCusine_update_robotMutation,
  useLazyList_cusine_robotQuery,
} from "../constants/redux/api/api";

import Modal from "react-bootstrap/Modal";
import { types } from "sass";

const AddCategory = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameErr, setCategoryNameErr] = useState(false);
  const [name2, setName2] = useState("");
  const [image, setImage] = useState({});
  const [types,settypes]=useState("")
  const [cattypes,setcattypes]=useState("")
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;

  const [nameErr, setNameErr] = useState(false);
  const [name2Err, setName2Err] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [fileName, setFileName] = useState("");
  const [branch, setBranch] = useState("");
  const [categoryBranch, setCategoryBranch] = useState("");
  const [categoryBranchErr, setCategoryBranchErr] = useState(false);
  const [branch2, setBranch2] = useState("");
  const [branchErr, setBranchErr] = useState(false);
  const [branch2Err, setBranch2Err] = useState(false);
  const [cusineBranch, setCusineBranch] = useState("");
  const [cusineBranchErr, setCusineBranchErr] = useState(false);
  const [cusineName, setCusineName] = useState("");
  const [cusineNameErr, setCusineNameErr] = useState(false);
  const [selectedCat, setSelectedCat] = useState("");
  const [selectTab, setSelectTab] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [cusineData, setCusineData] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const onChangeImage = (e) => {
    setImage(e.target.files[0]);
    console.log(image, "image26");
  };

  // RTK QUERY
  const [list_restaurant_menu] = useLazyList_restaurant_menuQuery();
  const [update_category] = useUpdate_categoryMutation();
  const [create_cusine_robot] = useCreate_cusine_robotMutation();
  const [cusine_update_robot] = useCusine_update_robotMutation();
  const [menu_management_list] = useMenu_management_listMutation();
  const [menu_management_add_category] =
    useMenu_management_add_categoryMutation();
  const [list_cusine_robot] = useLazyList_cusine_robotQuery();
  const [cusineList, setCusineList] = useState([]);

  const [getRobotList, setRobotList] = useState([]);
  //   RTK QUERY VARIABLE
  // console.log(image, "image32");

  const onSubmit = (type) => {
    if (type == "cusine") {
      //  console.log(getRobotList[0],"company_info42")
      if (name2?.length == 0 || branch2?.length == 0) {
        setName2Err(true);
        setBranch2Err(true);
      } else {
        const formData = new URLSearchParams();
        formData.append("cusine_name", name2);
        formData.append(
          "company_id",
          location?.state?.data?.data?.company_name?._id
        );
        formData.append("company_branch_id", branch2);
        create_cusine_robot(formData)
          .unwrap()
          .then((res) => {
            console.log(res, "res49");
            listCusine();
            setName2("");
            setBranch2("");
          })
          .catch((err) => {
            console.log({ message: err.message });
          });
      }
    } else {
      if (
        name?.length == 0 ||
        branch?.length == 0 ||
        image?.size === undefined
      ) {
        setNameErr(true);
        setBranchErr(true);
        setImageErr(true);
      } else {
        setDisableBtn(true);

        const formData = new FormData();
        formData.append("category_name", name);
        formData.append(
          "company_id",
          location?.state?.data?.data?.company_name?._id
        );
        formData.append("company_branch_id", branch);

        if (image?.name) {
          formData.append("image", image);
        }

        menu_management_add_category(formData)
          .unwrap()
          .then((res) => {
            setDisableBtn(false);
            if (res?.status === "success") {
              MenuListFunction();
              setName("");
              setBranch("");
              setImage({});
            }
          })
          .catch((err) => {
            console.log(err);
            setDisableBtn(false);
          });
      }
    }
  };

  const getMenu = async () => {
    list_restaurant_menu()
      .unwrap()
      .then((res) => {
        const finalRes = res.data;
        const filtered = finalRes.filter((res) => res._id == editData);
        setRobotList(filtered);
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
  };

  useEffect(() => {
    // getMenu();
    listCusine();
    MenuListFunction();
  }, []);

  //   LIST GET
  const MenuListFunction = () => {
    menu_management_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let finder = res?.data?.filter(
            (i) =>
              i.company_id._id == location?.state?.data?.data?.company_name?._id
          );
          setCategoryList([...finder].reverse());
        }
      })
      .catch((err) => {
        console.log(err, "error message");
      });
  };

  const listCusine = () => {
    list_cusine_robot()
      .unwrap()
      .then((res) => {
        let finder = res?.data?.filter(
          (i) =>
            i.company_id._id == location?.state?.data?.data?.company_name?._id
        );
        setCusineList([...finder].reverse());
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
  };

  // console.log(getRobotList, "getRobotList80");
  const onEditSubmit = (type) => {
    if (type == "cusine") {
      if (cusineName?.length == 0 || cusineBranch?.length == 0) {
        setCusineNameErr(true);
        setCusineBranchErr(true);
      } else {
        const formData = new URLSearchParams();
        formData.append("cusine_name", cusineName);
        // formData.append("company_branch_id", cusineBranch);
        setDisableBtn(true);
        cusine_update_robot({ payload: formData, id: cusineData?._id })
          .unwrap()
          .then((res) => {
            listCusine();
            setDisableBtn(false);
            setShow(false);
          })
          .catch((err) => {
            setShow(false);
            setDisableBtn(false);
            console.log({ message: err.message });
          });
      }
    } else {
      if (categoryName?.length == 0 || categoryBranch?.length == 0) {
        setCategoryNameErr(true);
        setCategoryBranchErr(true);
        setImageErr(true);
      } else {
        setDisableBtn(true);
        const formData = new FormData();
        formData.append("category_name", categoryName);
        formData.append(
          "company_id",
          location?.state?.data?.data?.company_name?._id
        );
        formData.append("company_branch_id", categoryBranch);
        if (image?.name) {
          formData.append("image", image);
        }
        update_category({ payload: formData, id: categoryData?._id })
          .unwrap()
          .then((res) => {
            setDisableBtn(false);
            if (res.status == "success") {
              MenuListFunction();
              setShow2(false);
            }
          })
          .catch((err) => {
            setDisableBtn(false);
            setShow2(false);
            console.log(err);
          });
        // editCategory(formData)
        //   .unwrap()
        //   .then((res) => {
        //     setDisableBtn(false);
        //     if (res?.status === "Success") {
        //       console.log(res);
        //       navigate(-1);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setDisableBtn(false);
        //   });
      }
    }
  };

  // console.log("location", location);

  const cusineSubmit = () => {
    console.log("cusine114");
    const formData = new FormData();
    formData.append("cusine_name");
    create_cusine_robot();
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const getUpdate = (item,type) => {
    settypes(type)
    setCusineData(item);
    setCusineBranch(item?.company_branch_details?.id);
    setCusineName(item?.cuisine_name);
    console.log(type)
    setShow(true);
  };

  const getCategoryUpdate = (item,type) => {
    // console.log("item", item);
    setcattypes(type)
    setCategoryData(item);
    setCategoryBranch(item?.company_branch_details?.id);
    setCategoryName(item?.category_name);
    setImage(item?.image_url?.CategoryImage);
    setShow2(true);
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"add-menu"} />
      <div className="menu-list">
        {/* <h1>Add Category</h1> */}
        <div className="tab_cont mb-4">
          <div className="position-relative">
            <button
              onClick={() => setSelectTab(1)}
              className={` ${
                selectTab === 1 ? "select_tab" : "unselect_tab"
              } cust-btn menu-list`}
            >
              <h1 className={`${selectTab === 1 ? "text-light" : ""} mb-1`}>
                Add Category
              </h1>
            </button>
            <div className="underline position-absolute" />
          </div>
          <div className="position-relative">
            <button
              onClick={() => setSelectTab(2)}
              className={` ${
                selectTab === 2 ? "select_tab" : "unselect_tab"
              } cust-btn menu-list`}
            >
              <h1 className={`${selectTab === 2 ? "text-light" : ""} mb-1`}>
                Cusine type
              </h1>
            </button>
            <div className="underline position-absolute" />
          </div>
        </div>
      </div>
      {selectTab === 1 && (
        <div className="side_pad">
          <div className="add-food-section">
            <div action="">
              <div className="d-flex flex-wrap">
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label htmlFor="">Select Branch</label>
                  <select
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    type="text"
                    className="form-control"
                  >
                    <option>Select Branch</option>
                    {location?.state?.data?.data?.company_branch_details?.map(
                      (item, ind) => {
                        return (
                          <option value={item?._id} key={ind}>
                            {item?.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                  {branch?.length == 0 && branchErr && (
                    <p className="err-state">Select Branch</p>
                  )}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label htmlFor="">Category Name</label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter category name"
                  />
                  {name?.length == 0 && nameErr && (
                    <p className="err-state">Enter category name</p>
                  )}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label htmlFor="">Category Image</label>
                  <input
                    onChange={onChangeImage}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    className="form-control"
                  />
                  {/* {type === "edit" && <p>Current File: {fileName}</p>} */}
                  {image?.size === undefined && imageErr && (
                    <p className="err-state">Enter category image*</p>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-12  d-flex gap-2  position-relative mx-3">
                <div>
                  <button
                    className="btn-submit"
                    disabled={disableBtn ? true : false}
                    onClick={() =>
                      type === "edit1" ? onEditSubmit() : onSubmit()
                    }
                  >
                    Add Category
                  </button>
                </div>
                <div onClick={() => setSelectTab(2)} className="btn-outline">
                  <p style={{ margin: 0 }}>Next</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectTab === 2 && (
        <div className="side_pad">
          <div className="add-food-section">
            <div action="">
              <div className="d-flex flex-wrap ac-jb">
                {/* <div className=""> */}
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label htmlFor="">Select Branch</label>
                  <select
                    value={branch2}
                    onChange={(e) => setBranch2(e.target.value)}
                    type="text"
                    className="form-control"
                  >
                    <option>Select Branch</option>
                    {location?.state?.data?.data?.company_branch_details?.map(
                      (item, ind) => {
                        return (
                          <option value={item?._id} key={ind}>
                            {item?.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                  {branch2?.length == 0 && branch2Err && (
                    <p className="err-state">Select Branch</p>
                  )}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label htmlFor="">Enter Cuisine</label>
                  <input
                    value={name2}
                    onChange={(e) => setName2(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Cusine name"
                  />
                  {name2 === "" && name2Err && (
                    <p className="err-state">Enter Cuisine name*</p>
                  )}
                </div>
                {/* </div> */}
                <div className="d-flex px-md-3 px-0 position-relative">
                  <div className="px-md-3 pe-3">
                    <button
                      className="btn-submit"
                      disabled={disableBtn ? true : false}
                      onClick={() => onSubmit("cusine")}
                    >
                      Add Cuisine
                    </button>
                  </div>
                  <div onClick={() => navigate(-1)} className="btn-outline">
                    <p style={{ margin: 0 }}>Back</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectTab === 1 && (
        <div className="menu-list mt-4">
          <h1>Category List</h1>
          <CategoryList
            MenuListFunction={MenuListFunction}
            categoryList={categoryList}
            getCategoryUpdate={getCategoryUpdate}
          />
        </div>
      )}
      {selectTab === 2 && (
        <div className="menu-list mt-4">
          <h1>Cuisine List</h1>
          <CusineList
            listCusine={listCusine}
            cusineList={cusineList}
            getUpdate={getUpdate}
          />
        </div>
      )}

      <Modal show={show} onHide={handleClose} animation={false} >
        <Modal.Header closeButton style={{ backgroundColor: "#191c24",color:" #25aae1" }}>
          <Modal.Title>{types=="view"?"View Cuisine":"Enter Cuisine"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#191c24" ,color:'white'}}>
          {" "}
          
          <div className="d-flex flex-wrap ac-jb">
            {/* <div className=""> */}
            <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
              <label htmlFor="">Select Branch</label>
              <select
              // disabled={types=="view"?true:false}
                value={cusineBranch}
                onChange={(e) => setCusineBranch(e.target.value)}
                type="text"
                className="form-control"
                disabled
              >
                <option>Select Branch</option>
                {location?.state?.data?.data?.company_branch_details?.map(
                  (item, ind) => {
                    return (
                      <option value={item?._id} key={ind}>
                        {item?.name}
                      </option>
                    );
                  }
                )}
              </select>
              {cusineBranch?.length == 0 && cusineBranchErr && (
                <p className="err-state">Select Branch</p>
              )}
            </div>
            <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
              <label htmlFor="">Enter Cuisine</label>
              <input
              disabled={types=="view"?true:false}
                value={cusineName}
                onChange={(e) => setCusineName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Enter Cusine name"
              />
              {cusineName === "" && cusineNameErr && (
                <p className="err-state">Enter Cuisine name*</p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#191c24",color:" #25aae1" }}>
          <div
            className="d-flex gap-2 side_pad add-food-section"
            style={{ background: "transparent" }}
          >
            {types !== "view" &&(<button
              className="btn-submit"
              disabled={disableBtn ? true : false}
              onClick={() => onEditSubmit("cusine")}
            >
              Edit Cuisine
            </button>)}
            <div
              onClick={() => handleClose()}
              className="btn-outline"
              style={{ background: "yellowgreen" }}
            >
              <p style={{ margin: 0 }}>Close</p>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} animation={false}>
        <Modal.Header closeButton style={{ backgroundColor: "#191c24",color:" #25aae1" }}>
          <Modal.Title>{cattypes==="view"?"View Category":"Edit Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#191c24" ,color:'white'}}>
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label htmlFor="">Select Branch</label>
                <select
                // disabled={cattypes=="view"?true:false} 
                  value={categoryBranch}
                  onChange={(e) => setCategoryBranch(e.target.value)}
                  type="text"
                  className="form-control"
                  disabled
                >
                  <option>Select Branch</option>
                  {location?.state?.data?.data?.company_branch_details?.map(
                    (item, ind) => {
                      return (
                        <option value={item?._id} key={ind}>
                          {item?.name}
                        </option>
                      );
                    }
                  )}
                </select>
                {categoryBranch?.length == 0 && categoryBranchErr && (
                  <p className="err-state">Select Branch</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label htmlFor="">Category Name</label>
                <input
                  disabled={cattypes=="view"?true:false}
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter category name"
                />
                {categoryName?.length == 0 && categoryNameErr && (
                  <p className="err-state">Enter category name</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label htmlFor="">Category Image</label>
                <input
                  disabled={cattypes=="view"?true:false}
                  onChange={onChangeImage}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/webp"
                  className="form-control"
                />
                {/* {type === "edit" && <p>Current File: {fileName}</p>} */}
                {image?.size === undefined && imageErr && (
                  <p className="err-state">Enter category image*</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                {image && (
                  <img
                    src={image?.name ? URL.createObjectURL(image) : image}
                    style={{ width: "100px" }}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#191c24",color:" #25aae1" }}>
          <div
            className="d-flex gap-2 side_pad add-food-section"
            style={{ background: "transparent" }}
          >
            {cattypes!="view"&&<button
              className="btn-submit"
              disabled={disableBtn ? true : false}
              onClick={() => onEditSubmit()}
            >
              Edit Category
            </button>}
            <div
              onClick={() => handleClose2()}
              className="btn-outline"
              style={{ background: "yellowgreen" }}
            >
              <p style={{ margin: 0 }}>Close</p>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCategory;
