import React from "react";
import { useNavigate } from "react-router-dom";

const ScreenHeader = ({
  title,
  menuLink,
  menuTitle,
  menuBtn,
  description,
  img,
  title2,
  title3,
  Subtitle,
  type,
  data,
}) => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="menu-list">
        <h1>{title}</h1>
      </div>
      {Subtitle && (
        <div className="menu-list">
          <h4>{Subtitle}</h4>
        </div>
      )}
      <div className="side_pad">
        <div className="menu_section">
          <div className="align-items-center justify-content-between d-flex flex-md-wrap">
            <div className="align-items-center d-flex ">
              <div className="px-2">
                <img src={img} alt="decor" />
              </div>
              <div className="px-2 ">
                <h5>{menuTitle}</h5>
                <p>{description}</p>
              </div>
            </div>
            <div className="d-flex gap-2">
              {title2 == "add Menu" && (
                <button
                  onClick={() =>
                    navigate("/add-category", {
                      state: { type: type, data: data },
                    })
                  }
                  className="btn-outline"
                >
                  + Add Category
                </button>
              )}
              {title3 == "Add User" && (
                <button
                  onClick={() => navigate("/company-add-category")}
                  className="btn-outline"
                >
                  + Add User
                </button>
              )}
              {title == "Menu List" && (
                <button
                  onClick={() =>
                    navigate("/add-category", { state: { data: data } })
                  }
                  className="btn-outline"
                >
                  + Add Category
                </button>
              )}

              {
                (title = "Check out your Company robots" && (
                  <button
                    onClick={() =>
                      navigate(menuLink, {
                        state: { type: type, data: data, method: "agv" },
                      })
                    }
                    className="btn-outline"
                  >
                    + {menuBtn}
                  </button>
                ))
              }

              {!title3 && (
                <button
                  onClick={() => navigate(menuLink, { state: { type: type } })}
                  className="btn-outline"
                >
                  + {menuBtn}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenHeader;
