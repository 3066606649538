import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import TopHeader from "../../../components/TopHeader";
import {
  useBranchDeleteMutation,
  useCompany_management_addMutation,
  useCompany_management_updateMutation,
  useLazyCompany_management_viewQuery,
} from "../../../constants/redux/api/api";

const AddCompany = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;
   console.log(type,"types")
  //   RTK QUERY
  const [company_management_add] = useCompany_management_addMutation();
  const [company_management_update] = useCompany_management_updateMutation();
  const [company_view] = useLazyCompany_management_viewQuery();
  const [branchDelete] = useBranchDeleteMutation();

  //   State variable Declaration
  const [submitBtn, setSubmitBtn] = useState(false);
  const [number, setNumber] = useState();
  const [profile,setProfile]=useState("")
  const [profileErr,setProfileErr]=useState(false)
  const[document,setDocument]=useState(false)
  const [trimfile,setTrimfile]=useState("")
  const[docs,setdoc]=useState("")
  const [branchList, setBranchList] = useState([
    { branch_name: "", branch_location: "", branch_pincode: "" },
  ]);
  const [addFormData, setAddFormData] = useState({
    companyname: "",
    clientname: "",
    emailid: "",
    contactnumber: "",
    companypassword: "",
    image: "",
    branch: branchList,
    description: "",
  });

  const [addFormDataErr, setAddFormDataErr] = useState({
    companynameErr: false,
    clientnameErr: false,
    emailidErr: false,
    contactnumberErr: false,
    companypasswordErr: false,
    imageErr: false,
    branchErr: false,
    descriptionErr: false,
  });

  const handleRemoveSet = (index, id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          branchDelete(id)
            .unwrap()
            .then((res) => {
              if (res?.status == "success") {
                toast.success(res?.message);
                const updatedFieldSets = [...branchList];
                updatedFieldSets.splice(index, 1);
                setBranchList(updatedFieldSets);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      });
    } else {
      const updatedFieldSets = [...branchList];
      updatedFieldSets.splice(index, 1);
      setBranchList(updatedFieldSets);
    }
  };
  const handleAddSet = () => {
    let temp = [...branchList];
    temp.push({ branch_name: "", branch_location: "", branch_pincode: "" });
    setBranchList(temp);
  };

  //   Input change Event
  const handleChangeInput = (e, index) => {
    const { name, value, files } = e.target;
    if (name && index === undefined && name !== "image") {
      setAddFormData({
        ...addFormData,
        [name]: value,
      });
    } else if (index !== null && name && name !== "image") {
      let temp = {};
      temp[name] = value;
      setBranchList((prev) => [
        ...prev.slice(0, index),
        {
          ...prev[index],
          ...temp,
        },
        ...prev.slice(index + 1),
      ]);
    } else {
      setAddFormData({
        ...addFormData,
        [`image`]: files[0],
      });
    }
  };

  const handleBranch = (event, ind) => {
    let temp = [...branchList];
    let name = event.target.name;
    let value = event.target.value;

    temp[ind][name] = value;
    setBranchList(temp);
  };

  const getView = () => {
    company_view(editData?._id)
      .unwrap()
      .then((res) => {
        console.log("getView", res);
        let data = res?.data;
        let temp = {
          companyname: data?.companyname,
          clientname: data?.clientname,
          emailid: data?.emailid,
          contactnumber: data?.contactnumber,
          companypassword: data?.companypassword,
          image: data?.image_url?.companyImages,
          // branch: data?.branch,
          description: data?.description,
        };
        setProfile(data?.company_profile_image)
        setdoc(data?.image_url?.companyImages)
        setAddFormData({
          ...addFormData,
          ...temp,
        });
        setNumber(data?.contactnumber);
        let tempBranch = [];
        // console.log(data?.branches);
        if (data?.branches?.length > 0) {
          data?.branches?.map((item, ind) => {
            tempBranch.push({
              branch_name: item?.name,
              branch_location: item?.location,
              branch_pincode: item?.pincode,
              id: item?.id,
            });
          });
        }
        setBranchList(tempBranch);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (type == "edit" || type=="view") {
      getView();
    
    }
  }, []);
const doc=()=>{
  if(type=="edit"||type=="view"){
    // if(addFormData?.image>0){

      const url = docs
      const trimmedFilename = url.substring(url.lastIndexOf('/') + 1);
      console.log(addFormData?.image,"trim")
      setTrimfile(trimmedFilename)
    }
  // } 
}
console.log("branchList", addFormData?.image);
useEffect(()=>{
  if(docs){

    doc()
  }
},[docs])


  //   add and Edit Form submit function
  const handleSubmitForm = async () => {
    if (addFormData) {
      if (
        addFormData?.companyname === "" ||
        addFormData?.clientname === "" ||
        addFormData?.contactnumber === "" ||
        addFormData?.companypassword === "" ||
        addFormData?.emailid === "" ||
        addFormData?.image === "" ||
        profile?.length=== ""
      ) {
        let temp = {
          companynameErr: true,
          clientnameErr: true,
          emailidErr: true,
          contactnumberErr: true,
          companypasswordErr: true,
          imageErr: true,
          branchErr: true,
          descriptionErr: true,
        };
        setProfileErr(true)
        setAddFormDataErr(temp);
      } else {
        const formData = new FormData();

        formData.append("companyname", addFormData?.companyname);
        formData.append("clientname", addFormData?.clientname);
        formData.append("emailid", addFormData?.emailid);
        formData.append("description", addFormData?.description);
        if (addFormData?.companypassword) {
          formData.append("companypassword", addFormData?.companypassword);
        }
        formData.append("contactnumber", addFormData?.contactnumber);
        if (addFormData?.image?.name) {
          formData.append(`image`, addFormData?.image);
        }
        if(profile?.name){

          formData.append(`company_profile_image`, profile);
        }
        // const branchData = branchList || [];

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        // if (branchList?.length > 0) {
        //   branchList?.map((item, ind) => {
        //     formData.append(`branches[${ind}][name]`, item.branch_name);
        //     formData.append(`branches[${ind}][location]`, item.branch_location);
        //     formData.append(`branches[${ind}][pincode]`, item.branch_pincode);
        //   });
        // }

        if (type === "edit") {
          let temp = [];

          if (branchList?.length > 0) {
            branchList.map((item, ind) => {
              if (item?.id) {
                formData.append(`branches[${ind}][_id]`, item.id);
                formData.append(`branches[${ind}][name]`, item.branch_name);
                formData.append(
                  `branches[${ind}][location]`,
                  item.branch_location
                );
                formData.append(
                  `branches[${ind}][pincode]`,
                  item.branch_pincode
                );
              } else if (!item?.id) {
                formData.append(`branches[${ind}][name]`, item.branch_name);
                formData.append(
                  `branches[${ind}][location]`,
                  item.branch_location
                );
                formData.append(
                  `branches[${ind}][pincode]`,
                  item.branch_pincode
                );
                // temp.push(item);
              }
            });
          }

          // temp?.map((item, ind) => {
          //   formData.append(`branches[${ind}][name]`, item.branch_name);
          //   formData.append(`branches[${ind}][location]`, item.branch_location);
          //   formData.append(`branches[${ind}][pincode]`, item.branch_pincode);
          // });

          setSubmitBtn(true);
          company_management_update({ payload: formData, id: editData?._id })
            .unwrap()
            .then((res) => {
              if (res?.status === "success") {
                navigate(-1);
                setSubmitBtn(false);
              }
            })
            .catch((err) => {
              console.log(err, "error");
              setSubmitBtn(false);
            });
        } else {
          if (branchList?.length > 0) {
            branchList.map((item, i) => {
              formData.append(`branches[${i}][name]`, item.branch_name);
              formData.append(`branches[${i}][location]`, item.branch_location);
              formData.append(`branches[${i}][pincode]`, item.branch_pincode);
            });
          }
          setSubmitBtn(true);
          company_management_add(formData)
            .unwrap()
            .then((res) => {
              if (res?.status === "success") {
                navigate(-1);
                setSubmitBtn(false);
              }
            })
            .catch((err) => {
              console.log(err, "error");
              setSubmitBtn(false);
            });
        }
      }
    }
  };
  const onClick = ()=>{
  
    setDocument(true) 
  
}
// useEffect(()=>{
// const doc=()=>{
  // if(type=="edit"||type=="view"){
      // const url = addFormData?.image
      // const trimmedFilename = url.substring(url.lastIndexOf('/') + 1);
      // setTrimfile(trimmedFilename)
  // } 
// }
// },[])
console.log(trimfile); 
// useEffect(()=>{
//     doc() 
// },[])


  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={type === "edit" ? "edit-company" : "add-company"} />
      <div className="menu-list">
        <h1>{type=="view"?"View Company" : (type === "edit" ? "Edit Company" : "Add Company")}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Company Name
                </label>
                <input 
                 disabled={type=='view'}
                  name="companyname"
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Company name"
                  onChange={(e) => handleChangeInput(e)}
                  value={addFormData?.companyname || ""}
                />
                {addFormData?.companyname === "" &&
                  addFormDataErr?.companynameErr && (
                    <p className="mb-0 text-danger">Company Name is Required</p>
                  )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Client Name
                </label>
                <input
                   disabled={type=='view'}
                  name="clientname"
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Your name"
                  onChange={(e) => handleChangeInput(e)}
                  value={addFormData?.clientname || ""}
                />
                {addFormData?.clientname === "" &&
                  addFormDataErr?.clientnameErr && (
                    <p className="mb-0 text-danger">Client Name is Required</p>
                  )}
              </div>

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Contact Number
                </label>

                <input
                disabled={type=='view'}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 10) {
                      e.target.setCustomValidity("invalid Number");
                    } else if (e.target.value.length == 10) {
                      e.target.setCustomValidity("");

                      setNumber(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxLength={10}
                  name="contactnumber"
                  onChange={(e) => {
                    setNumber(e.target.value);
                    handleChangeInput(e);
                  }}
                  type="number"
                  className="form-control mb-1"
                  placeholder="Enter Contact Number"
                  value={number}
                />
                {addFormData?.contactnumber === "" &&
                  addFormDataErr?.contactnumberErr && (
                    <p className="mb-0 text-danger">
                      Contact Number is Required
                    </p>
                  )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Email Id
                </label>
                <input
                disabled={type=='view'}
                  name="emailid"
                  type="email"
                  className="form-control mb-1"
                  placeholder="Enter Email Id"
                  onChange={(e) => handleChangeInput(e)}
                  value={addFormData?.emailid || ""}
                />
                {addFormData?.emailid === "" && addFormDataErr?.emailidErr && (
                  <p className="mb-0 text-danger">Email Id is Required</p>
                )}
              </div>
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Company Password
                </label>
                <input
                disabled={type=='view'||type=="edit"}
                  name="companypassword"
                  type="password"
                  className="form-control mb-1"
                  placeholder="Enter Company Password"
                  onChange={(e) => handleChangeInput(e)}
                  value={addFormData?.companypassword || ""}
                  // disabled={type === "edit" ? true : false}
                />
                {addFormData?.companypassword === "" &&
                  addFormDataErr?.companypasswordErr && (
                    <p className="mb-0 text-danger">
                      Company Password is Required
                    </p>
                  )}
              </div> */}
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  File Upload(tiff,bmp,pdf)
                </label>
                <input
                disabled={type=='view'}
                  name="image"
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handleChangeInput(e)}
                  className="form-control mb-1"
                />
                 {(type=="view"||type=="edit")&&
                
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  
                  <p>{trimfile}</p>
                  <button onClick={()=>onClick() } className="border-0 ad-btn bg-danger ">Download</button>
              {document && (
          <iframe src={addFormData?.image} style={{ height: "0px" }}></iframe>)}    
           </div>}
                {addFormData?.image === "" && addFormDataErr?.imageErr && (
                  <p className="mb-0 text-danger">Image is Required</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Profile Upload(jpeg,jpg,png)
                </label>
                <input
                disabled={type=='view'}
                  name="profile"
                  type="file"
                  accept="image/*"
                  onChange={(e) => setProfile(e.target.files[0])}
                  className="form-control mb-1"
                />
                {profile === "" && profileErr&& (
                  <p className="mb-0 text-danger">Profile image Required</p>
                )}
                 {(type=="view"||type=="edit")&&<div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                {profile && (
                  <img
                    src={profile}
                    style={{ width: "100px" }}
                  />
                )}
                </div>}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Company Password
                </label>
                <input
                disabled={type=='view'?true:false}
                  name="companypassword"
                  type="password"
                  className="form-control mb-1"
                  placeholder="Enter Company Password"
                  onChange={(e) => handleChangeInput(e)}
                  value={addFormData?.companypassword || ""}
                  // disabled={type === "edit" ? true : false}
                />
                {addFormData?.companypassword === "" &&
                  addFormDataErr?.companypasswordErr && (
                    <p className="mb-0 text-danger">
                      Company Password is Required
                    </p>
                  )}
              </div>
              <div className="col-12 px-md-3 px-0 position-relative mb-3 align-items-center">
                <div className="d-flex align-items-center">
                  <label className="mb-2" htmlFor="">
                    Branch
                  </label>
                  {type!="view" && <div className="ms-3">
                    <button className="border-0 ad-btn" disabled={type=='view'}
                    onClick={handleAddSet}>
                      + Add
                    </button>
                  </div>}
                </div>
                {branchList?.map((item, index) => {
                  return (
                    <div className="position-relative mb-3 mt-3" key={index}>
                      <div className="d-flex align-items-center">
                        <div className="col-md-3 pe-md-3 pe-0">
                          <label className="mb-2" htmlFor="">
                            {" "}
                            Branch Name:
                          </label>
                          <div className="">
                            <input
                              disabled={type=='view'}
                              type="text"
                              name="branch_name"
                              className="form-control mb-1"
                              onChange={(e) => handleBranch(e, index)}
                              value={item?.branch_name}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 pe-md-3 ps-0">
                          <label className="mb-2" htmlFor="">
                            {" "}
                            Branch Location:
                          </label>
                          <div className="">
                            <input
                            disabled={type=='view'}
                              type="text"
                              name="branch_location"
                              className="form-control mb-1"
                              onChange={(e) => handleBranch(e, index)}
                              value={item?.branch_location}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 pe-md-3 ps-0">
                          <label className="mb-2" htmlFor="">
                            {" "}
                            Branch Pincode:
                          </label>
                          <div className="d-flex w-100">
                            <input
                            disabled={type=='view'}
                              type="number"
                              name="branch_pincode"
                              className="form-control mb-1"
                              onChange={(e) => handleBranch(e, index)}
                              value={item?.branch_pincode}
                            />
                          </div>
                        </div>

                      {type!="view" && <div className="pe-md-3 ps-0 text-center">
                          {branchList?.length > 1 && (
                            <button
                              className="border-0 ad-btn bg-secondary"
                              onClick={() => handleRemoveSet(index, item?.id)}
                            >
                              x
                            </button>
                          )}
                        </div>}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="col-md-12 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Description
                </label>
                <textarea
                disabled={type=='view'}
                  name="description"
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Description"
                  cols="30"
                  rows="4"
                  onChange={(e) => handleChangeInput(e)}
                  value={addFormData?.description || ""}
                ></textarea>
                {addFormData?.description === "" &&
                  addFormDataErr?.descriptionErr && (
                    <p className="mb-0 text-danger">Description is Required</p>
                  )}
              </div>
                {/* {type=='view'&&<p>ggyg</p>} */}
             {type!='view'&&<div className="px-md-3 pe-3">
                {type === "edit"? (
                  <button
                    className="btn-submit"
                    disabled={submitBtn ? true : false}
                    onClick={() => handleSubmitForm()}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn-submit"
                    disabled={submitBtn ? true : false}
                    onClick={() => handleSubmitForm()}
                  >
                    Submit
                  </button>
                )}
              </div>}
              {type!='view'&&<div onClick={() => navigate(-1)} className="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCompany;
