import React from "react";
import { headerBg } from "../assets/img";

const Header = ({ type }) => {
  return (
    <div className="header">
      <img src={headerBg} />
      {type === "menu" ? (
        <h1>
          What would you like to <span> eat?</span>
        </h1>
      ) : type === "checkout" ? (
        <h1>
          Eat a lot today! <span>(and again tomorrow)</span>
        </h1>
      ) : type === "table" ? (
        <h1>
          Select <span> Table</span>
        </h1>
      ) : null}
    </div>
  );
};

export default Header;
