import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { menu_list } from "../../../storage/dummyData";
import { Search } from "@mui/icons-material";
import ConfirmDeletePopup from "../../../components/ConfirmDeletePopup";

const IndRobotManagementList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [allCategData, setAllCategData] = useState([]);
  const [categItems, setCategItems] = useState([]);
  const [deleteDetails, setDeleteDetails] = useState({});

  const [deletePopup, setDeletePopup] = useState(false);

  // RTK QUERY

  // useEffect(() => {
  //   if (isCategSuccess) {
  //     console.log(categoryData?.result);
  //     setAllCategData(categoryData?.result);
  //   }
  // }, [categoryData, isCategSuccess]);

  useEffect(() => {
    if (allCategData?.length > 0) {
      let tmp = [];
      allCategData?.forEach((item) => {
        const categoryItemsTmp = item?.categoryItems?.map((el) => {
          return {
            ...el,
            categoryName: item?.categoryName,
            categoryNameId: item?.categoryNameId,
          };
        });
        tmp = [...tmp, ...categoryItemsTmp];
      });
      setCategItems([...tmp]);
    }
  }, [allCategData]);

  const onActiveClick = (item) => {
    console.log({
      categoryNameId: item?.categoryNameId,
      categoryItemsId: item?.id,
    });
    const formData = new FormData();
    formData.append("categoryNameId", item?.categoryNameId);
    formData.append("categoryItemsId", item?.id);
  };

  const onClickDelete = (item) => {
    setDeletePopup(true);
    setDeleteDetails(item);
  };

  const confirmDeleteClick = () => {
    const item = {
      ...deleteDetails,
    };
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}>
      <div className="menu-list d-flex ac-jb w-100">
        <div className="w-100">
          <h1>{"Robot Management"}</h1>
        </div>
        <div className="w-50 position-relative">
          <div className="add-food-section bg-transparent">
            <input
              type="text"
              // value={"Robot Type 2"}
              name="location"
              className="form-control ps-5"
              placeholder="Search..."
            />
          </div>
          <div style={{ position: "absolute", top: 30, left: 34 }}>
            <Search />
          </div>
        </div>
      </div>

      {/* new table start */}
      <div className="table-responsive menu-table side_pad">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Company</th>
              <th>Client Name</th>
              <th>Branch Count</th>
              <th>Robot Count</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {menu_list?.map((item, ind) => {
              return (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{item?.company}</td>
                  <td>{item?.client_name}</td>
                  <td>{item?.viewcount}</td>
                  <td>{item?.branchcount}</td>
                  <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/ind-robot-management", {
                                state: { type: "edit", data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary px-4">
                            <VisibilityIcon />
                          </button>
                          {/* <button
                            onClick={() => onClickDelete(item)}
                            type="button"
                            className="btn btn-secondary">
                            <CloseOutlinedIcon />
                          </button> */}
                        </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* new table end */}

      {deletePopup && (
        <ConfirmDeletePopup
          onConfirmClick={confirmDeleteClick}
          onCancelClick={() => setDeletePopup(false)}
        />
      )}
    </div>
  );
};

export default IndRobotManagementList;
