import React from "react";
import { useNavigate } from "react-router-dom";
// import TopHeader from "../components/TopHeader";
// import ScreenHeader from "../components/ScreenHeader";
// import BranchList from "../components/CustomerManagement/BranchList";
// import { menu_section_left } from "../assets/images";
import HiRobotList from "../HiRobot/HiRobotList";
import AgvList from "./AgvList";
import TopHeader from "../../components/TopHeader";
import ScreenHeader from "../../components/ScreenHeader";
import { menu_section_left } from "../../assets/images";

const AGVCompanyUsers = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"agv-robot"} />
      <ScreenHeader
        type="add"
        title="AGV Company Users List"
        menuLink="/add-agv-list"
        menuBtn="Add AGV Company Robot"
        menuTitle="Check out your Company robots"
        description="You can add your robots from here"
        img={menu_section_left}
      />
      <AgvList />
    </div>
  );
};

export default AGVCompanyUsers;
