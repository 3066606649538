import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const RestaurantTableList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [tableList, setTableList] = useState([]);

  //   RTK QUERY
  // const {
  //   data: tableData,
  //   isSuccess: isTableSuccess,
  //   refetch: refetchTables,
  // } = useGetTablesAdminQuery();

  // useEffect(() => {
  //   if (isTableSuccess) {
  //     setTableList(tableData?.result);
  //   }
  // }, [tableData, isTableSuccess]);

  return (
    <div
      className={
        menuOpen ? "side-content2 menu-list" : "side-content menu-list"
      }
      style={{ transition: "0.5s" }}
    >
      <h1 className="m-0">Filter</h1>
      <div className="d-flex ac-jb w-100 ps-2 add-food-section table-responsive menu-table side_pad align-items-center">
        <div className="w-100 row ac-jb py-2">
          <div className="form-group col-lg-3">
            <label className="text1 py-2">From Date </label>
            <input
              type="date"
              name="start date"
              className="form-control me-3"
              placeholder="Enter Phone No"
            />
          </div>
          <div className="form-group col-lg-3">
            <label className="text1 py-2">To Date </label>
            <input
              type="date"
              name="end date"
              className="border-0 form-control"
              placeholder="Enter Phone No"
            />
          </div>
          <div className="form-group col-lg-3">
            <label className="text1 py-2">Robot Type</label>
            <select
              type="text"
              name="end date"
              className="form-control"
              style={{ width: "200px" }}
              placeholder="Enter Phone No"
            >
              <option selected disabled hidden>
                Select Robot
              </option>
              <option>Robot 1</option>
              <option>Robot 2</option>
              <option>Robot 3</option>
            </select>
          </div>
          <div class="add-food-section2 p-0 bg-transparent col-lg-3 form-group">
            <input class="btn-submit" type="submit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantTableList;
