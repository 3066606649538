import { useLocation, useNavigate } from "react-router-dom";
import { useCompany_user_addMutation } from "../../../constants/redux/api/api";
import { useState } from "react";

const AddUser = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;

  //   RTK Query
  const [company_user_add] = useCompany_user_addMutation();

  //   state variable declare
  const [addFormData, setAddFormData] = useState({
    company_id: "",
    email: "",
    password: "",
    role_id: "",
  });
  const [addFormDataErr, setAddFormDataErr] = useState({
    company_id: false,
    email: false,
    password: false,
    role_id: false,
  });

  //   Onchange Event Input function
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setAddFormData({
      ...addFormData,
      [name]: value,
    });
  };

  //   Submit Form Function
  const handleSubmitForm = async () => {
    if (addFormData) {
      if (
        addFormData?.company_id === "" ||
        addFormData?.email === "" ||
        addFormData?.password === "" ||
        addFormData?.role_id === ""
      ) {
        setAddFormDataErr({
          company_id: true,
          email: true,
          password: true,
          role_id: true,
        });
      } else {
        // Add api call
        company_user_add(addFormData)
          .unwrap()
          .then((res) => {
            if (res?.status === "success") {
              navigate("/company-users");
            }
          })
          .catch((err) => {
            console.log(err, "errror");
          });
      }
    }
  };
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list">
        <h1>Add User</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Company Name
                </label>
                <select
                  name="company_id"
                  className="form-control mb-2"
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                >
                  <option value={""}>Select Company</option>
                  <option value={"654a2ad224d96d9f4c030c51"}>NINOS 1</option>
                  <option value={"654a2ad224d96d9f4c030c52"}>NINOS 2</option>
                  <option value={"654a2ad224d96d9f4c030c53"}>NINOS 3</option>
                </select>
                {addFormData?.company_id === "" &&
                  addFormDataErr?.company_id && (
                    <p className="m-0 text-danger">Company name is Require</p>
                  )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Branch Name
                </label>
                <select className="form-control" placeholder="Select Branch">
                  <option value="Select Branch">Select Branch</option>
                  <option value="Vadapalani">Vada Palani</option>
                  <option value="Chennai">Chennai</option>
                  <option value="T-nagar">T-Nagar</option>
                </select>
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  User Name
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Email
                </label>
                <input
                  name="email"
                  type="email"
                  className="form-control mb-2"
                  placeholder="Enter Email"
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
                {addFormData?.email === "" && addFormDataErr?.email && (
                  <p className="m-0 text-danger">Email is Require</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Phone No
                </label>
                <input
                  name="name"
                  type="tel"
                  className="form-control"
                  placeholder="Enter Phone No"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  User Role
                </label>
                <select
                  className="form-control mb-2"
                  name="role_id"
                  id=""
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                >
                  <option value="">Select User Role</option>
                  <option value="654a2ad224d96d9f4c030c56">Waiter</option>
                  <option value="654a2ad224d96d9f4c030c57">
                    Robot Maintainer
                  </option>
                  <option value="654a2ad224d96d9f4c030c58">
                    Kitchen Admin
                  </option>
                </select>
                {addFormData?.role_id === "" && addFormDataErr?.role_id && (
                  <p className="m-0 text-danger">User Role is Require</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Password
                </label>
                <input
                  className="form-control mb-2"
                  placeholder="Enter Password"
                  name="password"
                  id=""
                  type="password"
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
                {addFormData?.password === "" && addFormDataErr?.password && (
                  <p className="m-0 text-danger">Password is Require</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Confirm Password
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Confirm Password"
                  type="password"
                  name=""
                  id=""
                />
              </div>
              <div
                onClick={() => {
                  handleSubmitForm();
                }}
                className="px-md-3 pe-3"
              >
                {type === "edit" ? (
                  <button className="btn-submit">Update</button>
                ) : (
                  <input className="btn-submit" type="submit" />
                )}
              </div>
              <div onClick={() => navigate(-1)} class="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddUser;
