import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  useMenu_management_category_editMutation,
  useMenu_management_category_statusMutation,
  useMenu_management_listMutation,
  useDelete_categoryMutation,
  useCreate_add_category_statusMutation,
} from "../../constants/redux/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import EditCategoryPopUp from "../EditCategoryPopUp";
import moment from "moment";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { useRobots_listMutation } from "../../constants/redux/api/api";
import Swal from "sweetalert2";
function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={"text-nowrap"}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={"text-nowrap"}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}
const CategoryList = ({
  MenuListFunction,
  categoryList,
  getCategoryUpdate,
}) => {
  // State variable declare
  const [editPopUp, setEditPopUp] = useState(false);
  const navigate = useNavigate();
  const [editData, setEditData] = useState({});
  const location = useLocation();
  const data = location?.state?.data?.data;

  //   RTK QUERY
  const [menu_management_category_status] =
    useMenu_management_category_statusMutation();
  const [menu_management_category_edit] =
    useMenu_management_category_editMutation();
  const [delete_category] = useDelete_categoryMutation();
  const [create_add_category_status] = useCreate_add_category_statusMutation();
  //   API FUNCTIONS

  /* ====== CATEGORY STATUS ====== */
  const CategoryStatus = (_id) => {
    menu_management_category_status()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  /* ====== CATEGORY Edit ====== */
  const CategoryEdit = (_id) => {
    menu_management_category_edit()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  /* ====== CATEGORY DELETE ====== */
  const CategoryDelete = (_id) => {
    menu_management_category_status()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const handleStatusChange = (id) => {
    create_add_category_status(id)
      .unwrap()
      .then((res) => {
        console.log(res, "res183");
        if (res.status == "success") {
          MenuListFunction();
        }
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
  };
  //   close Edit PopUp
  const onCancelClick = () => {
    setEditPopUp(!editPopUp);
  };

  const deleteCategory = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_category(id._id)
          .unwrap()
          .then((res) => {
            if (res.status == "success") {
              MenuListFunction();
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Branch",
        accessor: "company_branch_details",
        Cell: ({ value, row }) => {
          return <p>{value?.name}</p>;
        },
      },
      {
        Header: "Category Name",
        accessor: "category_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Category Image",
        accessor: "image_url",
        Cell: ({ value, row }) => {
          return <img src={value?.CategoryImage} alt="images" />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                handleStatusChange(row.original._id);
              }}
              className={`${
                value ? "bg-success" : "bg-danger"
              } px-2 py-1 rounded-1 border-0 text-white`}
            >
              {value ? "Active" : "Deactive"}
            </button>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>{ getCategoryUpdate(row.original,"edit")}}
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              {/* <button
                className="bg-warning border-0 rounded-1 p-1"
                onClick={() => {
                  deleteCategory(row.original);
                }}
              >
                <Delete />
              </button> */}
              <button
               onClick={() => getCategoryUpdate(row.original,"view")}
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
                </button> 
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="table-responsive menu-table side_pad mt-4">
      <Table columns={columns} data={categoryList} className="" />
      {editPopUp && (
        <EditCategoryPopUp
          CategoryEdit={CategoryEdit}
          onCancelClick={onCancelClick}
          editData={editData && editData}
        />
      )}
    </div>
  );
};

export default CategoryList;
