import React from "react";

const MenuViewFormComp = ({ dropDownList, branchFilter, viewData }) => {
  return (
    <div className="side_pad">
      <div className="add-food-section">
        <div action="">
          <div className="d-flex flex-wrap">
            <div className="col-md-4 col-12 px-md-3 px-0 position-relative mb-3">
              <label className="mb-2" for="">
                Company Name
              </label>
              <input
                name="name"
                type="text"
                disabled
                value={viewData?.companyname}
                className="form-control"
                placeholder="Enter Company Name"
              />
              {/* {validation.name?.length == 0 && nameErr && (
                                    <p className="err-state">Enter User name*</p>
                                )} */}
            </div>
            <div className="col-md-3 col-12 px-md-3 px-0 position-relative mb-3">
              <label className="mb-2" for="">
                Client Name
              </label>
              <input
                name="name"
                type="text"
                disabled
                value={viewData?.clientname}
                className="form-control"
                placeholder="Enter Company Name"
              />
              {/* {validation.name?.length == 0 && nameErr && (
                                    <p className="err-state">Enter User name*</p>
                                )} */}
            </div>
            <div className="col-md-5 col-12 px-md-3 px-0 position-relative mb-3">
              <label className="mb-2" for="">
                Branch
              </label>
              <select
                className="form-control"
                value={dropDownList}
                onChange={(e) => branchFilter(e.target.value)}
                name=""
                id=""
              >
                <option selected disabled value=" ">
                  Select Branch
                </option>
                <option value="All">All</option>
                {viewData?.branches?.map((item, ind) => {
                  return (
                    <option key={ind} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuViewFormComp;
