import React from "react";
import { langingPageBg } from "../assets/img";
import { Link } from "react-router-dom";
import { useShowCategoriesQuery } from "../constants/redux/api/api";

const LandingPage = () => {
  const { data: categoryData, isSuccess: isCategSuccess } =
    useShowCategoriesQuery();
  return (
    <div className="landing-page-container">
      <img src={langingPageBg} />
      <div className="gradient"></div>
      <div className="content-wrapper">
        <h1>Hello Buddy!</h1>
        <h2>Welcome to the Restaurant</h2>
        <h3>"Order the Food that makes you happy"</h3>
        <Link className="link" to="/select-table">
          View Our Menus
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
