import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import LandingPage from "./screens/LandingPage";
import MenuPage from "./screens/MenuPage";
import CheckoutPage from "./screens/CheckoutPage";
import SelectTablePage from "./screens/SelectTablePage";
import OrderPage from "./screens/OrderPage";
import FinalPage from "./screens/FinalPage";
import LoginScreen from "./screens/LoginScreen";
import Layout from "./components/Layout/Layout";
import MenuList from "./screens/MenuList";
import AddMenu from "./screens/AddMenu";
import AddCategory from "./screens/AddCategory";
import RobotsList from "./screens/RobotsList";
import OrderList from "./screens/OrderList";
import OrderDetails from "./screens/OrderDetails";
import TableList from "./screens/TableList";
import AddTable from "./screens/AddTable";
import CategoryList from "./screens/CategoryList";
import PrivateRoutes from "./constants/PrivateRoutes";
import AdminScreen from "./screens/AdminScreen";
import AddUsers from "./screens/AddUsers";
import RollsPermission from "./screens/RolesPermission/RollsPermission";
import AddRoles from "./screens/RolesPermission/AddRoles";
import LeadManagement from "./screens/LeadManagement/LeadManagement";
import AddLeadManagement from "./screens/LeadManagement/AddLeadManagement";
import AddBranch from "./screens/CustomerManagment/BranchDetails/AddBranch";
import CompanyDetails from "./screens/CustomerManagment/CompanyDetails/CompanyDetails";
import BranchDetails from "./screens/CustomerManagment/BranchDetails/BranchDetails";
import SpeechDetails from "./screens/HiRobot/Hispeech.jsx";
import SpeechAdd from "./screens/HiRobot/SpeechDetails.jsx";
import CompanyUsers from "./screens/CustomerManagment/CompanyUsers/CompanyUsers";
import AddCompanyUser from "./screens/CustomerManagment/CompanyUsers/AddCompanyUser";
import AddRobotType from "./screens/RobotType/AddRobotType";
import AddRobotOrder from "./screens/RobotOrder/AddRobotOrder";
import RobotOrder from "./screens/RobotOrder/RobotOrder";
import RobotType from "./screens/RobotType/RobotType";
import ProfileDetailScreen from "./screens/ProfileDetailScreen";
import MenuViewScreen from "./screens/MenuViewScreen";
import CompanyViewScreen from "./screens/CompanyViewScreen";
import CompanyAddCategory from "./screens/CompanyAddCategory";
import AddUser from "./screens/CustomerManagment/CompanyUsers/AddUser";
import RobotManagementScreen from "./screens/RobotManagementScreen";
import AddBranchTableScreen from "./screens/AddBranchTableScreen";
import ViewMapScreen from "./screens/ViewMapScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import RobotManagementList from "./screens/RobotManagementList";
import RobotEdit from "./components/RobotManagement/RobotEdit";
import AddCompany from "./screens/CustomerManagment/CompanyDetails/AddComapny";
import AddRolesNew from "./screens/RolesPermission/AddRolesNew";
import BranchTableListScreen from "./screens/BranchTableListScreen";
import IndCompanyUsers from "./screens/IndustrialManagement/CompanyUsers/IndCompanyUsers";
import IndRobotManagementList from "./screens/IndustrialManagement/RobotManagement/IndRobotManagementList";
import IndRobotManagementScreen from "./screens/IndustrialManagement/RobotManagement/IndRobotManagementScreen";
import IndAddBranchTableScreen from "./screens/IndustrialManagement/RobotManagement/IndAddBranchTableScreen";
import BranchPointListScreen from "./screens/IndustrialManagement/RobotManagement/BranchPointListScreen";
import IndOrderDetailScreen from "./screens/IndustrialManagement/RobotManagement/IndOrderDetailScreen";
import Layout2 from "./components/Layout/Layout2";
import PrivateRoutes2 from "./constants/PrivateRoutes2";
import LoginResturentScreen from "./screens/LoginResturentScreen";
import RestaurantViewBranchDetails from "./restaurant/BranchDetails/RestaurantViewBranchDetails";
import RestaurantAddBranchManage from "./restaurant/BranchDetails/RestaurantAddBranchmanage";
import RestaurantBranchData from "./restaurant/BranchDetails/RestaurantBranchData";
import RestaurantBranchDetails from "./restaurant/BranchDetails/RestaurantBranchDetails";
import RestaurantTableList from "./restaurant/screens/RestaurantTableList";
import RestaurantAddUsers from "./restaurant/User/RestaurantAddUsers";
import RestaurantAdminScreen from "./restaurant/User/RestaurantAdminScreen";
import RestaurantEditUser from "./restaurant/User/RestaurantEditUser";
import RestaurantCategoryPage from "./restaurant/components/Category/RestaurantCategoryPage";
import RestaurantViewCategoryCuisine from "./restaurant/components/Category/RestaurantViewCategoryCuisine";
import RestaurantCategoryList from "./restaurant/components/Category/RestaurantCategoryList";
import RestaurantCuisineList from "./restaurant/components/Category/RestaurantCuisineList";
import RestaurantMenuManageList from "./restaurant/Menu/RestaurantMenuManageList";
import RestaurantMenuList from "./restaurant/Menu/RestaurantMenuList";
import RestaurantAddMenu from "./restaurant/screens/RestaurantAddMenu";
import RestaurantAddRobot from "./restaurant/screens/RestaurantAddRobot";
import RestaurantRobotManagementScreen from "./restaurant/screens/RestaurantRobotManagementScreen";
import RestaurantOrderDetailScreen from "./restaurant/screens/RestaurantOrderDetailScreen";
import RestaurantMenuViewScreen from "./restaurant/screens/RestaurantMenuViewScreen";
import RestaturantCompanyViewScreen from "./restaurant/screens/RestaturantCompanyViewScreen";
import RestaurantProfileDetailScreen from "./restaurant/screens/RestaurantProfileDetailScreen";
import RestaurantViewOrder from "./restaurant/RobotOrder/RestaurantViewOrder";
import RestaurantRobotOrder from "./restaurant/RobotOrder/RestaurantRobotOrder";
import RestaurantRobotOrderListScreen from "./restaurant/RobotOrder/RestaurantRobotOrderListScreen";
import RestaurantAddCategory from "./restaurant/components/Category/RestaurantAddCategory";
import RestaurantAddCuisine from "./restaurant/components/Category/RestaurantAddCuisine";
import AddAdmins from "./screens/AddAdmins";
import HiCompanyUsers from "./screens/HiRobot/HiCompanyUsers";
import HiAddCompany from "./screens/HiRobot/HiAddCompany";
import HiRobotList from "./screens/HiRobot/HiRobotList";
import AddHiBranchKey from "./screens/HiRobot/AddHiBranchKey";
import AGVCompanyUsers from "./screens/AgvManagement/AGVCompanyUsers";
// import HiCompanyUsers from "./screens/HiRobot/HiCompanyUsers.jsx";
// import HiAddCompany from "./screens/HiAddCompany";
// import HiRobotList from "./screens/HiRobot/HiRobotList.jsx";
// import AGVCompanyUsers from "./screens/AGVCompanyUsers";
// import AgvList from "./screens/AgvList";
// import AddAgv from "./screens/AddAgv";
// import AgvBranch from "./screens/AgvBranch.jsx";
// import AddAgvBranch from "./screens/AddAgvBranch.jsx";
// import AgvBranchList from "./screens/AgvBranchList.jsx";
import HiBranch from "./screens/HiRobot/HiBranch.jsx";
import AddHiBranch from "./screens/HiRobot/AddHiBranch.jsx";
import RobotBranchDetails from "./screens/RobotBranchDetails.jsx";
// import AgvBranchDetails from "./screens/AgvBranchDetails.jsx";
import AddAgv from "./screens/AgvManagement/AddAgv.jsx";
import AgvList from "./screens/AgvManagement/AgvList.jsx";
import AgvBranch from "./screens/AgvManagement/AgvBranch";
import AddAgvBranch from "./screens/AgvManagement/AddAgvBranch";
import AgvBranchList from "./screens/AgvManagement/AgvBranchList";
import AgvBranchDetails from "./screens/AgvBranchDetails.jsx";
import AddAgvLocation from "./screens/AgvManagement/AddAgvLocation.jsx";
import AddPointTable from "./screens/AddPointTable";
// import AddHiBranchKey from "./screens/HiRobot/AddHiBranchKey.jsx";

const ReactRoutes = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => {
    setMenuOpen(!menuOpen);
  };
  const openList = () => {
    setMenuOpen(false);
  };

  // console.log("menuOpen", menuOpen);
  // useEffect(() => {
  //   if(localStorage?.getItem("resturent_token")){
  //     navigate("/restaurant/table-list");
  //   }
  //   else if (localStorage?.getItem("token")){
  //     navigate("/table-list");
  //   }
  // }, []);
  return (
    <Routes>
      <Route path="/" index element={<LoginScreen />} />
      <Route
        path="/restaurant/login"
        index
        element={<LoginResturentScreen />}
      />
      <Route element={<PrivateRoutes token={token} />}>
        <Route path="/welcome" index element={<LandingPage />} />
        <Route path="/menu" index element={<MenuPage />} />
        <Route path="/checkout" index element={<CheckoutPage />} />
        <Route path="/order" index element={<OrderPage />} />
        <Route path="/final-page" index element={<FinalPage />} />
        <Route path="/select-table" index element={<SelectTablePage />} />
        {/* ADMIN PANEL */}
        <Route
          element={
            <Layout
              menuOpen={menuOpen}
              toggle={toggle}
              setMenuOpen={setMenuOpen}
            />
          }
        >
          <Route
            path="/hi-company-users"
            element={<HiCompanyUsers menuOpen={menuOpen} />}
          />
          <Route
            path="/add-hi-robots"
            element={<HiAddCompany menuOpen={menuOpen} />}
          />
            <Route
            path="/add-speech"
            element={<SpeechDetails menuOpen={menuOpen} />}
          />
           <Route
            path="/add-speeches"
            element={<SpeechAdd menuOpen={menuOpen} />}
          />
          <Route
            path="/add-agv-locations"
            element={<AddAgvLocation menuOpen={menuOpen} />}
          />
          <Route
            path="/hi-robots-list"
            element={<HiRobotList menuOpen={menuOpen} />}
          />

          <Route
            path="/hi-Robot-branch"
            element={<HiBranch menuOpen={menuOpen} />}
          />
          <Route
            path="/add-hi-Robot-branch"
            element={<AddHiBranch menuOpen={menuOpen} />}
          />

          <Route
            path="/add-hi-Robot-branch-key/:name"
            element={<AddHiBranchKey menuOpen={menuOpen} />}
          />

          <Route
            path="/hi-robot-branch-details"
            element={<RobotBranchDetails menuOpen={menuOpen} />}
          />
          <Route
            path="/agv-branch-details"
            element={<AgvBranchDetails menuOpen={menuOpen} />}
          />
          <Route
            path="/agv-user-list"
            element={<AGVCompanyUsers menuOpen={menuOpen} />}
          />
          <Route path="/agv-list" element={<AgvList menuOpen={menuOpen} />} />
          <Route
            path="/add-agv-list"
            element={<AddAgv menuOpen={menuOpen} />}
          />
          <Route
            path="/agv-branch"
            element={<AgvBranch menuOpen={menuOpen} />}
          />
          <Route
            path="/add-agv-branch"
            element={<AddAgvBranch menuOpen={menuOpen} />}
          />
          <Route
            path="/add-agv-list"
            element={<AgvBranchList menuOpen={menuOpen} />}
          />

          <Route
            path="/menu-management"
            element={<MenuList menuOpen={menuOpen} />}
          />
          <Route path="/add-menu" element={<AddMenu menuOpen={menuOpen} />} />
          <Route
            path="/add-category"
            element={<AddCategory menuOpen={menuOpen} />}
          />

          <Route
            path="/company-add-category"
            element={<CompanyAddCategory menuOpen={menuOpen} />}
          />
          <Route
            path="/robots-list"
            element={<RobotsList menuOpen={menuOpen} />}
          />
          <Route
            path="/category-list"
            element={<CategoryList menuOpen={menuOpen} />}
          />
          <Route
            path="/order-list"
            element={<OrderList menuOpen={menuOpen} />}
          />
          <Route
            path="/order-details"
            element={<OrderDetails menuOpen={menuOpen} />}
          />
          <Route
            path="/table-list"
            element={<TableList menuOpen={menuOpen} />}
          />
          <Route path="/add-table" element={<AddTable menuOpen={menuOpen} />} />
          {/* admin-access-start */}
          <Route
            path="/admin-users"
            element={<AdminScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/admin-add-users"
            element={<AddAdmins menuOpen={menuOpen} />}
          />
          <Route path="/add-users" element={<AddUsers menuOpen={menuOpen} />} />
          <Route
            path="/roles-permissions"
            element={<RollsPermission menuOpen={menuOpen} />}
          />
          <Route
            path="/lead-management"
            element={<LeadManagement menuOpen={menuOpen} />}
          />
          <Route
            path="/add-lead"
            element={<AddLeadManagement menuOpen={menuOpen} />}
          />
          <Route
            path="/add-roles"
            element={<AddRolesNew menuOpen={menuOpen} />}
          />
          <Route
            path="/company-details"
            element={<CompanyDetails menuOpen={menuOpen} />}
          />
          <Route
            path="/branch-details"
            element={<BranchDetails menuOpen={menuOpen} />}
          />
          <Route
            path="/add-branch"
            element={<AddBranch menuOpen={menuOpen} />}
          />
          
          <Route
            path="/company-users"
            element={<CompanyUsers menuOpen={menuOpen} />}
          />
          <Route
            path="/ind-company-users"
            element={<IndCompanyUsers menuOpen={menuOpen} />}
          />
          <Route
            path="/add-company"
            element={<AddCompany menuOpen={menuOpen} />}
          />
          <Route
            path="/company-add-user"
            element={<AddUser menuOpen={menuOpen} />}
          />
          <Route
            path="/add-company-users"
            element={<AddCompanyUser menuOpen={menuOpen} />}
          />
          <Route
            path="/robot-order"
            element={<RobotOrder menuOpen={menuOpen} />}
          />
          <Route
            path="/add-robot-order"
            element={<AddRobotOrder menuOpen={menuOpen} />}
          />
          <Route
            path="/robot-type"
            element={<RobotType menuOpen={menuOpen} />}
          />
          <Route
            path="/add-robot-type"
            element={<AddRobotType menuOpen={menuOpen} />}
          />
          <Route
            path="/view-profile"
            element={<ProfileDetailScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/menu-view"
            element={<MenuViewScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/company-view"
            element={<CompanyViewScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/robot-management"
            element={<RobotManagementScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/ind-robot-management"
            element={<IndRobotManagementScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/add-branch-table"
            element={<AddBranchTableScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/add-point-table"
            element={<AddPointTable menuOpen={menuOpen} />}
          />
          <Route
            path="/ind-add-branch-table"
            element={<IndAddBranchTableScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/add-branch-list"
            element={<BranchTableListScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/add-point-list"
            element={<BranchPointListScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/add-robot-edit"
            element={<RobotEdit menuOpen={menuOpen} />}
          />
          <Route
            path="/map-view"
            element={<ViewMapScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/robot-manage-list"
            element={<RobotManagementList menuOpen={menuOpen} />}
          />
          <Route
            path="/ind-robot-manage-list"
            element={<IndRobotManagementList menuOpen={menuOpen} />}
          />
          <Route
            path="/robot-order-detail"
            element={<OrderDetailScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/ind-robot-order-detail"
            element={<IndOrderDetailScreen menuOpen={menuOpen} />}
          />
          {/* admin-access-end */}
        </Route>
      </Route>
      <Route element={<PrivateRoutes2 />}>
        {/* Resturent Admin */}
        <Route
          element={
            <Layout2
              menuOpen={menuOpen}
              toggle={toggle}
              setMenuOpen={setMenuOpen}
            />
          }
        >
          {/* dashBoard */}
          <Route
            path="/restaurant/table-list"
            element={<RestaurantTableList menuOpen={menuOpen} />}
          />

          {/* admin-user */}
          <Route
            path="/restaurant/users"
            element={<RestaurantAdminScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/add-users"
            element={<RestaurantAddUsers menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/edit-user"
            element={<RestaurantEditUser menuOpen={menuOpen} />}
          />

          {/* Category & Cuisine */}
          <Route
            path="/restaurant/category-cousin"
            element={<RestaurantCategoryPage menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/view-category-cousin"
            element={<RestaurantViewCategoryCuisine menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/list"
            element={<RestaurantCategoryList menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/add"
            element={<RestaurantAddCategory menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/Cousin-List"
            element={<RestaurantCuisineList menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/Cousin-add"
            element={<RestaurantAddCuisine menuOpen={menuOpen} />}
          />
          {/* <Route
            path="/restaurant/category-list"
            element={<RestaurantCategoryList menuOpen={menuOpen} />}
          /> */}
          {/* menu Management */}
          <Route
            path="/restaurant/menu-list"
            element={<RestaurantMenuManageList menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/menu-management"
            element={<RestaurantMenuList menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/add-menu"
            element={<RestaurantAddMenu menuOpen={menuOpen} />}
          />

          {/* Branch Management */}
          <Route
            path="/restaurant/branch-details"
            element={<RestaurantBranchDetails menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/view-branch"
            element={<RestaurantViewBranchDetails menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/add-branch-manage"
            element={<RestaurantAddBranchManage menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/view-branch-details"
            element={<RestaurantBranchData menuOpen={menuOpen} />}
          />

          {/* Robot List */}
          <Route
            path="/restaurant/add-robot"
            element={<RestaurantAddRobot menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/robot-management"
            element={<RestaurantRobotManagementScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/robot-order-detail"
            element={<RestaurantOrderDetailScreen menuOpen={menuOpen} />}
          />

          {/* Robot Order */}
          <Route
            path="/restaurant/robot-order-list"
            element={<RestaurantRobotOrderListScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/robot-orders"
            element={<RestaurantRobotOrder menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/view-robot-order"
            element={<RestaurantViewOrder menuOpen={menuOpen} />}
          />

          {/* View Profile */}
          <Route
            path="/restaurant/view-profile"
            element={<RestaurantProfileDetailScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/menu-view"
            element={<RestaurantMenuViewScreen menuOpen={menuOpen} />}
          />
          <Route
            path="/restaurant/company-view"
            element={<RestaturantCompanyViewScreen menuOpen={menuOpen} />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
