import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
} from "../../../constants/redux/api/api";
import BranchTableList from "./BranchTableList";

const IndAddBranchTableScreen = ({ menuOpen }) => {
  const [listShow, setListShow] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}>
      <div>
        <div className="add-food-section bg-transparent d-flex ac-je p-0 pe-4">
          <button
            class="btn-outline primary1"
            // onClick={() => setListShow(true)}
            onClick={() => navigate("/add-point-list")}>
            + Add Point
          </button>
        </div>
        <BranchTableList setListShow={setListShow} />
        <div
          className="btn-outline2 cp ac-jc d-flex"
          onClick={() => navigate("/ind-robot-management")}>
          <p className="mb-0">Back</p>
        </div>
      </div>
    </div>
  );
};
export default IndAddBranchTableScreen;
