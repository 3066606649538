import { CheckBoxOutlineBlankRounded } from "@material-ui/icons";
import { Delete, Edit } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const RobotLists = ({ item }) => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  console.log("item", item);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Robot ID",
        accessor: "code",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Robot Name",
        accessor: "robot_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "robot_status",
        Cell: ({ value, row }) => {
          return (
            <p>
              {/* {value == 1 ? (
                <div>
                  <CheckCircleIcon className="active_icon me-1" /> Active{" "}
                </div>
              ) : value == 2 ? (
                <div>
                  {" "}
                  <ErrorIcon className="danger_icon me-1" /> Inactive
                </div>
              ) : value == 3 ? (
                <div>
                  <CheckCircleIcon className="energizing_icon me-1" />
                  Energizing
                </div>
              ) : (
                ""
              )} */}
             {value=="null"?"-":value}
            </p>
          );
        },
      },
      {
        Header: "Orders",
        accessor: "action",
        Cell: ({ value, row }) => {
          const rowData = row.original;
          const companyId = item.company_info.company_id;
          console.log(companyId,"company")
          console.log(row,"row")
          const handleClick = () => {
            console.log(rowData, 'original'); // Log only when the button is clicked
            navigate("/robot-order-detail", { state: { data: rowData, companyId  } });
          };
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
               onClick={handleClick}
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                View
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="table-responsive menu-table side_pad mt-4">
      <Table columns={columns} data={item?.robot_info} className="" />
    </div>
  );
};

export default RobotLists;
