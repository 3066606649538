import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BTable from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
// import {
//   useLazyList_company_robotQuery,
//   useDelete_company_robotMutation,
//   useLazyCompanyRobotsStatusChangeQuery,
// } from "../../constants/redux/api/api";
import moment from "moment";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useDelete_company_robotMutation,
  useLazyAgvListCompanyRobotQuery,
  useLazyCompanyRobotsStatusChangeQuery,
  useLazyList_company_robotQuery,
  useLazyListLocationQuery,
} from "../../constants/redux/api/api";
// import { useDelete_company_robotMutation, useLazyCompanyRobotsStatusChangeQuery, useLazyList_company_robotQuery } from "../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end " size="lg">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              size="lg"
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const AgvLocationList = () => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [agvListCompanyRobot] = useLazyListLocationQuery();

  const getList = () => {
    agvListCompanyRobot()
      .unwrap()
      .then((res) => {
        console.log("res:", res);
        setData([...res?.data].reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Robot Name",
        accessor: "robot_name",
        Cell: ({ value, row }) => {
          return <p>{value ? value : ""}</p>;
        },
      },
      {
        Header: "Mapped ID",
        accessor: "_id",
        Cell: ({ value, row }) => {
          return <p>{value ? value : ""}</p>;
        },
      },
      {
        Header: "Location Images",
        accessor: "image_url",
        Cell: ({ value, row }) => {
          return <img src={value} />;
        },
      },

      {
        Header: "Mapped Robots",
        accessor: "manufacturing_date",
        Cell: ({ value, row }) => {
          return <p>{value ? moment(value).format("DD-MM-YYYY") : ""}</p>;
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="table-responsive menu-table side_pad mt-4">
        <Table columns={columns} data={data} className="" />
      </div>
    </>
  );
};

export default AgvLocationList;
