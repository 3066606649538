import React from "react";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const TopHeader = ({ type }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("resturent_users"));
  return (
    <div>
      {type == "roles" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Role</button>
          </div>
        </div>
      )}

      {type == "editRole" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/roles-permissions")}
            >
              Roles
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Role</button>
          </div>
        </div>
      )}

      {type == "addRole" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/roles-permissions")}
            >
              Roles
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Role</button>
          </div>
        </div>
      )}

      {type == "admins" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Admins</button>
          </div>
        </div>
      )}

      {type == "editAdmin" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/admin-users")}
            >
              Admins
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Admin</button>
          </div>
        </div>
      )}

      {type == "addAdmin" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/admin-users")}
            >
              Admins
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Admin</button>
          </div>
        </div>
      )}

      {type == "lead-managements" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Lead Management</button>
          </div>
        </div>
      )}

      {type == "edit-lead" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/lead-management")}
            >
              Lead Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Leads</button>
          </div>
        </div>
      )}

      {type == "add-Lead" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/lead-management")}
            >
              Lead Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Leads</button>
          </div>
        </div>
      )}
{type == "add-Hi-robots" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/speech-add")}
            >
              Hi-Robot
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Hi-robot</button>
          </div>
        </div>
      )}

      {type == "robot-types" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Robot Type</button>
          </div>
        </div>
      )}

      {type == "edit-robo" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/robot-type")}
            >
              Robot Type
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Robot Type</button>
          </div>
        </div>
      )}

      {type == "add-robo" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/robot-type")}
            >
              Robot Type
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Robot Type</button>
          </div>
        </div>
      )}

      {type == "company-management" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Company Management</button>
          </div>
        </div>
      )}

      {type == "edit-company" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/company-details")}
            >
              Company Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Company</button>
          </div>
        </div>
      )}

      {type == "add-company" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/company-details")}
            >
              Company Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Company</button>
          </div>
        </div>
      )}

      {type == "robo-order" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Robot Order</button>
          </div>
        </div>
      )}

      {type == "edit-order-robo" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/robot-order")}
            >
              Robot Order
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Robot Order</button>
          </div>
        </div>
      )}

      {type == "add-order-robo" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/robot-order")}
            >
              Robot Order
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Robot Order</button>
          </div>
        </div>
      )}

      {type == "company-robo" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive"> Company Robots</button>
          </div>
        </div>
      )}

      {type == "edit-company-robot" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/branch-details")}
            >
              Company Robots
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Company Robot</button>
          </div>
        </div>
      )}

      {type == "add-company-robot" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/branch-details")}
            >
              Company Robots
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Company Robot</button>
          </div>
        </div>
      )}

      {type == "menu-managment" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Restaurant Menu</button>
          </div>
        </div>
      )}

      {type == "menu-page" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/menu-management")}
            >
              Restaurant Menu
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Menu</button>
          </div>
        </div>
      )}

      {type == "add-menu" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/menu-management")}
            >
              Restaurant Menu
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Menu
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Category & Cusine</button>
          </div>
        </div>
      )}

      {type == "add-food" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/menu-management")}
            >
              Restaurant Menu
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Menu
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Food</button>
          </div>
        </div>
      )}

      {type == "edit-food" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/menu-management")}
            >
              Restaurant Menu
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Menu
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Food</button>
          </div>
        </div>
      )}

      {type == "admin-company-users" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Restaurant Employe List</button>
          </div>
        </div>
      )}

      {type == "admin-company-user" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/company-users")}
            >
              Restaurant Employe List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Employe List</button>
          </div>
        </div>
      )}

      {type == "admin-edit-user" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/company-users")}
            >
              Restaurant Employe List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Employe List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit User</button>
          </div>
        </div>
      )}

      {type == "admin-add-user" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/company-users")}
            >
              Restaurant Employe List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Employe List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add User</button>
          </div>
        </div>
      )}

      {type == "robot-manage-list" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Robot Management</button>
          </div>
        </div>
      )}

      {type == "robot-management" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/robot-manage-list")}
            >
              Robot Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Robots List</button>
          </div>
        </div>
      )}

      {type == "add-branch-table" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/robot-manage-list")}
            >
              Robot Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Robots List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Table List</button>
          </div>
        </div>
      )}

      {type == "admin-edit-table" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/robot-manage-list")}
            >
              Robot Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Robots List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Table List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Table</button>
          </div>
        </div>
      )}
      {type == "admin-add-table" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/robot-manage-list")}
            >
              Robot Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Robots List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtn" onClick={() => navigate(-1)}>
              Table List
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Table</button>
          </div>
        </div>
      )}
        {type == "userList" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">User List</button>
          </div>
        </div>
      )}
        {type == "edit_Role" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/users")}
            >
             User List 
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit User</button>
          </div>
        </div>
      )}
        {type == "add_Role" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/users")}
            >
             User List 
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add User</button>
          </div>
        </div>
      )}
      {type == "Branch Management" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Branch Management</button>
          </div>
        </div>
      )}
      {/* {type == "Branch Management" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Branch Management</button>
          </div>
        </div>
      )} */}
        {type == "Branch_details" && (
          <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
              >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/branch-details")}
              >
            Branch Management
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Branch Details</button>
          </div>
        </div>
      )}
      {type == "Category & Cuisine type" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Category & Cuisine type</button>
          </div>
        </div>
      )}
      {type == "View_Category_cuisine" && (
          <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
              >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&&<button
              className="headeBtn"
              onClick={() => navigate("/restaurant/category-cousin")}
              >
            Category & Cuisine type
            </button>}
            {!user?.company_branch_id&&<button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button className="headeBtnActive">View category & cuisine</button>
          </div>
        </div>
      )}
      {type == "View_Category" && (
          <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
              >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&&<button
              className="headeBtn"
              onClick={() => navigate("/restaurant/category-cousin")}
              >
            Category & Cuisine type
            </button>}
            {!user?.company_branch_id&&<button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button 
             className="headeBtn"
             onClick={() => navigate(-1)}
            >View category & cuisine</button>
             <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">CategoryList</button>
          </div>
        </div>
      )}
      {type == "View_Cuisine" && (
          <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
              >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&&<button
              className="headeBtn"
              onClick={() => navigate("/restaurant/category-cousin")}
              >
            Category & Cuisine type
            </button>}
            {!user?.company_branch_id&&<button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button 
             className="headeBtn"
             onClick={() => navigate(-1)}
            >View category & cuisine</button>
             <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">CuisineList</button>
          </div>
        </div>
      )}
      {type == "add_category" && (
          <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
              >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&&<button
              className="headeBtn"
              onClick={() => navigate("/restaurant/category-cousin")}
              >
            Category & Cuisine type
            </button>}
            {!user?.company_branch_id&&<button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button 
             className="headeBtn"
             onClick={() => navigate(-1)}
            >View category & cuisine</button>
             <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button 
            className="headeBtn"
            onClick={() => navigate(-1)}
            >CuisineList</button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add category</button>
          </div>
        </div>
      )}
      {type == "Edit_category" && (
          <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
              >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&&<button
              className="headeBtn"
              onClick={() => navigate("/restaurant/category-cousin")}
              >
            Category & Cuisine type
            </button>}
            {!user?.company_branch_id&&<button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button 
             className="headeBtn"
             onClick={() => navigate(-1)}
            >View category & cuisine</button>
             <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button 
            className="headeBtn"
            onClick={() => navigate(-1)}
            >CuisineList</button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit category</button>
          </div>
        </div>
      )}
      {type == "add_Cuisine" && (
          <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
              >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&&<button
              className="headeBtn"
              onClick={() => navigate("/restaurant/category-cousin")}
              >
            Category & Cuisine type
            </button>}
            {!user?.company_branch_id&&<button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button 
             className="headeBtn"
             onClick={() => navigate(-1)}
            >View category & cuisine</button>
             <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button 
            className="headeBtn"
            onClick={() => navigate(-1)}
            >CuisineList</button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Cuisine</button>
          </div>
        </div>
      )}
      {type == "Edit_cuisine" && (
          <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
              >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&& <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/category-cousin")}
              >
            Category & Cuisine type
            </button>}
            {!user?.company_branch_id&& <button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button 
             className="headeBtn"
             onClick={() => navigate(-1)}
            >View category & cuisine</button>
             <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button 
            className="headeBtn"
            onClick={() => navigate(-1)}
            >CuisineList</button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Edit Cuisine</button>
          </div>
        </div>
      )}
       {type == "menu_management" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Menu Management</button>
          </div>
        </div>
      )}
       {type == "menu" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
             <button className="headeBtn">
               <NavigateNextIcon />
             </button>
           {!user?.company_branch_id&&
            
            <button 
             className="headeBtn"
             onClick={() => navigate("/restaurant/menu-list")}
             >Menu Management</button>
           
            }
           {!user?.company_branch_id&& <button className="headeBtn">
              <NavigateNextIcon />
              </button>}
            <button className="headeBtnActive">Menu</button>
          </div>
        </div>
      )}
       {type == "Add_management" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&& <button 
            className="headeBtn"
            onClick={() => navigate(-1)}
            >Menu Management</button>}
            {!user?.company_branch_id&&<button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button className="headeBtn"
            onClick={() => navigate(-1)}
            >Menu</button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive"> Add Menu</button>
          </div>
        </div>
      )}
       {type == "Edit_management" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            {!user?.company_branch_id&&<button 
            className="headeBtn"
            onClick={() => navigate(-1)}
            >Menu Management</button>}
            {!user?.company_branch_id&&<button className="headeBtn">
              <NavigateNextIcon />
            </button>}
            <button className="headeBtn"
            onClick={() => navigate(-1)}
            >Menu</button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive"> Edit Menu</button>
          </div>
        </div>
      )}
       {type == "Robot" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Robot List</button>
          </div>
        </div>
      )}
       {type == "Robot_View" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button 
            className="headeBtn"
            onClick={() => navigate(-1)}
            >Robot List</button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Robot View</button>
          </div>
        </div>
      )}
      {type == "Robot_order" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Robot order</button>
          </div>
        </div>
      )}
      {type == "Robot_order_view" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/restaurant/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button 
             className="headeBtn"
             onClick={() => navigate("/restaurant/robot-order-list")}
            >Robot order</button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Robot order view</button>
          </div>
        </div>
      )}
      {type == "add-hi-robot" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/hi-company-users")}
            >
           <button className="headeBtnActive">Hi Robot</button>  
            </button>
            {/* <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Hi Robot</button> */}
          </div>
        </div>
      )}
      {type == "agv-robot" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/agv-user-list")}
            >
           <button className="headeBtnActive">AGV Robot</button>  
            </button>
            {/* <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Hi Robot</button> */}
          </div>
        </div>
      )}
      {type == "agv-branch" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/agv-user-list")}
            >
           <button className="headeBtnActive">AGV Branch</button>  
            </button>
            {/* <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Hi Robot</button> */}
          </div>
        </div>
      )}
      {type == "hi-branch" && (
        <div className="d-flex justify-content-end mb-3 mx-4">
          <div className="d-flex">
            <button
              className="headeBtn"
              onClick={() => navigate("/table-list")}
            >
              Home
            </button>
            <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button
              className="headeBtn"
              onClick={() => navigate("/agv-user-list")}
            >
           <button className="headeBtnActive">Hi Robot Branch</button>  
            </button>
            {/* <button className="headeBtn">
              <NavigateNextIcon />
            </button>
            <button className="headeBtnActive">Add Hi Robot</button> */}
          </div>
        </div>
      )}

    </div>
  );
};

export default TopHeader;
