import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-simple-keyboard/build/css/index.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useLoginResMutation } from "../constants/redux/api/api";
import useUser from "../constants/Storage/userData";
import { logo } from "../assets/images";

const LoginResturentScreen = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);

  const [usernameErr, setUsernameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  useEffect(() => {
    if(localStorage?.getItem("resturent_token")){
      navigate("/restaurant/table-list");
    }
},[])

  //   RTK QUERY
  const [login] = useLoginResMutation();

  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const [layouts, setLayouts] = useState("default");
  const keyboard = useRef();

  //  common call function onkeyUp & onChange
  const onChangeHandler = (e) => {
    if (e.target.name == "username") {
      setUsername(e.target.value);
      e.target.value == "" ? setUsernameErr(true) : setUsernameErr(false);
    }
    if (e.target.name == "password") {
      e.target.value == "" ? setPasswordErr(true) : setPasswordErr(false);
      setPassword(e.target.value);
    }
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const handleShifts = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayouts(newLayoutName);
  };

  const onKeyPress = (button) => {
    // console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onKeyPress2 = (button) => {
    // console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShifts();
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setInput(input);
    keyboard.current.setInput(input);
  };

  const passwordTypeHandle = () => {
    showPass ? setShowPass(false) : setShowPass(true);
    if (!showPass) {
      document.getElementById("login_password").type = "text";
    } else {
      document.getElementById("login_password").type = "password";
    }
  };

  const onSubmit = () => {
    if (username === "" || password === "") {
      username === "" && setUsernameErr(true);
      password === "" && setPasswordErr(true);
    } else {
      const data = {
        emailid: username,
        password: password,
      };
      login(data)
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            console.log(res);
            localStorage.setItem("type", 2);
            localStorage.setItem("resturent_token", res?.token);
            localStorage.setItem(
              "resturent_users",
              JSON.stringify(res?.userdata)
            );
            // setUser(res?.userdata);
            setTimeout(() => {
              navigate("/restaurant/table-list");
              window.location.reload();
            }, 500);

            // localStorage.setItem(
            //   "name",
            //   res?.result?.robots_name || res?.result?.name
            // );
            // if (res?.result?.role === "admin") {
            //   navigate("/table-list");
            // } else if (res?.result?.robots_id) {
            //   navigate("/welcome");
            // }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="login">
      <div className="d-flex ac-jc flex-wrap ">
        <div className="col-md-6">
          <div className="ad_bg_img">
            <div className="left-div">
              <div>
                <img src={logo} className="ad_logo " />
                <h1 className="">CRM FOR RESTAURANT</h1>
                <h6>ROBOTICS & TECHNOLOGY</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 right_log">
          <h1>Login</h1>
          <p>Welcome back! Login to access your account</p>
          <div className="form-control form-cl p-0">
            <input
              className="w-100 h-100"
              placeholder="Enter your username"
              type="text"
              name="username"
              value={username}
              onChange={(e) => onChangeHandler(e)}
              onKeyUp={(e) => onChangeHandler(e)}
            />
            {username?.length == 0 && usernameErr && (
              <p className="err">Enter UserName</p>
            )}
          </div>
          <div className="form-control form-cl p-0 position-relative">
            <input
              className="w-100 h-100"
              placeholder="Enter your password"
              type="password"
              name="password"
              id="login_password"
              value={password}
              onChange={(e) => onChangeHandler(e)}
              onKeyUp={(e) => onChangeHandler(e)}
            />
            {showPass ? (
              <VisibilityOutlinedIcon
                onClick={(e) => {
                  passwordTypeHandle();
                }}
                className="eye-password position-absolute"
              />
            ) : (
              <VisibilityOffIcon
                onClick={(e) => {
                  passwordTypeHandle();
                }}
                className="eye-password position-absolute"
              />
            )}
            {password?.length == 0 && passwordErr && (
              <p className="err">Enter Password</p>
            )}
          </div>

          <div onClick={onSubmit} className="login_btn text-center">
            <button className="bt_sub">Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginResturentScreen;
