import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate,useLocation, useSearchParams } from "react-router-dom";
import {useCusine_updateMutation,useLazyCusine_viewQuery,useCusine_AddMutation} from "../../../constants/redux/api/api"
import TopHeader from "../../../components/TopHeader";
import { toast } from "react-toastify";
import { loadergif } from "../../../assets/img";

const RestaurantAddCuisine = ({ menuOpen }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [cusinename,setcusinename]=useState("");
  const[disableBtn,setdisablebtn]=useState(false)
  const [cusinenameErr,setcusinenameErr]=useState(false)
  const navigate = useNavigate();
  const location = useLocation()
  const branchId = location.state.data ;

  const type =location.state.type
  console.log(type,"types")
  

  //RTK
   const [addCuisinApi]=useCusine_AddMutation()
   const [viewCusineApi]=useLazyCusine_viewQuery()
   const [updateApi]=useCusine_updateMutation()
   
   const viewdata=(id)=>{
    viewCusineApi(branchId?._id)
    .unwrap()
    .then(res=>{
      console.log(res)
      const data =res?.data
      setcusinename(data?.cusine_name);
    })
   }
  
   useEffect(()=>{
    if(type == 'edit'||type=="view"){
      viewdata()
    }
   },[type,branchId])
  



  const onSubmit=()=>{
    if(cusinename.length===0){
      setcusinenameErr(true)
    }else{
      setdisablebtn(true)
      let fromdata = new URLSearchParams()
      fromdata.append("cusine_name",cusinename)
      if(type=="edit"){
        const id = branchId._id
        console.log(branchId._id,"id")
        updateApi( {payload:fromdata,id})
        .unwrap()
        .then(res=>{
          console.log(res,"edit")
          setdisablebtn(false)
          toast.success(res?.message)
          navigate(-1)
        })
        .catch(err=>{
          setdisablebtn(false)
          })
      }else{
        fromdata.append("company_branch_id",branchId);
        addCuisinApi(fromdata)
        .unwrap()
        .then(res=>{
          navigate(-1)
          setdisablebtn(false)
          toast.success(res?.message)
        })
        .catch(err=>{
          setdisablebtn(false)
          console.log(err)})
      }
    }

  }

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <TopHeader type={type == 'edit'?"Edit_cuisine":"add_Cuisine"}/>
     <h3 style={{ "font-size": "25px", color: "#25aae1" }} >{type=="view"?"View Cuisine":(type=="edit"?"Edit Cuisine":"Add Cuisine")}</h3>
        
          <div className="add-food-section row">
            <div className="form-group col-4">
              <label>Cuisine Name</label>
              <input
              disabled={type=="view"?true:false}
                type="text"
                placeholder="Enter Cuisine Name"
                className="form-control"
                value={cusinename}
                onChange={(e)=>setcusinename(e.target.value)}
              />
              {cusinename?.length==0&&cusinenameErr&&<p className="errtext">Enter CusineName</p>}
            </div>
            {type!="view"&&<div className="form-group" onClick={onSubmit}>
              {type=="edit"?<button type="button" disabled={disableBtn}  className="btn btn-primary">
                Update
              </button>:
              <button type="button"  disabled={disableBtn}   className="btn btn-primary"

              >
                Submit
              </button>
            }
              
            </div>}
          </div>

    </div>
  );
};

export default RestaurantAddCuisine;
