import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useLazyRobots_statusQuery,
  useRobots_listMutation,
  useRobo_deleteMutation,
} from "../constants/redux/api/api";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import Swal from "sweetalert2";
import { loadergif } from "../assets/img";
function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end " size="lg">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const RoboTypeList = () => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const [robotList, setRobotList] = useState([]);
  const [loading, setLoading] = useState(false);
  //   RTK query
  const [robots_list] = useRobots_listMutation();
  const [robot_status] = useLazyRobots_statusQuery();
  const [roboDelete] = useRobo_deleteMutation();

  const GetRobotList = async () => {
    setLoading(true)
    robots_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = [];

          res?.data?.map((item, ind) => {
            temp.push(item);
          });
          setLoading(false)
          setRobotList(temp.reverse());
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err, "error");
      });
  };

  const roboStatusChange = (id) => {
    setLoading(true)
    robot_status(id)
      .unwrap()
      .then((res) => {
        setLoading(false)
        GetRobotList();
      })
      .catch((err) => {
        setLoading(false)
        console.log("err", err);
      });
  };

  const roboDeletes = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        roboDelete(id)
          .unwrap()
          .then((res) => {
            GetRobotList();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    });
  };

  useEffect(() => {
    GetRobotList();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Robot type",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Software Version",
        accessor: "software_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              className={`${
                value ? "bg-success" : "bg-danger"
              } px-2 py-1 rounded-1 border-0 text-white`}
              onClick={() => roboStatusChange(row?.original?.id)}
            >
              {value ? "Active" : "Deactive"}
            </button>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          console.log(row, "row216");
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/add-robot-type", {
                    state: { type: "edit", data: row.original },
                  })
                }
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              {/* <button
                className="bg-warning border-0 rounded-1 p-1"
                onClick={() => roboDeletes(row.original._id)}
              >
                <Delete />
              </button> */}
              <button
                onClick={() =>
                  navigate("/add-robot-type", {
                    state: { type: "view", data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
                </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
    {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <div className="table-responsive menu-table side_pad mt-4">
        <Table columns={columns} data={robotList} className="" />
      </div>
    </>
  );
};

export default RoboTypeList;
