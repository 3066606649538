import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import { loadergif } from "../../assets/img";

// import { loadergif } from "../assets/img";
// import TopHeader from "../components/TopHeader";


const AddAgvBranch = ({ menuOpen }) => {
  const navigate = useNavigate();


  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {/* {loading && ( */}
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    {/* )} */}
      <TopHeader
        // type={type === "edit" ? "edit-company-robot" : "add-company-robot"}
      />
      <div className="menu-list">
        <h1>Add AGV Branch</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2 " for="">
                  Company Name
                </label>
                <select
                //   disabled={type=='view'||type=="edit"?true:false}
                  className="form-control mb-1"
                  name="company_id"
                //   id=""
                //   value={companyName}
                //   onChange={(e) => {
                //     companySelecter(e.target.value);
                //   }}
                  // disabled={type == "edit" ? true : false}
                >
                  <option hidden>Select company</option>
                  {/* {comapnyManageList?.map((item, ind) => {
                    return (
                      <option value={item?._id} key={ind}>
                        {item?.companyname}
                      </option>
                    );
                  })} */}
                </select>
                {/* {companyName?.length == 0 && companyNameErr && (
                  <p className="text-danger m-0">Select Company</p>
                )} */}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Branch Name
                </label>

                <select
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                //   onChange={(e) => {
              
                //     branchSelecter(e.target.value);
                //   }}
                //   value={branch}
                //   disabled={type=='view'||type=="edit"?true:false}
                  // disabled={type == "edit" ? true : false}
                >
                  <option hidden>Branch Name</option>
                  {/* {branchList?.map((item, ind) => {
                    return (
                      <option value={item?.id} key={ind}>
                        {item?.name}
                      </option>
                    );
                  })} */}
                </select>
                {/* {branch?.length == 0 && branchErr && (
                  <p className="text-danger m-0">Select Branch</p>
                )} */}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative ">
                <label className="mb-2" for="">
                  Branch Location
                </label>
                <input
                // disabled={type == "view" ? true : false}
                  className="form-control mb-1"
                  name="company_branch_id"
                //   id=""
                //   readOnly
                //   value={branchData?.location}
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  {" "}
                  Branch Pincode
                </label>
                <input
                  className="form-control mb-1"
                  name="company_branch_id"
                //   id=""
                //   readOnly
                //   value={branchData?.pincode}
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  {/* Robot Order ID */}
                  Robot Type
                </label>
                <select
                  className="form-control mb-1"
                  name="company_branch_id"
                //   id=""
                //   value={robotIdChe}
                //   onChange={(e) => {
                //     selectRobotOrder(e.target.value);
                //   }}
                //   disabled={type == "edit"||type == "view" ? true : false}
                >
                  <option hidden>Select Robot Order ID</option>
                  {/* {robotList?.map((item, ind) => {
                    return (
                      <option value={item?.robot_type?._id} key={ind}>
                        {item?.robot_type?.name}
                      </option>
                    );
                  })} */}
                </select>
               
              </div>
             
              {/* {robotId?.map((item, index) => { */}
                {/* return ( */}
                  <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                    <label className="mb-2" for="">
                      Robot Id
                    </label>
                    <input
                    //   disabled={type == "view" ? true : false}
                    //   name={`robot_id${index}`}
                      type="text"
                      className="form-control mb-1"
                      placeholder="Enter Robot Id"
                    //   value={roboTemp[index]}
                    //   onChange={(e) => {
                    //     handleFormChanges(e.target.value, index);
                    //   }}
                    />
                   
                  </div>
                {/* );
              })} */}

              {/* {(type == "edit" || type == "view") && ( */}
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" for="">
                    Robot Id
                  </label>
                  <input
                    type="text"
                    className="form-control mb-1"
                    placeholder="Enter Robot Id"
                    // value={codeName}
                    // onChange={(e) => {
                    //   setCodeName(e.target.value);
                    // }}
                  />
                </div>
              {/* )} */}

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Manufacturing Date
                </label>
                <input
                //  disabled={type == "view" ? true : false}
                  name="manufacturing_date"
                  type="date"
                  className="form-control"
                  placeholder="Enter Manufacturing Date"
                //   value={manuFaDate}
                //   onChange={(e) => {
                //     setManuFactDate(e.target.value);
                //   }}
                />
                {/* {manuFaDate?.length == 0 && manuFaDateErr && ( */}
                  {/* <p className="text-danger m-0">Enter Manufacturing Date</p> */}
                {/* )} */}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Software Versions
                </label>
                <input
                //  disabled={type == "view" ? true : false}
                  name="software_version"
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Software Versions"
                //   value={sFVs}
                //   onChange={(e) => {
                //     setSFVs(e.target.value);
                //   }}
                />
                {/* {sFVs?.length == 0 && sFVsErr && ( */}
                  {/* <p className="text-danger m-0">Enter Software version</p> */}
                {/* )} */}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Date of Purchase
                </label>
                <input
                //  disabled={type == "view" ? true : false}
                  name="purchase_date"
                  type="date"
                  className="form-control mb-1"
                  placeholder="Enter Date of Purchase"
                //   value={dateOfPurch}
                //   onChange={(e) => {
                //     setDateOfPurch(e.target.value);
                //   }}
                />
                {/* {dateOfPurch?.length == 0 && dateOfPurchErr && ( */}
                  {/* <p className="text-danger m-0">Enter Date of Purchase</p> */}
                {/* )} */}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Warranty Period
                </label>
                <input
                //  disabled={type == "view" ? true : false}
                  type="date"
                  className="form-control mb-1"
                  placeholder="Enter  Warranty Period"
                  name="warranty_period"
                //   value={warrie}
                //   onChange={(e) => {
                //     setWarrien(e.target.value);
                //   }}
                />
                {/* {warrie?.length == 0 && warrieErr && ( */}
                  {/* <p className="text-danger m-0">Enter Warranty Period</p> */}
                {/* )} */}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Attachment
                </label>
                <input
                //  disabled={type == "view" ? true : false}
                  name="attachment"
                  type="file"
                  className="form-control"
                //   accept="application/pdf"
                //   onChange={(e) => {
                //     setAttachment(e.target.files[0]);
                //   }}
                  />
                    {/* {(type=="view"||type=="edit")&& */}
                
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  {/* <p>{trim}</p> */}
                  <button
                //    onClick={()=>onClick() } 
                   className="border-0 ad-btn bg-danger ">Download</button>
  {/* {document && (
    <iframe src={attachment} style={{ height: "0px" }}></iframe>)}     */}
</div>
                {/* {attachment?.length == 0 && attachmentErr && ( */}
                  {/* <p className="text-danger m-0">Please attach the image</p> */}
                {/* )} */}
              </div>
              {/* {type!="view" &&  */}
               <div className="col-md-6 col-12 px-md-0 px-0 position-relative mb-3 d-flex">
                <div className="px-md-3 pe-3">
                  {/* {type === "edit" ? ( */}
                    {/* <button
                      className="btn-submit"
                      onClick={(e) => handleSubmitForm(e)}
                      disabled={btn ? true : false}
                    >
                      Update
                    </button> */}
                  {/* ) : ( */}
                    <button
                      className="btn-submit"
                    //   onClick={(e) => handleSubmitForm(e)}
                    //   disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  {/* )} */}
                </div>
                <div onClick={() => navigate(-1)} class="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAgvBranch;
