import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAgvListLocationMutation,
  useAgvMapLocationMutation,
  useCompany_robot_addMutation,
  useLazyCompanyRobotViewQuery,
  useLazyRobotOrderAgvlistQuery,
  useRobotOrderAgvlistMutation,
} from "../../constants/redux/api/api";
import {
  useRobot_order_addMutation,
  useCompany_management_listMutation,
  useRobot_order_listMutation,
  useLazyGet_robotList_idQuery,
  useEdit_company_robotMutation,
  useRobots_listMutation,
  useLazyRobot_order_viewQuery,
  useLazyRobots_order_payments_listQuery,
  useRobots_order_payments_addMutation,
  useLazyRobot_list_companyQuery,
} from "../../constants/redux/api/api";
import moment from "moment";
import { loadergif } from "../../assets/img";
import TopHeader from "../../components/TopHeader";
import AgvRobotDetails from "./AgvRobotDetails";
import AGVLocation from "./AGVLocation";
const AddAgvLocation = ({ menuOpen }) => {
  const navigate = useNavigate();

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;

  //   RTK QUERY
  const [company_robot_add] = useCompany_robot_addMutation();
  const [agvListLocation] = useAgvMapLocationMutation();
  const [robot_list_company] = useLazyRobot_list_companyQuery();
  const [robot_order_list] = useCompany_management_listMutation();
  const [robotOrderList] = useRobotOrderAgvlistMutation();
  const [robotViewApi] = useLazyCompanyRobotViewQuery();

  //   state Variable declaration
  const [loading, setLoading] = useState(false);
  const [comapnyManageList, setCompanyManagementList] = useState([]);
  const [branchErr, setBranchErr] = useState(false);
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [manuFaDateErr, setManuFactDateErr] = useState(false);
  const [sFVsErr, setSFVsErr] = useState(false);
  const [dateOfPurchErr, setDateOfPurchErr] = useState(false);
  const [warrieErr, setWarrienErr] = useState(false);
  const [attachmentErr, setAttachmentErr] = useState(false);
  const [branch, setBranch] = useState("");
  const [branchData, setBranchData] = useState({});
  const [robotIdChe, setRobotIdChe] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [manuFaDate, setManuFactDate] = useState("");
  const [sFVs, setSFVs] = useState("");
  const [warrie, setWarrien] = useState("");
  const [dateOfPurch, setDateOfPurch] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [robotLists, setRobotLists] = useState([]);
  const [robotNumId, setClientName] = useState("");
  const [codeName, setCodeName] = useState("");
  const [robotType, setrobotType] = useState("");
  const [robotId, setRobotId] = useState([]);
  const [btn, setBtn] = useState(false);
  const [trim, settrim] = useState("");
  const [docs, setDocs] = useState("");

  const [robotName, setRobotName] = useState("");
  const [mappedId, setMappedId] = useState("");
  const [robotList, setRobotList] = useState([]);
  const [attachment, setAttachment] = useState("");
  const [document, setDounload] = useState(false);

  const [robotErr, setRobotErr] = useState(false);

  const onChangeRobotName = (e) => {
    setRobotName(e.target.value);
    console.log(e.target.value);
  };

  const onChangeMappedId = (e) => {
    setMappedId(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    const getROBOTId = () => {
      robot_list_company()
        .unwrap()
        .then((res) => {
          setRobotLists(res?.data);
        })
        .catch((err) => {
          console.log({ message: err.message });
        });
    };
    getROBOTId();
  }, []);

  //   Add form data function
  const handleSubmitForm = () => {
    let formData = new FormData();
    formData.append("robot_name", robotName);
    formData.append("code", mappedId);
    formData.append("mapped_robots", companyId);
    formData.append("image", attachment);

    console.log("formData,", formData);
    if (
      robotName?.length === 0 ||
      mappedId?.length === 0 ||
      attachment?.length === 0
    ) {
      setBranchErr(true);
      setRobotErr(true);
      setCompanyNameErr(true);
      setAttachmentErr(true);
    } else {
      setLoading(true);
      setBtn(true);
      agvListLocation(formData)
        .unwrap()
        .then((res) => {
          setLoading(false);
          setBtn(false);
          if (location?.state?.method == "agv") {
            navigate("/add-agv-list", {
              state: { data: location?.state?.data, tab: 3, type: "edit" },
            });
          } else {
            navigate(-1, { state: { tab: 3 } });
          }
        })
        .catch((err) => {
          setBtn(false);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const onClick = () => {
    setDounload(true);
  };

  const getCompanyManagementList = async () => {
    robot_order_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = res?.data?.filter((i) => i?.status == true);
          setCompanyManagementList(temp.reverse());
          console.log(temp, "list");
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const companySelecter = (item) => {
    let temp = comapnyManageList?.find((i) => i?._id == item);
    console.log("tem131", temp);
    setCompanyName(item);
    setCompanyId(item);
    // setRobotId(temp?.number_of_robots);
    setBranchList(temp?.branches);
    setClientName(temp?.name);
  };

  const branchSelecter = (item) => {
    // console.log("ite", item);
    let temp = branchList?.find((i) => i?.id == item);
    console.log("temp", temp);
    setBranch(item);
    setBranchData(temp);

    let obj = {
      company_branch_name: item,
      company_name: companyName,
    };

    robotOrderList(obj)
      .unwrap()
      .then((res) => {
        let temp = res?.data?.filter((i) => i?.status == true);
        setRobotList(temp);
        if (type !== "edit") {
          setRobotIdChe("");
          setrobotType("");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanyManagementList();
  }, []);

  // console.log(branchPin, "branchPinErr227");
  const [roboTemp, setRoboTemp] = useState({});

  const handleFormChanges = (value, index) => {
    setRoboTemp((val) => ({ ...val, [index]: value }));
  };

  const selectRobotOrder = (id) => {
    let finder = robotList?.find((i) => i?.robot_type?._id == id);
    setrobotType(finder?.robot_type?.name);
    setRobotIdChe(finder?.robot_type?._id);
    let temp = [];
    for (let i = 0; i < finder?.number_of_robots; i++) {
      temp.push(i);
    }
    // console.log("temtempp", temp);
    setRobotId(temp);
    // console.log("finder", finder);
  };

  const getVIew = () => {
    robotViewApi(editData?._id)
      .unwrap()
      .then((res) => {
        console.log("ddd", res);
        if (res?.status == "success") {
          let data = res?.data?.[0];
          console.log("data?.company_info?._id", data);
          // setCompanyName(data?.company_info?._id);
          companySelecter(data?.company_info?._id, data?.branches?._id);
          branchSelecter(data?.branches?._id);
          setRobotIdChe(data?.robot_details?._id);
          setrobotType(data?.robot_name[0]);

          setBranchData({
            location: data?.branches?.location,
            pincode: data?.branches?.pincode,
          });
          setSFVs(data?.software_version);
          setWarrien(data?.warranty_period);
          if (data?.manufacturing_date) {
            setManuFactDate(
              moment(data?.manufacturing_date).format("YYYY-MM-DD")
            );
          }

          if (data?.purchase_date) {
            setDateOfPurch(moment(data?.purchase_date).format("YYYY-MM-DD"));
          }
          setCodeName(data?.code);
          // setBranch(data?.branches?._id);
          // setAttachment(data?.attachment[0] )
          setDocs(data?.attachment[0]);
          let Id = [];
          console.log(data?.code, "code");

          data?.code?.map((item, index) => {
            Id?.push(item);
            setRoboTemp((previous) => ({ ...previous, [index]: item }));
          });
          setRobotId(Id);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  console.log(roboTemp, "robotId");
  const doc = () => {
    const url = docs;
    const trimmedFilename = url.substring(url.lastIndexOf("/") + 1);
    settrim(trimmedFilename);
  };
  useEffect(() => {
    if (docs && (type == "edit" || type == "view")) {
      doc();
    }
  }, [docs]);

  useEffect(() => {
    if (type == "edit" || (type == "view" && comapnyManageList?.length > 0)) {
      getVIew();
    }
  }, [comapnyManageList, type]);
  //   const url = attachment
  //   const trimmedFilename = url.substring(url.lastIndexOf('/') + 1);
  // console.log(trimmedFilename);
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {loading && (
        <div
          style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}
        >
          <span style={{ marginLeft: 10 }}>
            <img src={loadergif} />
          </span>
        </div>
      )}
      <TopHeader
        type={type === "edit" ? "edit-company-robot" : "add-company-robot"}
      />
      <div className="menu-list">
        <h1>
          {type === "view"
            ? "View Robot Order"
            : type === "edit"
            ? "Edit Company Robot"
            : "Agv Company & Branch"}
        </h1>
      </div>

      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2 " for="">
                  Robot Name
                </label>
                <input
                  disabled={type == "add" ? false : true}
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Robot Id"
                  value={robotName}
                  onChange={(e) => {
                    onChangeRobotName(e);
                  }}
                />
                {robotName?.length == 0 && robotErr && (
                  <p className="text-danger m-0">Enter Robot Name</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Mapped Id
                </label>
                <input
                  disabled={type == "add" ? false : true}
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Robot Id"
                  value={mappedId}
                  onChange={(e) => {
                    onChangeMappedId(e);
                  }}
                />
                {mappedId?.length == 0 && branchErr && (
                  <p className="text-danger m-0">Select Branch</p>
                )}
              </div>

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Mapped Robots
                </label>
                <select
                  disabled={type == "view" || type == "edit" ? true : false}
                  className="form-control mb-1"
                  name="company_id"
                  id=""
                  value={companyName}
                  onChange={(e) => {
                    companySelecter(e.target.value);
                  }}
                  // disabled={type == "edit" ? true : false}
                >
                  <option hidden>Robot Name</option>
                  {comapnyManageList?.map((item, ind) => {
                    return (
                      <option value={item?._id} key={ind}>
                        {item?.companyname}
                      </option>
                    );
                  })}
                </select>
                {companyName?.length == 0 && companyNameErr && (
                  <p className="text-danger m-0">Select Mapped Robot</p>
                )}
              </div>

              {robotId?.map((item, index) => {
                return (
                  <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                    <label className="mb-2" for="">
                      Robot Id
                    </label>
                    <input
                      disabled={type == "add" ? false : true}
                      name={`robot_id${index}`}
                      type="text"
                      className="form-control mb-1"
                      placeholder="Enter Robot Id"
                      value={item[index]}
                      onChange={(e) => {
                        handleFormChanges(e.target.value, index);
                      }}
                    />
                  </div>
                );
              })}

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Location Images
                </label>
                <input
                  disabled={type == "add" ? false : true}
                  name="attachment"
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setAttachment(e.target.files[0]);
                  }}
                />
                {attachment?.length == 0 && attachmentErr && (
                  <p className="text-danger m-0">Please attach the image</p>
                )}
              </div>

              <div className="col-md-6 col-12 px-md-0 px-0 position-relative mb-3 d-flex">
                <div className="px-md-3 pe-3">
                  <button
                    className="btn-submit"
                    onClick={() => handleSubmitForm()}
                    disabled={btn ? true : false}
                  >
                    Submit
                  </button>
                </div>
                <div onClick={() => navigate(-1)} class="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAgvLocation;
