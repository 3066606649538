import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useEffect, useState } from "react";
import TopHeader from "../../components/TopHeader";
import { useRobot_list_viewMutation } from "../../constants/redux/api/api";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import { loadergif } from "../../assets/img";

const RestaurantOrderDetailScreen = ({ menuOpen }) => {
  const [loading, setLoading] = useState(false);
  const [listApi] = useRobot_list_viewMutation();
  const [data, setdata] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [todate, setTodate] = useState("");
  const [fiterdata, setfilterdata] = useState([]);
  const location = useLocation();

  const filterDayOption = [
    "Sunday",
    "Monday",
    "TuesDay",
    "WednesDay",
    "ThursDay",
    "FriDay",
    "SaturDay",
  ];

  const getList = () => {
    setLoading(true);
    let formdata = new URLSearchParams();
    formdata.append("company_branch_id", location?.state?.data?._id);
    formdata.append("robot_id", location?.state?.data?.robot_id);
    listApi(formdata)
      .unwrap()
      .then((res) => {
        setLoading(false);
        setdata(res?.data);
        setfilterdata(res?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const filter = () => {
    const filtered = fiterdata.filter((item) => {
      const itemDate = moment(item?.date).format("DD-MM-YYYY");
      return (
        itemDate >= moment(startdate).format("DD-MM-YYYY") &&
        itemDate <= moment(todate).format("DD-MM-YYYY")
      );
    });
    setdata(filtered);
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (startdate && todate) {
      filter();
    }
  }, [startdate, todate]);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {loading && (
        <div
          style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}
        >
          <span style={{ marginLeft: 10 }}>
            <img src={loadergif} />
          </span>
        </div>
      )}
      <TopHeader type={"Robot_View"} />
      <div className="menu-list mb-0">
        <div className="">
          <h1>Robot Details</h1>
          <h1>Filter</h1>
        </div>
        <div className="side_pad">
          <div className="add-food-section">
            <div action="">
              <div className="d-flex flex-wrap">
                <div className="col-md-4 col-12 px-md-3 px-0 mb-3">
                  <label className="mb-2" htmlFor="">
                    Start Date
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Start Date"
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    className="form-control"
                    value={startdate}
                    onChange={(e) => setStartdate(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-12 px-md-3 px-0 mb-3">
                  <label className="mb-2" htmlFor="">
                    End Date
                  </label>
                  <input
                    type="text"
                    placeholder="Enter End Date"
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    className="form-control"
                    value={todate}
                    onChange={(e) => setTodate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data?.map((item, idx) => {
        return (
          <div className="side_pad" key={idx}>
            <div class="robot-order-det mb-3">
              <div class="d-flex flex-wrap justify-content-between ">
                <div class="robot_date">
                  <h5 className="mb-0">
                    Date :{" "}
                    <span>
                      {
                        //  date=new Date(item?.company_robot_info?.date)
                        //  date.toLocaleDateString()
                        item?.date
                          ? moment(item?.date).format("DD-MM-YYYY")
                          : "-"
                      }
                    </span>
                  </h5>
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-between pt-3">
                <div class="robot_name">
                  <h5>
                    Robot Name :{" "}
                    <span>{item?.company_robot_info?.robot_name}</span>
                  </h5>
                </div>
                <div class="robot_name mar_b">
                  <h5>
                    Robot : <span>{item?.company_robot_info?.robot_name}</span>
                  </h5>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="robot_content mar_b">
                  <p>
                    ID : <span>{item?.company_robot_info?.code}</span>
                  </p>
                </div>
                <div class="robot_content mar_b">
                  <p>
                    Map Location : <span>Map ID</span>{" "}
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="robot_content mar_b">
                  <p>Total Orders Orders</p>
                </div>
                <div class="robot_content mar_b">
                  <p>
                    <span>{item?.totalorders}</span>
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="robot_content mar_b">
                  <p>Completed Orders</p>
                </div>
                <div class="robot_content mar_b">
                  <p>
                    <span>{item?.count?.completed}</span>
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="robot_content mar_b">
                  <p>Canceled Orders</p>
                </div>
                <div class="robot_content mar_b">
                  <p>
                    <span>{item?.count?.failed}</span>
                  </p>
                </div>
              </div>
              {/* <div class="d-flex justify-content-between">
                <div class="robot_content mar_b">
                  <p>Pending Orders</p>
                </div>
                <div class="robot_content mar_b">
                  <p>
                    <span>05</span>
                  </p>
                </div>
              </div> */}
            </div>
            {/* <div class="robot-order-det mb-3"> */}
          </div>
        );
      })}
    </div>
  );
};
export default RestaurantOrderDetailScreen;
