import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryList from "../components/CategoryList/CategoryList";
import CusineList from "../components/CategoryList/CusineList";
import BranchTableList from "../components/RobotManagement/BranchTableList";
import TopHeader from "../components/TopHeader";
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useLazyTableViewAdminQuery,
  useTableAdminCreateMutation,
  useTableEditAdminMutation,
} from "../constants/redux/api/api";

const BranchTableListScreen = ({ menuOpen }) => {
  const [listShow, setListShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const type= location?.state?.type;
  const [tableName, setTableName] = useState("");
  const [tableNameErr, setTableNameErr] = useState(false);
  const [tableID, setTableID] = useState("");
  const [tableIDErr, setTableIDErr] = useState(false);

  console.log("data", data);

  // RTK QUERY
  const [tableAddApi] = useTableAdminCreateMutation();
  const [tableViewApi] = useLazyTableViewAdminQuery();
  const [tableEditApi] = useTableEditAdminMutation();

  const getList = () => {
    tableViewApi(location?.state?.item?._id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setTableName(res?.data?.table_name);
          setTableID(res?.data?.code)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.type == "edit"||location?.state?.type == "view") {
      getList();
    }
  }, []);

  const submitHandler = () => {
    if (tableName?.length == 0 || tableID?.length == 0) {
      setTableNameErr(true);
      setTableIDErr(true);
    } else {
      let urlencoded = new URLSearchParams();

      urlencoded.append("company_id", data?.company_info?.company_id);
      urlencoded.append("company_branch_id", data?.company_branch_id);
      urlencoded.append("code", tableID);
      urlencoded.append("table_name", tableName);

      if (location?.state?.type == "edit") {
        tableEditApi({ id: location?.state?.item?._id, payload: urlencoded })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              navigate(-1);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        tableAddApi(urlencoded)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              navigate(-1);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader
        type={
          location?.state?.type == "edit"
            ? "admin-edit-table"
            : "admin-add-table"
        }
      />
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter company name"
                  value={data?.company_info?.company_name}
                  disabled
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Branch Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter branch name"
                  value={data?.company_branch_info?.name}
                  disabled
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Table ID</label>
                <input
                 disabled={type=="view"?true:false}
                  type="text"
                  className="form-control"
                  placeholder="Enter table ID"
                  onChange={(e) => setTableID(e.target.value)}
                  value={tableID}
                />
                {tableID?.length == 0 && tableIDErr && (
                  <p className="err-state">Enter Table ID</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label for="">Table Name</label>
                <input
                  disabled={type=="view"?true:false}
                  type="text"
                  className="form-control"
                  placeholder="Enter table name"
                  onChange={(e) => setTableName(e.target.value)}
                  value={tableName}
                />
                {tableName?.length == 0 && tableNameErr && (
                  <p className="err-state">Enter Table Name</p>
                )}
              </div>
             {type!="view"&&<div className="px-md-3 pe-3">
                <button className="btn-submit" onClick={submitHandler}>
                  Submit
                </button>
              </div>}
              {type!="view"&&<div
                onClick={() => navigate(-1)}
                className="btn-outline2 cp ac-jc d-flex"
              >
                <p className="mb-0">Back</p>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BranchTableListScreen;
