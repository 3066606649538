import React, { useEffect, useState } from "react";
import { useLoginMutation } from "../constants/redux/api/api";
import { useNavigate } from "react-router-dom";
import "react-simple-keyboard/build/css/index.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { toast } from "react-toastify";
import useToken from "../constants/Storage/useToken";
import useUser from "../constants/Storage/userData";
import usePermission from "../constants/Storage/userPermission";
import { logo } from "../assets/images";

const LoginScreen = () => {
  const navigate = useNavigate();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const { permission, setPermisson } = usePermission();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);

  const [usernameErr, setUsernameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  useEffect(()=>{
    if(localStorage?.getItem("token")){
      navigate("/table-list");
    }
  },[])

  //   RTK QUERY
  const [login] = useLoginMutation();

  const onChange = (e) => {
    // setInput(input);
    setUsername(e.target.value);
  };
  const onChanges = (e) => {
    setPassword(e.target.value);
  };

  const passwordTypeHandle = () => {
    showPass ? setShowPass(false) : setShowPass(true);
    if (!showPass) {
      document.getElementById("login_password").type = "text";
    } else {
      document.getElementById("login_password").type = "password";
    }
  };

  const onSubmit = () => {
    if (username === "" || password === "") {
      setUsernameErr(true);
      setPasswordErr(true);
    } else {
      const data = {
        email: username,
        password: password,
      };
      login(data)
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            toast.success("Login Successfully");
            // console.log("ad", res);
            localStorage.setItem("type", 1);
            localStorage.setItem("token", res?.token);
            setUser(res?.userdata);
            setPermisson(res?.permissions);
            if (res?.user?.robots_id) {
              navigate("/welcome");
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              navigate("/table-list");
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          }
        })
        .catch((err) => {
          if (err?.status === 400 && err?.data?.status === "failed") {
            toast.error(err?.data?.message);
            setUsername("");
            setPassword("");
            setUsernameErr(false);
            setPasswordErr(false);
          }
          console.log(err);
        });
    }
  };

  return (
    <div className="login">
      <div className="d-flex ac-jc flex-wrap">
        <div className=" col-md-6" >
          <div className="ad_bg_img">
            <div className="left-div">
              <div>
                <img src={logo} className="ad_logo " />
                <h1 className="">WE CREATE THE FUTURE</h1>
                <h6>ROBOTICS & TECHNOLOGY</h6>
              </div>
            </div>
          </div>
          </div>
          <div className=" col-md-6 right_log">
            <h1>Login</h1>
            <p>Welcome back! Login to access your account</p>
            <div className="form-control form-cl p-0">
              <input
                className="w-100 h-100"
                placeholder="Enter your username"
                type="text"
                name="username"
                value={username}
                onChange={onChange}
              />
              {usernameErr && username === "" && (
                <p className="text-danger">Please Enter User Name</p>
              )}
            </div>
            <div className="form-control form-cl p-0 position-relative">
              <input
                className="w-100 h-100"
                placeholder="Enter your password"
                type="password"
                name="password"
                value={password}
                onChange={onChanges}
                id="login_password"
              />
              {showPass ? (
                <VisibilityOutlinedIcon
                  onClick={(e) => {
                    passwordTypeHandle();
                  }}
                  className="eye-password position-absolute"
                />
              ) : (
                <VisibilityOffIcon
                  onClick={(e) => {
                    passwordTypeHandle();
                  }}
                  className="eye-password position-absolute"
                />
              )}
              {passwordErr && password === "" && (
                <p className="text-danger">Please Enter password</p>
              )}
            </div>

            <div onClick={onSubmit} className="login_btn text-center">
              <button className="bt_sub">Continue</button>
            </div>
            {/* <div className="form">
            <div>
              {!board && (
                <button
                  className="form-control form-cl text-start"
                  onClick={() => {
                    setBoard(true);
                    setBoards(false);
                  }}
                >
                  {username ? username : "Enter your username"}
                </button>
              )}
              {board && (
                <input
                  className="form-control form-cl"
                  placeholder="Enter your username"
                  type="text"
                  name="username"
                  value={username}
                />
              )}
            </div>
            <div>
              {!boards && (
                <button
                  className="form-control form-cl text-start"
                  onClick={() => {
                    setBoards(true);
                    setBoard(false);
                  }}
                >
                  {password
                    ? passwords?.map((item) => item)
                    : "Enter your password"}
                </button>
              )}
              {boards && (
                <input
                  className="form-control form-cl"
                  placeholder="Enter your password"
                  type="password"
                  name="password"
                  value={password}
                />
              )}
            </div>
            <div onClick={onSubmit} className="login_btn text-center">
              <button className="bt_sub">Continue</button>
            </div>

            <div className="bt_center text-center">
              <p>
                New to Team Robotics? <button>Create account</button>
              </p>
            </div>
          </div> */}
          </div>
          
          </div>
          {/* {board && (
          <div
            className="key_board"
            style={{
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "100%",
            }}
          >
            <div
              style={{
                float: "right",
                border: "1px solid black",
                borderRadius: "100%",
                width: "30px",
                height: "28px",
              }}
            >
              <p style={{ marginLeft: "10px" }} onClick={() => setBoard(false)}>
                x
              </p>
            </div>
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              layoutName={layout}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
        {boards && (
          <div
            className="key_board"
            style={{
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "100%",
            }}
          >
            <div
              style={{
                float: "right",
                border: "1px solid black",
                borderRadius: "100%",
                width: "30px",
                height: "28px",
              }}
            >
              <p
                style={{ marginLeft: "10px" }}
                onClick={() => setBoards(false)}
              >
                x
              </p>
            </div>
            <Keyboard
              keyboardRef={(r) => (keyboards.current = r)}
              layoutName={layouts}
              onChange={onChanges}
              onKeyPress={onKeyPress2}
            />
          </div>
        )}
        {/* <Modal show={boards} onHide={() => setBoards(false)}>
          <Keyboard
            keyboardRef={(r) => (keyboards.current = r)}
            layoutName={layouts}
            onChange={onChanges}
            onKeyPress={onKeyPress2}
          />
        </Modal> */}
      
      
    </div>
  );
};

export default LoginScreen;
