import React from "react";
import ScreenHeader from "../../components/ScreenHeader";
import { menu_section_left } from "../../assets/images";
import UserList from "../screens/UserList";
import TopHeader from "../../components/TopHeader";
import { toast } from "react-toastify";


const RestaurantAdminScreen = ({ menuOpen }) => {
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type="userList"/>
      <ScreenHeader
        title="User List"
        menuLink="/restaurant/add-users"
        symbol={"+"}
        menuBtn={["Add User"]}
        menuTitle="Check out your Admin"
        description="You can add your admin from here"
        img={menu_section_left}
      />
      <UserList />
    </div>
  );
};

export default RestaurantAdminScreen;
