import React, { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";
import {
  useCategoriesQuery,
  useStatusChangeMutation,
} from "../constants/redux/api/api";

const CategoryList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [allCategData, setAllCategData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  // RTK QUERY
  const { data: categoryData, isSuccess: isCategSuccess } =
    useCategoriesQuery();
  const [statusChange] = useStatusChangeMutation();

  useEffect(() => {
    if (isCategSuccess) {
      console.log(categoryData?.result);
      setAllCategData(categoryData?.result);
    }
  }, [categoryData, isCategSuccess]);

  useEffect(() => {
    let tmp = [];
    allCategData?.forEach((el) => {
      tmp.push({
        categoryName: el?.categoryName,
        categoryNameId: el?.categoryNameId,
        categoryimage: el?.categoryimage,
        categoryActivity: el?.categoryActivity,
      });
    });
    setAllCategories(tmp);
  }, [allCategData]);

  console.log(allCategories);

  const onActiveClick = (item) => {
    console.log({
      categoryNameId: item?.categoryNameId,
      categoryItemsId: item?.id,
    });
    const formData = new FormData();
    formData.append("categoryNameId", item?.categoryNameId);
    formData.append("categoryItemsId", item?.id);
    statusChange({
      categoryNameId: item?.categoryNameId,
      //   categoryItemsId: item?.id,
    })
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          console.log(res);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}>
      <div className="menu-list">
        <h1>Category List</h1>
      </div>
      {/* table start */}
      <div className="table-responsive menu-table side_pad">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Category</th>
              <th>Category Image</th>
              <th>Status</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {allCategories?.map((item, ind) => {
              return (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{item?.categoryName}</td>
                  <td>
                    <img
                      src={item?.categoryimage}
                      // src={food}
                      alt="food"
                    />
                  </td>
                  <td>
                    <button
                      onClick={() => onActiveClick(item)}
                      className={`${
                        item?.categoryActivity == 1
                          ? "btn-success"
                          : "btn-danger"
                      } btn`}>
                      {item?.categoryActivity == 1 ? "Active" : "De-Active"}
                    </button>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/add-category", {
                                state: { type: "edit", data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary">
                            <CreateIcon />
                          </button>
                          {/* <button
                            onClick={() => onClickDelete(item)}
                            type="button"
                            className="btn btn-secondary">
                            <CloseOutlinedIcon />
                          </button> */}
                        </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* table end */}
      {/* {deletePopup && (
        <ConfirmDeletePopup
          onConfirmClick={confirmDeleteClick}
          onCancelClick={() => setDeletePopup(false)}
        />
      )} */}
    </div>
  );
};

export default CategoryList;
