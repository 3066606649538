import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryList from "../components/CompanyView/CategoryList";
import CusineList from "../components/CompanyView/CusineList";
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
} from "../constants/redux/api/api";

const CompanyAddCategory = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [image, setImage] = useState({});
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;
  const [nameErr, setNameErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectTab, setSelectTab] = useState(1);

  const [createCategory] = useCreateCategoryMutation();
  const [editCategory] = useEditCategoryMutation();

  const onChangeImage = (e) => {
    setImage(e.target.files[0]);
  };

  const onSubmit = () => {
    if (name === "" || image?.size === undefined) {
      setNameErr(true);
      setImageErr(true);
    } else {
      setDisableBtn(true);
      const formData = new FormData();
      formData.append("category_name", name);
      formData.append("image", image);
      createCategory(formData)
        .unwrap()
        .then((res) => {
          setDisableBtn(false);
          if (res?.status === "Success") {
            console.log(res);
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableBtn(false);
        });
    }
  };

  const onEditSubmit = () => {
    if (name === "") {
      setNameErr(true);
      setImageErr(true);
    } else {
      setDisableBtn(true);
      const formData = new FormData();
      formData.append("categoryName", name);
      formData.append("categoryNameId", selectedCat);
      if (image?.size) {
        formData.append("image", image);
      }
      editCategory(formData)
        .unwrap()
        .then((res) => {
          setDisableBtn(false);
          if (res?.status === "Success") {
            console.log(res);
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableBtn(false);
        });
    }
  };

  useEffect(() => {
    if (type === "edit") {
      console.log(editData);
      const fileNameTmp = editData?.categoryimage?.split("/").pop();
      setFileName(fileNameTmp);
      setName(editData?.categoryName);
      setSelectedCat(editData?.categoryNameId);
    }
  }, [type, location, editData]);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list">
        {/* <h1>Add Category</h1> */}
        <div className="tab_cont mb-4">
          <div className="position-relative">
            <button
              onClick={() => setSelectTab(1)}
              className={` ${
                selectTab === 1 ? "select_tab" : "unselect_tab"
              } cust-btn menu-list`}
            >
              <h1 className={`${selectTab === 1 ? "text-light" : ""} mb-1`}>
                Add Category
              </h1>
            </button>
            <div className="underline position-absolute" />
          </div>
          <div className="position-relative">
            <button
              onClick={() => setSelectTab(2)}
              className={` ${
                selectTab === 2 ? "select_tab" : "unselect_tab"
              } cust-btn menu-list`}
            >
              <h1 className={`${selectTab === 2 ? "text-light" : ""} mb-1`}>
                Cusine type
              </h1>
            </button>
            <div className="underline position-absolute" />
          </div>
        </div>
      </div>
      {selectTab === 1 && (
        <div className="side_pad">
          <div className="add-food-section">
            <div action="">
              <div className="d-flex flex-wrap">
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label for="">Category Name</label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter category name"
                  />
                  {name === "" && nameErr && (
                    <p className="err-state">Enter category name*</p>
                  )}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label for="">Category Image</label>
                  <input
                    onChange={onChangeImage}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    className="form-control"
                  />
                  {type === "edit" && <p>Current File: {fileName}</p>}
                  {image?.size === undefined && imageErr && (
                    <p className="err-state">Enter category image*</p>
                  )}
                </div>
                <div
                  onClick={
                    disableBtn
                      ? null
                      : type === "edit"
                      ? onEditSubmit
                      : onSubmit
                  }
                  className="px-md-3 pe-3"
                >
                  <input className="btn-submit" type="submit" />
                </div>
                <div onClick={() => setSelectTab(2)} class="btn-outline">
                  <p style={{ margin: 0 }}>Next</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectTab === 2 && (
        <div className="side_pad">
          <div className="add-food-section">
            <div action="">
              <div className="d-flex flex-wrap">
                <div className="col-md-12 col-12 px-md-3 px-0 position-relative mb-3">
                  <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                    <label for="">Enter Cusine</label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Enter Cusine name"
                    />
                    {name === "" && nameErr && (
                      <p className="err-state">Enter category name*</p>
                    )}
                  </div>
                </div>
                <div
                  onClick={
                    disableBtn
                      ? null
                      : type === "edit"
                      ? onEditSubmit
                      : onSubmit
                  }
                  className="px-md-3 pe-3"
                >
                  <input className="btn-submit" type="submit" />
                </div>
                <div onClick={() => navigate(-1)} class="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectTab === 1 && (
        <div className="menu-list mt-4">
          <h1>Category List</h1>
          <CategoryList />
        </div>
      )}
      {selectTab === 2 && (
        <div className="menu-list mt-4">
          <h1>Cusine List</h1>
          <CusineList />
        </div>
      )}
    </div>
  );
};

export default CompanyAddCategory;
