import React from "react";
import AddButton from "./AddButton";
import { biryaniCategory } from "../assets/img";

const Category = ({ item, className, onCategoryClick }) => {
  return (
    <>
      <div
        onClick={() => onCategoryClick(item?.categoryNameId)}
        className={`category ${className}`}
      >
        {/* <img src={biryaniCategory} alt="category" /> */}
        <img src={item?.categoryimage} alt="category" />
        <h3>{item?.categoryName}</h3>
      </div>
    </>
  );
};

export default Category;
