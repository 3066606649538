import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BTable from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
// import {
//   useLazyList_company_robotQuery,
//   useDelete_company_robotMutation,
//   useLazyCompanyRobotsStatusChangeQuery,
// } from "../../constants/redux/api/api";
import moment from "moment";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDelete_company_robotMutation, useLazyCompanyRobotsStatusChangeQuery, useLazyHilist_company_robotQuery } from "../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="d-flex ac-jb w-50 mx-5 mb-5">
        <select className="dropdownSelect ">
          {/* <option>
          select
        </option> */}
          <option>
            Company
          </option>
          <option>
            Users
          </option>
        </select>
        <select className="dropdownSelect">
          {/* <option>
          select
        </option> */}
          <option>
            Branch
          </option>
          <option>
            Users
          </option>
        </select>

      </div>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end " size="lg" >
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              size="lg"
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const HiBranchList = () => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [company_list] = useLazyHilist_company_robotQuery();
  const [companyStatusChangeApi] = useLazyCompanyRobotsStatusChangeQuery();

  const getList = () => {
    company_list()
      .unwrap()
      .then((res) => {
        setData([...res?.data].reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);
  const [delete_company_robot] = useDelete_company_robotMutation();
  const companyDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_company_robot(id)
          .unwrap()
          .then((res) => {
            // console.log(res, "res140");
            getList();
          })
          .catch((err) => {
            console.log(err.message, "errMsg142");
          });
      }
    });
  };

  const handleStatusChange = (id) => {
    companyStatusChangeApi(id)
      .then((res) => {
        if (res?.data?.status == "success") {
          getList();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      // {
      //   Header: "Robot Name",
      //   accessor: "company_info",
      //   Cell: ({ value, row }) => {
      //     return <p>{value ? value.company_id : ""}</p>;
      //   },
      // },
      {
        Header: "Company Name",
        accessor: "company_info",
        Cell: ({ value, row }) => {
          console.log(value,"value")
          return <p>{value ? value.company_name : ""}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "branches",
        Cell: ({ value, row }) => {
          return <p> 
            {value ? value.name : ""}
            </p>;
        },
      },
      {
        Header: "Robot Types",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>
            Hi Robot
            {/* {value ? value.name : ""} */}
          </p>;
        },
      },
      {
        Header: "Robot Count",
        accessor: "robot_count",
        Cell: ({ value, row }) => {
          return <p> 
            {value}
            </p>;
        },
      },
      {
        Header: "Date of Purchase",
        accessor: "manufacturing_date",
        Cell: ({ value, row }) => {
          return <p>{value ? moment(value).format("DD-MM-YYYY") : ""}</p>;
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <button
      //         className={`${value == 1 ? "bg-success" : "bg-danger"
      //           } px-2 py-1 rounded-1 border-0 text-white`}
      //         onClick={() => handleStatusChange(row.original._id)}
      //       >
      //         {value == 1 ? "Active" : "Deactive"}
      //       </button>
      //     );
      //   },
      // },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() =>
                  navigate("/add-hi-Robot-branch", {
                    state: { type: "edit", data: row.original },
                  })
                }
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button> 
              {/* <button
                className="bg-warning border-0 rounded-1 p-1"
                onClick={() => companyDelete(row?.original?._id)}
              >
                <Delete />
              </button> */}
              <button
                onClick={() =>
                  navigate("/add-hi-Robot-branch", {
                    state: { type: "view", data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="table-responsive menu-table side_pad">
        <Table columns={columns} data={data} className="" />
      </div>
    </>
  );
};  

export default HiBranchList;
