import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const RestaurantRobotOrderList = () => {
  const [status, setStatus] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <div class="menu-list d-flex ac-jb w-100">
        <div class="w-100">
          <h1>Robot Orders</h1>
        </div>
        <div class="w-50 position-relative">
          <div class="add-food-section bg-transparent">
            <input
              type="text"
              name="location"
              class="form-control ps-5"
              placeholder="Search..."
            />
          </div>
          <div style={{ position: "absolute", top: "30px", left: "34px" }}>
            <svg
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
              focusable="false"
               aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="SearchIcon"
            >
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="table-responsive menu-table side_pad mt-4">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Order Date</th>
              <th>Time</th>
              {/* <th>Amount</th> */}
              <th>Branch Name</th>
              <th>Table Name</th>
              {/* <th>Robot Name</th> */}
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="">
            <tr>
              <td>#001</td>
              <td>10-01-2023</td>
              <td>12:13 PM</td>
              {/* <td>&#8377; 17888</td> */}
              <td>Branch 3</td>
              <td>#34</td>
              {/* <td>Citty</td> */}
              <td>
                {status == 1 && (
                  <button
                    // onClick={() => setStatus((prev) => !prev)}
                    onClick={() => setStatus(2)}
                    className={`${
                      status ? "" : ""
                    } px-2 py-1 rounded-1 border-0 text-white bg-success`}
                  >
                    Order Deliver
                  </button>
                )}
                {status == 2 && (
                  <button
                    onClick={() => setStatus(3)}
                    className={`${
                      status ? "" : ""
                    } px-2 py-1 rounded-1 bg-warning border-0 text-white`}
                  >
                    Order Tacken
                  </button>
                )}
                {status == 3 && (
                  <button
                    onClick={() => setStatus(1)}
                    className={`${status ? "" : ""} 
                    bg-danger px-2 py-1 rounded-1 border-0 text-white`}
                  >
                    Order Cancel
                  </button>
                )}
              </td>
              <td>
                <div className="btn-group">
                  <td className="text-center">
                    <div className="btn-group">
                      <button
                        onClick={() => navigate("/restaurant/view-robot-order")}
                        type="button"
                        className="btn btn-secondary px-4"
                      >
                        <VisibilityIcon />
                      </button>
                    </div>
                  </td>
                </div>
              </td>
            </tr>
            <tr>
              <td>#003</td>
              <td>10-01-2102</td>
              <td>12:00 AM</td>
              {/* <td>&#8377; 100026</td> */}
              <td>Branch 6</td>
              <td>#44</td>
              {/* <td>Citty 2</td> */}
              {/* <td>
                <button
                  onClick={() => setStatus((prev) => !prev)}
                  className={`${
                    status ? "bg-success" : "bg-danger"
                  } px-2 py-1 rounded-1 border-0 text-white`}>
                  {status ? "Order Deliver" : "Order Tacken"}
                </button>
              </td> */}
              <td>
                {status == 1 && (
                  <button
                    // onClick={() => setStatus((prev) => !prev)}
                    onClick={() => setStatus(2)}
                    className={`${
                      status ? "" : ""
                    } px-2 py-1 rounded-1 border-0 text-white bg-success`}
                  >
                    Order Deliver
                  </button>
                )}
                {status == 2 && (
                  <button
                    onClick={() => setStatus(3)}
                    className={`${
                      status ? "" : ""
                    } px-2 py-1 rounded-1 bg-warning border-0 text-white`}
                  >
                    Order Tacken
                  </button>
                )}
                {status == 3 && (
                  <button
                    onClick={() => setStatus(1)}
                    className={`${status ? "" : ""} 
                    bg-danger px-2 py-1 rounded-1 border-0 text-white`}
                  >
                    Order Cancel
                  </button>
                )}
              </td>
              <td>
                <div className="btn-group">
                  <button
                    onClick={() => navigate("/restaurant/view-robot-order")}
                    type="button"
                    className="btn btn-secondary px-4"
                  >
                    <VisibilityIcon />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RestaurantRobotOrderList;
