import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useState } from "react";
import { useRobot_management_viewMutation } from "../constants/redux/api/api";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
const   OrderDetailScreen = ({ menuOpen }) => {
  const location=useLocation();
  const data=location?.state?.data.robot_id;
  const company =location?.state?.companyId;
  const [datas,setdata]=useState([]);
  const[startdate,setStartdate]=useState("")
  const[todate,setTodate]=useState("")
  const[fiterdata,setfilterdata]=useState([])
  const [robotviewApi]=useRobot_management_viewMutation();
  
  const robotList = () => {
    const formdata={
      "robot_id":data,
      "company_id":company
    }
    robotviewApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          console.log(res,"res")
          setdata(res?.data)
          setfilterdata(res?.data)
          // let temp = res?.data?.filter((i) => i?.status == true);
          // setCompanyManagementList(temp.reverse());
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const filter=()=>{
    
    const filtered = fiterdata.filter(item => {
      const itemDate = moment(item?.date).format("DD-MM-YYYY");
      return itemDate >=moment (startdate).format("DD-MM-YYYY") && itemDate <= moment (todate).format("DD-MM-YYYY")
    });
    setdata(filtered);
    console.log(filtered,"tt" )
  
}
  useEffect(()=>{
    if(data&&company){
       robotList()
    }
  },[])
  useEffect(()=>{
    if(startdate&&todate){
  
      filter()
    }
  },[startdate,todate])
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list mb-0">
        <div className="">
          <h1>Order Details</h1>
        </div>
        {/* <div className="d-flex ac-jb w-100 ps-2 table-responsive menu-table side_pad align-items-center"> */}
      </div>
      
      <div className="side_pad">
          <div className="add-food-section">
            <div action="">
              <div className="d-flex flex-wrap">
                <div className="col-md-4 col-12 px-md-3 px-0 mb-3">
                  <label className="mb-2" htmlFor="">
                    Start Date
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Start Date"
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    className="form-control"
                    value={startdate}
                    onChange={e => setStartdate(e.target.value)}
                  />
                </div>
                <div className="col-md-4 col-12 px-md-3 px-0 mb-3">
                  <label className="mb-2" htmlFor="">
                    End Date
                  </label>
                  <input
                    type="text"
                    placeholder="Enter End Date"
                    onBlur={(e) => {
                      e.target.type = "text";
                    }}
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    className="form-control"
                    value={todate}
                    onChange={e => setTodate(e.target.value)}
                  />
                </div>
              
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
      
      {datas?.length>0?(datas.map((item,idx )=>(
      
      <div className="side_pad">
         <div class="robot-order-det mb-3">
           <div class="d-flex flex-wrap justify-content-between ">
             <div class="robot_date">
               <h5 className="mb-0">
                 Date : <span>{
                 //  date=new Date(item?.company_robot_info?.date)
                 //  date.toLocaleDateString()
                 moment(item?.date).format("DD-MM-YYYY")
                   }</span>
               </h5>
             </div>
           </div>
           <div class="d-flex flex-wrap justify-content-between pt-3">
             <div class="robot_name">
               <h5>
                 Robot Name : <span>{item?.company_robot_info?.robot_name}</span>
               </h5>
             </div>
             <div class="robot_name mar_b">
               <h5>
                 Robot : <span>{item?.company_robot_info?.robot_name}</span>
               </h5>
             </div>
           </div>
           <div class="d-flex justify-content-between">
             <div class="robot_content mar_b">
               <p>
                 ID : <span>{item?.company_robot_info?.code}</span>
               </p>
             </div>
             <div class="robot_content mar_b">
               <p>
                 Map Location : <span>Map ID</span>{" "}
               </p>
             </div>
           </div>
           <div class="d-flex justify-content-between">
             <div class="robot_content mar_b">
               <p>Total Orders Orders</p>
             </div>
             <div class="robot_content mar_b">
               <p>
                 <span>{item?.totalorders}</span>
               </p>
             </div>
           </div>
           <div class="d-flex justify-content-between">
             <div class="robot_content mar_b">
               <p>Completed Orders</p>
             </div>
             <div class="robot_content mar_b">
               <p>
                 <span>{item?.count?.completed}</span>
               </p>
             </div>
           </div>
           <div class="d-flex justify-content-between">
             <div class="robot_content mar_b">
               <p>Canceled Orders</p>
             </div>
             <div class="robot_content mar_b">
               <p>
                 <span>{item?.count?.failed}</span>
               </p>
             </div>
           </div>
           {/* <div class="d-flex justify-content-between"> */}
             {/* <div class="robot_content mar_b"> */}
               {/* <p>Pending Orders</p> */}
             {/* </div> */}
             {/* <div class="robot_content mar_b"> */}
               {/* <p> */}
                 {/* <span>05</span> */}
               {/* </p> */}
             {/* </div> */}
           {/* </div> */}
         </div>
         {/* <div class="robot-order-det mb-3"> */}
           
       </div>))):"No Data"}
     
    </div>
  );
};
export default OrderDetailScreen;
