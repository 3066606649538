import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, URL } from "./constants";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const currentUrl = window.location.href;
      if (
        localStorage.getItem("resturent_token") &&
        currentUrl.includes("restaurant")
      ) {
        headers.set(
          "Authorization",
          "Bearer " + localStorage.getItem("resturent_token")
        );
      } else {
        headers.set("Authorization", "Bearer " + localStorage.getItem("token"));
      }

      //  headers.set("Content-Type", "application/json");
      // headers.set("Accept", "multipart/form-data");
      // headers.set("Content-Type", "multipart/form-data");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["profileData", "menuList", "tableList"],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),

    // LOGOUT
    logout: builder.mutation({
      query: (payload) => ({
        url: URL.LOGOUT,
        method: "POST",
        body: payload,
      }),
    }),

    // NEW_PASSWORD
    new_password: builder.mutation({
      query: () => ({
        url: URL.NEW_PASSWORD,
        method: "POST",
      }),
    }),

    //  FORGOT_PASSWORD
    forgot_password: builder.mutation({
      query: () => ({
        url: URL.FORGOT_PASSWORD,
        method: "POST",
      }),
    }),

    // ADMIN_ADD
    admin_add: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // Admin List
    adminList: builder.mutation({
      query: () => ({
        url: URL.ADMIN_LIST,
        method: "GET",
      }),
    }),

    //  ADMIN_STATUS
    admin_status: builder.mutation({
      query: (id) => ({
        url: URL.ADMIN_STATUS + id,
        method: "GET",
      }),
    }),

    //  ADMIN_UPDATE
    admin_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.ADMIN_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),

    // ADMIN_VIEW
    admin_view: builder.mutation({
      query: (id) => ({
        url: URL.ADMIN_VIEW + id,
        method: "GET",
      }),
    }),

    // ADMIN DELETE
    admin_delete: builder.mutation({
      query: (id) => ({
        url: URL.ADMIN_DELETE + id,
        method: "DELETE",
      }),
    }),

    //  ROLE_LIST
    role_list: builder.mutation({
      query: () => ({
        url: URL.ROLE_LIST,
        method: "GET",
      }),
    }),

    // ROLE_STATUS
    role_status: builder.mutation({
      query: (id) => ({
        url: URL.ROLE_STATUS + id,
        method: "POST",
      }),
    }),
    //ADD_CATEGORY_STATUS
    create_add_category_status: builder.mutation({
      query: (id) => ({
        url: `${URL.ADD_CATEGORY_STATUS}/${id}`,
        method: "GET",
      }),
    }),
    //CUSINE_UPDATE_ROBOT
    cusine_update_robot: builder.mutation({
      query: ({ payload, id }) => ({
        url: `${URL.UPDATE_CUSINE_ROBOT}/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    // ROLE_ADD
    role_add: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    //  LEAD_LIST
    lead_list: builder.mutation({
      query: () => ({
        url: URL.LEAD_LIST,
        method: "GET",
      }),
    }),

    // LEAD_STATUS
    lead_status: builder.mutation({
      query: (id) => ({
        url: URL.LEAD_STATUS + id,
        method: "POST",
      }),
    }),
    //CUSINE_STATUS
    cusineStatus: builder.mutation({
      query: (id) => ({
        url: `${URL.CUSINE_STATUS}/${id}`,
        method: "POST",
      }),
    }),
    // LEAD_ADD
    lead_add: builder.mutation({
      query: (payload) => ({
        url: URL.LEAD_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // LEAD UPDATE
    lead_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.LEAD_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),

    //  ROBOTS_LIST
    robots_list: builder.mutation({
      query: () => ({
        url: URL.ROBOTS_LIST,
        method: "GET",
      }),
    }),
    //ROBOT_LIST_CATEGORY_COMPANY
    robot_list_company: builder.query({
      query: () => ({
        url: URL.ROBOT_LIST_COMPANY,
        method: "GET",
      }),
    }),
    // ROBOTS_STATUS
    robots_status: builder.query({
      query: (id) => ({
        url: URL.ROBOTS_STATUS + id,
        method: "GET",
      }),
    }),

    // ROBOT_ADD
    robot_add: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOT_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // COMPANY_MANAGEMENT_LIST
    company_management_list: builder.mutation({
      query: () => ({
        url: URL.COMPANY_MANAGEMENT_LIST,
        method: "GET",
      }),
    }),

    // COMPANY_MANAGEMENT_ADD
    company_management_add: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_MANAGEMENT_ADD,
        method: "POST",
        body: payload,
        // headers: {
        //   Accept: "multipart/form-data",
        //   "Content-Type": "multipart/form-data",
        // },
      }),
    }),

    // COMPANY_MANAGEMENT_STATUS
    company_management_status: builder.query({
      query: (id) => ({
        url: URL.COMPANY_MANAGEMENT_STATUS + id,
        method: "PUT",
      }),
    }),

    // COMPANY_MANAGEMENT_UPDATE
    company_management_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.COMPANY_MANAGEMENT_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),

    // COMPANY_MANAGEMENT_VIEW
    company_management_view: builder.query({
      query: (id) => ({
        url: URL.COMPANY_MANAGEMENT_VIEW + id,
        method: "GET",
      }),
    }),

    // ROBOT_ORDER_LIST
    robot_order_list: builder.mutation({
      query: () => ({
        url: URL.ROBOT_ORDER_LIST,
        method: "GET",
      }),
    }),
    // ROBOT_ORDER_ADD
    robot_order_add: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOT_ORDER_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // ROBOT_ORDER_STATUS
    robot_order_status: builder.mutation({
      query: (id) => ({
        url: URL.ROBOT_ORDER_STATUS + id,
        method: "GET",
      }),
    }),

    // ROBOT_ORDER_VIEW
    robot_order_view: builder.query({
      query: (id) => ({
        url: URL.ROBOT_ORDER_VIEW + id,
        method: "GET",
      }),
    }),

    // ROBOT_UPDATE
    robots_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.ROBOT_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),

    // ROBOT_EDIT
    robotsEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.ROBOT_EDIT + id,
        method: "PUT",
        body: payload,
      }),
    }),

    // COMPANY_ROBOT_ADD
    company_robot_add: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_ROBOT_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // MENU MANAGEMENT LIST
    menu_management_list: builder.mutation({
      query: () => ({
        url: URL.MENU_MANAGEMENT_LIST,
        method: "GET",
      }),
    }),

    // MENU MANAGEMENT ADD
    menu_management_add: builder.mutation({
      query: (payload) => ({
        url: URL.MENU_MANAGEMENT_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    //  MENU MANAGEMENT CATEGORY EDIT
    menu_management_category_edit: builder.mutation({
      query: (payload) => ({
        url: URL.MENU_MANAGEMENT_CATEGORY_EDIT,
        method: "POST",
        body: payload,
      }),
    }),

    //  MENU MANAGEMENT DELETE
    menu_management_delete: builder.mutation({
      query: (payload) => ({
        url: URL.MENU_MANAGEMENT_DELETE,
        method: "POST",
        body: payload,
      }),
    }),

    // MENU_MANAGEMENT_ADD_CATEGORY

    menu_management_add_category: builder.mutation({
      query: (payload) => ({
        url: URL.MENU_MANAGEMENT_ADD_CATEGORY,
        method: "POST",
        body: payload,
      }),
    }),

    // MENU MANAGEMENT CATEGORY STATUS
    menu_management_category_status: builder.mutation({
      query: (id) => ({
        url: URL.MENU_MANAGEMENT_CATEGORY_STATUS + id,
        method: "POST",
      }),
    }),

    // COMPANY_USER_VIEW
    company_user_view: builder.mutation({
      query: (id) => ({
        url: URL.COMPANY_USER_VIEW + id,
        method: "GET",
      }),
    }),

    // COMPANY_USER_ADD
    company_user_add: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_USER_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // COMPANY_USER_LIST
    company_user_list: builder.mutation({
      query: () => ({
        url: URL.COMPANY_USER_LIST,
        method: "GET",
      }),
    }),

    // ROLE MODULE
    roles_modules: builder.query({
      query: () => URL.ROLE_MODULES,
    }),

    // ROLE VIEW
    role_view: builder.query({
      query: (id) => URL.ROLE_VIEW + id,
    }),

    // ROLE UPDATE
    role_update: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROLE_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),

    //  LEAD_NEW_STATUS
    lead_new_status: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.lEADSTATUS + id,
        method: "PUT",
        body: payload,
      }),
    }),

    // LEAD VIEW
    lead_view: builder.query({
      query: (id) => URL.LEAD_VIEW + id,
    }),

    // ROLE DELETE
    role_delete: builder.mutation({
      query: (id) => ({
        url: URL.ROLE_DELETE + id,
        method: "DELETE",
      }),
    }),

    // LEAD DELETE
    lead_delete: builder.mutation({
      query: (id) => ({
        url: URL.LEAD_DELETE + id,
        method: "DELETE",
      }),
    }),

    // ROBOTS VIEW
    robots_view: builder.query({
      query: (id) => URL.ROBOTS_VIEW + id,
    }),

    // ROBOT DELETE
    robo_delete: builder.mutation({
      query: (id) => ({
        url: URL.ROBOTS_DELETE + id,
        method: "DELETE",
      }),
    }),

    // ROLE DELETE
    company_management_delete: builder.mutation({
      query: (id) => ({
        url: URL.COMPANY_MANAGEMENT_DELETE + id,
        method: "DELETE",
      }),
    }),

    // ROBOTS ORDER DELETE
    robot_order_delete: builder.mutation({
      query: (id) => ({
        url: URL.ROBOTS_ORDER_DELETE + id,
        method: "DELETE",
      }),
    }),

    // ROBOT ORDER PAYMENT STATUS
    robot_order_payment_status: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROBOT_ORDER_PAYMENT_STATUS + id,
        method: "PUT",
        body: payload,
      }),
    }),

    // ROBOTS ORDER PAYMENTS LIST
    robots_order_payments_list: builder.query({
      query: (id) => URL.ROBOTS_ORDER_PAYMENTS_LIST + id,
    }),

    //ADD_COMPANY_ROBORT
    company_robo_add: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_COMPANY,
        method: "POST",
        body: payload,
      }),
    }),
    //CUSINE_CREATE_ROBOT
    create_cusine_robot: builder.mutation({
      query: (payload) => ({
        url: URL.CUSINE_CREATE_ROBOT,
        method: "POST",
        body: payload,
      }),
    }),

    //CREATE_ADD_MENU
    create_add_menu: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_MENU,
        method: "POST",
        body: payload,
      }),
    }),
    //ADD_COMPANY_ROBOT
    hilist_company_robot: builder.query({
      query: () => ({
        url: URL.COMPANY_ROBOT_HiLIST_ROBO,
      }),
    }),
      //ADD_COMPANY_ROBOT
      hirobotlist: builder.query({
        query: () => ({
          url: URL.HiROBOTLIST,
        }),
      }),
    // list Hi-robot
    list_company_robot: builder.query({
      query: (payload) => ({
        url: URL.LIST_COMPANY_ROBOT,
        method: "GET",
      }),
    }),
      // HI_ROBOT VIEW
      Hi_robot_view: builder.mutation({
        query: (id) => ({
          url: URL. HIROBOTVIEW + id,
          method: "GET",
        }),
      }),
    //RESTAURANT_MENU
    list_restaurant_menu: builder.query({
      query: () => ({
        url: URL.RESTAURANT_MENU,
        method: "GET",
      }),
    }),
    //CUSINE_LIST_ROBOT
    list_cusine_robot: builder.query({
      query: () => ({
        url: URL.CUSINELIST_ROBOT,
        method: "GET",
      }),
    }),
    //LIST_MENU
    list_add_menu: builder.query({
      query: () => ({
        url: URL.LIST_MENU,
        method: "GET",
      }),
    }),
    listAddMenus: builder.mutation({
      query: (payload) => ({
        url: URL.LIST_MENU,
        method: "POST",
        body: payload,
      }),
    }),
    //UPDATE_ROBOT_ORDER
    update_robot_order: builder.mutation({
      query: ({ payload, id }) => ({
        url: `${URL.UPDATE_ROBOT_ORDER}/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    //ADD_MENU_UPDATE
    add_menu_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: `${URL.ADD_MENU_UPDATE}/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    //UPDATE_CATEGORY
    update_category: builder.mutation({
      query: ({ payload, id }) => ({
        url: `${URL.CATEGORY_UPDATES}/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    //ROBOT_COMAPNY_DELETE
    delete_company_robot: builder.mutation({
      query: (payload) => ({
        url: `${URL.DELETE_COMAPANY_ROBOT}/${payload}`,
        method: "DELETE",
      }),
    }),
    //CATEGORY_DELETE
    delete_category: builder.mutation({
      query: (payload) => ({
        url: `${URL.DELETE_CATEGORY}/${payload}`,
        method: "DELETE",
      }),
    }),
    //DELETE_CUSINE_ROBOT
    delete_cusine_robot: builder.mutation({
      query: (payload) => ({
        url: `${URL.DELETE_CUSINE_ROBOT}/${payload}`,
        method: "DELETE",
      }),
    }),
    //DELETE_ADD_MENU
    delete_add_menu: builder.mutation({
      query: (payload) => ({
        url: `${URL.ADD_MENU_DELETE}/${payload}`,
        method: "DELETE",
      }),
    }),
    //ROBOT_COMAPNY_EDIT
    edit_company_robot: builder.mutation({
      query: ({ payload, id }) => ({
        url: `${URL.EDIT_COMPANY_ROBOT}/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    companyRobotsStatusChange: builder.query({
      query: (id) => URL.COMPANY_ROBOTS_STATUS + id,
    }),
    // ROBOTS ORDER PAYMENTS ADD

    //GET_LIST_ID
    get_robotList_id: builder.query({
      query: (payload) => ({
        url: `${URL.GET_ROBOTLIST_ID}/${payload}`,
        method: "GET",
      }),
    }),
    //SINGLE_ADD_MENU_LIST
    single_add_menu_list: builder.query({
      query: (payload) => ({
        url: `${URL.SINGLE_ADD_MENU_LIST}/${payload}`,
        method: "GET",
      }),
    }),
    robots_order_payments_add: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOTS_ORDER_PAYMENTS_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    // ROBOT_ORDER_LIST_WITH_BRANCH
    robotOrderBranchList: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOT_ORDER_LIST_WITH_BRANCH,
        method: "POST",
        body: payload,
      }),
    }),

    // COMPANT_ROBOT_VIEW
    companyRobotView: builder.query({
      query: (id) => URL.COMPANT_ROBOT_VIEW + id,
    }),
    // Hi-robot COMPANT_ROBOT_VIEW
    hi_view: builder.query({
      query: (id) => ({
        url: URL.COMPANY_ROBOT_HiVIEW_ROBO + id,
      }),
    }),
    companyUserLists: builder.query({
      query: () => URL.COMPANY_USERS_LIST,
    }),

    // companyUserBranch: builder.query({
    //   query: () => URL.COMPANY_USERS,
    // }),

    companyUserBranch: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_USERS,
        method: "POST",
        body: payload,
      }),
    }),

    companyUserViewBranch: builder.query({
      query: (id) => URL.COMPANY_USER_VIEW_NEW + id,
    }),

    companyUserEditBranch: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.COMPANY_USER_EDIT_NEW + id,
        body: payload,
        method: "PUT",
      }),
    }),

    companyUserCreateBranch: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_USER_ADD_NEW,
        method: "POST",
        body: payload,
      }),
    }),

    companyUserDeleteNew: builder.mutation({
      query: (id) => ({
        url: URL.COMPANY_USER_DELETE_URL + id,
        method: "DELETE",
      }),
    }),

    companyUserStatusNew: builder.query({
      query: (id) => URL.COMPANY_USER_STATUS_CHANGE + id,
    }),

    robotManagementList: builder.query({
      query: () => URL.ROBOT_MANAGEMNET_LIST,
    }),

    robotManagementBranchList: builder.query({
      query: () => URL.ROBOT_MANAGEMENT_BRANCH_LIST,
    }),

    tableListAdmin: builder.query({
      query: () => URL.TABLE_ALL_LIST,
    }),

    tableAdminCreate: builder.mutation({
      query: (payload) => ({
        url: URL.TABLE_CREATE_ADMIN,
        method: "POST",
        body: payload,
      }),
    }),

    tableViewAdmin: builder.query({
      query: (id) => URL.TABLE_VIEW_ADMIN + id,
    }),

    tableEditAdmin: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.TABLE_EIDT_ADMIN + id,
        body: payload,
        method: "PUT",
      }),
    }),

    branchDelete: builder.mutation({
      query: (id) => ({
        url: URL.BRANCH_DELETE + id,
        method: "DELETE",
      }),
    }),

    companyRobotListRobo: builder.query({
      query: () => URL.COMPANY_ROBOT_LIST_ROBO,
    }),
   
    companyRobotViewRobo: builder.mutation({
      query: (payload) => ({
        url: URL.COMPANY_ROBOTS_VIEW_NEW,
        body: payload,
        method: "POST",
      }),
    }),

    robot_management_view: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOT_MANAGEMENT_VIEW,
        method: "POST",
        body: payload,
      }),
    }),
    // FOR RESTAURANT PANAL

    // LOGIN
    loginRes: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN_RES,
        method: "POST",
        body: payload,
        headers: {
          // data: console.log(payload),
          // "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
    }),

    branchList: builder.query({
      query: () => URL.BRANCH_LIST,
    }),

    branch_status: builder.query({
      query: (id) => ({
        url: URL.BRANCH_STATUS + id,
      }),
    }),

    branch_add: builder.mutation({
      query: (payload) => ({
        url: URL.BRANCH_ADD,
        method: "Post",
        body: payload,
      }),
    }),

    dinning_branchList: builder.query({
      query: () => URL.DINNING_BRANCHLIST,
    }),

    dinninguser_add: builder.mutation({
      query: (payload) => ({
        url: URL.DINNINGUSER_ADD,
        method: "Post",
        body: payload,
      }),
    }),

    dinninguser_list: builder.query({
      query: () => URL.DINNINGUSER_LIST,
    }),

    dinninguser_delete: builder.mutation({
      query: (id) => ({
        // url:`${URL.DINNINSUSER_DELETE}/${id}`,
        url: URL.DINNINSUSER_DELETE + id,
        method: "DELETE",
      }),
    }),

    dinningStatus: builder.query({
      query: (id) => ({
        url: URL.DINNINGSTATUS + id,
      }),
    }),
    dinningUser_Update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.DINNINGUSER_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),
    dinningView: builder.query({
      query: (id) => ({
        url: URL.DINNING_VIEW + id,
      }),
    }),
    //branch
    restaurant_branch_view: builder.query({
      query: (id) => ({
        url: URL.RESTAURANT_BRANCH_VIEW + id,
      }),
    }),

    restaurant_branch_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.RESTAURANT_BRANCH_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),
    //
    branchList_Table: builder.mutation({
      query: (payload) => ({
        url: URL.BRANCH_LIST_TABLE,
        method: "POST",
        body: payload,
      }),
    }),

    branch_view: builder.query({
      query: (id) => ({
        url: URL.BRANCH_VIEW_TABLE + id,
      }),
    }),

    table_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.BRANCH_UPDATE_TABLE + id,
        method: "PUT",
        body: payload,
      }),
    }),
    category_Lists: builder.query({
      query: () => ({
        url: URL.CATEGORY_LISTCATEGORY,
      }),
    }),

    category_add: builder.mutation({
      query: (payload) => ({
        url: URL.CATEGORY_ADDCATEGORY,
        method: "POST",
        body: payload,
      }),
    }),

    category_status: builder.query({
      query: (id) => ({
        url: URL.CATEGORY_STATUS + id,
      }),
    }),

    category_view: builder.query({
      query: (id) => ({
        url: URL.CATEGORY_VIEW + id,
      }),
    }),
    
    category_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.CATEGORY_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),

    category_List: builder.mutation({
      query: (payload) => ({
        url: URL.CATEGORY_LISTCATEGORY,
        method: "POST",
        body: payload,
      }),
    }),

    cusine_List: builder.mutation({
      query: (payload) => ({
        url: URL.CUSINE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    cusine_Add: builder.mutation({
      query: (payload) => ({
        url: URL.CUSINE_Add,
        method: "POST",
        body: payload,
      }),
    }),

    cusine_view: builder.query({
      query: (id) => ({
        url: URL.CUSINE_VIEW + id,
      }),
    }),

    cusine_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.CUSINE_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),

    cusine_status: builder.query({
      query: (id) => ({
        url: URL.CUSINE_STATE + id,
      }),
    }),

    menu_list: builder.mutation({
      query: (payload) => ({
        url: URL.MENU_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    menu_status: builder.query({
      query: (id) => ({
        url: URL.MENU_STATUS + id,
      }),
    }),

    menu_add: builder.mutation({
      query: (payload) => ({
        url: URL.MENU_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    menu_delete: builder.mutation({
      query: (id) => ({
        url: URL.MENU_DELETE + id,
        method: "DELETE",
      }),
    }),
    menu_view: builder.query({
      query: (id) => ({
        url: URL.MENU_VIEW + id,
      }),
    }),
    menu_update: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.MENU_UPDATE + id,
        method: "PUT",
        body: payload,
      }),
    }),
    menu_filter: builder.mutation({
      query: (payload) => ({
        url: URL.MENU_FILTER,
        method: "POST",
        body: payload,
      }),
    }),
    profile: builder.query({
      query: () => ({
        url: URL.PROFILE_VIEW,
      }),
    }),
    robot_list: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOTS_LISTS,
        method: "POST",
        body: payload,
      }),
    }),
    company_logout: builder.query({
      query: () => ({
        url: URL.COMPANY_LOGOUT,
      }),
    }),
    view_databranch: builder.query({
      query: (id) => ({
        url: URL.BRANCH_VIEW_DATA + id,
      }),
    }),
    branchview_list: builder.query({
      query: () => ({
        url: URL.BRANCHUSER_LIST,
      }),
    }),
    robot_list_view: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOIT_LIST_VIEW,
        method: "POST",
        body: payload,
      }),
    }),
    order_list: builder.mutation({
      query: (payload) => ({
        url: URL.ORDER_LIST,
        method: "POST",
        body: payload,
      }),
    }),
    robot_menu_list: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOIT_MENU_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    //agv
    agvListCompanyRobot: builder.query({
      query: () => ({
        url: URL.AGVLISTCOMPANYROBOT,
      }),
    }),

    listLocation: builder.query({
      query: () => ({
        url: URL.LISTLOCATION,
      }),
    }),

    robotOrderAgvlist: builder.mutation({
      query: (payload) => ({
        url: URL.ROBOTORDERAGVLIST,
        method: "POST",
        body: payload,
      }),
    }),
    robotdsetaillist: builder.mutation({
      query: (payload) => ({
        url: URL.HIROBOT_DETAILSLIST,
        method: "POST",
        body: payload,
      }),
    }),
    hiquestionlist: builder.mutation({
      query: (payload) => ({
        url: URL.HIROBOTSPEECHFUCTION,
        method: "POST",
        body: payload,
      }),
    }),
    agvMapLocation: builder.mutation({
      query: (payload) => ({
        url: URL.AGVMAPLOCATION,
        method: "POST",
        body: payload,
      }),
    }),
    hirobotspeech: builder.mutation({
      query: (payload) => ({
        url: URL.HIROBOTSPEECHADD,
        method: "POST",
        body: payload,
      }),
    }),
    hirobotspeechconversation: builder.mutation({
      query: (payload) => ({
        url: URL.HIROBOTSPEECHCONVERSATION,
        method: "PUT",
        body: payload,
      }),
    }),
    update_hirobot: builder.mutation({
      query: ({ payload, id }) => ({
        url: `${URL.HIROBOTUPDATE}/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    agvRobotListView: builder.mutation({
      query: (payload) => ({
        url: URL.AGVROBOTLISTVIEW,
        method: "POST",
        body: payload,
      }),
    }),

    // POINT LIST
    pointList: builder.query({
      query: () => URL.POINTS_LIST,
    }),

    // POINT VIEW
    pointView: builder.query({
      query: (id) => URL.POINT_VIEW + id,
    }),

    // POINT EDIT
    pointEdit: builder.mutation({
      query: ({ payload, id }) => ({
        url: URL.POINT_EDIT + id,
        method: "PUT",
        body: payload,
      }),
    }),
  }),
});

export const {
  useShowCategoriesQuery,
  useGetTablesQuery,
  usePlaceOrderMutation,
  useCategoriesQuery,
  useCreateCategoryMutation,
  useCreateItemMutation,
  useEditItemMutation,
  useStatusChangeMutation,
  useDeleteItemMutation,
  useCreateTableMutation,
  useEditTableMutation,
  useGetTablesAdminQuery,
  useUpdate_robot_orderMutation,
  useLazyTableStatusChangeQuery,
  useCreate_add_category_statusMutation,
  useOrderListQuery,
  useLazyOrderDetailsQuery,
  useEditCategoryMutation,
  useCompany_management_deleteMutation,
  useLazyRole_viewQuery,
  useLead_new_statusMutation,
  useLazyLead_viewQuery,
  useLazyRobots_viewQuery,
  useRole_updateMutation,
  useLoginMutation,
  useLogoutMutation,
  useAdmin_addMutation,
  useAdminListMutation,
  useAdmin_statusMutation,
  useAdmin_updateMutation,
  useAdmin_deleteMutation,
  useAdmin_viewMutation,
  useRole_listMutation,
  useRole_statusMutation,
  useRole_addMutation,
  useLead_listMutation,
  useLead_statusMutation,
  useCusineStatusMutation,
  useLead_addMutation,
  useLead_updateMutation,
  useRobots_listMutation,
  useRobot_addMutation,
  useCompany_management_listMutation,
  useLazyCompany_management_statusQuery,
  useCompany_management_updateMutation,
  useCompany_management_addMutation,
  useLazyCompany_management_viewQuery,
  useRobot_order_addMutation,
  useRobot_order_statusMutation,
  useRobot_order_listMutation,
  useLazyRoles_modulesQuery,
  useMenu_management_addMutation,
  useMenu_management_listMutation,
  useMenu_management_deleteMutation,
  useMenu_management_add_categoryMutation,
  useMenu_management_category_statusMutation,
  useMenu_management_category_editMutation,
  useCompany_robot_addMutation,
  useCompany_user_addMutation,
  useCompany_user_listMutation,
  useCompany_user_viewMutation,
  useCusine_update_robotMutation,
  useLead_deleteMutation,
  useRole_deleteMutation,
  useDelete_add_menuMutation,
  useRobots_updateMutation,
  useLazyRobots_statusQuery,
  useLazyRobot_list_companyQuery,
  useRobo_deleteMutation,
  useUpdate_categoryMutation,
  useRobot_order_deleteMutation,
  useRobot_order_payment_statusMutation,
  useLazyRobot_order_viewQuery,
  useLazyRobots_order_payments_listQuery,
  useRobots_order_payments_addMutation,
  useCompany_robo_addMutation,
  useLazyList_company_robotQuery,
  useDelete_company_robotMutation,
  useCreate_add_menuMutation,
  useDelete_categoryMutation,
  useDelete_cusine_robotMutation,
  useEdit_company_robotMutation,
  useLazyGet_robotList_idQuery,
  useLazyList_restaurant_menuQuery,
  useLazyList_cusine_robotQuery,
  useLazyList_add_menuQuery,
  useLazySingle_add_menu_listQuery,
  useCreate_cusine_robotMutation,
  useAdd_menu_updateMutation,
  useRobotOrderBranchListMutation,
  useLazyCompanyRobotViewQuery,
  useLazyCompanyUserListsQuery,
  // useLazyCompanyUserBranchQuery,
  useCompanyUserBranchMutation,
  useLazyCompanyUserViewBranchQuery,
  useCompanyUserEditBranchMutation,
  useCompanyUserCreateBranchMutation,
  useLazyRobotManagementListQuery,
  useLazyRobotManagementBranchListQuery,
  useLazyTableListAdminQuery,
  useTableAdminCreateMutation,
  useLazyTableViewAdminQuery,
  useTableEditAdminMutation,
  useBranchDeleteMutation,
  useLazyCompanyRobotListRoboQuery,
  useRobotsEditMutation,
  useCompanyRobotViewRoboMutation,
  useLazyCompanyRobotsStatusChangeQuery,
  useListAddMenusMutation,
  useLazyCompanyUserStatusNewQuery,
  useCompanyUserDeleteNewMutation,
  useRobot_management_viewMutation,

  // FOR RESTAURANT PANAL
  useLoginResMutation,
  useLazyBranchListQuery,
  useLazyBranch_statusQuery,
  useBranch_addMutation,
  useLazyDinning_branchListQuery,
  useDinninguser_addMutation,
  useLazyDinninguser_listQuery,
  useDinninguser_deleteMutation,
  useLazyDinningStatusQuery,
  useLazyDinningViewQuery,
  useDinningUser_UpdateMutation,
  useCategory_ListMutation,
  useBranchList_TableMutation,
  useLazyBranch_viewQuery,
  useCategory_addMutation,
  useLazyCategory_statusQuery,
  useCategory_updateMutation,
  useCusine_ListMutation,
  useCusine_AddMutation,
  useLazyCusine_viewQuery,
  useLazyCategory_viewQuery,
  useCusine_updateMutation,
  useLazyCusine_statusQuery,
  useMenu_listMutation,
  useLazyMenu_statusQuery,
  useTable_updateMutation,
  useMenu_addMutation,
  useMenu_deleteMutation,
  useLazyMenu_viewQuery,
  useMenu_updateMutation,
  useMenu_filterMutation,
  useLazyProfileQuery,
  useRobot_listMutation,
  useLazyCompany_logoutQuery,
  useLazyView_databranchQuery,
  useLazyBranchview_listQuery,
  useRobot_list_viewMutation,
  useOrder_listMutation,
  useRobot_menu_listMutation,
  useDinningUpdateMutation,
  useRestaurant_branch_updateMutation,
  useLazyRestaurant_branch_viewQuery,
  useLazyPointListQuery,
  useLazyPointViewQuery,
  usePointEditMutation,

  //AGV
  useLazyAgvListCompanyRobotQuery,
  useRobotOrderAgvlistMutation,
  useAgvRobotListViewMutation,
  useLazyListLocationQuery,
  useAgvMapLocationMutation,

  // HI-Robot
  useLazyHilist_company_robotQuery,
  useLazyHi_viewQuery,
  useRobotdsetaillistMutation,
  useHirobotspeechMutation,
  useLazyHirobotlistQuery,
  useHi_robot_viewMutation,
  useUpdate_hirobotMutation,
  useHiquestionlistMutation,
  useHirobotspeechconversationMutation,
} = api;
