import React, { useEffect, useState } from "react";
import MenuViewFormComp from "../components/MenuComp/MenuViewFormComp";
import { detail_menu, menu_list } from "../storage/dummyData";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ScreenHeader from "../components/ScreenHeader";
import { menu_section_left } from "../assets/images";
import {
  useLazyList_restaurant_menuQuery,
  useLazyList_add_menuQuery,
  useDelete_add_menuMutation,
  useLazySingle_add_menu_listQuery,
  useListAddMenusMutation,
} from "../constants/redux/api/api";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Edit, Delete } from "@mui/icons-material";
import moment from "moment/moment";
import TopHeader from "../components/TopHeader";
// import VisibilityIcon from "@mui/icons-material/Visibility";
function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={"text-nowrap"}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className={"text-nowrap"}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const MenuViewScreen = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location?.state;
  const formType = location?.state?.type;
  const [dropDownList, setDropDownList] = useState();
  const [addMenu, setAddMenu] = useState([]);
  const [addMenus, setAddMenus] = useState([]);
  const [list_restaurant_menu] = useLazyList_restaurant_menuQuery();
  const [list_add_menu] = useLazyList_add_menuQuery();
  const [listApi] = useListAddMenusMutation();
  const [delete_add_menu] = useDelete_add_menuMutation();
  const [single_add_menu_list] = useLazySingle_add_menu_listQuery();
  const [getRobotList, setRobotList] = useState([]);

  // LIST MENU ITEMS
  const listAddMenu = (id) => {
    listApi({ company_id: id })
      .unwrap()
      .then((res) => {
        console.log(res, "res154");
        const arraydata=res?.data
        setAddMenus(arraydata);
        setAddMenu(arraydata);
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
  };

  console.log("stateData", stateData);

  useEffect(() => {
    if (stateData?.data?.company_name?._id) {
      listAddMenu(stateData?.data?.company_name?._id);
    } else {
      navigate(-1);
    }

    // setDropDownList(stateData?.data?.branch_info[0]?._id);
  }, []);

  const handleClickDelete = (id) => {
    delete_add_menu(id)
      .unwrap()
      .then((res) => {
        // console.log(res, "res167");
        if (res.status == "success") {
          listAddMenu();
        }
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
  };

  const onEditMenu = (id) => {
    let singleData = "";
    single_add_menu_list(id)
      .unwrap()
      .then((res) => {
        singleData = res.data;
      })
      .catch((err) => {
        console.log({ message: err.message });
      });
    // console.log(singleData, "singleData187");
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Branch",
        accessor: "company_branch_id",
        Cell: ({ value, row }) => {
          return <p>{value?.name}</p>;
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ value, row }) => {
          return <p>{value?.name}</p>;
        },
      },
      {
        Header: "Cusine",
        accessor: "cuisine",
        Cell: ({ value, row }) => {
          return <p>{value?.name}</p>;
        },
      },

      {
        Header: "Dish",
        accessor: "Name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Image",
        accessor: "image_url.MenuImage",
        Cell: ({ value, row }) => {
          return <img src={value} alt="images" />;
        },
      },

      // {
      //   Header: "Product Name",
      //   accessor: "robot_type_info",
      //   Cell: ({ value, row }) => {
      //     return <p>{value?.robot_type}</p>;
      //   },
      // },
      // {
      //   Header: "Price",
      //   accessor: "Price",
      //   Cell: ({ value, row }) => {
      //     return <p>{value}</p>;
      //   },
      // },
      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <button
                onClick={() => {
                  navigate("/add-menu", {
                    state: {
                      type: "edit",
                      data: stateData,
                      editData: row.original,
                    },
                  });
                  // onEditMenu(value);
                }}
                className="bg-primary text-white border-0 rounded-1 p-1 me-2"
              >
                <Edit />
              </button>
              {/* <button
                className="bg-warning border-0 rounded-1 p-1"
                onClick={() =>
                  handleClickDelete(row.original._id, row.original.email)
                }
              >
                <Delete />
              </button> */}
              <button
                onClick={() =>
                  navigate("/add-menu", {
                    state: { type: "view", data: stateData,
                    editData: row.original, },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
                </button> 
            </div>
          );
        },
      },
    ],
    []
  );

  const seletBranch = (id) => {
    // console.log("id", id);
    setDropDownList(id);
    let finder = addMenus?.filter((i) => i?.company_branch_details?._id == id);
    // console.log("finder", finder);
    if (id == "All") {
      setAddMenu(addMenus);
    } else {
      setAddMenu(finder);
    }
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"menu-page"} />
      <div className="menu-list">
        <h1>Add Menu</h1>
      </div>
      <MenuViewFormComp
        menuOpe={menuOpen}
        dropDownList={dropDownList}
        seletBranch={seletBranch}
        data={stateData}
      />

      <div className="">
        <div className="menu-list"></div>

        <div className="side_pad">
          <div className="menu_section">
            <div className="align-items-center justify-content-between d-flex flex-md-wrap">
              <div className="align-items-center d-flex ">
                <div className="px-2">
                  <img src={menu_section_left} alt="decor" />
                </div>
                <div className="px-2 ">
                  <h5>{"Check out your menu"}</h5>
                  <p>{"You can add your menu from here"}</p>
                </div>
              </div>
              <div className="d-flex gap-2">
                <button
                  onClick={() =>
                    navigate("/add-category", {
                      state: { type: "add", data: stateData },
                    })
                  }
                  className="btn-outline"
                >
                  + Add Category
                </button>
                <button
                  onClick={() =>
                    navigate("/add-menu", {
                      state: { type: "add", data: stateData },
                    })
                  }
                  className="btn-outline"
                >
                  + {"Add Menu"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* new table start */}

      <div className="table-responsive menu-table side_pad my-4">
        <Table columns={columns} data={addMenu} className="" />
      </div>

      {/* new table end */}
    </div>
  );
};

export default MenuViewScreen;
