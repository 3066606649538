import tea from "../../assets/images/tea.jpg";
import fried_rice from "../../assets/images/fried_rice.jpg";
import React, { useState, useEffect } from "react";
import BTable from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import {
  useLazyBranchListQuery,
  useRobot_menu_listMutation,
} from "../../constants/redux/api/api";
import moment from "moment";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Table } from "../Table/Table";
import { useLocation } from "react-router-dom";

const RestaurantViewOrder = ({ menuOpen }) => {
  const location = useLocation();
  const branchdata = location?.state?.data;
  const [tab, setTab] = useState(false);
  const [status, setStatus] = useState(1);
  const [data, setdata] = useState([]);
  const [branchList, setbranchList] = useState([]);

  console.log("location", location?.state);

  const [menulist] = useRobot_menu_listMutation();
  const [branchListApi] = useLazyBranchListQuery();

  const getList = () => {
    const formdata = new URLSearchParams();
    formdata.append(
      "company_branch_id",
      location?.state?.data?.company_branch_id
    );
    menulist(formdata)
      .unwrap()
      .then((res) => {
        setdata(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getBranchList = () => {
    branchListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res, "list");
          const temp = [...res?.data?.branches].reverse();
          // setBranch(res?.data?.branches?.id)
          setbranchList(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
    getBranchList();
  }, []);

  useEffect(() => {
    getList();
  }, []);

  const columns1 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Food Name",
        accessor: "company_robot_info.menu_info[0]",
        Cell: ({ value, row }) => {
          return <p>{value?.menu_name}</p>;
        },
      },
      {
        Header: "Qty",
        accessor: "company_robot_info.menu_info[0]?.qty",
        Cell: ({ value, row }) => {
          return <p>{row.original.company_robot_info.menu_info[0].qty}</p>;
        },
      },
      {
        Header: "Taken",
        accessor: "company_robot_info.robot_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Robot Name",
        accessor: "company_robot_info.robot_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Robot ID",
        accessor: "company_robot_info.code",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Duration",
        accessor: "company_robot_info.duration",
        Cell: ({ value, row }) => {
          // return <p>{row.original.company_robot_info.duration}</p>;
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "order_status",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
    ],
    []
  );
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list d-flex ac-js gap-4">
        <button
          className="border-0 bg-transparent"
          onClick={() => setTab(true)}
        >
          <h1 className={tab ? "text-light" : ""}>Oder Details</h1>
        </button>
        <button
          className="border-0 bg-transparent"
          onClick={() => setTab(false)}
        >
          <h1 className={!tab ? "text-light" : ""}>Robot Details</h1>
        </button>
      </div>
      {tab && (
        <div className="side_pad">
          <div className="row mx-1 mb-3 add-food-section">
            <div className="form-group col-6">
              <label>Branch Name:</label>
              <select
                className="form-control"
                placeholder="Select Branch"
                value={branchdata?.company_branch_id}
                disabled
              >
                <option value="All">All</option>
                {branchList?.map((item, ind) => {
                  return (
                    <option key={ind} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group col-6">
              <label>Oder Id:</label>
              <input
                type="text"
                className="form-control"
                value={branchdata?._id}
                readOnly
                disabled
              />
            </div>
            <div className="form-group col-6">
              <label>Table Id:</label>
              <input
                type="text"
                className="form-control"
                value={branchdata?.drop_point}
                readOnly
                disabled
              />
            </div>
            <div className="form-group col-6">
              <label>Order Date:</label>
              <input
                type="text"
                className="form-control"
                value={moment(branchdata?.createdAt).format("MMMM Do YYYY")}
                readOnly
                disabled
              />
            </div>
            <div className="form-group col-6">
              <label>Order Time:</label>
              <input
                type="text"
                className="form-control"
                value={moment(branchdata?.createdAt).format(" h:mm:ss a")}
                readOnly
                disabled
              />
            </div>
            <div className="form-group col-6">
              <label>Status:</label>
              <input
                type="text"
                className="form-control"
                value={branchdata?.task_status}
                readOnly
                disabled
              />
              {/* {status == 1 && (
                 <button
                 disabled
                    // onClick={() => setStatus((prev) => !prev)}
                    onClick={() => setStatus(2)}
                    className={`${
                      status ? "" : ""
                    }  bg-success form-control px-3 py-2 rounded-1 border-0 text-white  `}
                  >
                    Order Deliver
                  </button>
                )}
                {status == 2 && (
                  <button
                  disabled
                    onClick={() => setStatus(3)}
                    className={`${
                      status ? "" : ""
                    } px-2 py-1 rounded-1 bg-warning border-0 text-white`}
                  >
                    Order Tacken
                  </button>
                )}
                {status == 3 && (
                  <button
                  disabled
                    onClick={() => setStatus(1)}
                    className={`${status ? "" : ""} 
                    bg-danger px-2 py-1 rounded-1 border-0 text-white`}
                  >
                    Order Cancel
                  </button>
                )} */}
            </div>
          </div>
          {/* <div className="menu-list">
            <h1>Filter</h1>
            <div className="row mx-1 mb-3 add-food-section">
                <div className="form-group col-md-6">
                    <label>Food Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Food Name"
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Order Status:</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Order Status"
                    />
                </div>
            </div>
        </div> */}
          <div className="table-responsive menu-table side_pad mt-4">
            <Table columns={columns1} data={data} className="" />
          </div>
        </div>
      )}
      {!tab && (
        <div className="side_pad">
          <div className="table-responsive menu-table side_pad mt-4">
            <Table columns={columns} data={data} className="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default RestaurantViewOrder;
