import React from "react";
import { useNavigate } from "react-router-dom";
import RoboTypeList from "../../components/RoboTypeList";
import ScreenHeader from "../../components/ScreenHeader";
import { menu_section_left } from "../../assets/images";
import TopHeader from "../../components/TopHeader";

const RobotType = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"robot-types"} />
      <ScreenHeader
        title="Robot Type"
        menuLink="/add-robot-type"
        menuBtn="Add Robot"
        menuTitle="Check out your Robot Type"
        description="You can add your Robot Type from here"
        img={menu_section_left}
      />
      <RoboTypeList />
    </div>
  );
};

export default RobotType;
