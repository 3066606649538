import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRole_listMutation } from "../constants/redux/api/api";
import useUser from "../constants/Storage/userData";

const ProfileDetailScreen = ({ menuOpen }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  //   Rtk Query
  const [role_list] = useRole_listMutation();

  const [roleList, setRoleList] = useState([]);

  const GetRoleList = () => {
    role_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = [];
          res?.data?.map((item, ind) => {
            temp.push(item);
          });
          setRoleList(temp.reverse());
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    GetRoleList();
  }, []);

  console.log("user", user);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <div className="menu-list">
        <h1>Profile Detail</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Name
                </label>
                <input
                  disabled
                  value={user?.name}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Email
                </label>
                <input
                  disabled
                  value={user?.email}
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="Enter Email name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Phone No
                </label>
                <input
                  disabled
                  value={user?.phone}
                  name="phone"
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone No"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Role
                </label>
                <select
                  className="form-control"
                  disabled
                  name=""
                  id=""
                  value={user?.role}
                >
                  {roleList?.map((item, ind) => {
                    return <option value={item?._id}>{item?.name}</option>;
                  })}
                </select>
              </div>
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                                <label className="mb-2" htmlFor="">
                                    Password
                                </label>
                                <input
                                    disabled
                                    value={password}
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter Password"
                                />
                            </div> */}
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3 invisible invissble">
                                <label className="mb-2" htmlFor="">
                                    Confirm Password
                                </label>
                                <input
                                    disabled
                                    name="confirmPassword"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Confirm Password"
                                />
                            </div> */}
              <div onClick={() => navigate(-1)} class="btn-outline">
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailScreen;
