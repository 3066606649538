import React, { useEffect, useState } from "react";
import { logo, profileimg } from "../../assets/images";
import MenuIcon from "@mui/icons-material/Menu";
import GridViewIcon from "@mui/icons-material/GridView";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutPopup from "../LogoutPopup";
import {
  useLogoutMutation,
  useLazyCompany_logoutQuery,
} from "../../constants/redux/api/api";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CategoryIcon from "@mui/icons-material/Category";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import useUser from "../../constants/Storage/userData";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Navbar3 = ({ menuOpen, toggle, setMenuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("resturent_users"));
  const pathname = location?.pathname;
  const [logoutPopupShow, setLogoutPopupShow] = useState(false);
  const [customermanagement, setCustomermanagement] = useState(false);
  const [logout] = useLazyCompany_logoutQuery();
  const [showDropdown, setShowDropDown] = useState(false);
  const [showListDropdown, setShowListDropDown] = useState(false);
  // console.log(user, "user");
  const toggleLogoutPopup = () => {
    setLogoutPopupShow(!logoutPopupShow);
  };

  const confirmLogout = () => {
    // navigate("/restaurant/login");
    // localStorage.clear();
    if (user?.email) {
      logout()
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            navigate("/restaurant/login");
            localStorage.clear();
          }
        });
    } else {
      navigate("/restaurant/login");
      localStorage.clear();
    }
  };

  useEffect(() => {
    if (!menuOpen) {
      setShowListDropDown(false);
    }
  });
  return (
    <div className="view_pad">
      <header className="header-admin user_head">
        <div
          className={`${menuOpen && "logo-toggle"} d-flex align-items-center`}
          style={{ transition: "0.3s" }}
        >
          <button
            className="header_toggle bg-transparent border-0"
            onClick={toggle}
          >
            {!menuOpen ? (
              <MenuIcon className="menu_icon fs-3" />
            ) : (
              <CloseOutlinedIcon CloseOutlinedIcon className="menu_icon fs-3" />
            )}
          </button>
          <div className="nav_logo">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="position-relative">
          <div className="d-flex align-items-center">
            <p className="text-light company_name m-0 px-2">
              {/* {
                user?.companyname?.map((item,ind)=>({
                  
                }
                
                ))
            } */}
              Company Name :
              {user?.companyname?.length > 0
                ? user?.companyname?.[0]?.companyname
                : "Test"}
            </p>
            <button
              onClick={() => setShowDropDown(!showDropdown)}
              className="header_img align-selfe-end border-0 p-0 m-0"
              // className="bg-transparent text-light border-0"
            >
              <img
                src={
                  user?.company_profile_image ? user?.company_profile_image : ""
                }
                alt="profile"
              />
              {/* <AccountCircleIcon color="primary" sx={{ fontSize: 35 }} /> */}
              {/* <p>profile</p> */}
            </button>
            {showDropdown && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setShowDropDown(!showDropdown)}
              />
            )}
            {showDropdown && (
              <div className="profile_dropdown">
                <button
                  className="text m-0 py-2 px-3 cust-btn "
                  onClick={() => {
                    navigate("/restaurant/view-profile");
                    setShowDropDown(false);
                  }}
                >
                  Profile views
                </button>
                <button
                  className="text m-0 py-2 px-3 cust-btn"
                  onClick={() => {
                    setLogoutPopupShow(true);
                    setShowDropDown(false);
                  }}
                >
                  Log out
                </button>
              </div>
            )}
          </div>
        </div>
      </header>
      <div className={menuOpen ? "show l-navbar" : "l-navbar"}>
        <nav className="nav user-side-nav">
          <div>
            <div className="nav_list">
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  pathname === "/restaurant/table-list" ? "active" : ""
                }`}
                onClick={() => {
                  navigate("/restaurant/table-list");
                  setMenuOpen(true);
                }}
              >
                <GridViewIcon
                  className={`fs-5' ${
                    pathname === "/restaurant/table-list"
                      ? "active"
                      : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Dashboard</p>
              </button>
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0
                 ${
                   pathname === "/restaurant/users" ||
                   pathname === "/restaurant/add-users"
                     ? "active"
                     : ""
                 }
                `}
                onClick={() => {
                  navigate("/restaurant/users");
                  setMenuOpen(true);
                }}
              >
                <AdminPanelSettingsIcon
                  className={`fs-5' ${
                    pathname === "/restaurant/users" ? "active" : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">User</p>
              </button>
              {!user?.company_branch_id && (
                <button
                  className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                    pathname === "/restaurant/branch-details" ||
                    pathname === "/restaurant/view-branch"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    navigate("/restaurant/branch-details");
                    setMenuOpen(true);
                  }}
                >
                  <AccountBalanceIcon
                    className={`fs-5' ${
                      pathname === "/restaurant/branch-details" ||
                      pathname === "/restaurant/view-branch"
                        ? "active"
                        : "primary1"
                    } `}
                  />
                  <p className="nav_name mb-0">Branch Management</p>
                </button>
              )}
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  pathname === "/restaurant/category-cousin" ||
                  pathname === "/restaurant/view-category-cousin" ||
                  pathname === "/restaurant/add-category" ||
                  pathname === "/restaurant/add-cuisine"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  {
                    user?.company_branch_id
                      ? navigate("/restaurant/view-category-cousin")
                      : navigate("/restaurant/category-cousin");
                  }
                  setMenuOpen(true);
                }}
              >
                <CategoryIcon
                  className={`fs-5' ${
                    pathname === "/restaurant/category-cousin" ||
                    pathname === "/restaurant/view-category-cousin" ||
                    pathname === "/restaurant/add-category" ||
                    pathname === "/restaurant/add-category" ||
                    pathname === "/restaurant/add-cuisine"
                      ? "active"
                      : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Category & Cuisine type</p>
              </button>
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  pathname === "/restaurant/menu-list" ||
                  pathname === "/restaurant/add-menu" ||
                  pathname === "/restaurant/menu-view"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  //  navigate("/restaurant/menu-list")
                  {
                    user?.company_branch_id
                      ? navigate("/restaurant/menu-view")
                      : navigate("/restaurant/menu-list");
                  }
                  setMenuOpen(true);
                }}
              >
                <MenuBookIcon
                  className={`fs-5' ${
                    pathname === "/restaurant/menu-list" ||
                    pathname === "/restaurant/add-menu" ||
                    pathname === "/restaurant/menu-view"
                      ? "active"
                      : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Menu Management</p>
              </button>
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  pathname === "/restaurant/add-robot" ||
                  pathname === "/restaurant/robot-order-detail"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  navigate("/restaurant/add-robot");
                  setMenuOpen(true);
                }}
              >
                <SmartToyOutlinedIcon
                  className={`MuiSvgIcon-root MuiSvgIcon-fontSizeMedium fs-5 ${
                    pathname === "/restaurant/add-robot" ||
                    pathname === "/restaurant/robot-order-detail"
                      ? "active"
                      : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Robots List</p>
              </button>
              <button
                className={`nav_link bg-transparent d-flex text-start align-items-center border-0 ${
                  pathname === "/restaurant/robot-order-list" ||
                  pathname === "/restaurant/view-robot-order" ||
                  pathname === "/restaurant/robot-orders"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  navigate("/restaurant/robot-order-list");
                  setMenuOpen(true);
                }}
              >
                <PrecisionManufacturingIcon
                  className={`fs-5' ${
                    pathname === "/restaurant/robot-order-list" ||
                    pathname === "/restaurant/view-robot-order" ||
                    pathname === "/restaurant/robot-orders"
                      ? "active"
                      : "primary1"
                  } `}
                />
                <p className="nav_name mb-0">Order</p>
              </button>
              {showListDropdown && (
                <>
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/restaurant/menu-management" ||
                      pathname === "/restaurant/add-menu"
                        ? "active"
                        : ""
                    }
                  `}
                    onClick={() => {
                      navigate("/restaurant/menu-management");
                    }}
                  >
                    <p className="nav_name mb-0">Menu Management</p>
                  </button>

                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/restaurant/company-users" ||
                      pathname === "/restaurant/add-company-users"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/restaurant/company-users");
                    }}
                  >
                    <p className="nav_name mb-0">Company Users</p>
                  </button>
                  <button
                    className={`nav_link bg-transparent ms-4 d-flex text-start align-items-center border-0 ${
                      pathname === "/restaurant/robot-manage-list" ||
                      pathname === "/restaurant/add-branch-manage"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      navigate("/restaurant/robot-manage-list");
                    }}
                  >
                    <p className="nav_name mb-0">Robot Management</p>
                  </button>
                </>
              )}
            </div>
          </div>
          <p onClick={toggleLogoutPopup} className="nav_link">
            <LogoutIcon className="primary1 fs-5" />
            <span className="nav_name">SignOut</span>{" "}
          </p>
        </nav>
      </div>
      {logoutPopupShow && (
        <LogoutPopup
          onCancelClick={toggleLogoutPopup}
          onConfirmClick={confirmLogout}
        />
      )}
    </div>
  );
};

export default Navbar3;
