import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useRobot_order_addMutation,
  useCompany_management_listMutation,
  useRobots_listMutation,
  useLazyRobot_order_viewQuery,
  useLazyRobots_order_payments_listQuery,
  useRobots_order_payments_addMutation,
  useUpdate_robot_orderMutation,
} from "../../constants/redux/api/api";
import PaymentListComp from "../../components/AddRobotOrderComp/PaymentListComp";
import TopHeader from "../../components/TopHeader";
import { loadergif } from "../../assets/img";
const AddRobotOrder = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;
console.log(location,"/add-robot-order")
  //   RTK QUERY
  const [robot_order_add] = useRobot_order_addMutation();
  const [company_management_list] = useCompany_management_listMutation();
  const [robots_list] = useRobots_listMutation();
  const [robot_order_view] = useLazyRobot_order_viewQuery();
  const [robots_order_payments_list] = useLazyRobots_order_payments_listQuery();
  const [robots_order_payments_add] = useRobots_order_payments_addMutation();
  const [update_robot_order] = useUpdate_robot_orderMutation();
  //   State variable declaration
  const [loading, setLoading] = useState(false);
  const [selectTab, setSelectTab] = useState(1);
  const [listShow, setListShow] = useState(false);
  const [addFormData, setAddFormData] = useState({
    companyname: "",
    clientname: "",
    branchname: "",
    robottype: "",
    description: "",
    image: "",
    location: "",
    pincode: "",
    price: "",
    status: "",
    number_of_robots: "",
  });
  const [addFormDataErr, setAddFormDataErr] = useState({
    comapanyname: false,
    clientname: false,
    branchname: false,
    robottype: false,
    description: false,
    image: false,
    location: false,
    pincode: false,
    price: false,
    status: false,
    number_of_robots: false,
  });

  const [comapnyManageList, setCompanyManagementList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [robotList, setRobotList] = useState([]);
  const [image, setImage] = useState([]);
  const [branchData, setBranchData] = useState({});
  const [branch, setBranch] = useState("");
  const [clientName, setClientName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameID, setCompanyID] = useState("");
  const [robotName, setRobotName] = useState("");
  const [noOfRobots, setNoOfRobots] = useState("");
  const [status, setStatus] = useState("order_received");
  const [productCost, setProductCost] = useState("");
  const [description, setDescription] = useState("");

  const [branchErr, setBranchErr] = useState(false);
  const [clientNameErr, setClientNameErr] = useState(false);
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [robotNameErr, setRobotNameErr] = useState(false);
  const [noOfRobotsErr, setNoOfRobotsErr] = useState(false);
  const [statusErr, setStatusErr] = useState(false);
  const [productCostErr, setProductCostErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [orderPaymentList, setOrderPaymentList] = useState([]);

  const [robot_order_id, setRobot_order_id] = useState("");
  const [amount_received, setAmount_received] = useState("");
  const [transaction_no, setTransaction_no] = useState("");
  const [payment_mode, setPayment_mode] = useState("");
  const [payment_date, setPayment_date] = useState("");
  const [amount, setAmount] = useState("");
  const [attachment, setAttachment] = useState("");

  const [robot_order_idErr, setRobot_order_idErr] = useState(false);
  const [amount_receivedErr, setAmount_receivedErr] = useState(false);
  const [transaction_noErr, setTransaction_noErr] = useState(false);
  const [payment_modeErr, setPayment_modeErr] = useState(false);
  const [payment_dateErr, setPayment_dateErr] = useState(false);
  const [amountErr, setAmountErr] = useState(false);
  const [attachmentErr, setAttachmentErr] = useState(false);
  const [btn, setBtn] = useState(false);
  // console.log(branchList, "branchList96");
  const getView = () => {
    robot_order_view(editData?._id)
      .unwrap()
      .then((res) => {
        // console.log("getView", res);
        let data = res?.data;
        let temp = [];
        data?.branch_info?.map((item, ind) => {
          temp.push({
            location: item?.company_branch_location,
            pincode: item?.company_branch_pincode,
            name: item?.company_branch_name,
            id: item?._id,
          });
        });
        setBranchList(temp);
        temp.map((item, ind) => {
          setBranchData({
            location: item?.location,
            pincode: item?.pincode,
            name: item?.name,
            id: item?.id,
          });
        });
        setRobot_order_id(editData?._id);
        setAmount(data?.due_amount);
        setAmount_received(data?.due_amount);
        setRobotName(data?.robot_type?._id);
        setProductCost(data?.product_cost);
        setStatus(data?.payment_status);
        setNoOfRobots(data?.number_of_robots);
        setDescription(data?.description);
        setClientName(data?.company_info?.client_name);
        setCompanyName(data?.company_info?.company_name);
        setCompanyID(data?.company_info?._id)
        setImage(data?.image_urls?.robotOrderImages[0]);
        setBranch(data?.branch_info[0]?._id);
      })
      .catch((err) => {
        console.log("err", err);
      });

    getPaymentList();
  };

  // console.log(amount_received, "amount_received134");
  const getPaymentList = () => {
    robots_order_payments_list(editData?._id)
      .unwrap()
      .then((res) => {
        // console.log("getPaymentList", res);
        setOrderPaymentList([...res?.data]?.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getCompanyManagementList = () => {
    company_management_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = res?.data?.filter((i) => i?.status == true);
          setCompanyManagementList(temp.reverse());
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const GetRobotList = async () => {
    robots_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = res?.data?.filter((i) => i?.status == true);
          setRobotList(temp.reverse());
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    if (type == "edit"|| type=="view") {
      getView();
    }
    getCompanyManagementList();
    GetRobotList();
  }, []);

  const companySelecter = (item) => {
    let temp = comapnyManageList?.find((i) => i?._id == item);
    // console.log("tem", temp);
    setBranchList(temp?.branches);
    setClientName(temp?.clientname);
    setCompanyName(item);
    setBranch("");
    setBranchData({});
  };
  // console.log(robotList, "robotList200");
  // console.log("branchList", branchList);
  // console.log("branchData", branchData);

  const branchSelecter = (item) => {
    // console.log("ite", item);
    // console.log("branchList", branchList);
    let temp = branchList?.find((i) => i?.id == item);
    // console.log("temp", temp);
    setBranchData(temp);
    setBranch(item);
  };

  const handleImage = (even) => {
    let temp = [...image];
    temp.push(even);
    setImage(temp);
  };

  // console.log("image", image);

  //   Change Event Input
  let count = 0;
  const handleOnChange = (e) => {
    const { value, name, files } = e.target;
    if (name && name !== "image") {
      setAddFormData({
        ...addFormData,
        [name]: value,
      });
    } else if (name === "image") {
      count++;
      setAddFormData({
        ...addFormData,
        [`image`]: [...addFormData[`image`], { [name[count]]: files[0] }],
      });
    }
  };
  // console.log(editData, "editData236");
  //   Form Data submit Function
  const handleSubmitForm = async () => {
    if (
      companyName?.length == 0 ||
      branch?.length == 0 ||
      robotName?.length == 0 ||
      description?.length == 0 ||
      noOfRobots?.length == 0 ||
      status?.length == 0 ||
      productCost?.length == 0
    ) {
      setCompanyNameErr(true);
      setBranchErr(true);
      setRobotNameErr(true);
      setDescriptionErr(true);
      setDescriptionErr(true);
      setNoOfRobotsErr(true);
      setStatusErr(true);
      setProductCostErr(true);
    } else {
      if (type === "edit") {
        setLoading(true)
        // console.log("update257");
        const formdata = new FormData();
        // formdata.append("client_name", clientName);
        formdata.append("number_of_robots", noOfRobots);
        formdata.append("product_cost", productCost);
        formdata.append("payment_status", status);
        // formdata.append('image', image[0]);
        formdata.append("company_name", companyNameID);
        formdata.append("company_branch_name", branch);
        formdata.append("description", description);
        // if (image?.length > 0) {
        //   image?.map((data, i) => {
        //     if(data?.name){

        //       formdata.append(`image`, data);
        //     }
        //   });
        // }
          // if(image[0]?.name){

          //   formdata.append(`image`,image[0]);
          // }
        setBtn(true);
        update_robot_order({ payload: formdata, id: editData._id })
          .unwrap()
          .then((res) => {
            // console.log(res, "res260");
            setBtn(false);
            setLoading(false)
            res.status == "success" && navigate(-1);
          })
          .catch((err) => {
            setLoading(false)
            setBtn(false);
            console.log("err", err);
          });
        // update api cal
      } else {
        // add api call\
        setLoading(true)
        const formData = new FormData();

        formData.append("company_name", companyName);
        // formData.append("client_name", clientName);
        formData.append("company_branch_name", branch);
        // formData.append("branch_location", branchData?.branch_location);
        // formData.append("branch_pincode", branchData?.branch_pincode);
        formData.append("robot_type", robotName);
        formData.append("payment_status", status);
        formData.append("product_cost", productCost);
        formData.append("description", description);
        formData.append("number_of_robots", noOfRobots);
        formData.append(`image`, image[0]);

        setBtn(true);
        robot_order_add(formData)
          .unwrap()
          .then((res) => {
            setLoading(false)
            setBtn(false);
            if (res?.status === "success") {
              navigate(-1);
            }
          })
          .catch((err) => {
            setLoading(false)
            setBtn(false);
            console.log(err, "error");
          });
      }
    }
  };
  // console.log(branch, "branch312");
  // console.log(image[0], "image310");
  const handlePayment = () => {
    let formdata = new FormData();
    formdata.append("robot_order_id", robot_order_id);
    formdata.append("amount_received", amount_received);
    formdata.append("transaction_no", transaction_no);
    formdata.append("payment_mode", payment_mode);
    formdata.append("payment_date", payment_date);
    formdata.append("amount", amount);
    formdata.append("attachment", attachment);

    if (
      amount_received?.length == 0 ||
      transaction_no?.length == 0 ||
      payment_mode?.length == 0 ||
      payment_date?.length == 0 ||
      attachment?.length == 0
    ) {
      setRobot_order_idErr(true);
      setAmount_receivedErr(true);
      setTransaction_noErr(true);
      setPayment_modeErr(true);
      setPayment_dateErr(true);
      setAmountErr(true);
      setAttachmentErr(true);
    } else {
      robots_order_payments_add(formdata)
        .unwrap()
        .then((res) => {
          getPaymentList();
          setListShow(false);
          setPayment_date("");
          setTransaction_no("");
          setPayment_mode("");
          getView();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <TopHeader
        type={type === "edit" ? "edit-order-robo" : "add-order-robo"}
      />
      <div className="d-flex ac-jb">
        <div className="tab_cont">
          <div className="position-relative">
            <button
              onClick={() => {
                setSelectTab(1);
                setListShow(false);
              }}
              className={` ${
                selectTab === 1 ? "select_tab" : "unselect_tab"
              } cust-btn menu-list`}
            >
              <h1 className={`${selectTab === 1 ? "text-light" : ""} mb-1`}>
                {type === "view"?"View Robot Order":(type === "edit" ? "Edit Robot Order" : "Add Robot Order")}
              </h1>
            </button>
            <div className="underline position-absolute" />
          </div>
          {(type == "edit"||type=="view") && (
            <div className="position-relative">
              <button
                onClick={() => setSelectTab(2)}
                className={` ${
                  selectTab === 2 ? "select_tab" : "unselect_tab"
                } cust-btn menu-list`}
              >
                <h1 className={`${selectTab === 2 ? "text-light" : ""} mb-1`}>
                  Payment
                </h1>
              </button>
              <div className="underline position-absolute" />
            </div>
          )}
        </div>
        {!listShow && selectTab === 2 && (
          <div>
            <button
              style={{ borderColor: "#25aae1", backgroundColor: "#000" }}
              onClick={() => {
                setSelectTab(2);
                setListShow(true);
              }}
              className="btn-outline_pay text-nowrap primary1 me-4"
            >
              + Add Payment
            </button>
          </div>
        )}
      </div>
      {selectTab === 1 ? (
        <div className="side_pad mt-4">
          <div className="add-food-section">
            <div action="">
              <div className="d-flex flex-wrap">
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Company Name
                  </label>
                  <select
                  disabled={type=='view'?true:false}
                    className="form-control mb-1"
                    value={companyName}
                    onChange={(e) => companySelecter(e.target.value)}
                    name="companyname"
                    id=""
                  >
                    <option value="" disabled selected hidden>
                      Select Company
                    </option>
                    {comapnyManageList?.map((item, ind) => {
                      return (
                        <option value={item?._id} key={ind}>
                          {item?.companyname}
                        </option>
                      );
                    })}
                  </select>
                  {companyName?.length == 0 && companyNameErr && (
                    <p className="text-danger m-0">Select Company Name</p>
                  )}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Client Name
                  </label>
                  <input
                  //  disabled={type=='view'?true:false}
                    name="clientname"
                    type="text"
                    className="form-control mb-1"
                    placeholder="Enter your name"
                    value={clientName}
                    disabled
                    onChange={(e) => setClientName(e.target.value)}
                  />
                </div>

                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <div>
                    <label className="mb-2" htmlFor="">
                      Branch Name
                    </label>

                    <select
                     disabled={type=='view'?true:false}
                      className="form-control mb-1"
                      name="branchname"
                      id=""
                      value={branch}
                      onChange={(e) => branchSelecter(e.target.value)}
                    >
                      <option value="" hidden>
                        Select Branch
                      </option>
                      {branchList?.map((item, ind) => {
                        return (
                          <option value={item?.id} key={ind}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                    {branch?.length == 0 && branchErr && (
                      <p className="text-danger m-0">Select Branch Name</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Robot Type
                  </label>
                  <select
                   disabled={type=='view'?true:false}
                    className="form-control mb-1"
                    name="robottype"
                    id=""
                    onChange={(e) => setRobotName(e.target.value)}
                    value={robotName}
                  >
                    <option value="" hidden>
                      Select Robot Type
                    </option>
                    {robotList?.map((item, ind) => {
                      return (
                        <option value={item?._id} key={ind}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                  {robotName?.length == 0 && robotNameErr && (
                    <p className="text-danger m-0">Select Robot Type</p>
                  )}
                </div>

                <div className="col-md-12 d-flex ac-jc col-6 position-relative mb-3 mt-3 ">
                  <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="col-md-6 px-md-3 px-0 col-6 d-flex ac-jb gap-3 mb-3">
                      <div className="w-50">
                        <label className="mb-2" htmlFor="">
                          {" "}
                          Branch Location
                        </label>
                        <div className="">
                          <input
                          //  disabled={type=='view'?true:false}
                            type="text"
                            name="location"
                            className="form-control mb-1"
                            placeholder="Enter Branch Location"
                            onChange={(e) => handleOnChange(e)}
                            disabled
                            value={branchData?.location}
                          />
                        </div>
                      </div>
                      <div className="w-50">
                        <label className="mb-2" htmlFor="">
                          {" "}
                          Branch Pincode
                        </label>
                        <div className="d-flex w-100">
                          <input
                          //  disabled={type=='view'?true:false}
                            type="text"
                            name="pincode"
                            className="form-control mb-1"
                            placeholder="Enter Branch Pincode"
                            onChange={(e) => handleOnChange(e)}
                            disabled
                            value={branchData?.pincode}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-50 px-md-3 px-0 col-6 d-flex ac-jb gap-3 mb-3">
                      <div className="w-100">
                        <label className="mb-2">Number of Robots</label>
                        <input
                         disabled={type=='view'?true:false}
                          type="text"
                          name="number_of_robots"
                          className="form-control mb-1"
                          placeholder="Enter No of Robot"
                          onChange={(e) => setNoOfRobots(e.target.value)}
                          value={noOfRobots}
                        />
                        {noOfRobots?.length == 0 && noOfRobotsErr && (
                          <p className="text-danger m-0">Enter No of Robot</p>
                        )}
                      </div>
                      {/* <div className="w-50">
                        <label className="mb-2">Robot Order ID</label>
                        <div className="">
                          <input
                            type="text"
                            name="number_of_robots"
                            className="form-control mb-1"
                            placeholder="Enter Robot order id"
                            onChange={(e) => handleOnChange(e)}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Status
                  </label>
                  <select
                   disabled={type=='view'?true:false}
                    name="status"
                    className="form-control mb-1"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                    id=""
                  >
                    <option selected disabled hidden>
                      Status
                    </option>
                    <option value="order_received">Order Received</option>
                    <option value="manufacturing">Manufacturing</option>
                    <option value="under_testing">Under Testing</option>
                    <option value="order_ready">Order Ready</option>
                    <option value="order_dispatched">Order Dispatched</option>
                  </select>
                  {status?.length == 0 && statusErr && (
                    <p className="text-danger m-0">Select Status</p>
                  )}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Product Cost
                  </label>
                  <input
                   disabled={type=='view'?true:false}
                    name="price"
                    type="text"
                    className="form-control mb-1"
                    placeholder="Enter Price"
                    onChange={(e) => setProductCost(e.target.value)}
                    value={productCost}
                  />
                  {productCost?.length == 0 && productCostErr && (
                    <p className="text-danger m-0">Enter Price</p>
                  )}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    image
                  </label>
                  <input
                   disabled={type=='view'?true:false}
                    name="image"
                    type="file"
                    className="form-control mb-1"
                    onChange={(e) => handleImage(e.target.files[0])}
                    accept="application/pdf"
                  />
                  {addFormDataErr?.image && addFormData?.image === "" && (
                    <p className="text-danger m-0">Select Image</p>
                  )}
                  
                {(type=="view"||type=="edit")&&<div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                {image && (
                  <img
                    src={image}
                    style={{ width: "100px" }}
                  />
                )}
              </div>}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Description
                  </label>
                  <textarea
                   disabled={type=='view'?true:false}
                    className="form-control mb-1"
                    name="description"
                    id=""
                    cols="30"
                    rows="4"
                    placeholder="Description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  ></textarea>
                  {description?.length == 0 && descriptionErr && (
                    <p className="text-danger m-0">Enter Description</p>
                  )}
                </div>
                {type!="view" && <div className="px-md-3 pe-3">
                  {type === "edit" ? (
                    <button
                      className="btn-submit"
                      onClick={() => handleSubmitForm()}
                      disabled={btn ? true : false}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className="btn-submit"
                      onClick={() => handleSubmitForm()}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  )}
                </div>}
                {(type =="view"||type=="edit") &&<div onClick={() => setSelectTab(2)} className="btn-outline">
                  <p style={{ margin: 0 }}>Next</p>
                </div>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="side_pad mt-4 pb-4">
          {!listShow && (
            <div className="add-food-section">
              <PaymentListComp orderPaymentList={orderPaymentList} />
              <div className=" w-100 btn_cont">
                <div className="px-md-3 pe-3 btn_cont">
                  <div
                    onClick={() => setSelectTab(1)}
                    className="btn-outline2 cp"
                  >
                    <p style={{ margin: 0 }}>Back</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {selectTab === 2 && listShow && (
        <div className="side_pad mt-4">
          <div className="add-food-section">
            <div action="">
              <div className="d-flex flex-wrap">
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Amount Received
                  </label>
                  <input
                    name="name"
                    type="number"
                    className="form-control mb-1"
                    placeholder="Enter Received Amount"
                    onChange={(e) => setAmount_received(e.target.value)}
                    value={amount_received}
                  />
                  {amount_received?.length == 0 && amount_receivedErr && (
                    <p className="text-danger m-0">Enter Received Amount</p>
                  )}
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Payment Mode
                  </label>
                  <select
                    className="form-control mb-1"
                    name=""
                    id=""
                    onChange={(e) => setPayment_mode(e.target.value)}
                    value={payment_mode}
                  >
                    <option value="" disabled selected hidden>
                      Select Payment Mode
                    </option>
                    <option value="1">Bank</option>
                    <option value="2">UPI</option>
                    <option value="3">G Pay</option>
                    <option value="4">Cash</option>
                  </select>
                  {payment_mode?.length == 0 && payment_modeErr && (
                    <p className="text-danger m-0">Select Payment Mode</p>
                  )}
                </div>

                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <div>
                    <label className="mb-2" htmlFor="">
                      Transaction ID
                    </label>
                    <input
                      name="name"
                      onChange={(e) => setTransaction_no(e.target.value)}
                      value={transaction_no}
                      className="form-control mb-1"
                      placeholder="Enter Transaction Id"
                    />
                    {transaction_no?.length == 0 && transaction_noErr && (
                      <p className="text-danger m-0">Enter Transaction Id</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Payment Date
                  </label>
                  <input
                    name="name"
                    type="date"
                    className="form-control mb-1"
                    placeholder="Enter Payment Date"
                    onChange={(e) => setPayment_date(e.target.value)}
                    value={payment_date}
                  />
                  {payment_date?.length == 0 && payment_dateErr && (
                    <p className="text-danger m-0">Select Payment Date</p>
                  )}
                </div>

                {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Paid Amount
                  </label>
                  <input
                    name="name"
                    type="number"
                    className="form-control"
                    placeholder="Enter Payment Date"
                  />
                </div> */}

                <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Attachment
                  </label>
                  <input
                    name="name"
                    type="file"
                    className="form-control mb-1"
                    onChange={(e) => setAttachment(e.target.files[0])}
                    accept="application/pdf"
                  />
                  {attachment?.length == 0 && attachmentErr && (
                    <p className="text-danger m-0">Upload Attachment</p>
                  )}
                </div>

                {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                  <label className="mb-2" htmlFor="">
                    Due amount
                  </label>
                  <input
                    name="name"
                    type="number"
                    className="form-control"
                    placeholder="Enter Payment Date"
                  />
                </div> */}
                <div className=" w-100 btn_cont">
                  <div className="px-md-3 pe-3 btn_cont">
                    <button
                      onClick={() => handlePayment()}
                      className="btn-submit"
                    >
                      Save
                    </button>
                    <div
                      onClick={() => setListShow(false)}
                      className="btn-outline2 cp"
                    >
                      <p style={{ margin: 0 }}>Back</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {selectTab === 2 && listShow && (
       
      )} */}
    </div>
  );
};

export default AddRobotOrder;
