import React, { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" responsive {...getTableProps()} variant="dark">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const StaticsOrder = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  const onActiveClick = (item) => {
    // tableStatusChange(item?._id)
    //   .unwrap()
    //   .then((res) => {
    //     if (res?.status === "success") {
    //       console.log(res);
    //       // refetchTables();
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  //   delete table data event :
  const onClickDelete = (item) => {};

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Table Name",
        accessor: "table_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => onActiveClick(row.original)}
              className={`${value == 1 ? "btn-success" : "btn-danger"} btn`}
            >
              {value == 1 ? "Active" : "De-Active"}
            </button>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button
                onClick={() =>
                  navigate("/add-table", {
                    state: { type: "edit", data: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary"
              >
                <CreateIcon />
              </button>
              <button
                onClick={() => onClickDelete(row.original)}
                type="button"
                className="btn btn-secondary"
              >
                <CloseOutlinedIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="table-responsive menu-table side_pad mt-4">
      <Table columns={columns} data={list} className="" />
    </div>
  );
};

export default StaticsOrder;
