import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { Search } from "@mui/icons-material";

export const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);
  return (
    <div className="w-100 position-relative">
      <div className="global-filter bg-transparent">
        <div className="d-flex justify-content-center align-items-center">
          {/* <Search style={{ color: "#fff" }} /> */}
          <input
            type="text"
            name="location"
            className="form-control ps-5"
            placeholder="Search..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </div>
      </div>
    </div>

    // <span className="d-flex align-items-center justify-content-end">
    //   <p className="px-2">Search :</p>{" "}
    //   <input
    //     className="form-control ml-2 w-auto p-2 py-1"
    //     value={value || ""}
    //     onChange={(e) => {
    //       setValue(e.target.value);
    //       onChange(e.target.value);
    //     }}
    //   />
    // </span>
  );
};
