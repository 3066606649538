import React from "react";
import { useNavigate } from "react-router-dom";
import LeadList from "../../components/LeadList";
import ScreenHeader from "../../components/ScreenHeader";
import { menu_section_left } from "../../assets/images";
import TopHeader from "../../components/TopHeader";

const LeadManagement = ({ menuOpen }) => {
  const navigate = useNavigate();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"lead-managements"} />
      <ScreenHeader
        title="Lead Management"
        menuLink="/add-Lead"
        menuBtn="Add Lead"
        menuTitle="Check out your Lead"
        description="You can add your Lead from here"
        img={menu_section_left}
      />
      <LeadList />
    </div>
  );
};

export default LeadManagement;
