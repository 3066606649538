import { useState } from "react";
import { menu_section_left } from "../../../assets/images";
import TopHeader from "../../../components/TopHeader";
import useUser from "../../../constants/Storage/userData";
import ScreenHeader from "../../screens/ScreenHeader";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";


const RestaurantViewCategoryCuisine = ({ menuOpen  }) => {
  const user = JSON.parse(localStorage.getItem("resturent_users"));
  // const {user, setUser}=useUser();
  const [data,setdata]=useState();
  const[branches,setbranchdata]=useState();
  const location =useLocation()
  const userdata = location?.state?.data;
  const id= location?.state?.data?.id

  let branchId
  console.log('id',id)

  const branchid=()=>{
  // id==undefined ? branchId=user.company_branch_id:branchId=id 
  if(id==undefined){
    branchId=user?.company_branch_id
    setbranchdata(branchId)
    console.log(branchId,"66")
  }else{
    branchId=id 
    setbranchdata(branchId)
  }
  
}
console.log(branchId,"66r")
  useEffect(()=>{
     
    branchid()
     branchdata()
  },[])
  
  let result
  const branchdata=()=>{
  if(userdata==undefined){
   result= user?.branches.find((item)=>item?.id==branchId)
    console.log(result,"resut")
    setdata(result)
    // data=result
  }else{
    result=userdata
    setdata(result)
    console.log(result,"resut")
  }
  
}

console.log(data,"resut")

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader type={"View_Category_cuisine"}/>
      <div className="menu-list">
        <h1>Category & Cuisine Type</h1>
        <div className="add-food-section row">
          <div className="form-group col-4">
            <label>Branch Name</label>
            <input
              type="text"
              placeholder="Enter Branch Name"
              className="form-control"
              value={data?.name}
              disabled
            />
          </div>
          <div className="form-group col-4">
            <label>Location</label>
            <input
              type="text"
              value={data?.location}
              placeholder="Enter Location"
              className="form-control"
              disabled
            />
          </div>
          <div className="form-group col-4">
            <label>Pin Code</label>
            <input
              type="text"
              value={data?.pincode}
              placeholder="Enter Pin Code"
              className="form-control"
              disabled
            />
          </div>
        </div>
      </div>
      <ScreenHeader
        title=""
        menuLink={[`/restaurant/list?no=${branches}`, `/restaurant/Cousin-List?no=${branches}`]}
        // menuLink={user.company_branch_id?[`/restaurant/list`, `/restaurant/Cousin-List`]:[`/restaurant/list?no=${branchId}`, `/restaurant/Cousin-List?no=${branchId}`]}
        menuBtn={["View Category", "View Cuisine"]}
        menuTitle="Check out your Admin"
        description="You can add your admin from here"
        img={menu_section_left}
      />
    </div>
  );
};

export default RestaurantViewCategoryCuisine;
