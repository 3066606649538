import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { cusine, menu_list } from "../../storage/dummyData";
import {
  useCategory_ListMutation,useCusine_ListMutation,useMenu_addMutation,useLazyMenu_viewQuery,
  useMenu_updateMutation
} from "../../constants/redux/api/api";
import TopHeader from "../../components/TopHeader";
import { toast } from "react-toastify";
import { loadergif } from "../../assets/img";


const RestaurantAddMenu = ({ menuOpen }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const branchData = location?.state?.data;
  const editData =location?.state?.editdata;
  // console.log(editData,"data")
  // console.log(branchData.companyBranch.id,"data")
  console.log('location', location)
  const [allCategData, setAllCategData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedCuisine, setSelectedCuisine] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState({});
  const [price, setPrice] = useState("");
  const [fileName, setFileName] = useState("hhh");
  const [availTime, setAvailTime] = useState([]);
  const [data,setdata]=useState([]);
  const [datacusine,setdatacusine]=useState([]);

  const [selectedCatErr, setSelectedCatErr] = useState(false);
  const[selectedCuisineErr,setSelectedCuisineErr]=useState(false)
  const [nameErr, setNameErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [availabletime,setavailabletime]=useState(false);
  const [disbtn,setdisbtn] =useState(false)

 //RTK query 
 const [categoryList]=useCategory_ListMutation()
 const [Cuisinelist]=useCusine_ListMutation()
 const [addMenu]=useMenu_addMutation()
 const [viewMenu]=useLazyMenu_viewQuery()
 const [updateMenu]=useMenu_updateMutation()


const getview = () =>{
  let id
  if(type=="edit" || type=="view"||type=="views"){
   id = editData?._id
  }else{
    id=branchData?._id
  }
  viewMenu(id)
  .unwrap()
  .then(res=>{
    const data=res?.data;
    setName(data?.Name);
    setSelectedCat(data?.category?.id);
    setSelectedCuisine(data?.cuisine?.id);
    setImage(data?.image_url?.MenuImage);
    setPrice(data?.Price);
   
    const availTime = data?.Food_Available_time.map(option => ({
      name: option,
    }));
    setAvailTime(availTime);
    // console.log(,"editdata")
  })
  .catch(err=>console.log(err))

}

useEffect(()=>{
  if(editData?._id&&(type=="edit" || type=="view"||type=="views")){
    getview()
  }
},[type,editData,branchData])


 const getList = () => {
  const formdata = new URLSearchParams();
  if(type=="edit"|| type=="view"||type=="views"){
    formdata.append("company_branch_id",editData?.companyBranch?.id)
  }else{

    formdata.append("company_branch_id",branchData?.id)
  }
  categoryList(formdata)
    .unwrap()
    .then((res) => {
      if (res?.status == "success") {

        setdata(res?.data)
        console.log(res?.data,'ss')
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
    Cuisinelist(formdata)
    .unwrap()
    .then((res)=>{
      if(res?.status=="success"){
        setdatacusine(res?.data)
      }
    })
    .catch(err=>err)
  


};

useEffect(()=>{
  getList()
},[])

  useEffect(() => {
    setAllCategData(menu_list);
  }, []);

  useEffect(() => {
    let tmp = [];
    allCategData?.forEach((el) => {
      tmp.push({
        categoryName: el?.categoryName,
        categoryNameId: el?.categoryNameId,
      });
    });
    setAllCategories(tmp);
  }, [allCategData]);

  const onCategoryChange = (e) => {
    console.log(e?.target?.value);
    setSelectedCat(e?.target?.value);
  };
  const onCuisineChange = (e) => {
    console.log(e?.target?.value);
    setSelectedCuisine(e?.target?.value);
  };

  const onFoodImageChange = (e) => {
    setImage(e.target.files[0]);
  };
   


  const onSubmit = () => {
    console.log(image);
    if (
      selectedCat?.length === 0 ||selectedCuisine?.length==0||
     (type!="edit"&& image?.size === undefined) ||
      name.length === 0 
       || availTime?.length==0
    ) {
      setSelectedCatErr(true);
      setSelectedCuisineErr(true);
      if(type!="edit"){

        setImageErr(true);
      }
      setNameErr(true);
      setavailabletime(true)

    } else {
      
      setLoading(true);
      const formData = new FormData();
      formData.append("category_id", selectedCat);
      formData.append("cusine_id",selectedCuisine);
      formData.append("Name", name);
      // formData.append("Price", price);
      if(image?.name){

        formData.append("food_image", image);
      }
      availTime?.map((item,ind)=>{
        formData.append(`Food_Available_time[${ind}]`,item?.name);
        
      })
      if (type=="edit"||type=="view"){
        setLoading(true);
        setdisbtn(true)
        let id
        // if(type=="view"){
          id = editData?._id
        // } else{
        //   id = editData?._id
        // }
        updateMenu({payload:formData,id})
        .unwrap()
        .then(res=>{
          navigate(-1)
          setdisbtn(false)
          setLoading(false);
          toast.success(res?.message)
          console.log(res)})
        .catch(err=>{
           setdisbtn(false)
           setLoading(false);
          })
      }else{
        
        formData.append("company_id", branchData?.company_id);
        formData.append("company_branch_id", branchData?.id);
        setdisbtn(true)
        setLoading(true);
        addMenu(formData)
        .unwrap()
        .then(res=>{
          navigate(-1)
          setdisbtn(false)
          setLoading(false);
          toast.success(res?.message)
          console.log(res,"res")})
        .catch(err=>{
          setdisbtn(false)
          setLoading(false);
          console.log(err)})
      }





      // createItem(formData)
      //   .unwrap()
      //   .then((res) => {
      //     if (res?.status === "success") {
      //       console.log(res);
      //       navigate(-1);
      //     }
      //   })
      //   .catch((err) => console.log(err));
    }
  };

  // const onEditSubmit = () => {
  //   if (
  //     selectedCat === "" ||
  //     // image?.size === undefined ||
  //     name === "" ||
  //     price === ""
  //   ) {
  //     setSelectedCatErr(true);
  //     setImageErr(true);
  //     setNameErr(true);
  //     setPriceErr(true);
  //   } else {
  //     const formData = new FormData();
      // formData.append("categoryNameId", selectedCat);
      // formData.append("categoryItemid", editData?.id);
      // formData.append("food", name);
      // formData.append("price", price);
      // if (image?.size) {
      //   formData.append("image", image);
      // }
  //     const formdata = new FormData();
  //   formdata.append("category_id", "65ea535b8c80ce511c2d2e3d");
  //   formdata.append("cusine_id", "65e61a96597aaac53af43e03");
  //   formdata.append("Name", "briya");
  //   formdata.append("Price", "100")
  //   }
  // };

  // useEffect(() => {
  //   if (type === "edit") {
  //     console.log(editData);
  //     const fileNameTmp = editData?.url?.split("/").pop();
  //     setFileName(fileNameTmp);
  //     setPrice(editData?.price);
  //     setName(editData?.food);
  //     setSelectedCat(editData?.categoryNameId);
  //   }
  // }, [type, location, editData]);

  const [foodAvilable, setFoodAvilable] = useState([]);
  const handleATime = (e) => {
    const { avilabletime, value } = e.target;
    setCurrentSet({
      ...currentSet,
      [avilabletime]: value,
    });
  };

  const [fieldSets, setFieldSets] = useState([]);
  const [currentSet, setCurrentSet] = useState({
    location: "",
    name: "",
    pincode: "",
  });

  const handleAddSet = () => {
    setFieldSets([...fieldSets, currentSet]);
    setCurrentSet({
      location: "",
      name: "",
      pincode: "",
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentSet({
      ...currentSet,
      [name]: value,
    });
  };
  const handleRemoveSet = (index) => {
    const updatedFieldSets = [...fieldSets];
    updatedFieldSets.splice(index, 1);
    setFieldSets(updatedFieldSets);
  };
  //   availabel time option
  const timeOption = [
    { name: "All", id: 1 },
    { name: "Breakfast", id: 2 },
    { name: "Lunch", id: 3 },
    { name: "Dinner", id: 4 },
  ];
   console.log(availTime,"rr")
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <TopHeader type={(type=="edit" || type=="view")?"Edit_management":"Add_management"}/>
      <div className="menu-list">
        <h1>{type=="views"?"View Food":(type=="edit" || type=="view")? "Edit Food" : "Add Food"}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">Branch Name</label>
                <input
                  disabled
                  // accept="image/png, image/jpeg, image/jpg, image/webp"
                  type="text"
                  className="form-control"
                  // value={branchData?.name}
                  value={type=="edit"||type=="view"||type=="views"?editData?.companyBranch?.name:branchData?.name}
                />
              
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">Category Type</label>
                <select
                  // disabled={type === "edit" ? true : false}
                  disabled={type=="views"?true:false}
                  value={selectedCat}
                  // onChange={onCategoryChange}
                  onChange={(e)=>setSelectedCat(e.target.value)}
                  className="form-control form-select"
                  style={{ textTransform: "capitalize" }}
                  aria-label="Default select example"
                >
                  <option value={""} hidden selected>
                    Select Category
                  </option>
                  { Array.isArray(data)&&data?.map((item, ind) => {
                    return (
                      <option key={ind} value={item?._id}>
                        {item?.category_name}
                      </option>
                    );
                  })}
                </select>
                {selectedCat?.length==0 && selectedCatErr && (
                  <p className="errtext">Select category</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">Cuisine Type</label>
                <select
                  // disabled={type === "edit" ? true : false}
                  disabled={type=="views"?true:false}
                  value={selectedCuisine}
                  onChange={onCuisineChange}
                  className="form-control form-select"
                  style={{ textTransform: "capitalize" }}
                  aria-label="Default select example"
                >
                  <option value={""} selected hidden>
                    Select Cuisine
                  </option>
                  {datacusine?.map((item, ind) => {
                    return (
                      <option key={ind} value={item?._id}>
                        {item?.cusine_name}
                      </option>
                    );
                  })}
                </select>
                {selectedCuisine.length === 0 && selectedCuisineErr && (
                  <p className="errtext">Select cusien</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">Food Image</label>
                <input
                disabled={type=="views"?true:false}
                  onChange={onFoodImageChange}
                  // accept="image/png, image/jpeg, image/jpg, image/webp"
                  type="file"
                  className="form-control"
                />
                {/* {type === "edit" && <p>Current File: {fileName}</p>} */}
                {image?.size === undefined && imageErr && (
                  <p className="errtext">Select food image</p>
                )}
                 {(type=="view"||type=="edit"||type=="views")&&<div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                {image && (
                  <img
                    src={image}
                    style={{ width: "100px" }}
                  />
                )}
              </div>}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">Food Name</label>
                <input
                disabled={type=="views"?true:false}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Food name"
                />
                {name?.length === 0 && nameErr && (
                  <p className="errtext">Enter food name</p>
                )}
              </div>
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">Price</label>
                <input
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder="Enter price"
                />
                {price?.length ===0 && priceErr && (
                  <p className="errtext">Enter price</p>
                )}
              </div> */}

              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-2">
                <label htmlFor="">Food Available time</label>
                <Multiselect
                disabled={type=="views"?true:false}
                  options={timeOption}
                  // selectedValues={setAvailTime}
                  selectedValues={availTime}
                  onSelect={setAvailTime}
                  displayValue="name"
                  placeholder="Select available Time"
                  className="add_availTime form-control"
                  // onRemove={}
                  // value={availTime}
                  
                />
                  
                {availTime?.length == 0 && availabletime && (
                  <p className="errtext">Select Availabletime</p>
                  )}
              </div>

              {/* <div className="col-6 px-md-3 px-0 position-relative mb-3 align-items-center">
                <label htmlFor="">Ingredient list</label>
                <div className="d-flex align-items-center">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Ingredient list"
                  />
                  <div className="ms-3 mb-4" onClick={handleAddSet}>
                    <button className="border-0 ad-btn">+ Add</button>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-12 px-md-3 px-0 position-relative mb-3 align-items-center">
                {fieldSets.map((fieldSet, index) => (
                  <div className="col-6 ps-0 px-0 position-relative mb-3 align-items-center">
                    <div className="d-flex align-items-center me-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Ingredient list"
                        onChange={handleInputChange}
                      />

                      <button
                        className="px-md-3 ms-3 mb-4 border-0 ad-btn bg-secondary"
                        onClick={() => handleRemoveSet("")}
                      >
                        x
                      </button>
                    </div>
                  </div>
                ))}
              </div> */}

              <div className="w-100 d-flex">
                {type!="views"&&<div
                disabled={disbtn}
                  onClick={ onSubmit}
                  className="px-md-3 pe-3"
                >
                  {
                    (type=="edit" || type=="view")?(
                      <button 
                      disabled={disbtn}
                      className="btn btn-primary">
                        Update
                      </button>
                    ):(
                      <button 
                      disabled={disbtn}
                      className="btn btn-primary">
                        submit
                      </button>
                    )
                  }
                  {/* <input className="btn-submit" type="submit" /> */}
                </div>}
                <div onClick={() => navigate(-1)} class="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantAddMenu;
