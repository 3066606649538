import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { food, menu_section_left } from "../../assets/images";
import { Delete, Edit } from "@mui/icons-material";
import MenuViewFormComp from "./MenuViewFormComp";
import ScreenHeader from "./ScreenHeader";

const RestaturantCompanyViewScreen = ({ menuOpen }) => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const [dropDownList, setDropDownList] = useState();
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <div className="menu-list">
        <h1>Add Menu</h1>
      </div>
      <MenuViewFormComp
        menuOpe={menuOpen}
        dropDownList={dropDownList}
        setDropDownList={setDropDownList}
      />

      <ScreenHeader
        // title3="Add User"
        menuLink="/company-add-user"
        menuBtn="Add User"
        menuTitle="Check out your menu"
        description="You can add your menu from here"
        img={menu_section_left}
      />
      {/* new table start */}
      {dropDownList && (
        <div className="table-responsive menu-table side_pad my-4">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>User Name</th>
                <th>Branch Name</th>
                <th>Role</th>
                <th>Status</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="">
              <tr>
                <td>1</td>
                {/* <td>DunsKart</td> */}
                <td>Rubesh</td>
                <td>Vadapalani</td>
                {/* <td>wait lifted for max 70kg</td> */}
                <td>TL</td>
                <td>
                  <button
                    onClick={() => setStatus((prev) => !prev)}
                    className={`${
                      status ? "bg-success" : "bg-danger"
                    } px-2 py-1 rounded-1 border-0 text-white`}
                  >
                    {status ? "Active" : "Deactive"}
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => navigate("/add-users")}
                    className="bg-primary text-white border-0 rounded-1 p-1 me-2"
                  >
                    <Edit />
                  </button>
                  <button className="bg-warning border-0 rounded-1 p-1">
                    <Delete />
                  </button>
                </td>
              </tr>
              <tr>
                <td>2</td>
                {/* <td>DunsKart</td> */}
                <td>Rubesh</td>
                <td>Vadapalani</td>
                {/* <td>wait lifted for max 70kg</td> */}
                <td>TL</td>
                <td>
                  <button
                    onClick={() => setStatus((prev) => !prev)}
                    className={`${
                      status ? "bg-success" : "bg-danger"
                    } px-2 py-1 rounded-1 border-0 text-white`}
                  >
                    {status ? "Active" : "Deactive"}
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => navigate("/add-users")}
                    className="bg-primary text-white border-0 rounded-1 p-1 me-2"
                  >
                    <Edit />
                  </button>
                  <button className="bg-warning border-0 rounded-1 p-1">
                    <Delete />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {/* new table end */}
    </div>
  );
};

export default RestaturantCompanyViewScreen;
