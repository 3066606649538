import { CheckBoxOutlineBlankRounded } from "@material-ui/icons";
import { Delete, Edit } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
const RobotList = () => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="table-responsive menu-table side_pad mt-4">
      <table>
        <thead>
          <tr>
            <th>Count</th>
            <th>Robot ID</th>
            <th>Robot Name</th>
            <th>Status</th>
            <th>Orders</th>
            {/* <th>Confirm Password</th> */}
          </tr>
        </thead>
        <tbody className="">
          <tr>
            <td>01</td>
            <td> #001</td>
            <td>Dunscart</td>

            <td>
              <CheckCircleIcon className="active_icon me-1" /> Active
            </td>
            <td>
              <button
                onClick={() => navigate("/ind-robot-order-detail")}
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2">
                View
              </button>
            </td>
          </tr>
          <tr>
            <td>02</td>
            <td> #002</td>
            <td>Dunscart</td>

            <td>
              <ErrorIcon className="danger_icon me-1" /> Inactive
            </td>
            <td>
              <button
                onClick={() => navigate("/ind-robot-order-detail")}
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2">
                View
              </button>
            </td>
          </tr>
          <tr>
            <td>03</td>
            <td> #003</td>
            <td>Dunscart</td>

            <td>
              <CheckCircleIcon className="energizing_icon me-1" />
              Energizing
            </td>
            <td>
              <button
                onClick={() => navigate("/ind-robot-order-detail")}
                className="px-3 bg-primary text-white border-0 rounded-1 p-1 me-2">
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RobotList;
