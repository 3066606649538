import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useLazyBranchListQuery,useMenu_listMutation,useLazyMenu_statusQuery,useMenu_deleteMutation} from "../../constants/redux/api/api"
import { Table } from "../Table/Table";
import { Delete, Edit } from "@mui/icons-material";
import Swal from "sweetalert2";
import { GlobalFilter } from "../Table/GlobalFilter";
import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
const MenuViewFormComp = ({ dropDownList, setDropDownList,setBranchData }) => {
  const [branch, setBranch] = useState("");
  const[branchId, setBranchId]=useState("")
  const [name,setname] = useState("")
  const navigate = useNavigate();
  // const [dropDownList, setDropDownList] = useState();
  const [active, setActive] = useState(true);
  const [data,setData] =useState([])
  const [branchdata,setbranchdata]=useState({})
  const [disbtn,setdisbtn] = useState(false)
  const [datas,setdatas] =useState([])
  const [datafil,setdatafil]=useState([])
  
  
  //RTK
  const [menuList]=useMenu_listMutation();
  const [branchList] =useLazyBranchListQuery();
  const [menuState]=useLazyMenu_statusQuery();
  const [menuDelete]=useMenu_deleteMutation();

  // const getfilter =(value)=>{
  //   const formdata =new URLSearchParams();
  //   formdata.append("company_branch_id",branch)
  //   formdata.append("Name",value)
  //   setname(value)
  //   if(value?.length > 3){
  //   filtermenu(formdata)
  //   .unwrap()
  //   .then(res=>{
  //     console.log(res,"menu")
  //     setdatas(res?.data)
  //   })
  //   .catch(err=>err)
  // }
    
  // }
  const getmenulist=(value)=>{
    setBranchId(value)
    const id = branchdata
    // console.log(id,"tttttttttt")
    setBranch(value)
    const formdata = new URLSearchParams()
    formdata.append("company_branch_id",value)
  
    menuList(formdata)
    .unwrap()
    .then(res=>{
      console.log(res)
      setdatas(res?.data)
      setdatafil(res?.data)
      setBranchData(res?.data)
    })
    .catch(err=>err)
   }

   

  // useEffect(()=>{
  //   getmenulist()
  // },[branch])
  
  const deletemenu=(id,value)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
      setdisbtn(true)
    menuDelete(id)
    .unwrap()
    .then(res=>{
      setdisbtn(false)
      getmenulist(value)
      toast.success(res?.message)
    })
    .catch(err=>{
      setdisbtn (false)
      console.log(err)})
   }
  })
   }

  // useEffect(()=>{
  //   getfilter()
  // },[])

  console.log('branch', branch);

 const getStatus=(id,value)=>{
  setdisbtn(true)
    menuState(id)
    .unwrap()
    .then(res=>{
      setdisbtn(false)
      console.log(res)
      console.log('value2', branch);
      toast.success(res?.message)
      getmenulist(value)
    })
    .catch(err=>setdisbtn(false))
   }



  const getList = () => {
    branchList()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res,"list")
          const temp = [...res?.data?.branches].reverse();
          // setBranch(res?.data?.branches?.id)
          setData(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
   
  const filterdata=(value)=>{
    setname(value)
    // console.log(value)
    // console.log(name,"nmae")
    const filter = datafil.filter(item=>
      item?.Name.toLowerCase().includes(value.toLowerCase())
    )
    setdatas(filter)
    setBranchData(filter)
    console.log(filter,"filter")
  }

  const branchdataid=(value)=>{
    // setname(value)
    console.log('value', value);
    // setBranch(value)
   const filtered = data.find(item=>
    item?.id==value
    )
    console.log('filtered', filtered);
    setbranchdata(filtered)
    
  }

 console.log("branchId", branchId)
 const onClick=()=>{
  window.location.reload();
 }

  
  useEffect(()=>{
    getList()
  },[])
  const columns = React.useMemo(
    () => [
      {
        
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Food",
        accessor: "Name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Food Image",
        accessor: "image_url.MenuImage",
        Cell: ({ value, row }) => {
          return <img src={value} />;
        },
      },
      // {
      //   Header: "Price",
      //   accessor: "Price",
      //   Cell: ({ value, row }) => {
      //     return <p>{value}</p>;
      //   },
      // },
   
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
            disabled={disbtn}
            onClick={() =>
              getStatus(row?.original?._id,row?.original?.companyBranch?.id
                )
            }
            className={`${
              value
                ? "btn-success"
                : "btn-danger"
            } btn`}
          >
            {value ? "Active" : "Deactive"}
          </button>
          )
        },
      },
     
     
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          // console.log(row)
          return (
            <div className="d-flex justify-content-center gap-2">
              {/* <button
                onClick={() =>
                  navigate("/restaurant/add", {
                    state: {
                      type: "edit",
                      data: row.original,
                    
                    },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button> */}
              {/* <button
                        onClick={() => navigate("/restaurant/Cousin-add",{
                          state:{
                            type:'edit',
                            data:row?.original
                          }
                        })}
                        className="bg-primary text-white border-0 rounded-1 p-1 me-2"
                      >
                        <Edit />
                      </button> */}
                <button onClick={
                  
                  ()=>navigate("/restaurant/add-menu",{state:{
                    
                  type:"view",
                  editdata:row.original,
                  // branchList:branchdata,

                  
              }})}  
                
                className="bg-primary text-white border-0 rounded-1 p-1 me-2">
                  <Edit />
                </button>
                <button
                onClick={() =>
                  navigate("/restaurant/add-menu", {
                    state: { type: "views", editdata: row.original },
                  })
                }
                type="button"
                className="btn btn-secondary text-white border-0 rounded-1 p-1 me-2"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  
  return (
    <div className="side_pad">
      <div className="add-food-section">
        <div action="" className="d-flex">
          <div className="d-flex flex-wrap w-100">
            <div className="col-md-4 col-12 px-md-3 px-0 mb-3">
              <label className="mb-2" htmlFor="">
                Branch Name
              </label>
              <select
                className="form-control"
                placeholder="Select Branch"
                value={branch}
                onChange={(e) =>{getmenulist(e.target.value)}}
              >
                <option value="" hidden selected>Select Branch</option>
                
                {data.map((item,i)=>{
                  return(
                    <option value={item.id}>
                      {item?.name}
                    </option>
                  )
                })}
                {/* <option value={"All"}>All</option> */}
              </select>
            </div>
            <div className="col-md-3 col-12 px-md-3 px-0 mb-3">
              <label className="mb-2" htmlFor="">
                Food
              </label>
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder="Enter Food Name"
                value={name}
                onChange={(e)=>filterdata(e.target.value)
                }
              />
            </div>
            <div className="col-md-4 col-12 px-md-9 px-0 mb-3">
             <label className="mb-7 text-dark"></label>
             <button className="btn-outline form-control"
             onClick={onClick}
             >
              Reset
             </button>
            </div>
          </div>
          {/* <Col className="d-flex justify-content-end">
          <div class="menu-list d-flex ac-jb w-100">
            <div class="w-100"></div>
            <div class="w-100 position-relative">
              <GlobalFilter
              //  filter={globalFilter} setFilter={setGlobalFilter}
              />
            </div>
          </div> */}
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        {/* </Col> */}
        </div>
      </div>
      <div className="table-responsive menu-table side_pad mt-4">
          
            {datas.length>0&&<Table data={datas} columns={columns}/>}
            
          </div>
    </div>
  );
};

export default MenuViewFormComp;
