import React, { useState ,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Search } from "@mui/icons-material";
import { menu_list } from "../../storage/dummyData";
import MenuViewFormComp from "../screens/MenuViewFormComp";
import { Table } from "../Table/Table";
import {useLazyBranchListQuery} from "../../constants/redux/api/api"
import TopHeader from "../../components/TopHeader";
import { toast } from "react-toastify";

//RTk

const RestaurantMenuManageList = ({ menuOpen }) => {
  const navigate = useNavigate();
  const [dropDownList, setDropDownList] = useState();
  const [active, setActive] = useState(true);
  const [data,setData] =useState([])
  const [branchdata,setBranchData] =useState([])
  
  
  const [branchList] =useLazyBranchListQuery();
  const getList = () => {
    branchList()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res,"list")
         
          const temp = [...res?.data?.branches].reverse();
          setData(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  useEffect(()=>{
    getList()
  },[])

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Branch Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Pin Code",
        accessor: "pincode",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
     
     
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              {/* {console.log(row.original)} */}
              <button
                onClick={() =>
                  navigate("/restaurant/menu-view", {
                    state: {
                      type: "view",
                      data: row.original,
                    },
                  })
                }
                type="button"
                className="btn btn-secondary px-4"
              >
                <VisibilityIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{
        transition: "0.5s",
      }}
    >
      <TopHeader type={"menu_management"}/>
      <div className="menu-list d-flex ac-jb w-100">
        <div className="w-100">
          <h1>Menu Management</h1>
        </div>
        {/* <div className="w-50 position-relative"> */}
          {/* <div className="add-food-section bg-transparent">
            <input
              type="text"
              // value={"Robot Type 2"}
              name="location"
              className="form-control ps-5"
              placeholder="Search..."
            />
          </div> */}
          {/* <div style={{ position: "absolute", top: 30, left: 34 }}>
            <Search />
          </div> */}
        {/* </div> */}
      </div>

      <div className="menu-list">
        <h1>Filter</h1>
      </div>
      <MenuViewFormComp
        menuOpen={menuOpen}
        dropDownList={dropDownList}
        setDropDownList={setDropDownList}
        setBranchData={setBranchData}
      />

      {/* new table start */}
      {/* <div className="table-responsive menu-table side_pad">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Branch Name</th>
              <th>Location</th>
              <th>Pin Code</th> */}
              {/* <th>Robot Count</th> */}
              {/* <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-capitalize">
            {menu_list?.map((item, ind) => {
              return (
                <tr> */}
                  {/* <td>{ind + 1}</td>
                  <td>{item?.company}</td>
                  <td>{item?.client_name}</td>
                  <td>{item?.viewcount}</td> */}
                  {/* <td>{item?.branchcount}</td> */}
                  {/* <td className="text-center">
                    <div className="btn-group">
                      <td className="text-center">
                        <div className="btn-group">
                          <button
                            onClick={() =>
                              navigate("/restaurant/menu-view", {
                                state: { type: "edit", data: item },
                              })
                            }
                            type="button"
                            className="btn btn-secondary px-4"
                          >
                            <VisibilityIcon /> */}
                          {/* </button> */}
                        {/* </div>
                      </td>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}
      {/* new table end */}
      <div className="table-responsive menu-table side_pad mt-4">
          {branchdata?.length==0 && <Table data={data} columns={columns}/> }
          </div>
    </div>
  );
};

export default RestaurantMenuManageList;
