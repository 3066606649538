import { useState } from "react";

export default function useUser() {
  const getuser = () => {
    const userString = JSON.parse(localStorage.getItem("userdetails"));

    return userString;
  };

  const [user, setUser] = useState(getuser());

  const saveUser = (userDetails) => {
    localStorage.setItem("userdetails", JSON.stringify(userDetails));
    setUser(userDetails);
  };

  return {
    setUser: saveUser,
    user,
  };
}
