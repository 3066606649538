import React from "react";
import context from "react-bootstrap/esm/AccordionContext";
import { useNavigate } from "react-router-dom";

const ScreenHeader = ({
  dataes,
  title,
  menuLink,
  menuTitle,
  description,
  menuBtn,
  img,
  Subtitle,
  symbol,
  
}) => {
  const navigate = useNavigate();
  console.log(menuLink,"null")
  return (
    <div className="">
      <div className="menu-list">
        <h1>{title}</h1>
      </div>
      {Subtitle && (
        <div className="menu-list">
          <h4>{Subtitle}</h4>
        </div>
      )}
      <div className="side_pad">
        <div className="menu_section">
          <div className="align-items-center justify-content-between d-flex flex-md-wrap">
            <div className="align-items-center d-flex ">
              <div className="px-2">
                <img src={img} alt="decor" />
              </div>
              <div className="px-2 ">
                <h5>{menuTitle}</h5>
                <p>{description}</p>
              </div>
            </div>
            <div className="d-flex gap-2">
              {Array.isArray(menuBtn) &&
                menuBtn.length > 0 &&
                menuBtn.map((data, i) => {
                  return (
                    <>
                      {symbol && (
                        <button
                          onClick={() =>{
                            console.log(data,"dat")
                            navigate(
                              Array.isArray(menuLink) ? menuLink[i] : menuLink
                            )
                          }
                          }
                          className="btn-outline"
                          key={i}
                        >
                          {symbol + "  " + data}
                        </button>
                      )}
                      {symbol == undefined && (
                        <button
                          onClick={() =>
                            
                            navigate(
                              Array.isArray(menuLink) ? menuLink[i] : menuLink
                            )
                          }
                          className="btn-outline"
                          key={i}
                        >
                          {data}
                        </button>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenHeader;
