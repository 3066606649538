import { CheckBoxOutlineBlankRounded } from "@material-ui/icons";
import { Delete, Edit } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const BranchTableList = ({ setListShow }) => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="table-responsive menu-table side_pad mt-4">
      <table>
        <thead>
          <tr>
            <th>Count</th>
            <th>Company Name</th>
            <th>Branch Name</th>
            <th>Point ID</th>
            <th>Point Name</th>
            <th>Action</th>
            {/* <th>Confirm Password</th> */}
          </tr>
        </thead>
        <tbody className="">
          <tr>
            <td>01</td>
            <td>Dunscart</td>
            <td>Dunscart</td>
            <td>Dunscart</td>
            <td> #001</td>
            <td>
              <button
                onClick={() => {
                  navigate("/add-robot-edit");
                }}
                className="bg-primary text-white border-0 rounded-1 p-1 me-2">
                <Edit />
              </button>
              <button className="bg-warning border-0 rounded-1 p-1">
                <Delete />
              </button>
            </td>
          </tr>
          <tr>
            <td>02</td>
            <td>Dunscart</td>
            <td>Dunscart</td>
            <td>Dunscart</td>
            <td> #002</td>

            <td>
              <button
                onClick={() => {
                  navigate("/add-robot-edit");
                }}
                className="bg-primary text-white border-0 rounded-1 p-1 me-2">
                <Edit />
              </button>
              <button className="bg-warning border-0 rounded-1 p-1">
                <Delete />
              </button>
            </td>
          </tr>
          <tr>
            <td>03</td>
            <td>Dunscart</td>
            <td>Dunscart</td>
            <td>Dunscart</td>
            <td> #003</td>

            <td>
              <button className="bg-primary text-white border-0 rounded-1 p-1 me-2">
                <Edit />
              </button>
              <button className="bg-warning border-0 rounded-1 p-1">
                <Delete />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default BranchTableList;
