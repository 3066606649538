import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { langingPageBg, successAnimation } from "../assets/img";
import Websocket from "../components/Websocket";

const FinalPage = () => {
  const navigate = useNavigate();
  const storage = localStorage.getItem("name");
  const [socket, setSocket] = useState(false);
  const [recieve, setRecieve] = useState("");

  useEffect(() => {
    let split = recieve.split(":");
    console.log("split", split);
    if (storage == split[0] && split[1] == "home") {
      navigate("/welcome");
    }
  }, [recieve]);

  return (
    <>
      {socket && <Websocket setRecieve={setRecieve} type={"exit"} />}
      {/* <div className="landing-page-container">
        <img src={langingPageBg} />
        <div className="gradient"></div>
        <div className="content-wrapper">
          <h1 style={{ marginBottom: "10px" }}>Hello Buddy!</h1>
          <h3 style={{ marginBottom: "30px" }}>
            Please take your order from serving tray
          </h3>
          <button
            className="link"
            to="/select-table"
            style={{ border: "none", width: "25" }}
            onClick={() => setSocket(true)}>
            Home
          </button>
        </div>
      </div> */}
      <div style={{ padding: "20px" }} className="success-popup">
        <video autoPlay muted>
          <source src={successAnimation} type="video/mp4" />
        </video>
        <h1>Please take your order from serving tray!!!</h1>
        <div onClick={() => setSocket(true)} className="back-btn">
          Back to Home
        </div>
      </div>
    </>
  );
};

export default FinalPage;
