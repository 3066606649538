import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAdmin_addMutation,
  useAdmin_updateMutation,
  useAdmin_viewMutation,
  useRole_listMutation,
} from "../constants/redux/api/api";
import { validation as regexValid } from "../constants/validation";
import { toast } from "react-toastify";
import TopHeader from "../components/TopHeader";
import { loadergif } from "../assets/img";


const AddAdmins = ({ menuOpen }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;
  const [validation, setValidation] = useState({
    name: "",
    email: "",
    mobile: null,
    password: "",
    confirmPassword: "",
    role: "",
  });
  const [roleList, setRoleList] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [profile,setProfile]=useState("")

  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [match, setMatch] = useState(false);
  const [emailRegexerr, setEmailRegexErr] = useState(false);
  const [passMinErr, setPassMinErr] = useState(false);
  const [profileErr,setProfileErr]=useState(false);

  const [btn, setBtn] = useState(false);

  //   RTK QUERY
  const [admin_add] = useAdmin_addMutation();
  const [role_list] = useRole_listMutation();
  const [admin_update] = useAdmin_updateMutation();
  const [userView] = useAdmin_viewMutation();

  const getView = () => {
    userView(editData?._id)
      .unwrap()
      .then((res) => {
        console.log("editData", res);

        let data = res?.data;
        setName(data?.name);
        setPhone(data?.mobile);
        setEmail(data?.email);
        setRole(data?.role);
        
        setProfile(data?.imageUrl)
      })
      .catch((err) => {
        console.log("err");
      });
  };

  //   Local Storage variable
  let user = localStorage.getItem("user");
  let userId = JSON.parse(user)?._id;

  const onChangeFormInput = (e) => {
    const { name, email, mobile, password, confirmPassword, role } = validation;
    if (e.target.name === "mobile") {
      let inputVal = e.target.value.replace(/\D/g, "");
      inputVal?.length <= 10 &&
        setValidation({
          ...validation,
          [e.target.name]: e.target.value,
        });
    } else if (e.target.name === "password") {
      let inputVal = e.target.value;
      inputVal.length < 6 ? setPassMinErr(true) : setPassMinErr(false);
      setValidation({
        ...validation,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name !== "mobile") {
      setValidation({
        ...validation,
        [e.target.name]: e.target.value,
      });
    }

    // regex validation
    if (email && !email.toLowerCase().match(regexValid?.email)) {
      setEmailRegexErr(true);
    } else {
      setEmailRegexErr(false);
    }

    if (e.target.name === "confirmPassword") {
      e.target.value !== validation?.password
        ? setMatch(true)
        : setMatch(false);
    }
  };

  const onSubmit = (e) => {
    if (
      name?.length == 0 ||
      email?.length == 0 ||
      phone?.length < 10 ||
      (type !== "edit" && password?.length == 0) ||
      (type !== "edit" && confirmPassword?.length == 0) ||
      role?.length == 0 ||
      profile?.length == ""
    ) {
      setNameErr(true);
      setEmailErr(true);
      setPhoneErr(true);
      setRoleErr(true);
      if (type !== "edit") {
        setPasswordErr(true);
        setConfirmPasswordErr(true);
      }
      setProfileErr(true);
    } else {
      var urlencoded = new FormData();

      urlencoded.append("name", name);
      urlencoded.append("email", email);
      urlencoded.append("mobile", phone);
      urlencoded.append("role", role);

      if(profile?.name){
        urlencoded.append("profile_image",profile)
      }
  
    

      setLoading(true)
      setDisableBtn(true);
      if (type === "edit") {

        if (password) {
          urlencoded.append("password", password);
          urlencoded.append("confirm_password", confirmPassword);
        }
        let id = editData?._id;
        delete validation[`password`];
        delete validation[`confirmPassword`];
        admin_update({ payload: urlencoded, id })
          .unwrap()
          .then((res) => {
            setLoading(false)
            setDisableBtn(false);
            if (res?.status === "success") {
              navigate(-1);
              toast.success(res?.message);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false)
            setDisableBtn(false);
          });
      } else {
        if (password) {
          urlencoded.append("password", password);
          urlencoded.append("confirmPassword", confirmPassword);
        }
        setLoading(true)
        admin_add(urlencoded)
          .unwrap()
          .then((res) => {
            setLoading(false)
            setDisableBtn(false);
            if (res?.status === "success") {
              navigate(-1);
            }
          })
          .catch((err) => {
            setLoading(false)
            console.log(err);
            setDisableBtn(false);
          });
      }
    }
  };

  useEffect(() => {
    if (type === "edit" || type==="view") {
      getView();
    }
  }, [type, editData]);

  const RoleList = async () => {
    role_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = res?.data?.filter((i) => i?.status == true);
          setRoleList(temp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // role list
  useEffect(() => {
    RoleList();
    
  }, []);

  console.log("roleList", profile);
  const urlToObject= async()=> {
    const response = await fetch(profile);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', {type: blob.type});
    
    setProfile(file)
  }
  
  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      {loading && (
      <div
        style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}>  
        <span style={{ marginLeft: 10 }}><img src={loadergif}/></span>
      </div>
    )}
      <TopHeader type={type === "edit" ? "editAdmin" : "addAdmin"} />
      <div className="menu-list">
        <h1>{ type === "view" ?"View Admin":(type === "edit" ? "Edit Admin" : "Add Admin")}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Name
                  <span className="text-danger">*</span>
                </label>
                <input
                  disabled={type === "view"?true:false}
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
                {name?.length == 0 && nameErr && (
                  <p className="err-state">Enter User name.</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Email
                  <span className="text-danger">*</span>
                </label>
                <input
                 disabled={type === "view"?true:false}
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Email name"
                />
                {email === "" && emailErr && (
                  <p className="err-state">Enter Email name.</p>
                )}
                {emailRegexerr && email !== "" && (
                  <p className="err-state">Please Enter valid email id.</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Phone No
                  <span className="text-danger">*</span>
                </label>
                <input
                disabled={type === "view"?true:false}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 10) {
                      e.target.setCustomValidity("invalid Number");
                    } else if (e.target.value.length == 10) {
                      e.target.setCustomValidity("");

                      setPhone(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxLength={10}
                  name="mobile"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  type="number"
                  className="form-control"
                  placeholder="Enter Phone No"
                  value={phone}
                />
                {phone === "" && phoneErr && (
                  <p className="err-state">Enter Phone No.</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Role
                  <span className="text-danger">*</span>
                </label>
                <select
                  disabled={type === "view"?true:false}
                  className="form-control"
                  name="role"
                  id=""
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                >
                  <option value="">Select User Role</option>
                  {roleList?.map((data, i) => {
                    const { name, _id } = data;
                    return (
                      <option key={i} value={_id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                {role === "" && roleErr && (
                  <p className="err-state">Select Role.</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Password
                  <span className="text-danger">*</span>
                </label>
                <input
                disabled={type === "view"?true:false}
                  value={password}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                />
                {password === "" && passwordErr && (
                  <p className="err-state">Enter Password.</p>
                )}
                {password !== "" && passMinErr && (
                  <p className="err-state">
                    Password minimum 6 digit required.
                  </p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Confirm Password
                  <span className="text-danger">*</span>
                </label>
                <input
                disabled={type === "view"?true:false}
                  value={confirmPassword}
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                />
                {confirmPassword === "" && confirmPasswordErr && (
                  <p className="err-state">Enter Confirm Password</p>
                )}
                {confirmPassword?.length > 1 &&
                  password !== confirmPassword && (
                    <p className="err-state">
                      Confirm Password does not match.
                    </p>
                  )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Profile Upload(jpeg,jpg,png)
                  <span className="text-danger">*</span>
                </label>
                <input
                  disabled={type === "view"?true:false}
                  // value={profile}
                  name="profile"
                  type="file"
                  onChange={(e) => setProfile(e.target.files[0])}
                  className="form-control mb-1"
                />
                {profile?.length == "" && profileErr && (
                  <p className="mb-0 text-danger">Profile image Required</p>
                )}
                {(type=="view"||type=="edit")&&<div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                {profile && (
                  <img
                    src={profile} alt="profile"
                    style={{ width: "100px" }}
                  />
                )}
              </div>}
              </div>
              
              {type!="view" &&<div className="col-md-12 col-12 px-md-0 px-0  d-flex  position-relative mb-3">
                <div className="px-md-3 pe-3 d-flex" >
                  {type === "edit" ? (
                    <button
                      className="btn-submit"
                      onClick={() => onSubmit()}
                      disabled={disableBtn ? true : false}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className="btn-submit"
                      onClick={() => onSubmit()}
                      disabled={disableBtn ? true : false}
                    >
                      Submit
                    </button>
                  )}
                </div>
                <div onClick={() => navigate(-1)} className="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmins;
