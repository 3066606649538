import React, { useState, useRef,useEffect} from "react";
import JoditEditor from "jodit-react";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { useHirobotspeechMutation,  useCompany_management_listMutation,
  useRobotOrderBranchListMutation,useHi_robot_viewMutation, 
  useUpdate_hirobotMutation} from "../../constants/redux/api/api";
import { htmlToText } from 'html-to-text';

const Hispeech = ({ id , menuOpen } ) => {
  const [inputs, setInputs] = useState([{question: "", keyword: [""], answer: "" }]);
  const [conversation, setConversation] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [robotList, setRobotList] = useState([]);
  const [robotIdChe, setRobotIdChe] = useState("");
  const [robotType, setrobotType] = useState("");
  const editData = location?.state?.data;

  const [trim,settrim]=useState("")

  //   RTK QUERY
  const [robotOrderList] = useRobotOrderBranchListMutation();
  const [speechadd] = useHirobotspeechMutation();
  const [robot_order_list] = useCompany_management_listMutation();
  const [robotViewApi] = useHi_robot_viewMutation();
  const [robotupdateApi]=useUpdate_hirobotMutation();


  const editor = useRef(null);
  const [submitBtn, setSubmitBtn] = useState(false);
  const [inputErr, setInputErr] = useState(false);
  const [conversationErr, setConversationErr] = useState(false);
  const data = location.state?.data || {};
  const type = location?.state?.type;
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [comapnyManageList, setCompanyManagementList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [clientName, setClientName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [branch, setBranch] = useState("");
  const [branchData, setBranchData] = useState({});
  const [branchErr, setBranchErr] = useState(false);
  const [robotId, setRobotId] = useState([]);
  const [docs,setDocs]=useState("")


  const handleContentChange = (newContent) => {
    setConversation(htmlToText(newContent, { wordwrap: 130 }));
  };

  const handleInputChange = (id, event) => {
    const { name, value } = event.target;
    const updatedInputs = inputs.map((input) =>
      input.id === id ? { ...input, [name]: value } : input
    );
    setInputs(updatedInputs);
  };
  const companySelecter = (item) => {
    let temp = comapnyManageList?.find((i) => i?._id == item);
    setCompanyName(item);
    setCompanyId(item);
    setBranchList(temp?.branches);
    setClientName(temp?.name);
  };
  const branchSelecter = (item) => {
    let temp = branchList?.find((i) => i?.id == item);
    setBranch(item);
    setBranchData(temp);

    let obj = {
      company_branch_name: item,
      company_name: companyName,
    };

    robotOrderList(obj)
      .unwrap()
      .then((res) => {
        let temp = res?.data?.filter((i) => i?.status == true);
        setRobotList(temp);
        if (type !== "edit") {
          setRobotIdChe("");
          setrobotType("");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanyManagementList();
  }, []);
  useEffect(()=>{
    if(docs&&(type == "edit" || type=="view")){
      doc()
    }
    },[docs])
    
      useEffect(() => {
        if (type == "edit" || type=="view"&& comapnyManageList?.length > 0) {
          getVIew();
        }
      }, [comapnyManageList, type]);
  const getCompanyManagementList = async () => {
    robot_order_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = res?.data?.filter((i) => i?.status == true);
          setCompanyManagementList(temp.reverse());
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const selectRobotOrder = (id) => {
    let finder = robotList?.find((i) => i?.robot_type?._id == id);
    setrobotType(finder?.robot_type?.name);
    setRobotIdChe(finder?.robot_type?._id);
    let temp = [];
    for (let i = 0; i < finder?.number_of_robots; i++) {
      temp.push(i);
    }
    setRobotId(temp);
  };
  const getVIew = () => {
    robotViewApi(editData?._id)
      .unwrap()
      .then((res) => {
        console.log("ddd", res);
        if (res?.status == "success") {
          let data = res?.data?.[0];
          
          companySelecter(data?.company_id?._id, data?.company_branch_id?._id);
          branchSelecter(data?.company_branch_id?._id);          
          setRobotIdChe(data?.robot_id?._id);
          setrobotType(data?.robot_details?.name);
          setBranchData({
            location: data?.company_branch_id?.location,
            pincode: data?.company_branch_id?.pincode,
          });
          setConversation(data?.conversation);
          
          // Handle speeches
          let tempSpeeches = data?.speeches.map(item => ({
            id: item?._id,
            question: item?.question,
            answer: item?.answer,
            keyword: item?.keywords?.map(kw => kw.keyword) || [""],          }));
          setInputs(tempSpeeches);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  
const doc=()=>{
  const url = docs
  const trimmedFilename = url.substring(url.lastIndexOf('/') + 1);
   settrim(trimmedFilename);
}
  const handleKeywordChange = (inputId, keywordIndex, event) => {
    const { value } = event.target;
    const updatedInputs = inputs.map((input) =>
      input.id === inputId
        ? {
            ...input,
            keyword: input.keyword.map((kw, idx) =>
              idx === keywordIndex ? value : kw
            ),
          }
        : input
    );
    setInputs(updatedInputs);
  };

  const handleKeywordClose = (inputId, keywordIndex) => {
    const updatedInputs = inputs.map((input) =>
      input.id === inputId
        ? {
            ...input,
            keyword: input.keyword.filter((_, idx) => idx !== keywordIndex),
          }
        : input
    );
    setInputs(updatedInputs);
  };

  const handleSubmit = () => {
    if (!inputs.length || !conversation.trim()) {
      setInputErr(!inputs.length);
      setConversationErr(!conversation.trim());
      return;
    }
  
    const formattedMessages = inputs.map((input) => {
      let formattedInput = { _id: input._id, question: input.question, answer: input.answer };
  
      const keywordMap = {}; 
      input.keyword.forEach((kw, kwIndex) => {
        if (typeof kw === 'string' && kw.trim()) {
          const keywordsArray = kw.split(',').map(kwItem => kwItem.trim()).filter(Boolean);
          keywordsArray.forEach((keyword, index) => {
            keywordMap[`keyword${kwIndex === 0 ? '' : kwIndex}${index === 0 ? '' : index}`] = keyword;
          });
        } else if (Array.isArray(kw)) {
          kw.forEach((keyword, index) => {
            keywordMap[`keyword${kwIndex === 0 ? '' : kwIndex}${index === 0 ? '' : index}`] = keyword;
          });
        }
      });
  
      Object.assign(formattedInput, keywordMap);
  
      return formattedInput;
    });
  
    // Common payload
    const data = {
      conversation,
      messages: formattedMessages,
      company_id: companyId,
      company_branch_id: branch,
      robot_id: robotIdChe
    };
  
    if (type === "edit") {
      if (inputs.length > 0) {
        const updatedMessages = inputs.map((item) => {
          let formattedInput = {
            question: item.question,
            answer: item.answer
          };
  
          if (item?.id) {
            formattedInput._id = item.id;
          }
  
          const keywordMap = {}; 
  
          item.keyword.forEach((kw, kwIndex) => {
            if (typeof kw === 'string' && kw.trim()) {
              const keywordsArray = kw.split(',').map(kwItem => kwItem.trim()).filter(Boolean);
              keywordsArray.forEach((keyword, index) => {
                keywordMap[`keyword${kwIndex === 0 ? '' : kwIndex}${index === 0 ? '' : index}`] = keyword;
              });
            } else if (Array.isArray(kw)) {
              kw.forEach((keyword, index) => {
                keywordMap[`keyword${kwIndex === 0 ? '' : kwIndex}${index === 0 ? '' : index}`] = keyword;
              });
            }
          });
  
          Object.assign(formattedInput, keywordMap);
  
          return formattedInput;
        });
  
        const updateData = {
          conversation,
          messages: updatedMessages,
          company_id: companyId,
          company_branch_id: branch
        };
  
        robotupdateApi({ payload: updateData, id: editData?._id })
          .unwrap()
          .then((res) => {
            if (res?.status === "success") {
              navigate(-1);
              setSubmitBtn(false);
            }
          })
          .catch((err) => {
            console.log("Submission error:", err);
          });
      }
    } else {
      speechadd(data)
        .unwrap()
        .then((res) => {
          if (res?.status === "success") {
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log("Submission error:", err);
        });
    }
  };
  
  const addInput = () => {
    setInputs([...inputs, {question: "", keyword: [""], answer: "" }]);
  };

  const addKeywordField = (inputId) => {
    const updatedInputs = inputs.map((input) =>
      input.id === inputId
        ? { ...input, keyword: [...input.keyword, ""] }
        : input
    );
    setInputs(updatedInputs);
  };

  const handleClose = (id) => {
    if (inputs.length > 1) {
      setInputs(inputs.filter((input) => input.id !== id));
    }
  };

  return (
    <div  className={menuOpen ? "side-content2" : "side-content"}
    style={{ transition: "0.5s" }} >
    <div className="mt-3" style={{ color: "#000" }}>
      <h4 className="head-text">Welcome Conversation</h4>
      <JoditEditor
        ref={editor}
        value={conversation}
        config={{ readonly: false }}
        tabIndex={1}
        onChange={handleContentChange}
      />
         <div className="d-flex justify-content-between mt-4 w-100 tabbs" >
         <div action="">
         <div className="d-flex flex-wrap">
        <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2 " for="">
                  Company Name
                </label>
                <select
                  disabled={type=='view'||type=="edit"?true:false}
                  className="form-control mb-1"
                  name="company_id"
                  id=""
                  value={companyName}
                  onChange={(e) => {
                    companySelecter(e.target.value);
                  }}
                  // disabled={type == "edit" ? true : false}
                >
                  <option hidden>Select company</option>
                  {comapnyManageList?.map((item, ind) => {
                    return (
                      <option value={item?._id} key={ind}>
                        {item?.companyname}
                      </option>
                    );
                  })}
                </select>
                {companyName?.length == 0 && companyNameErr && (
                  <p className="text-danger m-0">Select Company</p>
                )}  
                              </div>
                              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  Branch Name
                </label>

                <select
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  onChange={(e) => {
                    // setSUbmitDatas((prev) => ({...prev,branchName: e.target.value}))
                    branchSelecter(e.target.value);
                  }}
                  value={branch}
                  disabled={type=='view'||type=="edit"?true:false}
                  // disabled={type == "edit" ? true : false}
                >
                  <option hidden>Branch Name</option>
                  {branchList?.map((item, ind) => {
                    return (
                      <option value={item?.id} key={ind}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                {branch?.length == 0 && branchErr && (
                  <p className="text-danger m-0">Select Branch</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative ">
                <label className="mb-2" for="">
                  Branch Location
                </label>
                <input
                disabled={type == "view" ? true : false}
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  readOnly
                  value={branchData?.location}
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  {" "}
                  Branch Pincode
                </label>
                <input
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  readOnly
                  value={branchData?.pincode}
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" for="">
                  {/* Robot Order ID */}
                  Robot Type
                </label>
                <select
                  className="form-control mb-1"
                  name="company_branch_id"
                  id=""
                  value={robotIdChe}
                  onChange={(e) => {
                    selectRobotOrder(e.target.value);
                  }}
                  disabled={type == "edit"||type == "view" ? true : false}
                >
                  <option hidden>Select Robot Order ID</option>
                  {robotList?.map((item, ind) => {
                    return (
                      <option value={item?.robot_type?._id} key={ind}>
                        {item?.robot_type?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              </div>
              </div>
              </div>
    
    

      <div>
        <button className="border-0 ad-btn mt-4 ms-0" onClick={addInput}>
          Add
        </button>

        {inputs.map((item) => (
          <div className="d-flex justify-content-between mt-4 w-100 tabbs" key={item.id}>
            <div style={{ width: "30%" }}>
              <h4 className="head-text">Question</h4>
              <input
                style={{ padding: "5px 25px 5px 4px", width: "100%" }}
                placeholder="For what Purpose You Came For?"
                name="question"
                value={item.question}
                onChange={(e) => handleInputChange(item.id, e)}
              />
            </div>

            <div style={{ width: "30%" }}>
              <h4 className="head-text">Key Words</h4>
              {item.keyword.map((keyword, keywordIndex) => (
                <div key={keywordIndex} style={{ marginBottom: "10px", position: "relative" }}>
                  {/* <label>{`Keyword${keywordIndex === 0 ? '' : keywordIndex}`}</label> */}
                  <input
                    style={{ padding: "5px 25px 5px 4px", width: "100%" }}
                    placeholder="Enter Keyword"
                    value={keyword}
                    onChange={(e) => handleKeywordChange(item.id, keywordIndex, e)}
                  />
                  <div 
                    onClick={() => handleKeywordClose(item.id, keywordIndex)} 
                    style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }}
                  >
                    <CloseIcon />
                  </div>
                </div>
              ))}
              <button
                className="border-0 ad-btn ms-0 mt-2"
                onClick={() => addKeywordField(item.id)}
              >
                Add Keyword
              </button>
            </div>

            <div style={{ width: "30%" }}>
              <h4 className="head-text">Answer</h4>
              <textarea
                style={{ padding: "5px 25px 5px 4px", width: "100%" }}
                placeholder="Enter Your Answer"
                name="answer"
                value={item.answer}
                onChange={(e) => handleInputChange(item.id, e)}
              />
            </div>

            <div onClick={() => handleClose(item.id)}>
              <CloseIcon />
            </div>
          </div>
        ))}
          <div className="d-flex justify-content-end">
        {type!='view'&&<div className="px-md-3 pe-3">
                {type === "edit"? (
                  <button
                    className="border-0 ad-btn mt-4 ms-0"
                    disabled={submitBtn ? true : false}
                    onClick={() => handleSubmit()}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="border-0 ad-btn mt-4 ms-0"
                    disabled={submitBtn ? true : false}
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                )}
              </div>}
      </div>
      </div>
      </div>
    </div>
    
  );
};

export default Hispeech;
