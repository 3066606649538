import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAdmin_addMutation,
  useAdmin_updateMutation,
  useAdmin_viewMutation,
  useCompanyUserCreateBranchMutation,
  useCompanyUserEditBranchMutation,
  useLazyCompanyUserViewBranchQuery,
  useRole_listMutation,
} from "../constants/redux/api/api";
import { validation as regexValid } from "../constants/validation";
import { toast } from "react-toastify";
import TopHeader from "../components/TopHeader";
import { loadergif } from "../assets/img";
const AddUsers = ({ menuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const editData = location?.state?.data;
  const itemData = location?.state?.item;
  const [validation, setValidation] = useState({
    name: "",
    email: "",
    mobile: null,
    password: "",
    confirmPassword: "",
    role: "",
  });
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [branch, setBranch] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [branchErr, setBranchErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [match, setMatch] = useState(false);
  const [emailRegexerr, setEmailRegexErr] = useState(false);
  const [passMinErr, setPassMinErr] = useState(false);

  //   RTK QUERY
  const [admin_add] = useCompanyUserCreateBranchMutation();
  const [role_list] = useRole_listMutation();
  const [admin_update] = useCompanyUserEditBranchMutation();
  const [userView] = useLazyCompanyUserViewBranchQuery();

  const getView = () => {
    userView(editData?._id)
      .unwrap()
      .then((res) => {
        console.log("editData", res);

        let data = res?.data;
        setName(data?.user_name);
        setPhone(data?.contactnumber);
        setEmail(data?.email);
        setRole(data?.role_id);
        setBranch(data?.company_branch_info?._id);
      })
      .catch((err) => {
        console.log("err");
      });
  };

  //   Local Storage variable

  const onSubmit = (e) => {
    if (
      name?.length == 0 ||
      email?.length == 0 ||
      phone?.length < 10 ||
      branch?.length == 0 ||
      (type !== "edit" && password?.length == 0) ||
      (type !== "edit" && confirmPassword?.length == 0) 
      // role?.length == 0
    ) {
      setNameErr(true);
      setEmailErr(true);
      setPhoneErr(true);
      // setRoleErr(true);
      setBranchErr(true);
      if (type !== "edit") {
        setPasswordErr(true);
        setConfirmPasswordErr(true);
      }
    } else {
      setLoading(true)
      var urlencoded = new URLSearchParams();

      urlencoded.append("company_id", itemData?._id);
      urlencoded.append("company_branch_id", branch);
      urlencoded.append("email", email);
      urlencoded.append("contactnumber", phone);
      // urlencoded.append("role_id", role);
      // urlencoded.append("role_id", role);

      if (password) {
        urlencoded.append("password", password);
        urlencoded.append("confirm_password", confirmPassword);
      }

      setDisableBtn(true);
      if (type === "edit") {
        setLoading(true)
        let id = editData?._id;
        delete validation[`password`];
        delete validation[`confirm_password`];
        urlencoded.append("name", name);
        admin_update({ id: id, payload: urlencoded })
          .unwrap()
          .then((res) => {
            setLoading(false)
            setDisableBtn(false);
            if (res?.status === "success") {
              navigate(-1);
              toast.success(res?.message);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false)
            setDisableBtn(false);
          });
      } else {
        setLoading(true)
        urlencoded.append("user_name", name);
        admin_add(urlencoded)
          .unwrap()
          .then((res) => {
            setLoading(false)
            setDisableBtn(false);
            if (res?.status === "success") {
              navigate(-1);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false)
            setDisableBtn(false);
          });
      }
    }
  };

  useEffect(() => {
    if (editData?._id&&(type === "edit" ||type==="view")) {
      getView();
    }
  }, [type, editData]);

  const RoleList = async () => {
    role_list()
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          let temp = res?.data?.filter((i) => i?.status == true);
          setRoleList(temp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // role list
  useEffect(() => {
    RoleList();
  }, []);

  console.log("itemData", itemData);

  return (
    <div
      className={menuOpen ? "side-content2" : "side-content"}
      style={{ transition: "0.5s" }}
    >
      <TopHeader
        type={type === "edit" ? "admin-edit-user" : "admin-add-user"}
      />
      <div className="menu-list">
        <h1>{type==="view"?"View Company Branch Admin":(type === "edit" ? "Edit Company Branch Admin" : "Add Company Branch Admin")}</h1>
      </div>
      <div className="side_pad">
        <div className="add-food-section">
          <div action="">
            <div className="d-flex flex-wrap">
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Company Name
                </label>
                <input
                // disabled={type=="view"?true:false}
                  value={itemData?.companyname}
                  name="name"
                  disabled
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Branch
                  <span className="text-danger">*</span>
                </label>
                <select
                disabled={type=="view"?true:false}
                  className="form-control"
                  name="role"
                  id=""
                  onChange={(e) => setBranch(e.target.value)}
                  value={branch}
                >
                  <option value="">Select Branch</option>
                  {itemData?.branches?.map((data, ind) => {
                    return (
                      <option key={ind} value={data?.id}>
                        {data?.name}
                      </option>
                    );
                  })}
                </select>
                {branch?.length == 0 && branchErr && (
                  <p className="err-state">Select Branch</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Name
                  <span className="text-danger">*</span>
                </label>
                <input
                disabled={type=="view"?true:false}
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter User name"
                />
                {name?.length == 0 && nameErr && (
                  <p className="err-state">Enter User name.</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Email
                  <span className="text-danger">*</span>
                </label>
                <input
                disabled={type=="view"?true:false}
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter Email name"
                />
                {email === "" && emailErr && (
                  <p className="err-state">Enter Email name.</p>
                )}
                {emailRegexerr && email !== "" && (
                  <p className="err-state">Please Enter valid email id.</p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Phone No
                  <span className="text-danger">*</span>
                </label>
                <input
                disabled={type=="view"?true:false}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 10) {
                      e.target.setCustomValidity("invalid Number");
                    } else if (e.target.value.length == 10) {
                      e.target.setCustomValidity("");

                      setPhone(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxLength={10}
                  name="mobile"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  type="number"
                  className="form-control"
                  placeholder="Enter Phone No"
                  value={phone}
                />
                {phone === "" && phoneErr && (
                  <p className="err-state">Enter Phone No.</p>
                )}
              </div>
              {/* <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  User Role
                  <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name=""
                  id=""
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                >
                  <option value="" selected hidden>
                    Select Role
                  </option>
                  <option value="2">Waiter</option>
                  <option value="1">Kitchen </option>
                  {/* <option value="1">Admin</option> */}
                {/* </select>

                {role === "" && roleErr && (
                  <p className="err-state">Select Role</p>
                )}
              </div> */} 
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Password
                  <span className="text-danger">*</span>
                </label>
                <input
                 disabled={type=="view"?true:false}
                  value={password}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                />
                {password === "" && passwordErr && (
                  <p className="err-state">Enter Password.</p>
                )}
                {password !== "" && passMinErr && (
                  <p className="err-state">
                    Password minimum 6 digit required.
                  </p>
                )}
              </div>
              <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                <label className="mb-2" htmlFor="">
                  Confirm Password
                  <span className="text-danger">*</span>
                </label>
                <input
                  disabled={type=="view"?true:false}
                  value={confirmPassword}
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                />
                {confirmPassword === "" && confirmPasswordErr && (
                  <p className="err-state">Enter Confirm Password</p>
                )}
                {confirmPassword?.length > 0 &&
                  password !== confirmPassword && (
                    <p className="err-state">
                      Confirm Password does not match.
                    </p>
                  )}
              </div>
              {type!=="view"&&<div className="col-md-6 col-12 px-md-0 px-0  d-flex  position-relative mb-3">
                <div
                  onClick={disableBtn ? null : onSubmit}
                  className="px-md-3 pe-3"
                >
                  {type === "edit" ? (
                    <button className="btn-submit">Update</button>
                  ) : (
                    <input className="btn-submit" type="submit" />
                  )}
                </div>
                <div onClick={() => navigate(-1)} className="btn-outline">
                  <p style={{ margin: 0 }}>Back</p>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUsers;
